import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Flex, Spinner, Stack, Text, useToast, VStack } from '@chakra-ui/react';
import actions from '@actions';
import { analytics, showErrorToast } from '@utils';
import CourseListItem from './components/CourseListItem';
import { zipWhiteBG, cardMaxWidth } from '../constants';
import ProfileContext from '../../Context/ProfileContext';

const Courses = () => {
  // State
  const [courses, setCourses] = useState(null);
  const [loading, setLoading] = useState(false);

  // Context
  const profileContext = useContext(ProfileContext);

  // Hooks
  const toast = useToast();

  // Actions
  const fetchCohortsAction = async () => {
    try {
      setLoading(true);
      const result = await actions.fetchCohorts();
      setCourses(result.items);
      setLoading(false);
    } catch (error) {
      showErrorToast(toast, error.message);
      setLoading(false);
    }
  };

  const getStudentName = () => {
    if (profileContext.data.children && profileContext.data.children.length > 0) {
      return profileContext.data.children[0].first_name;
    }

    return 'Your Child';
  };

  // Render Methods
  const renderNoItemsView = () => (
    <Flex h="100%" w="100%" maxW={cardMaxWidth} justifyContent="center" alignItems="center">
      <VStack bg="white" rounded="lg" p={5} shadow="lg">
        <Text color="#383838" fontSize={['xl', '2xl']} fontWeight="bold" textAlign="center">
          🔢 Signup Now
        </Text>
        <Text
          color="gray.400"
          fontWeight="bold"
          fontSize={['sm', 'md']}
          textAlign="center"
          pt={[1, 3]}
        >
          {`You haven't signed ${getStudentName()} up for a math course yet! Click the button below to to get started.`}
        </Text>
        <Button
          w="100%"
          h={['40px', '45px', '50px', '55px']}
          colorScheme="yellow"
          rounded="full"
          boxShadow="md"
          fontSize={['md', 'lg', 'xl', 'xl']}
          fontWeight="bold"
          bg="#ffc60b"
          backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                            rgba(255, 255, 255, 0) 93.72%)"
          style={{
            backgroundBlendMode: 'soft-light, normal',
          }}
          onClick={() => {
            window.open('https://zipschool.typeform.com/to/gViOXpJ1');
            analytics.t('courses: clicked view courses CTA');
          }}
        >
          <Text>Let's go!</Text>
        </Button>
      </VStack>
    </Flex>
  );

  // UseEffect
  useEffect(() => {
    fetchCohortsAction();
  }, []);

  return (
    <Box bg={zipWhiteBG} w="100%" minH="100%" overflowY="auto" p={[3, 5]}>
      <Box pb={3}>
        <Text fontWeight="bold" fontSize="xl">
          {`${getStudentName()}'s Dashboard`}
        </Text>
        <Text color="#9D9D9D">{`This is where ${getStudentName()} will see their courses!`}</Text>
      </Box>
      <Stack w="100%" h="100%" spacing={4} pb={['50px', '100px']} alignItems="center">
        {loading && (
          <Flex h="100%" justifyContent="center" alignItems="center">
            <Spinner fontSize={['2xl', '2xl', '2xl', '3xl']} mt={4} color="purple.500" />
          </Flex>
        )}
        {!loading &&
          courses &&
          courses.length > 0 &&
          courses.map(course => <CourseListItem key={course.id} course={course} />)}
        {!loading && courses && courses.length === 0 && renderNoItemsView()}
      </Stack>
    </Box>
  );
};

export default Courses;
