/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Flex, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { analytics, showErrorToast } from '@utils';
import actions from '@actions';
import { onboardingSteps } from '../constants';
import LoginOrSignup from './components/LoginOrSignup';
import AddChildForm from './components/AddChildForm';
import PetSelect from './components/PetSelect';
import CredsForm from './components/CredsForm';
import { loadStripe } from '@stripe/stripe-js';

const CoursesSignup = ({ fetchAuthState, currentStep, setCurrentStep }) => {
  // State
  const [childData, setChildData] = useState(null);
  const [selectedPet, setSelectedPet] = useState(null);
  const [loading, setLoading] = useState(false);

  // Hooks
  const form = useForm();
  const history = useHistory();
  const toast = useToast();

  const redirectToStripe = async sessionId => {
    const stripeClient = await loadStripe(process.env.REACT_APP_STRIPE_PK);
    stripeClient.redirectToCheckout({ sessionId });
  };

  // Actions
  const onNext = async data => {
    if (currentStep === onboardingSteps.ADD_CHILD) {
      const childAge = data.child_age;

      setChildData(prevState => {
        return { ...prevState, child_first_name: data.child_first_name, child_age: childAge };
      });
      setCurrentStep(onboardingSteps.CREDS);

      analytics.t('courses: clicked continue on add child form', { age: childAge });
    } else if (currentStep === onboardingSteps.PET_SELECT) {
      setCurrentStep(onboardingSteps.CREDS);

      analytics.t('courses: clicked continue on pet select', {
        selectedPet: selectedPet.title,
      });
    } else if (currentStep === onboardingSteps.CREDS) {
      analytics.t('courses: clicked create account');
      const queryParams = new URLSearchParams(window.location.search);
      const cohortId = queryParams.get('cohort_id');
      setLoading(true);
      try {
        const req = {
          first_name: data.first_name,
          last_name: data.last_name,
          child_first_name: childData.child_first_name,
          child_age: childData.child_age,
          phone: data.phone,
          country_code: data.country_code,
          email: data.email,
          password: data.password,
        };

        if (cohortId) {
          // Create Profile
          await actions.createProfile(req);

          // Redeem pet
          if (selectedPet) {
            await actions.updateRedemption('redeem', { redeemable_id: selectedPet.id });
          }

          // Create booking
          const { stripe_session_id } = await actions.createCohortBooking(cohortId);
          if (stripe_session_id) {
            await redirectToStripe(stripe_session_id);
          } else {
            // Get authState
            await fetchAuthState();
            history.push(`/courses/${cohortId}?enrolled=success`, { cohortId });
          }
        } else {
          setLoading(false);
          showErrorToast(
            toast,
            '😔 Oh no! Please copy the link in your address bar and send to ZipSchool Support',
          );
          analytics.t('courses: error on sign-up', { message: 'No cohortId found', cohortId });
        }
      } catch (err) {
        setLoading(false);
        showErrorToast(toast, err.message);
        analytics.t('courses: error on sign-up', { message: err.message, cohortId });
      }
    }
  };

  // Render Methods
  const renderCTA = () => {
    let title;
    if (currentStep === onboardingSteps.ADD_CHILD) {
      title = `🏎 Ready to go`;
    } else if (currentStep === onboardingSteps.PET_SELECT) {
      title = `🐣 Thats the one`;
    } else if (currentStep === onboardingSteps.CREDS) {
      // Here check for a free course vs paid course
      title = `🪄 Enroll now`;
    }

    return (
      <Button
        w="100%"
        h={['50px', '60px']}
        bg="rgb(255, 197, 3)"
        colorScheme="yellow"
        fontSize="lg"
        rounded="full"
        mt={2}
        isLoading={loading}
        onClick={form.handleSubmit(onNext)}
      >
        {title}
      </Button>
    );
  };

  return (
    <Flex
      flexDir="column"
      color="black"
      w={['100%', 'md', 'md', 'lg']}
      minH="sm"
      bg="white"
      p={4}
      mb={currentStep === onboardingSteps.CREDS ? '50px' : 4}
      rounded="lg"
      mx="auto"
      justifyContent="space-around"
    >
      {/* Login or Signup */}
      {currentStep === onboardingSteps.LOGIN_OR_SIGNUP && (
        <LoginOrSignup setCurrentStep={setCurrentStep} />
      )}

      {/* Add Child */}
      {currentStep === onboardingSteps.ADD_CHILD && <AddChildForm form={form} />}

      {/* Pet Select */}
      {currentStep === onboardingSteps.PET_SELECT && (
        <PetSelect selectedPet={selectedPet} setSelectedPet={setSelectedPet} />
      )}

      {/* Pet Select */}
      {currentStep === onboardingSteps.CREDS && <CredsForm form={form} />}

      {currentStep !== onboardingSteps.LOGIN_OR_SIGNUP && renderCTA()}
    </Flex>
  );
};

export default CoursesSignup;
