/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from 'react';
import '../App.css';
import { Text, Stack, Box, Button, useToast, Spinner, VStack } from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import { showErrorToast, analytics, isiOSNativeDevice, navigateTo } from '@utils';
import actions from '@actions';
import ClassListItem from '@components/ClassListItem';

const ZipClass = () => {
  // Properties
  const { class_id } = useParams();

  // State
  const [metadata, setMetadata] = useState(null);
  const [slots, setSlots] = useState(null);

  const [isLoading, setLoading] = useState(true);

  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    if (metadata && metadata.title) {
      analytics.t('hit noauth class specific page', { title: metadata.title });
    }
  }, [metadata]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchClass(class_id);
    };

    fetchData();
  }, []);

  const fetchClass = async () => {
    setLoading(true);
    try {
      const { metadata: m = {}, slots: s = [] } = await actions.fetchNoAuthClass(class_id);
      m.class_id = m.id;
      setMetadata(m);
      setSlots(s);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const renderDisclaimers = () => (
    <Box rounded="lg" p={3} w="100%">
      <Stack color="white" fontWeight="bold">
        <Text>✅ This class is 100% free.</Text>
        <Text>✅ Taught by a top teacher.</Text>
        <Text>✅ Engaging, educational, entertaining</Text>
      </Stack>
    </Box>
  );

  const handleNext = type => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryEmail = urlParams.get('email');
    const decodedEmail = queryEmail ? decodeURIComponent(queryEmail) : '';
    let path;
    if (metadata && metadata.title && type) {
      analytics.t(`clicked ${type} on noauth class page`, { title: metadata.title });
    }

    if (type === 'signup') {
      path = `/signup?&class_id=${class_id}`;
      if (decodedEmail) {
        path += `&email=${decodedEmail}`;
      }
    } else if (type === 'login') {
      path = `/login?redirectUri=${encodeURIComponent(`/live/${class_id}`)}`;
    } else {
      return;
    }

    navigateTo(history, path);
  };

  const renderNextButtons = () => (
    <Stack w="100%">
      <Button
        onClick={() => handleNext('signup')}
        w="100%"
        h="50px"
        fontWeight="bold"
        fontSize="lg"
        color="black"
        bg="#FFC503"
      >
        🚀 Sign Up And Watch Now
      </Button>
      <Button
        onClick={() => handleNext('login')}
        w="100%"
        h="50px"
        fontWeight="bold"
        fontSize="lg"
        color="white"
        colorScheme="green"
        style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
      >
        ⭐️ Log In And Watch Now
      </Button>
    </Stack>
  );

  return (
    <Box bg="purple.500" pb="50px">
      <Box maxW="md" mx="auto" mt={[0, 5]} px={5}>
        {isLoading && (
          <Box my={['20%', '10%']} d="flex" justifyContent="center">
            <VStack>
              <Spinner fontSize={['2xl', '2xl', '2xl', '3xl']} mt={4} color="white" />
            </VStack>
          </Box>
        )}
        {!isLoading && (
          <Box justifyContent="center">
            <VStack spacing={5}>
              {renderDisclaimers()}
              <ClassListItem
                channelTitle="All Classes"
                item={metadata}
                hideStartingSoon
                mode="singleclass"
              />
              {renderNextButtons()}
            </VStack>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ZipClass;
