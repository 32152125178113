/* eslint-disable react/prop-types */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AUTH_STATES } from '@constants';
import Courses from './Courses';
import CourseDetail from './components/CourseDetail';
import CoursesSignup from '../CoursesSignup';

// NOTE: These IDs are going to be hardcoded for the time being
/**
 * Number Sense: Skip Counting, Quantities, Finding Missing Numbers & Logical Thinking: CO39a19537-f338-47de-944c-c15db6d6f5e3 (July 25th)
 *
 * Early Algebra: Addition and Subtraction to 50 With Algebraic Thinking Logical Problem Solving: COb66f6155-6259-417e-8107-272f927c0e67 (July 18th)
 *
 * Fact Families & Strategies to Add/Subtract Up to 20: COc2cd5b9d-6a8f-4f03-9d1c-d9200a68a9ed (July 18th)
 *
 * Adding & Subtracting with Regrouping & Number Sense to 1000: COd8b29b1a-8c46-45b7-9133-48715340817d (July 27th)
 */

const CoursesContainer = ({ fetchAuthState, authState }) => {
  // Action
  const getCourse = () => {
    const GRADE_LEVELS = {
      PK: 'preschooler',
      K: 'kindergartener',
      FIRST: '1st grader',
      SECOND: '2nd grader',
      THIRD: '3rd grader',
    };

    const COURSES = {
      NUMBER_SENSE: 'CO39a19537-f338-47de-944c-c15db6d6f5e3',
      EARLY_ALGEBRA: 'COb66f6155-6259-417e-8107-272f927c0e67',
      FACT_FAMILIES: 'COc2cd5b9d-6a8f-4f03-9d1c-d9200a68a9ed',
      ADDING_SUBTRACTING_REGROUPING: 'COd8b29b1a-8c46-45b7-9133-48715340817d',
    };

    const DESCRIPTIONS = {
      CHALLENGED:
        "My child is struggling with math at school and I'm looking for something to get them back on track.",
      ON_TRACK:
        "My child is doing just fine and I want them to practice concepts they're currently working with with and gain confidence.",
      AHEAD:
        "My child is easily doing the math at their grade level and I'd like them to get ahead with more challenging material.",
    };

    // Get query params - not using grade just quite yet
    const urlParams = new URLSearchParams(window.location.search);
    const gradeParam = urlParams.get('grade');
    const childDescriptionParam = urlParams.get('child_description');

    if (gradeParam && childDescriptionParam) {
      const replacedDescription = childDescriptionParam.replaceAll('*', '');

      // PreK
      if (gradeParam === GRADE_LEVELS.PK) {
        return `/courses/${COURSES.NUMBER_SENSE}`;
      }

      // Kinder && First
      if (gradeParam === GRADE_LEVELS.K || gradeParam === GRADE_LEVELS.FIRST) {
        if (replacedDescription === DESCRIPTIONS.CHALLENGED) {
          return `/courses/${COURSES.NUMBER_SENSE}`;
        }
        if (replacedDescription === DESCRIPTIONS.ON_TRACK) {
          return `/courses/${COURSES.FACT_FAMILIES}`;
        }
        if (replacedDescription === DESCRIPTIONS.AHEAD) {
          return `/courses/${COURSES.EARLY_ALGEBRA}`;
        }
      }

      // Second Grade
      if (gradeParam === GRADE_LEVELS.SECOND) {
        if (replacedDescription === DESCRIPTIONS.CHALLENGED) {
          return `/courses/${COURSES.FACT_FAMILIES}`;
        }
        if (replacedDescription === DESCRIPTIONS.ON_TRACK) {
          return `/courses/${COURSES.ADDING_SUBTRACTING_REGROUPING}`;
        }
        if (replacedDescription === DESCRIPTIONS.AHEAD) {
          return `/courses/${COURSES.EARLY_ALGEBRA}`;
        }
      }

      // Third Grade
      if (gradeParam === GRADE_LEVELS.THIRD) {
        if (replacedDescription === DESCRIPTIONS.CHALLENGED) {
          return `/courses/${COURSES.ADDING_SUBTRACTING_REGROUPING}`;
        }
        if (replacedDescription === DESCRIPTIONS.ON_TRACK) {
          return `/courses/${COURSES.EARLY_ALGEBRA}`;
        }
        if (replacedDescription === DESCRIPTIONS.AHEAD) {
          return `/courses/${COURSES.EARLY_ALGEBRA}`;
        }
      }
    }

    // Default to early algebra
    return `/courses/${COURSES.EARLY_ALGEBRA}`;
  };

  return (
    <Switch>
      {authState === AUTH_STATES.NEEDS_AUTH && (
        <Switch>
          <Route
            exact
            path="/courses/signup"
            render={() => <CoursesSignup fetchAuthState={fetchAuthState} authState={authState} />}
          />
          <Redirect from="/courses/redirect" to={getCourse()} />
          <Route
            exact
            path="/courses/:course_id"
            render={() => <CourseDetail authState={authState} />}
          />
          <Redirect exact to="/login" />
        </Switch>
      )}

      {authState === AUTH_STATES.AUTHENTICATED && (
        <Switch>
          <Route exact path="/courses" render={() => <Courses authState={authState} />} />
          <Redirect exact from="/courses/signup" to="/courses" />

          <Route
            exact
            path="/courses/:course_id"
            render={() => <CourseDetail authState={authState} />}
          />
        </Switch>
      )}
    </Switch>
  );
};

export default CoursesContainer;
