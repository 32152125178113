import amplitude from 'amplitude-js';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import { detect } from 'detect-browser';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

const TIME_ZONES = {
  EST: 'Eastern',
  CST: 'Central',
  MST: 'Mountain',
  PST: 'Pacific',
  EDT: 'Eastern',
  CDT: 'Central',
  MDT: 'Mountain',
  PDT: 'Pacific',
};

const startOfNextWeek = () => {
  return dayjs().day(8).set('hour', 0).set('minute', 0).valueOf();
};

const showErrorToast = (toast, message) => {
  toast({
    title: 'Woops!',
    description: message,
    status: 'error',
    duration: 5000,
    isClosable: true,
  });
};

const showSuccessToast = (toast, message) => {
  toast({
    title: 'Success!',
    description: message,
    status: 'success',
    duration: 5000,
    isClosable: true,
  });
};

const convertTZ = (timestamp, options = {}) => {
  const { mode, dayMode, showTimeZone, now } = options;
  const tz = dayjs.tz.guess();
  if (mode === 'countdown') {
    const classTime = new Date(timestamp);
    const difference = classTime - new Date(now);
    let countdownStr;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    if (days === 0 && hours <= 24) {
      countdownStr = `Starts in${hours > 0 ? ` ${hours}h` : ''}${
        minutes > 0 || hours > 0 ? ` ${minutes}m` : ''
      } ${seconds}s`;
    } else {
      countdownStr = `Starts ${now ? dayjs(timestamp).from(now) : dayjs(timestamp).fromNow()}!`;
    }

    return countdownStr;
  }

  if (mode === 'countdown_verbose') {
    const timestampDate = dayjs(timestamp).utc();
    const nowDate = dayjs(now).utc();
    const difference = timestampDate - nowDate;
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  let [date, timeZoneCode] = dayjs(timestamp)
    // .tz(tz)
    .utc()
    .format(`${dayMode === 'full' ? 'dddd, ' : dayMode === 'none' ? '' : 'ddd, '}MMM D - h:mmA#z`)
    .split('#');

  if (mode === 'date') return date.split(' - ').shift();
  if (mode === 'date-short') return date.split(' - ').shift().split(',').pop().trim();
  if (mode === 'day') return date.split(',').shift();
  if (mode === 'timezone') return TIME_ZONES[timeZoneCode] || timeZoneCode;
  if (mode === 'hour')
    return dayjs(timestamp).tz(tz).hour() + dayjs(timestamp).tz(tz).minute() / 60;
  if (mode === 'time') date = date.split(' - ').pop();

  if (showTimeZone)
    return TIME_ZONES[timeZoneCode]
      ? `${date} ${TIME_ZONES[timeZoneCode]}`
      : `${date} ${timeZoneCode}`;

  return date;
};

const currentDate = () => {
  return dayjs().tz(dayjs.tz.guess());
};

const currentTz = () => {
  return dayjs.tz.guess();
};

/**
 * Checks to see if the timestamp given is before the current date in regards to minutes
 * @param {string} timestamp
 */
const isBeforeClassTime = timestamp => {
  // Get current time and if it is less than class time, return true
  const today = dayjs();
  return today.diff(timestamp, 'minute') < 0;
};

/**
 * Checks to see if the timestamp given is before the current date in regartds to days
 * @param {string} timestamp
 */
const isDaysBeforeClassTime = timestamp => {
  // Get current time and if it is less than class time, return true
  const today = dayjs();
  const classTime = dayjs(timestamp);

  return classTime.diff(today, 'd') > 0;
};

const getTimeOfDayGreeting = () => {
  const today = new Date();
  const currentHour = today.getHours();

  if (currentHour < 12) {
    return 'Good Morning';
  }

  if (currentHour < 18) {
    return 'Good Afternoon';
  }

  return 'Good Evening';
};

/**
 * Checks to see if the current device is an iPad
 * For iPads running iPadOS 13.x it comes up as macOS UA this is why we have this second check here
 */
const isIpad = () => {
  return (
    /iPad/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );
};

/**
 * Checks to see if browser is currently running on iOS device
 */
const isiOSNativeDevice = () => {
  const browser = detect();

  return browser && browser.name && browser.name.toLowerCase() === 'ios-webview';
};

/**
 * Checks to see if browser is currently running on iOS device
 */
const isiOSDevice = () => {
  const browser = detect();

  return (browser && browser.os.toLowerCase() === 'ios') || isIpad();
};

/**
 * Checks native functions for Safari to see if the user is on Safari browser
 */
const isSafari = () => {
  const browser = detect();

  return (
    (browser && browser.name && browser.name.toLowerCase() === 'safari') ||
    (browser && browser.name && browser.name.toLowerCase() === 'ios')
  );
};

const isUnsupportedSafari14 = () => {
  const browser = detect();
  const versionSplit = browser.version.split('.');
  const majorVersion = parseInt(versionSplit[0], 10);
  const minorVersion = parseInt(versionSplit[1], 10);
  const patchVersion = parseInt(versionSplit[2], 10);

  return isSafari() && majorVersion === 14 && minorVersion === 0 && patchVersion < 2;
};

/**
 * Checks to see if the current browser is mobile chrome on iOS
 */
const isMobileChrome = () => {
  const browser = detect();

  if (browser) {
    const browserName = browser.name ? browser.name.toLowerCase() : '';
    return isiOSDevice() && browserName === 'crios';
  }

  return false;
};

/**
 * Checks to see if the current browser is mobile Safari
 */
const isMobileSafari = () => {
  const browser = detect();

  if (browser) {
    const browserName = browser.name ? browser.name.toLowerCase() : '';
    return (isiOSDevice() && (browserName === 'ios' || browserName === 'safari')) || isIpad();
  }

  return false;
};

/**
 * Checks to see if the current browser has granted the camera permission given a mediaStream
 * @param {object} mediaStream
 */
const isCameraPermissionGranted = mediaStream => {
  return mediaStream.getVideoTracks().length;
};

/**
 * Checks to see if the current browser has granted the microphone permission given a mediaStream
 * @param {object} mediaStream
 */
const isMicPermissionGranted = mediaStream => {
  return mediaStream.getAudioTracks().length;
};

/**
 * Checks to see if the current browser is on a mobile device
 */
const isMobileDevice = () => {
  const { userAgent } = navigator;
  return (
    /Android|Android OS|silk|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    ) ||
    isiOSDevice() ||
    isMobileSafari() ||
    isMobileChrome()
  );
};

/**
 * Gets the median value of an array of numbers
 * Resource: https://gist.github.com/carolineartz/ae3f1021bb41de2b1935
 * @param {Array<number>} values
 */
const median = values => {
  const sortredArray = sortBy(values);
  if (sortredArray.length % 2 === 0) {
    return (sortredArray[sortredArray.length / 2] + sortredArray[sortredArray.length / 2 - 1]) / 2;
  }
  return sortredArray[(sortredArray.length - 1) / 2];
};

const shuffle = array => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

const showIntercom = () => {
  if (window.$crisp) window.$crisp.push(['do', 'chat:toggle']);
};

const showCrispLauncher = () => {
  if (window.$crisp) window.$crisp.push(['do', 'chat:show']);
};

const hideCrispLauncher = () => {
  if (window.$crisp) window.$crisp.push(['do', 'chat:hide']);
};

const setupIntercom = params => {
  if (window.$crisp && params) {
    const { email, hmac } = params;
    window.$crisp.push(['set', 'user:email', [email, hmac]]);
  }
};

const signOutIntercom = () => {
  if (window.Intercom) window.Intercom('shutdown');
};

const prod = process.env.NODE_ENV === 'production';
console.log(process.env.NODE_ENV);
const initAnalytics = false;
const analytics = {
  setUserId: userId => {
    if (prod) {
      amplitude.setUserId(userId);
    }
  },
  t: (name, props) => {
    if (!initAnalytics) {
      amplitude.getInstance().init('04e4db3885219185a84a024c8407302f', null, {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true,
      });
    }

    const browser = detect();
    const device = localStorage.getItem('zipschool_device');

    const updatedProps = {
      ...props,
      browser: browser && browser.name ? browser.name.toLowerCase() : 'unknown',
      device,
    };

    if (prod) {
      amplitude.getInstance().logEvent(name, updatedProps);
    }

    console.log(name, updatedProps);
  },
};

const confirmDialog = msg => {
  return new Promise(function (resolve, reject) {
    const confirmed = window.confirm(msg);

    return confirmed ? resolve(true) : reject(false);
  });
};

const navigateTo = (history, path, params = {}) => {
  if (isiOSNativeDevice()) {
    const device = localStorage.getItem('zipschool_device');
    let url = `${window.location.protocol}//${window.location.hostname}${path}`;

    if (device) {
      const urlObject = new URL(url);
      const params = urlObject.searchParams;
      if (!params.get('device')) {
        params.append('device', device);
      }
      url = urlObject.toString();
    }
    window.location = url;
  } else if (history) {
    const { type } = params;
    if (type === 'back') {
      history.goBack();
    } else {
      history.push(path);
    }
  }
};

const sendDataToNative = (data = {}) => {
  // This function will send the name to native.
  // We can add an EventListener to an object on the page to call this function when it is clicked.
  if (isiOSNativeDevice()) {
    try {
      window.webkit.messageHandlers.callback.postMessage(JSON.stringify(data));
    } catch (err) {
      console.warn('Can not reach native code');
    }
  }
};

const lockLandscapeNative = () => {
  // Lock to landscape
  sendDataToNative({
    action: 'orientationlock',
    data: { orientation: 'landscape' },
  });

  // Hide navigation bar
  sendDataToNative({
    action: 'hidenavbar',
    data: { hidden: true },
  });
};

const lockPortraitNative = () => {
  // Lock to landscape
  sendDataToNative({
    action: 'orientationlock',
    data: { orientation: 'portrait' },
  });

  // Hide navigation bar
  sendDataToNative({
    action: 'hidenavbar',
    data: { hidden: true },
  });
};

const nativeActionTransaction = (hidden, orientation) => {
  // Lock to landscape
  sendDataToNative({
    action: 'orientationlock',
    data: { orientation },
  });

  // Hide navigation bar
  sendDataToNative({
    action: 'hidenavbar',
    data: { hidden: true },
  });

  // Hide tab bar
  sendDataToNative({
    action: 'hidetabbar',
    data: { hidden: true },
  });
};

// Convert Payload from Base64-URL to JSON
const decodePayload = payload => {
  const cleanedPayload = payload.replace(/-/g, '+').replace(/_/g, '/');
  const decodedPayload = atob(cleanedPayload);
  const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
    const uriEncodedChar = `00${char.charCodeAt(0).toString(16)}`.slice(-2);
    return `${acc}%${uriEncodedChar}`;
  }, '');
  const jsonPayload = decodeURIComponent(uriEncodedPayload);

  return JSON.parse(jsonPayload);
};

// Parse JWT Payload
const parseJWTPayload = token => {
  const [, payload] = token.split('.');
  const jsonPayload = decodePayload(payload);

  return jsonPayload;
};

const COLORS = [
  { value: 'red.300', title: 'Red' },
  { value: 'orange.300', title: 'Orange' },
  { value: 'yellow.300', title: 'Yellow' },
  { value: 'green.300', title: 'Green' },
  { value: 'teal.300', title: 'Teal' },
  { value: 'blue.300', title: 'Blue' },
  { value: 'cyan.300', title: 'Cyan' },
  { value: 'pink.300', title: 'Pink' },
];

const EMOJIS = [
  { value: '🦋', title: 'butterfly' },
  { value: '🦊', title: 'fox' },
  { value: '🐵', title: 'monkey' },
  { value: '🐶', title: 'dog' },
  { value: '🐰', title: 'rabbit' },
  { value: '🐨', title: 'koala' },
  { value: '🐠', title: 'fish' },
  { value: '🐻', title: 'bear' },
];

const AVATAR = {
  EMOJIS,
  COLORS,
};

export {
  AVATAR,
  convertTZ,
  showErrorToast,
  showSuccessToast,
  setupIntercom,
  signOutIntercom,
  showIntercom,
  showCrispLauncher,
  hideCrispLauncher,
  analytics,
  isBeforeClassTime,
  isDaysBeforeClassTime,
  isCameraPermissionGranted,
  isMicPermissionGranted,
  isMobileDevice,
  confirmDialog,
  currentTz,
  median,
  isSafari,
  isUnsupportedSafari14,
  isMobileSafari,
  isiOSDevice,
  isMobileChrome,
  isIpad,
  startOfNextWeek,
  shuffle,
  isiOSNativeDevice,
  navigateTo,
  sendDataToNative,
  parseJWTPayload,
  lockLandscapeNative,
  getTimeOfDayGreeting,
  lockPortraitNative,
  nativeActionTransaction,
};
