/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Stack,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Text,
  Flex,
  Select,
} from '@chakra-ui/react';
import actions from '@actions';
import { AVATAR, showErrorToast, showSuccessToast, analytics } from '@utils';
import * as constants from '../../../OnboardingFlows/constants';

const EditChildModal = ({
  child,
  fetchProfile,
  setEditChildModalOpen,
  editChildModalOpen,
  setHeaderProfile,
  toast,
  validateName,
}) => {
  // Local Properties
  const form = useForm();

  // State
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Actions
  const runChildUpdate = deleteChild => async data => {
    try {
      if (deleteChild) {
        setIsDeleting(true);
        analytics.t('deleting child edit');
      } else {
        setIsSubmitting(true);
        analytics.t('submitting child edit');
      }

      await actions.updateChild(
        { first_name: data.first_name, age: data.age, id: child.id },
        deleteChild ? 'delete' : 'update',
      );

      await fetchProfile(true);
      if (deleteChild) {
        setIsDeleting(false);
      } else {
        setIsSubmitting(false);
      }

      // We run another fetchProfile here to make sure
      // we have the latest child count to show header items
      const { profile: _ } = await actions.fetchProfile(false);
      setHeaderProfile(_);

      setEditChildModalOpen(false);
      const successMessage = deleteChild
        ? `${child.first_name} Deleted!`
        : `${child.first_name} Updated!`;
      showSuccessToast(toast, `✅ ${successMessage}`);
    } catch (error) {
      if (deleteChild) {
        setIsDeleting(false);
      } else {
        setIsSubmitting(false);
      }
      showErrorToast(toast, error.message);
    }
  };

  // Render Methods
  const renderFirstNameInput = () => (
    <FormControl mt={4} isInvalid={form.errors.first_name}>
      <Text fontWeight="bold">Child First Name</Text>
      <Input
        name="first_name"
        defaultValue={child.first_name}
        placeholder="First name"
        type="text"
        rounded="lg"
        fontSize="md"
        isRequired
        ref={form.register({ validate: validateName('First name') })}
      />
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        {form.errors.first_name && form.errors.first_name.message}
      </FormErrorMessage>
    </FormControl>
  );

  const renderAgeSelector = () => (
    <FormControl mt={4} isInvalid={form.errors.age}>
      <Flex flexDir="column">
        <Text fontWeight="bold" fontSize={['md', 'lg']} pb={2}>
          Child Age
        </Text>
        <Select
          name="age"
          defaultValue={child.age}
          placeholder="Select age"
          variant="filled"
          rounded="lg"
          spacing={3}
          cursor="pointer"
          ref={form.register({ required: true })}
        >
          {constants.childAgeOptions.map(t => (
            <option key={t.id}>{t.name}</option>
          ))}
        </Select>
      </Flex>
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        Child's age required
      </FormErrorMessage>
    </FormControl>
  );

  const renderDeleteButton = () => (
    <Button
      mt={4}
      isLoading={isDeleting}
      bg="red.400"
      fontWeight="medium"
      color="white"
      onClick={form.handleSubmit(runChildUpdate(true))}
    >
      {`Delete ${child.first_name}`}
    </Button>
  );

  const renderChildEmojiAndName = () => {
    const renderedEmoji = AVATAR.EMOJIS.find(e => e.title === child.emoji);
    return `${renderedEmoji ? renderedEmoji.value : '🐯'} ${child.first_name}`;
  };

  // UseEffects
  useEffect(() => {
    analytics.t('opened edit child modal', { childAge: child.age });
  }, []);

  return (
    <Modal isOpen={editChildModalOpen} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color="#383838" fontSize={['2xl', '3xl']} fontWeight="bold" textAlign="center">
            {renderChildEmojiAndName()}
          </Text>
          <Text
            color="gray.400"
            fontWeight="bold"
            fontSize={['sm', 'md']}
            textAlign="center"
            pt={[1, 3]}
          >
            Update your child's information!
          </Text>
        </ModalHeader>
        <ModalBody>
          <Stack justifyContent="center" flexDir="column" w="100%" spacing={3}>
            {renderFirstNameInput()}
            {renderAgeSelector()}
            {renderDeleteButton()}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            fontWeight="medium"
            mr={3}
            onClick={() => setEditChildModalOpen(false)}
            isDisabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={form.handleSubmit(runChildUpdate(false))}
            isDisabled={isDeleting}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditChildModal;
