/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  AspectRatio,
  Badge,
  Button,
  Collapse,
  Flex,
  Box,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { analytics } from '@utils';
import { cardMaxWidth } from '../../constants';

const CourseListItem = ({ course, mode }) => {
  // Local Properties
  const { description, id, title, poster_url: posterUrl, sections, subject } = course;

  // Hooks
  const history = useHistory();
  const { course_id } = useParams();

  // State
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  // Actions
  const ctaAction = () => {
    if (course_id) {
      analytics.t('courses: clicked other course cta', { title });
      let url = `${window.location.protocol}//${window.location.hostname}/courses/${id}`;
      window.open(url);
    } else {
      analytics.t('courses: clicked course page cta', { title });
      history.push(`/courses/${id}`);
    }
  };

  // Render Methods
  const renderDescriptionSection = () => (
    <Box>
      <Collapse
        startingHeight={40}
        in={descriptionOpen}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setDescriptionOpen(!descriptionOpen);
        }}
      >
        <Text color="gray.500" fontSize={['sm', 'md']}>
          {description}
        </Text>
      </Collapse>
      <Text
        fontSize={['sm', 'md']}
        textAlign="right"
        color="gray.600"
        onClick={() => {
          setDescriptionOpen(prevState => !prevState);
        }}
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
      >
        {descriptionOpen ? 'Show less' : 'Show more'}
      </Text>
    </Box>
  );

  const renderCardBadge = (badgeContent, fontWeight) => (
    <Badge
      variant="subtle"
      textTransform="capitalize"
      rounded="full"
      fontSize={['xs', 'sm', 'sm', 'sm']}
      fontWeight={fontWeight}
      paddingX={2}
      paddingY={1}
      background="white"
      borderWidth="1px"
      borderColor="#A0AEC0"
    >
      {badgeContent}
    </Badge>
  );

  const renderBadgeSection = () => (
    <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap">
      <Wrap>
        <WrapItem>{renderCardBadge(`${subject}`, 'regular')}</WrapItem>
        {course.sections.length > 1 && (
          <WrapItem>{renderCardBadge(`${sections.length} Weeks`)}</WrapItem>
        )}
      </Wrap>
    </Flex>
  );

  const renderTitleSection = () => (
    <Stack spacing={1}>
      <Text color="#383838" fontSize={['lg', 'lg', 'lg', 'xl']} noOfLines={3} fontWeight="black">
        {`${title}`}
      </Text>
    </Stack>
  );

  const renderVideoAsset = () => (
    <Box
      w="100%"
      h={['175px', '175px', '200px', '250px']}
      backgroundImage="url('https://assets.zipschool.com/posters/gadgets.png')"
      backgroundRepeat="repeat"
      overflow="hidden"
    >
      <AspectRatio className="mobile-border-radius" roundedTop="lg">
        <video
          muted
          playsInline
          autoPlay
          loop
          style={{
            width: '100%',
            height: '100%',
          }}
          src={posterUrl}
        />
      </AspectRatio>
    </Box>
  );

  const renderCTA = () => (
    <Button
      onClick={ctaAction}
      shadow="md"
      w="100%"
      h={['40px', '40px', '45px', '50px']}
      rounded="lg"
      textAlign="center"
      fontWeight="bold"
      fontSize={['md', 'lg']}
      bg="purple.500"
      colorScheme="purple"
    >
      Go to course
    </Button>
  );

  return (
    <Box
      h="100%"
      minW={['290px', '300px', '350px', '380px']}
      maxW={cardMaxWidth}
      rounded="lg"
      cursor="pointer"
      px={2}
    >
      <Stack bg="white" rounded="lg" shadow="lg">
        {posterUrl && renderVideoAsset()}
        <Stack spacing={3} py={4} px={6}>
          {renderTitleSection()}
          {mode !== 'other-courses' && renderBadgeSection()}
          {renderDescriptionSection()}
          {renderCTA()}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CourseListItem;
