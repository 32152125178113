import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { throttle } from 'lodash';

const useResizeListener = (id = '') => {
  // State
  const [currentElement, setCurrentElement] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  // References
  const currentElementRef = useRef(currentElement);
  const widthRef = useRef(width);
  const heightRef = useRef(height);

  // Actions
  const onElementResize = throttle(() => {
    if (currentElementRef && currentElementRef.current) {
      const paddingLeft = parseInt(
        window.getComputedStyle(currentElementRef.current).paddingLeft,
        10,
      );
      const paddingRight = parseInt(
        window.getComputedStyle(currentElementRef.current).paddingRight,
        10,
      );

      setWidth(currentElementRef.current.offsetWidth - (paddingLeft + paddingRight));
      setHeight(currentElementRef.current.offsetHeight);
    }
  }, 400);

  // UseEffects
  useLayoutEffect(() => {
    // Setup listeners for specific div
    const element = document.getElementById(id);

    if (element) {
      setCurrentElement(element);
      const paddingLeft = parseInt(window.getComputedStyle(element).paddingLeft, 10);
      const paddingRight = parseInt(window.getComputedStyle(element).paddingRight, 10);

      setWidth(element.offsetWidth - (paddingLeft + paddingRight));
      setHeight(element.offsetHeight);
      window.addEventListener('resize', onElementResize);
    } else {
      console.warn('[useResizeListener] Could not find element in dom');
    }
  }, []);

  useEffect(() => {
    currentElementRef.current = currentElement;
    widthRef.current = width;
    heightRef.current = height;
  }, [currentElement, width, height]);

  return { width, height };
};

export default useResizeListener;
