/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/accessible-emoji */

import React from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  Select,
  FormLabel,
  VStack,
} from '@chakra-ui/react';

import * as constants from '../../../constants';

const AddChildFlow = ({ form, childAge, childFirstName, validateName }) => {
  // Render Methods
  const renderFirstNameInput = () => (
    <FormControl isInvalid={form.errors.child_first_name} isRequired>
      <FormLabel fontSize={['md', 'lg']}>Child First Name</FormLabel>
      <Input
        name="child_first_name"
        placeholder="First name"
        defaultValue={childFirstName}
        type="text"
        h={['45px', '50px', '55px', '60px']}
        rounded="lg"
        fontSize="md"
        isRequired
        ref={form.register({ validate: validateName('First name') })}
      />
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        {form.errors.child_first_name && form.errors.child_first_name.message}
      </FormErrorMessage>
    </FormControl>
  );
  const renderAgeSelector = () => (
    <FormControl isInvalid={form.errors.child_age} isRequired>
      <Flex flexDir="column">
        <FormLabel fontSize={['md', 'lg']} pb={2}>
          Child Age
        </FormLabel>
        <Select
          name="child_age"
          placeholder="Select age"
          variant="filled"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          spacing={3}
          cursor="pointer"
          ref={form.register()}
        >
          {constants.childAgeOptions.map(t => (
            <option key={t.id} selected={childAge === t.name}>
              {t.name}
            </option>
          ))}
        </Select>
      </Flex>
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        Child age required
      </FormErrorMessage>
    </FormControl>
  );

  return (
    <VStack w="100%" px={4} animation="fadeIn 0.4s">
      <Text color="#383838" fontSize={['2xl', '3xl']} fontWeight="bold" textAlign="center">
        🦄 Who is attending class?
      </Text>
      <Text
        color="gray.400"
        fontWeight="bold"
        fontSize={['sm', 'md']}
        textAlign="center"
        pt={[1, 3]}
      >
        Let us know your child’s first name and age
      </Text>
      <VStack justifyContent="center" w="100%" spacing={3}>
        {renderFirstNameInput()}
        {renderAgeSelector()}
      </VStack>
    </VStack>
  );
};

export default AddChildFlow;
