/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Spinner, Text, useToast, VStack, HStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import actions from '@actions';
import {
  analytics,
  showErrorToast,
  isiOSNativeDevice,
  convertTZ,
  confirmDialog,
  navigateTo,
} from '@utils';
import ClassListItem from '../Components/ClassListItem';
import ClassChannel from '@components/ClassChannel';
import ClassList from 'ClassList/ClassList';
const Booked = ({ profile, setCurrentBookingClassData, setBookingCompletionHandler }) => {
  // Local Properties
  const toast = useToast();
  const history = useHistory();
  // State
  const [savedItems, setSavedItems] = useState([]);
  const [bookedItems, setBookedItems] = useState([]);
  const [organizedBookings, setOrganizedBookings] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  // Actions
  const fetchSaved = async silent => {
    if (!silent) setIsLoading(true);
    try {
      const { liked_items = [], booked_items = [] } = await actions.fetchSchedule();
      setBookedItems(booked_items);
      setSavedItems(liked_items);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (bookedItems) {
      const days = [
        ...new Set(
          bookedItems.map(b => {
            return convertTZ(b.timestamp, { mode: 'date' });
          }),
        ),
      ];

      const organizedItems = days.map(day => {
        const bookings = bookedItems.filter(b => {
          return convertTZ(b.timestamp, { mode: 'date' }) === day;
        });

        return { title: day, items: bookings };
      });

      setOrganizedBookings(organizedItems);
    }
  }, [bookedItems]);

  const onLikeClass = async classId => {
    try {
      await actions.toggleLike(classId);
      await fetchSaved(true);
    } catch (err) {
      console.warn(err);
      showErrorToast(toast, err.message);
    }
  };

  const onCancelBooking = async item => {
    try {
      if (!isiOSNativeDevice()) {
        await confirmDialog(`Are you sure you want to remove "${item.title}" from your schedule?`);
      }
      await actions.cancelBooking(item.booking_id);
      await fetchSaved(true);
    } catch (err) {
      console.warn(err);
      if (err) showErrorToast(toast, err.message);
    }
  };

  const renderChannel = item => (
    <Box>
      <Text color="white" fontSize="xl" textAlign="center" px={5} fontWeight="bold">
        {item.title}
      </Text>

      <Box key={item.title} w="100vw">
        <HStack
          justifyContent={[item.items.length > 1 ? 'left' : 'center', 'center']}
          w="100%"
          className="no-scroll-bar"
          overflow={item.items.length > 1 ? 'scroll' : null}
        >
          {item.items.map(i => (
            <ClassListItem
              style={{ width: '400px' }}
              key={i.class_id}
              channelTitle={'schedule'}
              item={i}
              mode="schedule"
              isChannelItem
              setCurrentBookingClassData={setCurrentBookingClassData}
              onLikeClass={onLikeClass}
              onCancelBooking={onCancelBooking}
            />
          ))}
        </HStack>
      </Box>
    </Box>
  );

  const fetchData = async () => {
    await fetchSaved(false);
  };

  // Render Methods
  const renderSchedulePageButton = () => (
    <Button
      w="100%"
      h={['40px', '45px', '50px', '55px']}
      rounded="full"
      boxShadow="md"
      fontSize={['md', 'lg', 'xl', 'xl']}
      fontWeight="bold"
      bg="#ffc60b"
      backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                            rgba(255, 255, 255, 0) 93.72%)"
      style={{
        backgroundBlendMode: 'soft-light, normal',
      }}
      _hover={{ backgroundColor: '#FFC503' }}
      onClick={() => {
        navigateTo(history, '/classes');
        analytics.t('clicked take me to schedule cta');
      }}
    >
      <Box>
        <Text>🚀 Go To Classes Page</Text>
      </Box>
    </Button>
  );

  const renderNoScheduleItems = () => (
    <VStack bg="white" rounded="lg" p={5}>
      <Text color="#383838" fontSize={['xl', '2xl']} fontWeight="bold" textAlign="center">
        😯 Your Schedule Is Empty
      </Text>
      <Text
        color="gray.400"
        fontWeight="bold"
        fontSize={['sm', 'md']}
        textAlign="center"
        pt={[1, 3]}
      >
        Head over to the Classes page to add a class to your schedule!
      </Text>
      {renderSchedulePageButton()}
    </VStack>
  );

  const renderNoItems = () => (
    <VStack bg="white" rounded="lg" p={5}>
      <Text color="#383838" fontSize={['xl', '2xl']} fontWeight="bold" textAlign="center">
        😯 No Saved Classes Yet
      </Text>
      <Text
        color="gray.400"
        fontWeight="bold"
        fontSize={['sm', 'md']}
        textAlign="center"
        pt={[1, 3]}
      >
        Head over to the Classes page to save a class!
      </Text>
      {renderSchedulePageButton()}
    </VStack>
  );

  // UseEffects
  useEffect(() => {
    analytics.t('viewed saved');
    window.scrollTo(0, 0);

    fetchData();

    const handleRefreshRequest = (params = {}) => {
      const { silent } = params;
      fetchSaved(silent);
    };

    window.zip_requestRefresh = handleRefreshRequest;

    const handleRefreshData = async () => {
      await fetchSaved(true);
    };

    setBookingCompletionHandler(() => handleRefreshData);
  }, []);

  return (
    <Box w="100%">
      {isLoading && (
        <Box d="flex" justifyContent="center">
          <Spinner fontSize={['2xl', '2xl', '2xl', '3xl']} mt={4} color="white" />
        </Box>
      )}

      {!isiOSNativeDevice() && !isLoading && (
        <Box px={5}>
          <Text color="white" fontWeight="black" fontSize={['2xl', '2xl', '2xl', '3xl']}>
            Schedule
          </Text>
          <Divider borderWidth="1px" opacity={1.0} borderColor="white" />
        </Box>
      )}

      {organizedBookings.length > 0 && !isLoading && (
        <VStack spacing={7} py={5}>
          {organizedBookings.map(renderChannel)}
        </VStack>
      )}
      {!isLoading && bookedItems.length === 0 && <Box pt={2}>{renderNoScheduleItems()}</Box>}

      {!isLoading && (
        <Box px={5} mt={4}>
          <Text color="white" fontWeight="black" fontSize={['2xl', '2xl', '2xl', '3xl']}>
            Saved Classes
          </Text>
          <Divider borderWidth="1px" opacity={1.0} borderColor="white" />
        </Box>
      )}
      {!isLoading && savedItems.length === 0 && <Box pt={2}>{renderNoItems()}</Box>}

      {savedItems.length > 0 && !isLoading && (
        <VStack py={5}>
          {savedItems.map(item => (
            <ClassListItem
              key={item.class_id}
              item={item}
              fetchSchedule={fetchSaved}
              onLikeClass={onLikeClass}
              setCurrentBookingClassData={setCurrentBookingClassData}
            />
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default Booked;
