const AGES = [4, 5, 6, 7, 8, 9, 10, 11, 12];

const CDN_HOSTNAME = 'https://assets.zipschool.com';

const TIMES = [
  { title: '6:00AM', value: 6 },
  { title: '6:30AM', value: 6.5 },
  { title: '7:00AM', value: 7 },
  { title: '7:30AM', value: 7.5 },
  { title: '8:00AM', value: 8 },
  { title: '8:30AM', value: 8.5 },
  { title: '9:00AM', value: 9 },
  { title: '9:30AM', value: 9.5 },
  { title: '10:00AM', value: 10 },
  { title: '10:30AM', value: 10.5 },
  { title: '11:00AM', value: 11 },
  { title: '11:30AM', value: 11.5 },
  { title: '12:00PM', value: 12 },
  { title: '12:30PM', value: 12.5 },
  { title: '1:00PM', value: 13 },
  { title: '1:30PM', value: 13.5 },
  { title: '2:00PM', value: 14 },
  { title: '2:30PM', value: 14.5 },
  { title: '3:00PM', value: 15 },
  { title: '3:30PM', value: 15.5 },
  { title: '4:00PM', value: 16 },
  { title: '4:30PM', value: 16.5 },
  { title: '5:00PM', value: 17 },
  { title: '5:30PM', value: 17.5 },
  { title: '6:00PM', value: 18 },
  { title: '6:30PM', value: 18.5 },
  { title: '7:00PM', value: 19 },
  { title: '7:30PM', value: 19.5 },
  { title: '8:00PM', value: 20 },
  { title: '8:30PM', value: 20.5 },
  { title: '9:00PM', value: 21 },
  { title: '9:30PM', value: 21.5 },
  { title: '10:00PM', value: 22 },
];

const AUTH_STATES = {
  LOADING: 0,
  NEEDS_AUTH: 1,
  AUTHENTICATED: 2,
};

export { AGES, CDN_HOSTNAME, TIMES, AUTH_STATES };
