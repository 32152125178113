/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import videojs from 'video.js';
import { analytics, isMobileDevice } from '@utils';

const VideoPreviewPlayer = ({
  classTitle,
  options,
  parentRef,
  previewStart,
  previewLength,
  setResourceData,
}) => {
  // Local Properties
  const isMobileDeviceResult = isMobileDevice();

  // State
  const [previewComplete, setPreviewComplete] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [isPortraitMode, setIsPortraitMode] = useState(false);
  const [isPlaying, setIsPlaying] = useState(null);

  // References
  const vodRef = useRef(null);

  // Actions
  const onPlayerReady = () => {
    console.log('PLAYER READY.');
  };

  const onWaiting = () => {
    console.log('ON WAITING');
    if (isPlaying) {
      setIsPlaying(false);
    }
  };

  const onPlaying = () => {
    console.log('ON PLAYING');
    setIsPlaying(true);
  };

  const onScreenModeChanged = e => {
    if (isMobileDeviceResult) {
      if (e.matches) {
        setIsPortraitMode(true);
      } else {
        setIsPortraitMode(false);
      }
    }
  };

  const onPlay = () => {
    try {
      analytics.t('pressed play on preview video', { classTitle });
      setTimeout(() => {
        setPreviewComplete(true);
      }, previewLength * 1000);
    } catch (error) {
      console.error('Preview video error: ', error);
    }
  };

  // UseEffects
  useEffect(() => {
    try {
      if (vodRef && vodRef.current) {
        const player = videojs(vodRef.current, options, onPlayerReady);
        player.currentTime(previewStart);
        setVideoPlayer(player);
      }
    } catch (error) {
      console.error('VideoPreviewPlayer Error: ', error);
    }
  }, []);

  useEffect(() => {
    if (videoPlayer) {
      // eslint-disable-next-line no-param-reassign
      parentRef.current = videoPlayer;
    }
  }, [videoPlayer]);

  useEffect(() => {
    try {
      const matchMediaQuery = window.matchMedia('(orientation: portrait)');

      // Let's properly build up and tear down these things
      if (previewComplete) {
        analytics.t('finished watching preview video', { classTitle });
        matchMediaQuery.removeListener(onScreenModeChanged);
        videoPlayer.pause();
        videoPlayer.dispose();
        setResourceData(null);
      } else {
        matchMediaQuery.addListener(onScreenModeChanged);
        setIsPortraitMode(matchMediaQuery.matches && isMobileDeviceResult);
      }
    } catch (error) {
      console.error('VideoPreviewPlayer Error: ', error);
    }
  }, [previewComplete]);

  return (
    <Flex
      className="mobile-border-radius"
      rounded="lg"
      w="100%"
      h={isPortraitMode ? '450px' : ['250px', '250px', '250px', '450px']}
      alignItems="center"
      position="relative"
    >
      <div data-vjs-player style={{ animation: 'fadeIn 0.4s ease' }}>
        <video
          className="video-js vjs-big-play-centered"
          style={{ width: '100%', height: '100%' }}
          ref={node => (vodRef.current = node)}
          controlsList="nodownload"
          onWaiting={onWaiting}
          onPlay={onPlay}
          onPlaying={onPlaying}
        />
      </div>
    </Flex>
  );
};

export default VideoPreviewPlayer;
