/* eslint-disable default-case */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import {
  Avatar,
  Text,
  Button,
  Box,
  Fade,
  Flex,
  Image as ChakraImage,
  Stack,
  VStack,
  HStack,
  IconButton,
  useToast,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useParams, useHistory } from 'react-router-dom';
import { throttle } from 'lodash';
import * as hello from '@tensorflow/tfjs';
import mux from 'mux-embed';
import { Auth } from 'aws-amplify';
import confetti from 'canvas-confetti';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import actions from '@actions';
import { analytics, AVATAR, isMobileDevice, isiOSNativeDevice, showErrorToast } from '@utils';
import { CDN_HOSTNAME } from '@constants';
import ProgressRing from '@components/ProgressRing';
import PlayButton from '@assets/icons/play-button.svg';
import RewardModal from '@modals/RewardModal';
import { useResizeListener } from '@hooks';
import VideoPlayer from '../VideoPlayer';
import useUserMedia from '../../useUserMedia';

const ANSWER_LETTERS = ['A', 'B', 'C', 'D'];
const THROTTLE_TIME = 1500;
const REACTIONS = {
  HEART: '❤️',
  THUMBS_UP: '👍',
  CONFETTI: '🎉',
};

const VODView = ({
  zipClass = {},
  student,
  timeDifference,
  setCurrentPoll,
  currentPoll,
  setSelectedAnswer,
  selectedAnswer,
  setCurrentGame,
  currentGame,
  vodRef,
  isPortraitMode,
  onBack,
  reward,
  next,
  isNoAuth,
  classType,
}) => {
  // Local Properties
  // eslint-disable-next-line global-require
  const bodyPix = require('@tensorflow-models/body-pix');
  const videoHeight = useBreakpointValue({ base: 104, sm: 104, md: 122, lg: 165 });

  // State
  const [currentReaction, setCurrentReaction] = useState(null);
  const [isMuted, setIsMuted] = useState(isMobileDevice());
  const [didStartVOD, setDidStartVod] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const [showVirtualBackground, setShowVirtualBackground] = useState(null);
  const [virtualBackgroundStarted, setVirtualBackgroundStarted] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentReward, setCurrentReward] = useState(null);
  const [rewardCount, setRewardCount] = useState(0);
  const [revealPollAudioMuted, setRevealPollAudioMuted] = useState(true);
  const [savedClass, setSavedClass] = useState(zipClass.liked);
  const [savingClass, setSavingClass] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [showPoll, setShowPoll] = useState(false);
  const [pollProgress, setPollProgress] = useState(0);
  const [recordTimeInterval, setRecordTimeInterval] = useState(null);
  const [showReward, setShowReward] = useState(false);

  let lastCheckedSecond = -1;

  // Hooks
  const history = useHistory();
  const { booking_id: bookingId } = useParams();
  const toast = useToast();
  const { width: videoWidth } = useResizeListener('landscape_channel');
  const { mediaStream, triggerPermissionsCheckAction } = useUserMedia({
    video: { width: videoWidth, height: videoHeight },
  });

  // References
  const participantVideoRef = useRef(null);
  const reactionAudioRef = useRef(null);
  const participantRef = useRef(null);
  const selectedPollRef = useRef(null);
  const revealPollRef = useRef(null);
  const videoJsRef = useRef(null);
  const canvasRef = useRef();
  const captureCanvasRef = useRef();
  const pollRef = useRef(null);
  const pollContainerRef = useRef(null);

  // Actions
  const drawVideo = async net => {
    try {
      if (!showVirtualBackground) {
        return;
      }

      const canvas = canvasRef.current;
      if (!canvas) return;

      const video = participantVideoRef.current;
      if (!video) return;

      const ctx = canvas.getContext('2d');

      const capture = () => {
        const canvas = captureCanvasRef.current;
        const video = participantVideoRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        canvas.getContext('2d').drawImage(video, 0, 0, videoWidth, videoHeight);
        const jpegFile = canvas.toDataURL();
        return jpegFile;
      };

      const screen = capture();
      if (!screen) {
        return;
      }

      const webcamImage = new Image();

      webcamImage.onload = async () => {
        try {
          const segmentation = await net.segmentPerson(webcamImage, {
            flipHorizontal: false,
            internalResolution: 'medium',
            segmentationThreshold: 0.7,
          });

          const spaceImage = new Image();
          let imgData;
          spaceImage.onload = () => {
            try {
              ctx.drawImage(spaceImage, 0, 0, videoWidth, videoHeight);
              imgData = ctx.getImageData(0, 0, videoWidth, videoHeight);

              for (let p = 0; p < imgData.data.length; p += 4) {
                if (segmentation.data[p / 4] === 1) {
                  imgData.data[p + 3] = 0;
                }
              }

              ctx.putImageData(imgData, 0, 0);

              if (!virtualBackgroundStarted) {
                setVirtualBackgroundStarted(true);
              }

              requestAnimationFrame(() => {
                drawVideo(net);
              });
            } catch (error) {
              console.error('SpaceImage.onload Error: ', error);
            }
          };

          spaceImage.crossOrigin = 'Anonymous';
          spaceImage.src = zipClass.virtual_bg_url;
        } catch (error) {
          console.error('WebcamImage.onload Error: ', error);
        }
      };

      webcamImage.src = screen;
    } catch (error) {
      console.error('VODView Error: ', error);
    }
  };

  /**
   * Action to dismiss a poll if visible.
   * On poll dismiss make sure to reset the pollProgress data.
   */
  const dismissPollAction = () => {
    if (currentPoll) {
      setCurrentPoll(null);
      setCurrentGame(null);
      setCurrentReward(null);
      setShowPoll(false);
      setSelectedAnswer(null);
    }

    if (pollProgress !== 0) {
      setPollProgress(0);
    }
  };

  const endClassRoute = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const cohort_id = searchParams.get('cohort_id');

    let pathUri = cohort_id ? `/courses/${cohort_id}` : `/classes`;

    if (isiOSNativeDevice()) {
      pathUri = '/classes-mobile';
    }

    return pathUri;
  };

  const endClass = async () => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const view_id = searchParams.get('view_id');
      if (view_id) {
        await actions.fetchZipClassroom(bookingId, view_id, 'complete');
      } else {
        console.log('No view id');
      }
    } catch (err) {
      console.log('could not record end class');
    }

    const { zipClass: zipClassData } = zipClass;
    analytics.t(`completed 100% of ${classType === 'quiz' ? 'quiz' : 'lesson'}`, {
      id: zipClassData.id,
      title: zipClassData.title,
      teacher: zipClassData.teacher.first_name,
      classDuration: zipClassData.duration,
    });

    // If the current lesson is the last lesson of the week, send this event
    if (zipClass.current_lesson_week + 1 === zipClass.week_lessons && classType !== 'quiz') {
      analytics.t('courses: completed weeks lessons', {
        courseTitle: zipClassData.title,
        weekNum: zipClass.current_week_course + 1,
      });
    }

    if (recordTimeInterval) {
      clearInterval(recordTimeInterval);
    }

    setShowReward(true);
  };

  const getCurrentProfile = async () => {
    const response = await Auth.currentSession();
    setCurrentUser(response);
  };

  const recordAnswer = async () => {
    if (currentPoll) {
      try {
        await actions.createClassroomInteraction(
          bookingId,
          zipClass.booking_id,
          currentPoll.id,
          selectedAnswer,
        );
      } catch (err) {
        console.log(`Could not record answer: ${err}`);
      }
    }
  };

  /**
   * Action to reveal a poll answer when trigger is initiated.
   * Just like showPollAction, this action also needs to grab data from the previous
   * poll data if it is not available at the moment it is initiated
   */
  const revealAnswerAction = async () => {
    if (currentPoll) {
      // Check to see if there are any correct answers
      // If none just dismiss
      const correctAnswer =
        currentPoll.answers.length > 0 ? currentPoll.answers.find(item => item.isCorrect) : null;

      if (!correctAnswer) {
        setCurrentPoll(null);
        setCurrentGame(null);
        setCurrentReward(null);
        setShowPoll(false);
      } else if (!currentPoll.showAnswer) {
        setCurrentPoll(prevPoll => {
          if (!prevPoll) return;

          if (classType === 'quiz' && vodRef && vodRef.current && vodRef.current.paused) {
            vodRef.current.play().catch(err => console.log('Could not resume play: ', err));
          }

          // eslint-disable-next-line consistent-return
          return { ...prevPoll, showAnswer: true, hidden: false };
        });

        recordAnswer();
      }
    } else if (vodRef && vodRef.current && classType !== 'quiz') {
      const { triggers = {} } = zipClass || {};
      const currentVideoSecond = Math.floor(vodRef.current.currentTime);
      let nextActionSecond = currentVideoSecond;

      // Check for next poll action
      const { duration } = vodRef.current;
      for (let i = currentVideoSecond; i <= Math.floor(duration); i += 1) {
        if (
          !triggers[nextActionSecond.toString()] ||
          triggers[nextActionSecond.toString()].action !== 'poll'
        ) {
          nextActionSecond += 1;
        } else {
          console.log('Found revealAnswerAction trigger @: ', nextActionSecond);
          break;
        }
      }

      const foundTrigger = triggers[nextActionSecond.toString()];
      const correctAnswer =
        foundTrigger && foundTrigger.data.answers.length > 0
          ? foundTrigger.data.answers.find(item => item.isCorrect)
          : null;

      if (foundTrigger && foundTrigger.action === 'poll' && correctAnswer) {
        setCurrentPoll(foundTrigger.data);
      }
    }
  };

  /**
   * Action to show poll when trigger is initiated.
   * If poll should be shown, we fetch the last poll data in the chain to use
   * @param {Object} data - Initial poll data if available
   */
  const showPollAction = data => {
    if (data && !currentPoll) {
      setCurrentPoll(data);
    } else if (!currentPoll) {
      // We should be showing poll, but don't have the poll data
      const { triggers = {} } = zipClass || {};
      const currentVideoSecond = Math.floor(vodRef.current.currentTime);
      let nextActionSecond = currentVideoSecond;

      // Check for next poll action
      const { duration } = vodRef.current;
      for (let i = Math.floor(duration); i >= currentVideoSecond; i -= 1) {
        if (!triggers[nextActionSecond.toString()]) {
          nextActionSecond -= 1;
        } else {
          console.log('Found trigger showPollAction @: ', nextActionSecond);
          break;
        }
      }

      const foundTrigger = triggers[nextActionSecond.toString()];

      if (foundTrigger && foundTrigger.action === 'poll') {
        setCurrentPoll(foundTrigger.data);
      }
    }
  };

  useEffect(() => {
    if (!showPoll && classType === 'quiz') {
      setShowPoll(true);
    }
  }, [showPoll]);

  /**
   * Checks poll triggers to see when certain events need to happen based on video time
   */
  const checkPollStatus = throttle(() => {
    if (vodRef && vodRef.current) {
      const { triggers = {} } = zipClass || {};
      const currentVideoSecond = Math.round(vodRef.current.currentTime);
      let nextActionSecond = currentVideoSecond;
      if (currentVideoSecond === lastCheckedSecond) {
        return;
      }
      lastCheckedSecond = currentVideoSecond;

      // Check for next poll action
      const { duration } = vodRef.current;
      for (let i = currentVideoSecond; i <= Math.floor(duration); i += 1) {
        if (!triggers[nextActionSecond.toString()]) {
          nextActionSecond += 1;
        } else {
          break;
        }
      }

      const foundTrigger = triggers[nextActionSecond.toString()];

      switch (foundTrigger && foundTrigger.action) {
        case 'poll':
          if (currentVideoSecond >= nextActionSecond) {
            showPollAction(foundTrigger.data || null);
          } else {
            // Next action is poll, but we are still not showing the poll yet
            dismissPollAction();
          }
          break;

        case 'reveal':
          if (currentVideoSecond >= nextActionSecond) {
            if (
              classType === 'quiz' &&
              (!selectedAnswer || (selectedAnswer && !currentPoll.showAnswer))
            ) {
              vodRef.current.pause();
            } else if (classType !== 'quiz') {
              revealAnswerAction();
            }
          } else {
            // Next action is reveal, but we ar currently in ShowPoll
            showPollAction(null);
          }
          break;

        case 'dismiss':
          if (currentVideoSecond >= nextActionSecond) {
            dismissPollAction();
          } else {
            revealAnswerAction();
          }
          break;
      }
    }
  }, 1000);

  /**
   * Checks triggers for end class, rewards, and games based on video time
   */
  const checkTriggerStatus = throttle(() => {
    if (vodRef && vodRef.current) {
      const { triggers = {} } = zipClass || {};
      const { currentTime, duration } = vodRef.current;
      const inSeconds = Math.round(currentTime);
      const trigger = triggers[inSeconds.toString()];
      if ((trigger && !showPlayButton) || (trigger && !isMobileDevice())) {
        const { action, data = {} } = trigger;

        switch (action) {
          case 'end_class':
            endClass();
            break;

          case 'game':
            setCurrentGame(data);
            break;

          case 'reward':
            if (!currentReward) {
              setCurrentReward(data);
              setRewardCount(prevCount => prevCount + 1);
            }
            break;
        }
      } else if (inSeconds >= Math.floor(duration)) {
        endClass();
      }
    }
  }, 1000);

  /**
   * Method that is passed to VideoPlayer and is called on every video, time update
   */
  const checkForTrigger = () => {
    try {
      checkPollStatus();
      checkTriggerStatus();
    } catch (error) {
      console.error('VODView Error: ', error);
    }
  };

  const handleLikeClass = async () => {
    try {
      if (zipClass) {
        setSavingClass(true);
        await actions.toggleLike(zipClass.zipClass.id);
        setSavingClass(false);
        setSavedClass(prevState => !prevState);
        analytics.t('toggled like class from vodview', {
          status: !savedClass ? 'saved' : 'removed',
        });
      }
    } catch (err) {
      setSavingClass(false);
      console.warn(err);
      showErrorToast(toast, err.message);
    }
  };

  const intitiateConfettiCannonAsync = type => {
    // There is currently no camera in portrait mode
    // So this will probably break if not returned
    if (type === 'reward' && isPortraitMode) return;

    const ref = type === 'poll' ? pollRef.current : participantRef.current;
    const myConfetti = confetti.create(ref, {
      useWorker: true,
    });

    const getConfettiSize = () => {
      if (type === 'poll') {
        if (isPortraitMode) {
          return 0.5;
        }

        return 0.4;
      }

      return 1.0;
    };

    setTimeout(() => {
      myConfetti({
        particleCount: 75,
        gravity: 0.75,
        spread: 60,
        startVelocity: 20,
        scalar: getConfettiSize(),
        origin: { y: type === 'poll' ? 0.5 : 1.0 },
      });
    }, 250);
  };

  const loadBodyPix = async () => {
    const net = await bodyPix.load({
      architecture: 'MobileNetV1',
      outputStride: 16,
      multiplier: 0.75,
      quantBytes: 2,
    });

    requestAnimationFrame(() => {
      drawVideo(net);
    });
  };

  const goBack = () => {
    if (videoJsRef && videoJsRef.current) {
      // Dispose of the player before navigating away
      videoJsRef.current.dispose();
    }
    history.push(endClassRoute());

    analytics.t('courses: clicked go back to courses at end of class', {
      courseTitle: zipClass.zipClass.title,
      currentLessonIndex: zipClass.current_lesson_course + 1,
    });
  };

  const onCanPlayWebcam = () => {
    try {
      console.log('OnCanPlayWebcam');
      if (
        participantVideoRef &&
        participantVideoRef.current &&
        participantVideoRef.current.paused
      ) {
        participantVideoRef.current.play();
      }
    } catch (error) {
      console.warn('Error in OnCanPlayWebcam: ', error);
    }
  };

  const playTriggerReactionAudio = reaction => {
    try {
      if (reactionAudioRef && reactionAudioRef.current) {
        const srcPath = `${CDN_HOSTNAME}/sounds/effects/`;
        let reactionSound;

        if (reaction === REACTIONS.THUMBS_UP) {
          reactionSound = 'thumbs-up-reaction.mp3';
        } else if (reaction === REACTIONS.CONFETTI) {
          reactionSound = 'confetti-reaction.mp3';
        } else if (reaction === REACTIONS.HEART) {
          reactionSound = 'heart-reaction.mp3';
        } else {
          console.log(`Reaction ${reaction} not recognized.`);
          return;
        }

        if (reactionAudioRef.current.paused) {
          reactionAudioRef.current.volume = 0.2;
          reactionAudioRef.current.src = `${srcPath}${reactionSound}`;
          reactionAudioRef.current.play();
        }
      }
    } catch (error) {
      console.warn('Error playing reaction sound: ', error);
    }
  };

  const playPollAudio = async action => {
    try {
      if (action === 'select' && selectedPollRef && selectedPollRef.current) {
        if (selectedPollRef.current.paused) {
          selectedPollRef.current.volume = 0.2;
          selectedPollRef.current.play();
        }

        // On poll select, lets check to see if this has been played already.
        // If not, play and then unmute
        if (revealPollAudioMuted) {
          revealPollRef.current.play();
        }
      } else if (
        action === 'reveal' &&
        !revealPollAudioMuted &&
        revealPollRef &&
        revealPollRef.current &&
        revealPollRef.current.paused
      ) {
        revealPollRef.current.volume = 0.2;
        await revealPollRef.current.play();
      }
    } catch (error) {
      console.warn('Error playing poll sound: ', error);
    }
  };

  const getStartedAction = () => {
    playPollAudio('reveal');
    setIsMuted(false);
    setIsBuffering(false);

    // Found here - https://goo.gl/LdLk22
    const playPromise = videoJsRef.current.play();
    if (playPromise !== undefined) {
      playPromise
        // eslint-disable-next-line no-unused-vars
        .then(_ => {
          console.log('PLAY PROMISE RESOLVED.');

          if (!isMobileDevice()) {
            setIsMuted(false);
          }

          setIsBuffering(false);
          setDidStartVod(true);
          setShowPlayButton(false);
        })
        .catch(error => {
          console.warn('OnCanPlayThrough Error: ', error);
        });
    }
  };

  const onPollAnswerClick = pollAnswer => () => {
    try {
      if (!currentPoll.showAnswer) {
        setSelectedAnswer(pollAnswer);
        playPollAudio('select');
      }
    } catch (error) {
      console.error('OnPollAnswerClick Error: ', error);
    }
  };

  const triggerReaction = reaction => {
    setCurrentReaction(reaction);
    playTriggerReactionAudio(reaction);
  };

  const onSkipAction = () => {
    let currentTime;

    if (videoJsRef && videoJsRef.current) {
      currentTime = Math.floor(videoJsRef.current.currentTime());
    }

    analytics.t('clicked skip parent lesson', { currentTime });
    history.push('/signup-native', { step: 'signup' });
  };

  // Render Methods
  const renderAnswerItem = answer => {
    if (currentPoll && currentPoll.poll_type === 'picture') {
      return renderPictureAnswer(answer);
    }
    return renderTextAnwer(answer);
  };

  const renderTextAnwer = answer => {
    const answerLetter = ANSWER_LETTERS[currentPoll.answers.indexOf(answer)];
    const answerText = answer.title.split(':').pop().trim();

    const getBgColor = () => {
      if (currentPoll.showAnswer && answer.isCorrect) {
        return 'purple.500';
      }
      if (!currentPoll.showAnswer && selectedAnswer === answer.title) {
        return 'green.400';
      }

      return 'transparent';
    };

    const getTextColor = () => {
      if (
        (currentPoll.showAnswer && answer.isCorrect) ||
        (!currentPoll.showAnswer && selectedAnswer === answer.title)
      ) {
        return 'white';
      }

      return 'black';
    };

    return (
      <Flex
        onClick={onPollAnswerClick(answer.title)}
        justifyContent="flex-start"
        alignItems="center"
        key={answer.title}
        cursor={currentPoll.showAnswer ? 'inherit' : 'pointer'}
        p={3}
        borderWidth="1px"
        bg={getBgColor()}
        backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
        borderColor={selectedAnswer === answer.title ? 'transparent' : 'gray.300'}
        animation={currentPoll.showAnswer && answer.isCorrect ? 'wiggle 1.5s infinite' : 'none'}
        style={{
          backgroundBlendMode: 'multiply, normal',
        }}
        rounded="full"
        shadow="md"
        w="100%"
      >
        <Box w="10%" pr={3}>
          <Text
            fontWeight="black"
            color={getTextColor()}
            fontSize={['md', 'md', 'lg', 'xl']}
            textAlign="center"
            style={{
              textShadow: '0 -1px 0 rgba(0, 0, 0, .35)',
              animation: !selectedAnswer && !currentPoll.showAnswer ? 'shake 1.25s infinite' : '',
            }}
          >
            {answerLetter}
          </Text>
        </Box>
        <Text color={getTextColor()} fontSize={['sm', 'sm', 'md', 'lg']}>
          {answerText}
        </Text>
      </Flex>
    );
  };
  const renderPictureAnswer = answer => {
    const answerLetter = ANSWER_LETTERS[currentPoll.answers.indexOf(answer)];
    const answerText = answer.title.split(':').pop().trim();

    const getBgColor = () => {
      if (currentPoll.showAnswer && answer.isCorrect) {
        return 'purple.500';
      }
      if (!currentPoll.showAnswer && selectedAnswer === answer.title) {
        return 'green.400';
      }

      return 'transparent';
    };

    return (
      <Box
        onClick={onPollAnswerClick(answer.title)}
        key={answer.title}
        cursor={currentPoll.showAnswer ? 'inherit' : 'pointer'}
        p={2}
        borderWidth={selectedAnswer === answer.title && !currentPoll.showAnswer ? '3px' : '1px'}
        bg={getBgColor()}
        backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
        borderColor={
          selectedAnswer === answer.title && !currentPoll.showAnswer ? 'green.300' : 'gray.300'
        }
        animation={currentPoll.showAnswer && answer.isCorrect ? 'wiggle 1.5s infinite' : 'none'}
        style={{
          backgroundBlendMode: 'multiply, normal',
        }}
        rounded="lg"
        shadow="md"
      >
        <ChakraImage src={answer.url} />
      </Box>
    );
  };

  const renderControlBar = () => (
    <Flex w="100%" h="100%" justifyContent="space-between" alignItems="center" px={2}>
      <Button
        colorScheme="gray"
        bg="#EBEBEB"
        color="gray.700"
        rounded="full"
        leftIcon={<ChevronLeftIcon fontSize={['25px', '30px']} />}
        size="md"
        shadow="lg"
        onClick={onBack}
      >
        Back
      </Button>
    </Flex>
  );

  const renderEmoji = () => {
    const emoji = AVATAR.EMOJIS.find(e => e.title === student.emoji);
    if (emoji) return emoji.value;

    return AVATAR.EMOJIS[0].value;
  };

  const renderGetStartedPlayButton = () => (
    <Flex
      position="absolute"
      zIndex="1"
      w="100%"
      h="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        bg="#805AD5BF"
        onClick={getStartedAction}
        rounded="full"
        w="68px"
        h="68px"
        pl={5}
        shadow="lg"
      >
        <ChakraImage w="24x" h="24px" src={PlayButton} />
      </Button>
    </Flex>
  );

  const renderInteractiveButtons = () => {
    const buttonSize = ['40px', '40px', '50px', '60px'];
    const fontSize = ['lg', 'lg', 'xl', '3xl'];
    return (
      <HStack flexDir="row" justifyContent="space-evenly" w="100%">
        <Button
          colorScheme="gray"
          w={buttonSize}
          h={buttonSize}
          fontSize={fontSize}
          bg="gray.400"
          shadow="lg"
          rounded="full"
          onClick={throttle(() => triggerReaction(REACTIONS.HEART), THROTTLE_TIME)}
        >
          ❤️
        </Button>
        <Button
          colorScheme="gray"
          w={buttonSize}
          h={buttonSize}
          fontSize={fontSize}
          bg="gray.400"
          shadow="lg"
          rounded="full"
          onClick={throttle(() => triggerReaction(REACTIONS.THUMBS_UP), THROTTLE_TIME)}
        >
          👍
        </Button>
        <Button
          colorScheme="gray"
          w={buttonSize}
          h={buttonSize}
          fontSize={fontSize}
          bg="gray.400"
          shadow="lg"
          rounded="full"
          onClick={throttle(() => triggerReaction(REACTIONS.CONFETTI), THROTTLE_TIME)}
        >
          🎉
        </Button>
      </HStack>
    );
  };

  const renderInteractiveChannel = () => (
    <Flex
      position="absolute"
      top="0"
      right="0"
      w="100%"
      h="100%"
      zIndex="2"
      justifyContent="center"
      alignItems="flex-end"
      pointerEvents="none"
    >
      {currentReaction && (
        <Text
          opacity={currentReaction ? 1 : 0}
          position="absolute"
          fontSize={['6xl', '3xl', '4xl', '5xl']}
          animation={currentReaction ? 'reactionFloat 1.5s ease' : 'none'}
          onAnimationEnd={() => setCurrentReaction(null)}
        >
          {currentReaction}
        </Text>
      )}
    </Flex>
  );

  const renderParticipant = () => {
    const studentColor = (student && student.color) || 'purple.300';
    const buttonSize = ['40px', '40px', '50px', '60px'];
    const fontSize = ['lg', 'lg', 'xl', '3xl'];

    if (!mediaStream) {
      return (
        <VStack w="100%" justifySelf="flex-start">
          <Button
            colorScheme="gray"
            w="100%"
            h={buttonSize}
            bg="gray.400"
            shadow="lg"
            rounded="full"
            onClick={() => {
              triggerPermissionsCheckAction(true);
              analytics.t('courses: clicked turn on camera');
            }}
          >
            <Text fontSize={fontSize} pl={1}>
              📷
            </Text>
          </Button>
          <Text fontSize={['xs', 'xs', 'md', 'lg']} textAlign="center">
            Turn on your selfie cam to be apart of the lesson!
          </Text>
        </VStack>
      );
    }

    return (
      <Flex
        className="mobile-border-radius"
        h={videoHeight}
        flexDir="column"
        bg="black"
        alignItems="center"
        borderWidth={4}
        borderColor={!mediaStream ? 'gray.400' : studentColor}
        rounded="lg"
        shadow="md"
        position="relative"
        overflow="hidden"
      >
        {currentReward && (
          <>
            <canvas
              ref={participantRef}
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: '3',
                backgroundColor: 'white',
                animation: 'fadeIn 0.25s',
              }}
            />
            <VStack
              fontWeight="bold"
              w="100%"
              h="100%"
              zIndex="4"
              position="absolute"
              justifyContent="center"
              fontSize={['md', 'md', 'md', '2xl']}
            >
              <Text>Great Job,</Text>
              <Text>{student.first_name}!</Text>
            </VStack>
          </>
        )}
        {mediaStream && (
          <>
            <video
              ref={participantVideoRef}
              onCanPlay={onCanPlayWebcam}
              autoPlay
              height={videoHeight}
              width={videoWidth}
              playsInline
              muted
              style={{
                display: mediaStream ? 'flex' : 'none',
                width: videoWidth,
                height: videoHeight,
                zIndex: 1,
              }}
            />
            {showVirtualBackground && (
              <>
                <canvas
                  ref={captureCanvasRef}
                  style={{
                    width: videoWidth,
                    height: videoHeight,
                    position: 'absolute',
                    zIndex: 0,
                  }}
                />
                <canvas
                  ref={canvasRef}
                  style={{ position: 'absolute', zIndex: 2 }}
                  height={videoHeight}
                  width={videoWidth}
                />
              </>
            )}
          </>
        )}

        <Flex
          h="80%"
          flexDir="column"
          justifyContent="center"
          display={mediaStream ? 'none' : 'flex'}
        >
          <Text fontSize="6xl" textAlign="center">
            {student && renderEmoji()}
          </Text>
        </Flex>
        {!mediaStream && (
          <Flex h="20%" alignItems="center" bg={mediaStream ? studentColor : 'gray.400'} w="100%">
            <Text
              w="100%"
              px={2}
              py={1}
              fontWeight={[null, null, 'regular', 'medium']}
              color={student.color ? 'black' : 'white'}
              textAlign="center"
              roundedBottomLeft="10px"
              roundedBottomRight="10px"
              fontSize={[null, '10px', 'sm', 'md']}
              isTruncated
            >
              {student.first_name}
            </Text>
          </Flex>
        )}
      </Flex>
    );
  };

  const renderRewardCounter = () => (
    <Flex
      bg="rgb(128, 90, 213, 0.75)"
      alignItems="center"
      rounded="lg"
      shadow="md"
      position="relative"
      overflow="hidden"
      mt={2}
      px={[2, 2, 2, 4]}
      animation={rewardCount === 0 ? 'fadeIn 0.5s' : currentReward ? 'bounce 1.0s' : null}
      fontSize={['2xl', '2xl', '2xl', '4xl']}
    >
      <Text>⭐️</Text>
      <Text ml={2} fontWeight="black" color="white">
        {rewardCount}
      </Text>
    </Flex>
  );

  const renderToggleVirtualBackground = () => (
    <Button
      isLoading={showVirtualBackground && !virtualBackgroundStarted}
      backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                    rgba(255, 255, 255, 0) 93.72%)"
      borderWidth="2px"
      borderColor={virtualBackgroundStarted && showVirtualBackground ? 'purple.500' : 'gray.700'}
      w="100%"
      _hover={{ color: 'black', fontWeight: 'bold' }}
      onClick={() => setShowVirtualBackground(!showVirtualBackground)}
      bg="#ffc60b"
      style={{
        backgroundBlendMode: 'soft-light, normal',
      }}
    >
      <Text fontSize={['xs', null, 'sm']}>
        {showVirtualBackground ? 'Turn Off Background' : 'Turn On Background'}
      </Text>
    </Button>
  );

  const renderVideoPlayer = () => (
    <VideoPlayer
      vodRef={vodRef}
      videoJsRef={videoJsRef}
      options={{
        poster: `${CDN_HOSTNAME}/posters/black.png`,
        controls: classType !== 'quiz',
        bigPlayButton: false,
        loadingSpinner: true,
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          vhs: {
            overrideNative: true,
          },
        },
        controlBar: {
          volumePanel: false,
          pictureInPictureToggle: false,
          fullscreenToggle: false,
          timeDivider: false,
          liveDisplay: false,
          seekToLive: false,
          playbackRateButtonMenu: false,
          chaptersButton: false,
          descriptionButton: false,
          subCapsButton: false,
          audioTrackButton: false,
        },
        sources: zipClass.sources,
      }}
      zipClass={zipClass}
      timeDifference={timeDifference}
      setIsBuffering={setIsBuffering}
      isBuffering={isBuffering}
      setIsMuted={setIsMuted}
      isMuted={isMuted}
      setDidStartVod={setDidStartVod}
      didStartVOD={didStartVOD}
      checkForTrigger={checkForTrigger}
      setRecordTimeInterval={setRecordTimeInterval}
      recordTimeInterval={recordTimeInterval}
      classType={classType}
    />
  );

  const renderMetadata = () => (
    <Stack w="100%" py={2} px={[5, 8]} bg="#EBEBEB" flexWrap="wrap">
      <Text fontWeight="medium" fontSize={['sm', 'md', 'lg']}>
        {`${zipClass.zipClass ? zipClass.zipClass.subject_icon : ''} ${
          zipClass.zipClass ? zipClass.zipClass.title : ''
        }`}
      </Text>
      {classType !== 'quiz' && (
        <Flex justifyContent="space-between">
          <HStack>
            <Avatar size="xs" src={zipClass.teacher ? zipClass.teacher.image_url : ''} />
            <Text fontWeight="medium" color="#8F8F8F" fontSize={['sm', 'md', 'lg']}>
              {`Teacher ${zipClass.teacher ? zipClass.teacher.first_name : ''}`}
            </Text>
          </HStack>
        </Flex>
      )}
    </Stack>
  );

  const renderPoll = () => (
    <Flex
      ref={pollContainerRef}
      position="relative"
      flexDir="column"
      w="100%"
      rounded="lg"
      shadow="lg"
      p={3}
      bg="white"
    >
      <Fade in={showPoll}>
        <Flex justifyContent="center" alignItems="center" w="100%">
          <ProgressRing
            radius={35}
            stroke={4}
            strokeColor="#805AD5"
            progress={classType === 'quiz' ? '100' : pollProgress}
            style={{
              animation:
                classType === 'quiz' && !selectedAnswer && didStartVOD
                  ? 'quiz-animation 2s infinite'
                  : '',
            }}
          />
        </Flex>
        <Text textAlign="center" fontWeight="bold" fontSize={['md', 'md', 'lg', 'xl']} pb={2}>
          {currentPoll && currentPoll.title}
        </Text>
        {currentPoll && currentPoll.poll_type === 'picture' && (
          <SimpleGrid columns={2} spacing={2}>
            {currentPoll.answers && currentPoll.answers.map(a => renderAnswerItem(a))}
          </SimpleGrid>
        )}

        {currentPoll && currentPoll.poll_type !== 'picture' && (
          <Stack w="100%" justifyContent="center" alignItems="center">
            {currentPoll.answers && currentPoll.answers.map(a => renderAnswerItem(a))}
          </Stack>
        )}

        {classType === 'quiz' && (
          <VStack mt={2} w="100%">
            {!didStartVOD && <Text color="black">Tap the 'Start Quiz' button below! ✨</Text>}
            <Button
              disabled={currentPoll && currentPoll.showAnswer}
              onClick={didStartVOD ? revealAnswerAction : getStartedAction}
              width="100%"
              color="white"
              bg="purple.500"
              fontWeight="bold"
              h="50px"
              rounded="lg"
              w="100%"
              style={{
                visibility: !didStartVOD || selectedAnswer ? 'visible' : 'hidden',
                animation: 'submit-animation 2s infinite',
              }}
              fontSize={['md', 'lg']}
            >
              {!didStartVOD && !currentPoll ? 'Start Quiz' : 'Submit Answer'}
            </Button>
          </VStack>
        )}
      </Fade>
    </Flex>
  );

  const renderLandscapeChannel = () => (
    <Flex
      id="landscape_channel"
      className="vod-width-transition"
      position="relative"
      flexDir="column"
      alignItems="center"
      bg="rgba(251, 244, 231, 0.5)"
      w={showPoll || (currentPoll && !currentPoll.hidden) ? '40%' : '25%'}
      h="100%"
      p={[3, 3]}
      onTransitionEnd={() => {
        if (currentPoll && !currentPoll.hidden) {
          setShowPoll(true);
        } else {
          setShowPoll(false);
        }
      }}
    >
      {/* Camera And Actions */}
      {!showPoll && (
        <>
          <VStack
            spacing={2}
            alignItems="center"
            justifyContent={!mediaStream ? 'space-between' : 'flex-end'}
            h="100%"
            w="100%"
          >
            <VStack>
              {student && !isiOSNativeDevice() && classType !== 'quiz' && renderParticipant()}
              {/* {mediaStream &&
                !isPortraitMode &&
                zipClass &&
                zipClass.virtual_bg_url &&
                !isiOSNativeDevice() &&
                renderToggleVirtualBackground()} */}
            </VStack>

            {classType !== 'quiz' && renderInteractiveButtons()}
          </VStack>
          {renderInteractiveChannel()}
        </>
      )}

      {/* Poll */}
      {showPoll && (
        <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
          {renderPoll()}
        </Flex>
      )}
    </Flex>
  );

  const renderVODView = () => {
    const getVideoContainerWidth = () => {
      if (isPortraitMode) {
        return '100%';
      }

      if (currentPoll && !currentPoll.hidden) {
        return '60%';
      }

      return '75%';
    };

    return (
      <>
        {isiOSNativeDevice() && isPortraitMode && (
          <Box bg="rgba(251, 244, 231, 0.5)" w="100%" h="8%" p={2}>
            {renderControlBar()}
          </Box>
        )}

        {/* Video Section */}
        <Box
          className="vod-width-transition"
          position="relative"
          w={getVideoContainerWidth()}
          h={isPortraitMode ? '40%' : '100%'}
          bg="black"
          zIndex="2"
          overflow="scroll"
        >
          {/* Get Started Play Button */}
          {isMobileDevice() && didStartVOD && showPlayButton && renderGetStartedPlayButton()}

          {/* Back Button */}
          {isiOSNativeDevice() && !isPortraitMode && !isNoAuth && (
            <IconButton
              position="absolute"
              zIndex="3"
              m={5}
              aria-label="Back button"
              icon={<ChevronLeftIcon />}
              size={('md', 'lg')}
              fontSize={['25px', '30px']}
              isRound
              bg="gray.300"
              color="gray.700"
              onClick={onBack}
            />
          )}

          {/* Skip Button */}
          {isiOSNativeDevice() && !isPortraitMode && isNoAuth && (
            <Button
              position="absolute"
              zIndex="3"
              left="0"
              m={3}
              colorScheme="gray"
              bg="#EBEBEB"
              color="gray.700"
              rounded="full"
              size="md"
              shadow="lg"
              onClick={onSkipAction}
            >
              Skip
            </Button>
          )}

          {/* Game  */}
          {currentGame && (
            <iframe
              title="game"
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 2,
              }}
              width="100%"
              height="100%"
              src={currentGame.url}
            />
          )}

          {/* Reward Counter */}
          <Flex
            w="30%"
            position="absolute"
            right="0"
            zIndex="5"
            justifyContent="flex-end"
            p={[2, 2, 4, 6]}
          >
            {rewardCount > 0 && renderRewardCounter()}
          </Flex>

          {/* Video Player */}
          {renderVideoPlayer()}
        </Box>

        {/* Landscape Channel */}
        {!isPortraitMode && renderLandscapeChannel()}

        {/* Polls & Metadata */}
        {isPortraitMode && (
          <Flex w="100%" h="60%" flexDir="column">
            {!currentPoll && renderMetadata()}
            {showPoll && (
              <Flex w="100%" justifyContent="center" alignItems="center" p={[3, 5]}>
                {renderPoll()}
              </Flex>
            )}
          </Flex>
        )}
      </>
    );
  };

  // UseEffects
  useEffect(() => {
    try {
      window.scroll(0, 0);
      if (!isNoAuth) {
        getCurrentProfile();
      }
      setIsBuffering(true);
      if (zipClass && zipClass.child && zipClass.zipClass) {
        const {
          zipClass: { title },
        } = zipClass;
        analytics.t('courses: viewed vod view', {
          courseTitle: title,
          lessonIndex: zipClass.current_lesson_course + 1,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  }, []);

  useEffect(() => {
    if (!currentPoll || currentPoll.showAnswer) return;

    const pollLength = currentPoll.reveal - currentPoll.start;
    const pollCountdownInterval = setInterval(() => {
      if (vodRef && vodRef.current) {
        const { currentTime } = vodRef.current;
        const inSeconds = Math.floor(currentTime);

        if (inSeconds === currentPoll.reveal - 1) {
          setPollProgress(100);
          if (pollCountdownInterval) {
            clearInterval(pollCountdownInterval);
          }
        } else {
          setPollProgress(Math.floor(100 - ((currentPoll.reveal - inSeconds) / pollLength) * 100));
        }
      }
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      if (pollCountdownInterval) clearInterval(pollCountdownInterval);
    };
  }, [currentPoll]);

  useEffect(() => {
    try {
      if (showVirtualBackground) {
        loadBodyPix();
        analytics.t('turned on virtual background');
      } else if (showVirtualBackground !== null) {
        analytics.t('turned off virtual background');
        setVirtualBackgroundStarted(false);
      }
    } catch (error) {
      console.warn('VODView Error: ', error);
    }
  }, [showVirtualBackground]);

  useEffect(() => {
    try {
      if (vodRef.current && currentUser) {
        let id;

        if (currentUser.idToken) {
          const { payload = {} } = currentUser.idToken;
          id = payload.sub;
        }

        // mux.monitor(vodRef.current, {
        //   debug: false,
        //   data: {
        //     env_key: zipClass.mux_env_key, // required
        //     player_init_time: Date.now() - timeDifference || Date.now(),
        //     player_version: process.env.REACT_APP_VERSION,
        //     viewer_user_id: id,
        //     video_id: zipClass.zipClass.id,
        //     video_title: zipClass.zipClass.title,
        //     view_session_id: viewId,
        //   },
        // });
      }
    } catch (error) {
      console.error('VODView Error: ', error);
    }
  }, [vodRef, currentUser]);

  useEffect(() => {
    try {
      if (currentReward && vodRef && vodRef.current) {
        intitiateConfettiCannonAsync('reward');
      }
    } catch (error) {
      console.error('VODView Error: ', error);
    }
  }, [currentReward]);

  useEffect(() => {
    if (isPortraitMode && currentPoll) {
      setShowPoll(true);
    }
  }, [currentPoll]);

  useEffect(() => {
    try {
      if (currentPoll && currentPoll.showAnswer && pollRef && pollRef.current) {
        // Play sound
        playPollAudio('reveal');
        intitiateConfettiCannonAsync('poll');
      }
    } catch (error) {
      console.error('VODView Error: ', error);
    }
  }, [currentPoll && currentPoll.showAnswer]);

  useEffect(() => {
    try {
      if (mediaStream && participantVideoRef && participantVideoRef.current && !showPoll) {
        participantVideoRef.current.srcObject = mediaStream;
      }
    } catch (error) {
      console.error('VODView Error: ', error);
    }
  }, [mediaStream, isPortraitMode, showPoll]);

  return (
    <Box minW="100%" h="100%" bg="white">
      {showReward && (
        <RewardModal
          reward={reward}
          next={next}
          onNext={() => {
            const params = new URLSearchParams(window.location.search);
            params.set('cohort_id', next.campaign_id);
            setShowReward(false);
            const path = `/live/${next.class_id}?${params.toString()}`;
            const url = `${window.location.protocol}//${window.location.hostname}${path}`;
            window.location = url;
          }}
          onClose={goBack}
          currentLessonTitle={zipClass.zipClass.title}
          currentLessonIndex={zipClass.current_lesson_course + 1}
        />
      )}
      <Flex
        w="100%"
        h="100%"
        flexDir={isPortraitMode ? 'column' : 'row'}
        bg="rgba(251, 244, 231, 0.5)"
        backgroundImage="url(https://www.transparenttextures.com/patterns/cubes.png)"
      >
        {renderVODView()}
      </Flex>
      {/* Audio Tags */}
      <audio ref={selectedPollRef} autoPlay={false}>
        <source src={`${CDN_HOSTNAME}/sounds/effects/poll-answer-selected.mp3`} />
      </audio>
      <audio
        ref={revealPollRef}
        autoPlay={false}
        muted={revealPollAudioMuted}
        onEnded={() => {
          if (revealPollAudioMuted) {
            setRevealPollAudioMuted(false);
          }
        }}
      >
        <source src={`${CDN_HOSTNAME}/sounds/effects/correct-poll-answer.mp3`} />
      </audio>
      <audio ref={reactionAudioRef} autoPlay={false} />
    </Box>
  );
};

export default VODView;
