/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Flex, Text, Stack } from '@chakra-ui/react';
import { AVATAR, analytics, convertTZ, isMobileSafari } from '@utils';
import { CDN_HOSTNAME } from '@constants';

const CountdownView = ({
  zipClass,
  student,
  mediaStream,
  timeDifference = 0,
  style,
  videoWidth,
  videoHeight,
}) => {
  // Local Properties
  const { zipClass: zipClassData } = zipClass;

  // State
  const [timeLeft, setTimeLeft] = useState('placeholder');
  const [trackIndex, setTrackIndex] = useState(0);

  // References
  const videoRef = useRef(null);
  const audioRef = useRef(null);

  // Actions
  const onAudioEnded = () => {
    try {
      console.log('Audio ended, getting next track');
      setTrackIndex(prevState => {
        audioRef.current.src = `${CDN_HOSTNAME}/sounds/songs/${prevState + 1}.mp3`;

        return prevState + 1;
      });
    } catch (error) {
      console.error('CountdownView Error: ', error);
    }
  };

  const onAudioError = () => {
    console.log('Error loading song, start from the beginning');
    setTrackIndex(0);
    audioRef.current.src = `${CDN_HOSTNAME}/sounds/songs/0.mp3`;
  };

  const onAudioLoaded = () => {
    try {
      if (audioRef && audioRef.current) {
        audioRef.current.volume = 0.1;
        audioRef.current.play();
      }
    } catch (error) {
      console.error('CountdownView Error: ', error);
    }
  };

  // Render Methods
  const renderEmoji = () => {
    const emoji = AVATAR.EMOJIS.find(e => e.title === student.emoji);
    if (emoji) return emoji.value;

    return AVATAR.EMOJIS[0].value;
  };

  const renderParticipant = () => {
    const studentColor = (student && student.color) || 'purple.300';

    const onCanPlay = () => {
      try {
        if (videoRef && videoRef.current) {
          videoRef.current.play();
        }
      } catch (error) {
        console.error('CountdownView Error: ', error);
      }
    };

    return (
      <Flex
        w={videoWidth}
        flexDir="column"
        bg={studentColor}
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
        borderWidth={4}
        borderColor={!mediaStream ? 'gray.400' : studentColor}
        rounded="lg"
        shadow="md"
      >
        <video
          ref={videoRef}
          onCanPlay={onCanPlay}
          autoPlay
          playsInline
          muted
          style={{
            display: mediaStream ? 'block' : 'none',
          }}
        />
        <Flex
          flexDir="column"
          minH={videoHeight}
          display={mediaStream ? 'none' : 'flex'}
          alignItems="center"
          justifyContent="center"
        >
          <Text fontSize="6xl" textAlign="center">
            {student && renderEmoji()}
          </Text>
        </Flex>
        <Flex zIndex="1" bg={mediaStream ? studentColor : 'gray.400'} w="100%">
          <Text
            w="100%"
            px={2}
            py={1}
            fontWeight={[null, null, 'regular', 'medium']}
            color={student.color ? 'black' : 'white'}
            textAlign="center"
            roundedBottomLeft="10px"
            roundedBottomRight="10px"
            fontSize={[null, '10px', 'sm', 'md']}
            isTruncated
          >
            {student.first_name}
          </Text>
        </Flex>
      </Flex>
    );
  };

  // UseEffects
  useEffect(() => {
    try {
      analytics.t('viewed countdown view');
      if (isMobileSafari() && audioRef && audioRef.current) {
        // The onDataLoaded event does not get called on Mobile Safari
        // so we need to start audio playback here
        audioRef.current.volume = 0.1;
        audioRef.current.play();
      }
    } catch (error) {
      console.error('CountdownView Error: ', error);
    }
  }, []);

  useEffect(() => {
    let interval;

    try {
      interval = setInterval(() => {
        const correctTime = Date.now() - timeDifference;
        if (zipClass && correctTime < zipClass.timestamp) {
          setTimeLeft(convertTZ(zipClass.timestamp, { mode: 'countdown', now: correctTime }));
        } else {
          setTimeLeft(false);
        }
      }, 1000);
    } catch (error) {
      console.error('CountdownView Error: ', error);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timeDifference]);

  useEffect(() => {
    try {
      if (mediaStream && videoRef) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (error) {
      console.error('CountdownView Error: ', error);
    }
  }, [mediaStream]);

  return (
    <Stack
      style={style}
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      spacing={5}
      p={3}
    >
      {zipClass && (
        <Stack spacing={1} flexDir="column" justifyContent="center" alignItems="center">
          <Text
            color="white"
            fontWeight="black"
            fontSize={['3xl', '4xl', '5xl', '6xl']}
            textAlign="center"
            style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)', animation: 'fadeIn 0.8s' }}
          >
            {zipClassData && zipClassData.classTitle}
          </Text>
          <Text
            color="white"
            fontWeight="bold"
            fontSize={['sm', 'md', 'lg', 'xl']}
            textAlign="center"
            style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)', animation: 'fadeIn 1.5s' }}
          >
            Hang tight! You will automatically enter the classroom when class starts!
          </Text>
        </Stack>
      )}

      <Text
        color="white"
        fontWeight="bold"
        fontSize={['2xl', '3xl', '4xl', '5xl']}
        style={{
          textShadow: '0 -1px 0 rgba(0, 0, 0, .35)',
          animation: timeLeft === 'placeholder' ? 'none' : 'fadeIn 0.8s',
          opacity: timeLeft === 'placeholder' ? 0 : 1,
        }}
      >
        {timeLeft ? `⏰ ${timeLeft}` : ' '}
      </Text>
      {renderParticipant()}
      {process.env.NODE_ENV === 'production' && (
        <audio
          ref={audioRef}
          autoPlay={false}
          onEnded={onAudioEnded}
          onLoadedData={onAudioLoaded}
          onError={onAudioError}
        >
          <source src={`${CDN_HOSTNAME}/sounds/songs/${trackIndex}.mp3`} />
        </audio>
      )}
    </Stack>
  );
};

export default CountdownView;
