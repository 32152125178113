/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Image, Flex, useToast } from '@chakra-ui/react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import actions from '@actions';
import { analytics, showErrorToast, nativeActionTransaction } from '@utils';
import { CDN_HOSTNAME } from '@constants';
import { useNativeCallback } from '@hooks';
import ClassListNative from './ClassListNative';
import TrackDetailPageNative from '../TrackDetailPageNative/TrackDetailPageNative';
import constants from '../constants';

const ClassListNativeContainer = ({ fetchProfile }) => {
  // State
  const [campaigns, setCampaigns] = useState([]);
  const [currentView, setCurreView] = useState(null);
  const [trackPageItem, setTrackPageItem] = useState(null);

  // Hooks
  const toast = useToast();
  const location = useLocation();
  const nativeCallback = useNativeCallback();

  // References
  const petRef = useRef(null);

  // Actions
  /**
   * The goal here is to grab the next lesson that has not been completed based on track order
   */
  const getNextClass = items => {
    let foundClass = null;

    for (let i = 0; i < items.length; i += 1) {
      if (foundClass) break;

      const campaign = items[i];

      // Look to see if this campaign has any untaken classes, if so push to that
      foundClass = campaign.classes.find(classItem => !classItem.completed);
      if (foundClass) {
        foundClass.campaign_id = campaign.id;
      }
    }

    return foundClass;
  };

  const fetchCampaigns = async () => {
    try {
      const result = await actions.fetchCampaigns();
      const pathId = location.pathname.split('/').pop();
      const foundCampaign = result.campaigns.find(item => item.id === pathId);

      if (foundCampaign) {
        setTrackPageItem(foundCampaign);
      }

      // Add an item that will be the next class so we can add to the list
      const nextClass = getNextClass(result.campaigns);

      if (nextClass) {
        result.campaigns.unshift({ ...nextClass, mode: 'next_class_action' });
      }

      setCampaigns(result.campaigns);
    } catch (error) {
      console.warn('Error fetching campaigns: ', error);
      showErrorToast(toast, error.message);
    }
  };

  const fetchProfileAction = async () => {
    await fetchProfile();
  };

  const getBackgroundImage = () => {
    if (currentView === 'main') {
      return `url(${CDN_HOSTNAME}/native/background-images/main.jpeg)`;
    }

    if (trackPageItem) {
      return `url(${trackPageItem.details_background_url})`;
    }

    return null;
  };

  // UseEffects
  useEffect(() => {
    analytics.t('viewed lessons page');
    nativeActionTransaction(true, 'landscape');
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (nativeCallback) {
      const { action, data = {} } = nativeCallback;
      const { type } = data;

      if (
        action === constants.NATIVE_ACTIONS.LIFECYCLE &&
        type === constants.LIFECYCLE_EVENTS.VIEW_DID_APPEAR
      ) {
        nativeActionTransaction(true, 'landscape');

        if (petRef && petRef.current) {
          if (petRef.current.paused) {
            petRef.current.play();
          }
        }
      }
    }
  }, [nativeCallback]);

  // This useEffect is to tell if we are on /class-mobile OR /class-mobile/campaign/*
  useEffect(() => {
    if (location.pathname.split('/').pop() === 'classes-mobile') {
      setCurreView('main');
    } else {
      setCurreView('details');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (currentView === 'main') {
      fetchProfileAction();
    }
  }, [currentView]);

  return (
    <Flex
      w="100%"
      h="100%"
      backgroundImage={getBackgroundImage()}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      py={[3, 5]}
      pl={[3, 5]}
    >
      <Switch>
        <Route
          exact
          path="/classes-mobile"
          render={() => (
            <ClassListNative items={campaigns} fetchProfile={fetchProfile} petRef={petRef} />
          )}
        />
        <Route
          exact
          path="/classes-mobile/campaign/:campaign_id"
          render={() => (
            <TrackDetailPageNative fetchCampaigns={fetchCampaigns} item={trackPageItem} />
          )}
        />
        <Redirect exact to="/classes-mobile" />
      </Switch>
    </Flex>
  );
};

export default ClassListNativeContainer;
