/* eslint-disable react/prop-types */
import React from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';

const AddEmailAndPassword = ({ form, validateEmail, validatePassword, decodedEmail }) => {
  // Render Methods
  const renderEmailAndPasswordFields = () => (
    <VStack mt={3} spacing={3}>
      {/*<FormControl isInvalid={form.errors.email} isRequired>
        <FormLabel fontSize={['md', 'lg']}>Email</FormLabel>
        <Input
          name="email"
          placeholder="Email"
          defaultValue={decodedEmail || ''}
          type="email"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          fontSize="md"
          ref={form.register({ validate: validateEmail })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.email && form.errors.email.message}
        </FormErrorMessage>
  </FormControl>*/}
      <FormControl isInvalid={form.errors.password} isRequired>
        <FormLabel fontSize={['md', 'lg']}>Create Password</FormLabel>
        <Text my={1} fontSize="sm" color="gray.600">
          Make sure this is at least 8 characters
        </Text>
        <Input
          name="password"
          placeholder="Password"
          type="password"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          fontSize="md"
          ref={form.register({ validate: validatePassword })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.password && form.errors.password.message}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  );

  return (
    <VStack px={4} w="100%" animation="fadeIn 0.4s">
      <Flex w="100%" flexDir="column" justifyContent="center" pt={3}>
        <Text color="#383838" fontSize={['xl', '3xl']} fontWeight="bold" textAlign="center">
          🎉 Last Step. Promise!
        </Text>
        <Text
          color="gray.400"
          fontWeight="bold"
          fontSize={['sm', 'md']}
          textAlign="center"
          pt={[1, 3]}
        >
          Create an account to get started
        </Text>
        {renderEmailAndPasswordFields()}
      </Flex>
    </VStack>
  );
};

export default AddEmailAndPassword;
