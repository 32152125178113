/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/prop-types */
import React from 'react';
import { Flex } from '@chakra-ui/react';
import Rewards from './Rewards';

const RewardsContainer = ({ setHeaderProfile, profile }) => {
  return (
    <Flex w="100%" minH="100%" flexDir="column" bg="purple.500">
      <Flex w="100%" h="100%" flexDir="column" alignItems="center">
        <Rewards setHeaderProfile={setHeaderProfile} profile={profile} />
      </Flex>
    </Flex>
  );
};

export default RewardsContainer;
