/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import {
  AspectRatio,
  Box,
  Button,
  Text,
  Image,
  Stack,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { analytics } from '@utils';
import { CDN_HOSTNAME } from '@constants';
import { modalActions } from '../../../constants';

const POSTER_GIF = `${CDN_HOSTNAME}/posters/match-modal-poster.gif`;

const ActionModal = ({ action, classData, isOpen, onClose, setClassMatch }) => {
  // Hooks
  const history = useHistory();

  // Actions
  const takeFreeClassAction = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryEmail = urlParams.get('email');
    const decodedEmail = queryEmail ? decodeURIComponent(queryEmail) : '';
    let path = `/noauth/classes/${classData.class_id}`;

    analytics.t('clicked book now on swipe onboarding match modal', {
      title: classData ? classData.title : '',
      ageRange: classData ? classData.age_range : '',
    });

    if (decodedEmail) {
      path = `/noauth/classes/${classData.class_id}?email=${decodedEmail}`;
    }

    history.push(path);
  };

  // Render Methods
  const renderHeaderText = () =>
    action === modalActions.WELCOME ? (
      <Stack spacing={2}>
        <Text fontWeight="black" fontSize={['xl', '2xl']}>
          ✨ Welcome to ZipSchool
        </Text>
        <Text opacity="0.9" color="gray.700" fontSize={['sm', 'md']}>
          We create free classes for kids K-5 around topics they love.
        </Text>
      </Stack>
    ) : (
      <Stack spacing={2}>
        <Text fontWeight="black" fontSize={['xl', '2xl']}>
          💜 You found a class!
        </Text>
        {classData && (
          <Text opacity="0.9" color="gray.700" fontSize={['sm', 'md']}>
            {classData.title} (Ages {classData.age_range})
          </Text>
        )}
      </Stack>
    );

  const renderContent = () =>
    action === modalActions.WELCOME ? (
      <Stack mt={2} spacing={2}>
        <Stack spacing={1}>
          <Text fontSize={['md', 'lg']}>➡️ Swipe right on a class if you like it</Text>
          <Text fontSize={['md', 'lg']}>⬅️ Swipe left on a class if you want to see more</Text>
        </Stack>
      </Stack>
    ) : (
      <Stack mt={2} spacing={2}>
        <Stack spacing={1}>
          <Text fontSize={['md', 'lg']}>✅ Tap "Take Free Class" to take this class.</Text>
          <Text fontSize={['md', 'lg']}>
            ✅ Tap "Keep Exploring" to swipe your way through some more classes!
          </Text>
        </Stack>
      </Stack>
    );

  const renderClassVideo = () => {
    let posterImage = POSTER_GIF;

    if (action === modalActions.MATCH && classData) {
      posterImage = classData.poster.url;

      if (classData.poster.type === 'video') {
        return (
          <Box
            w="100%"
            h={['180px', '180px', '180px', '200px']}
            position="relative"
            rounded="lg"
            className="mobile-border-radius"
          >
            <Box
              w="100%"
              h="100%"
              backgroundImage="url('https://assets.zipschool.com/posters/gadgets.png')"
              backgroundRepeat="repeat"
              overflow="hidden"
            >
              <AspectRatio roundedTop="lg">
                <video
                  src={posterImage}
                  muted
                  playsInline
                  autoPlay
                  loop
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </AspectRatio>
            </Box>
          </Box>
        );
      }

      return (
        <Box
          w="100%"
          h={['180px', '180px', '180px', '200px']}
          position="relative"
          rounded="lg"
          className="mobile-border-radius"
        >
          <AspectRatio>
            <Image
              height="100%"
              animation="fadeIn 0.6s ease"
              roundedTop="lg"
              src={posterImage}
              objectFit="cover"
            />
          </AspectRatio>
        </Box>
      );
    }

    return null;
  };

  const renderActionButtons = () =>
    action === modalActions.WELCOME ? (
      <>
        <Button
          h={['50px', '55px']}
          bg="purple.500"
          fontSize={['lg', 'xl']}
          rounded="lg"
          color="white"
          _hover={{ background: 'purple.500' }}
          onClick={() => {
            analytics.t('clicked close on initial modal in swipe onboarding');
            onClose(false);
          }}
        >
          Let's Go!
        </Button>
      </>
    ) : (
      <Stack isInline>
        <Button
          h={['50px', '55px']}
          bg="gray.400"
          fontSize={['lg', 'xl']}
          rounded="lg"
          color="white"
          _hover={{ background: 'gray.400' }}
          onClick={() => {
            setClassMatch(false);
            analytics.t('clicked close on onboarding match modal', {
              title: classData ? classData.title : '',
              ageRange: classData ? classData.age_range : '',
            });
            onClose(false);
          }}
        >
          Keep Exploring
        </Button>
        <Button
          h={['50px', '55px']}
          bg="purple.500"
          fontSize={['lg', 'xl']}
          rounded="lg"
          color="white"
          _hover={{ background: 'purple.500' }}
          onClick={takeFreeClassAction}
        >
          Take Free Class!
        </Button>
      </Stack>
    );

  return (
    <Modal
      isOpen={isOpen}
      isCentered={action === modalActions.WELCOME}
      onClose={() => {
        if (action === modalActions.MATCH) {
          analytics.t('clicked close on onboarding match modal', {
            title: classData ? classData.title : '',
            ageRange: classData ? classData.age_range : '',
          });
          setClassMatch(false);
        } else {
          analytics.t('clicked close on initial modal in swipe onboarding');
        }
        onClose(false);
      }}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent m={3} rounded="lg">
        <ModalHeader>{renderHeaderText()}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {renderClassVideo()}
          {renderContent()}
        </ModalBody>
        <ModalFooter>{renderActionButtons()}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(ActionModal);
