/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useToast } from '@chakra-ui/react';
import { Redirect, Route, Switch, useParams, useLocation, useHistory } from 'react-router-dom';
import actions from '@actions';
import { analytics, showErrorToast } from '@utils';
import VODRoom from './VODRoom';
import ReviewScreen from '../ReviewScreen';
import NextClass from '../NextClass';

const VODRoomRouter = ({ authState }) => {
  // State
  const [currentUser, setCurrentUser] = useState({});
  const [student, setStudent] = useState({});
  const [zipClass, setZipClass] = useState({});
  const [loading, setLoading] = useState(false);
  const [reward, setReward] = useState(null);
  const [next, setNext] = useState(null);
  const [classType, setClassType] = useState(null);

  // Hooks
  const { booking_id: bookingId } = useParams();
  const location = useLocation();
  let history = useHistory();

  const toast = useToast();

  // Actions
  const getCurrentProfile = async () => {
    const response = await Auth.currentSession();
    setCurrentUser(response);
  };

  // UseEffects
  useEffect(() => {
    try {
      if (!location.pathname.includes('noauth')) {
        getCurrentProfile();
      }
    } catch (error) {
      console.warn(error);
    }
  }, []);

  // Actions
  const fetchClassroom = async silent => {
    if (!silent) setLoading(true);
    try {
      const {
        student: s,
        view_id: classViewId,
        reward: _reward,
        next: _next,
        class_type: _class_type,
        ...rest
      } = await actions.fetchZipClassroom(bookingId);
      setZipClass({ ...rest });
      setStudent(s);
      setReward(_reward);
      setNext(_next);
      setClassType(_class_type);
      if (classViewId) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set('view_id', classViewId);
        history.replace(`/live/${bookingId}?${searchParams.toString()}`);
      }
    } catch (err) {
      showErrorToast(toast, err.message);
      analytics.t('error connecting to in-class', { msg: err.message });
      // history.push(`/schedule`);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchClassroom();
    };

    fetchData();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path="/live/:booking_id"
        render={() => (
          <VODRoom
            authState={authState}
            fetchClassroom={fetchClassroom}
            loading={loading}
            student={student}
            setStudent={setStudent}
            zipClass={zipClass}
            profile={currentUser}
            reward={reward}
            next={next}
            classType={classType}
          />
        )}
      />
      <Route
        exact
        path="/noauth/live/:booking_id"
        render={() => (
          <VODRoom
            isNoAuth={location.pathname.includes('noauth')}
            fetchClassroom={fetchClassroom}
            loading={loading}
            zipClass={zipClass}
          />
        )}
      />
      <Route
        exact
        path="/live/:booking_id/feedback"
        render={() => <ReviewScreen currentUser={currentUser} />}
      />
      <Route
        exact
        path="/live/:booking_id/next"
        render={() => (
          <NextClass
            loading={loading}
            student={student}
            zipClass={zipClass}
            profile={currentUser}
          />
        )}
      />
      <Redirect exact to="/live/:booking_id" />
    </Switch>
  );
};

export default VODRoomRouter;
