/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AspectRatio, Box, Flex } from '@chakra-ui/react';
import videojs from 'video.js';
import { throttle } from 'lodash';
import { analytics, isMobileDevice } from '@utils';
import actions from '@actions';
// import ControlsModal from './components/ControlsModal';

const VideoPlayer = ({
  vodRef,
  videoJsRef,
  options,
  zipClass,
  timeDifference = 0,
  setIsBuffering,
  setIsMuted,
  isMuted,
  setDidStartVod,
  didStartVOD,
  checkForTrigger,
  setRecordTimeInterval,
  recordTimeInterval,
  classType,
}) => {
  // State
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [reachedCompletePoint, setReachedCompletePoint] = useState(false);

  // Hooks
  const { booking_id: bookingId } = useParams();

  // Actions
  const getWatchPct = () => {
    const time = currentPlayer.currentTime();
    return parseFloat(time) / zipClass.zipClass.duration;
  };

  const onPlaying = () => {
    setIsBuffering(false);

    setRecordTimeInterval(setInterval(recordTimeThrottle, 15000));
  };

  const recordTime = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const view_id = searchParams.get('view_id');
    if (currentPlayer && view_id) {
      try {
        if (getWatchPct() > 0.8) {
          setReachedCompletePoint(true);
        }

        await actions.fetchZipClassroom(bookingId, view_id, currentPlayer.currentTime());
      } catch (err) {
        console.log('error: ', err);
      }
    }
  };

  const recordTimeThrottle = throttle(recordTime, 15000, { trailing: true });

  const onCanPlayThrough = () => {
    console.log('CAN PLAY THROUGH');
    if (currentPlayer) {
      if (!bookingId.startsWith('CL')) {
        const startTime = Date.now() - timeDifference - zipClass.timestamp;

        if (startTime > 0) {
          console.log('CURRENT TIME SET');
          currentPlayer.currentTime(startTime / 1000);
        }
      }

      if (classType !== 'quiz') {
        // Found here - https://goo.gl/LdLk22
        const playPromise = currentPlayer.play();
        if (playPromise !== undefined) {
          playPromise
            // eslint-disable-next-line no-unused-vars
            .then(_ => {
              console.log('PLAY PROMISE RESOLVED.');

              if (!isMobileDevice()) {
                setIsMuted(false);
              }

              setIsBuffering(false);
              setDidStartVod(true);
            })
            .catch(error => {
              console.warn('OnCanPlayThrough Error: ', error);
            });
        }
      }
    }
  };

  const onPlayerReady = () => {
    console.log('PLAYER READY');
  };

  const onPlay = () => {
    if (didStartVOD) {
      let data = {};
      if (currentPlayer) {
        data = { time: Math.floor(currentPlayer.currentTime()) };
      }

      analytics.t('played video', data);
    }
  };

  const onPause = () => {
    if (didStartVOD) {
      let data = {};
      if (currentPlayer) {
        data = { time: Math.floor(currentPlayer.currentTime()) };
      }
      analytics.t('clicked paused on lesson', data);
    }
  };

  const onWaiting = () => {
    console.log('ON WAITING');
    // setIsBuffering(true);
  };

  // UseEffects
  useEffect(() => {
    try {
      if (vodRef && vodRef.current) {
        setCurrentPlayer(videojs(vodRef.current, options, onPlayerReady));
      }
    } catch (error) {
      console.error('VideoPlayer Error: ', error);
    }

    return () => {
      if (currentPlayer) {
        currentPlayer.dispose();
      }

      if (recordTimeInterval) {
        clearInterval(recordTimeInterval);
      }
    };
  }, []);

  useEffect(() => {
    // Setup listeners
    if (currentPlayer) {
      // eslint-disable-next-line no-param-reassign
      videoJsRef.current = currentPlayer;

      // onError
      currentPlayer.on('error', () => {
        analytics.t('vod error', { errorObj: currentPlayer.error().message });
      });

      // onSeeked
      currentPlayer.on(
        'seeked',
        throttle(() => recordTime, 10000),
      );
    }
  }, [currentPlayer]);

  useEffect(() => {
    if (didStartVOD && !isMobileDevice()) {
      let data = {};
      if (currentPlayer) {
        data = { time: Math.floor(currentPlayer.currentTime()) };
      }

      analytics.t('played video', data);
    }
  }, [didStartVOD]);

  useEffect(() => {
    if (reachedCompletePoint) {
      const { zipClass: zipClassData } = zipClass;
      analytics.t('completed 80% of lesson', {
        id: zipClassData.id,
        title: zipClassData.title,
        teacher: zipClassData.teacher.first_name,
        classDuration: zipClassData.duration,
      });
    }
  }, [reachedCompletePoint]);

  return (
    <Box className="native-bottom" w="100%" h="100%">
      <div data-vjs-player>
        <video
          className="video-js vjs-16-9"
          ref={node => (vodRef.current = node)}
          style={{ width: '100%', height: '100%' }}
          playsInline
          autoPlay={classType !== 'quiz'}
          preload="metadata"
          muted={isMuted}
          onCanPlayThrough={didStartVOD ? null : onCanPlayThrough}
          controlsList="nodownload"
          onPlay={onPlay}
          onPause={onPause}
          onWaiting={onWaiting}
          onPlaying={onPlaying}
          onTimeUpdate={checkForTrigger}
        />
      </div>
    </Box>
  );
};

export default VideoPlayer;
