/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { memo, useContext, useEffect, useRef } from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';
import { analytics } from '@utils';
import { CDN_HOSTNAME } from '@constants';
import VideoPreviewPlayer from './components/VideoPreviewPlayer';
import VideoPlayerModalContext from '../../Context/VideoPlayerModalContext';

const VideoPlayerModal = ({ resourceData }) => {
  // Hooks
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Context
  const videoPlayerModal = useContext(VideoPlayerModalContext);

  // References
  const videoPlayerRef = useRef(null);

  // Actions
  const onCloseAction = () => {
    if (videoPlayerRef && videoPlayerRef.current) {
      analytics.t('length of preview video watched', {
        seconds: Math.round(
          Math.abs(videoPlayerRef.current.currentTime() - resourceData.preview_start),
        ),
        preview_length: resourceData.preview_length,
      });
    }
    videoPlayerModal.setResourceData(null);
    onClose();
  };

  // UseEffects
  useEffect(() => {
    if (resourceData) {
      onOpen();
    }
  }, [resourceData]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCloseAction} autoFocus={false} size="4xl">
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalCloseButton size="lg" />
        <ModalHeader>
          <Text fontSize="xl" fontWeight="bold">
            {resourceData.title} Preview
          </Text>
        </ModalHeader>
        <ModalBody>
          <VideoPreviewPlayer
            options={{
              controls: true,
              controlBar: false,
              playsinline: true,
              poster: `${CDN_HOSTNAME}/posters/black.png`,
              sources: resourceData.sources,
            }}
            parentRef={videoPlayerRef}
            classTitle={resourceData.title}
            setResourceData={videoPlayerModal.setResourceData}
            previewStart={resourceData.preview_start}
            previewLength={resourceData.preview_length}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default memo(VideoPlayerModal);
