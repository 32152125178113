/* eslint-disable react/prop-types */
import React, { memo, useEffect } from 'react';
import {
  AspectRatio,
  Button,
  Text,
  Image,
  Stack,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { analytics } from '@utils';
import { CDN_HOSTNAME } from '@constants';

const POSTER_GIF = `${CDN_HOSTNAME}/posters/noauth-welcome-poster.gif`;

const WelcomeModal = ({ deviceType }) => {
  // Hooks
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Render Methods
  const renderImage = () =>
    deviceType === 'desktop' ? (
      <AspectRatio h={['250px', '275px']}>
        <Image rounded="lg" src={POSTER_GIF} />
      </AspectRatio>
    ) : (
      <Image objectFit="contain" m="auto" h={['200px', '250px']} rounded="lg" src={POSTER_GIF} />
    );

  // UseEffects
  useEffect(() => {
    onOpen();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        analytics.t('dismissed noauth modal', { button: 'close' });
        onClose();
      }}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent m={3} rounded="lg">
        <ModalHeader>
          <Stack isInline alignItems="center" spacing={2}>
            <Text fontWeight="black" fontSize={['xl', '2xl']}>
              ✨ Welcome to ZipSchool
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {renderImage()}
          <Stack mt={2} spacing={2}>
            <Stack spacing={1}>
              <Text fontSize={['md', 'lg']}>
                ✅ Book as many classes as you want, 100% free, no card required.
              </Text>
              <Text fontSize={['md', 'lg']}>
                ✅ Classes for students ages 4-12. Something for everyone!
              </Text>
              <Text fontSize={['md', 'lg']}>
                ✅ Can't make it? No worries. Cancel or reschedule anytime.
              </Text>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            h={['50px', '55px']}
            bg="purple.500"
            fontSize={['lg', 'xl']}
            rounded="lg"
            color="white"
            _hover={{ background: 'purple.500' }}
            onClick={() => {
              analytics.t('dismissed noauth modal', { button: 'explore_classes' });
              onClose();
            }}
          >
            Explore Classes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(WelcomeModal);
