/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Flex,
  Text,
  VStack,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { analytics } from '@utils';
import { childAgeOptions } from '../../constants';

const AddChildForm = ({ form }) => {
  // Actions
  const validateName = fieldName => value => {
    if (!value) {
      return `${fieldName} is required`;
    }

    return true;
  };

  // Render Methods
  const renderAgeSelector = () => (
    <FormControl isInvalid={form.errors.child_age} isRequired>
      <Flex flexDir="column">
        <FormLabel fontSize={['md', 'lg']} pb={2}>
          Child Age
        </FormLabel>
        <Select
          name="child_age"
          placeholder="Select age"
          variant="filled"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          spacing={3}
          cursor="pointer"
          ref={form.register({ required: true })}
        >
          {childAgeOptions.map(t => (
            <option key={t.id}>{t.name}</option>
          ))}
        </Select>
      </Flex>
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        Child age required
      </FormErrorMessage>
    </FormControl>
  );

  const renderFirstNameInput = () => (
    <FormControl isInvalid={form.errors.child_first_name} isRequired>
      <FormLabel fontSize={['md', 'lg']}>Child First Name</FormLabel>
      <Input
        name="child_first_name"
        placeholder="First name"
        type="text"
        h={['45px', '50px', '55px', '60px']}
        rounded="lg"
        fontSize="md"
        isRequired
        ref={form.register({ validate: validateName('First name') })}
      />
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        {form.errors.child_first_name && form.errors.child_first_name.message}
      </FormErrorMessage>
    </FormControl>
  );

  // UseEffects
  useEffect(() => {
    window.scroll(0, 0);
    analytics.t('courses: viewed add child form');
  }, []);

  return (
    <Flex flexDir="column" w="100%" h="100%" px={4} animation="fadeIn 0.4s">
      <Text
        color="#383838"
        fontSize={['lg', 'lg', '2xl', '3xl']}
        fontWeight="bold"
        textAlign="center"
      >
        🦄 Who is taking this course?
      </Text>
      <Text
        color="gray.400"
        fontWeight="bold"
        fontSize={['sm', 'md']}
        textAlign="center"
        pt={[1, 3]}
      >
        Let us know your child’s first name and age
      </Text>
      <VStack w="100%" spacing={3}>
        {renderFirstNameInput()}
        {renderAgeSelector()}
      </VStack>
    </Flex>
  );
};

export default AddChildForm;
