export const onboardingSteps = {
  ADD_TOPICS: 'add_topics',
  PUSH_NOTIFICATION_MODAL: 'push_notification_modal',
  SIGNUP: 'signup',
  ADD_EMAIL_PASSWORD: 'add_email_password',
  ADD_EMAIL_PW: 'add_email_pw',
  ADD_PARENT_NAME_PHONE: 'add_parent_name_phone',
  ADD_CHILD: 'add_child',
  SCHEDULE_PICKER: 'schedule_picker',
  CHILD_INFO: 'child_info',
  VIEW_SCHEDULE: 'view_schedule',
  PACKAGE_SIGNUP_FLOW: 'package_signup',
  PET_SELECT: 'pet_select',
  PARENT_LESSON: 'parent_lesson',
};
export const PUSH_AUTHORIZATION_STATUS = {
  NOT_DETERMINED: 0,
  DENIED: 1,
  AUTHORIZED: 2,
  PROVISIONAL: 3,
  EPHMERAL: 4,
};

export const modalActions = { WELCOME: 'welcome', MATCH: 'match' };

export const TOPICS_LIST = [
  { id: 'space', name: '🚀 Space' },
  { id: 'earthscience', name: '🌎 Earth Science' },
  { id: 'dinosaurs', name: '🦖 Dinosaurs' },
  { id: 'geology', name: '🌋 Geology' },
  { id: 'humanbody', name: '🦴 Human Anatomy' },
  { id: 'insects', name: '🦋 Insects' },
  { id: 'animals', name: '🐱 Animals' },
  { id: 'math', name: '💯 Math' },
  { id: 'plants', name: '🌱 Plant Science' },
  { id: 'marinebiology', name: '🐬 Marine Science' },
  { id: 'reading', name: '📚 Reading' },
  { id: 'howthingsaremade', name: '🛠 How Things Are Made' },
  { id: 'machines', name: '🚜 Machines and Vehicles' },
  { id: 'physics', name: '⚛️ Early Physics' },
  { id: 'chemistry', name: '👩‍🔬 Basic Chemistry' },
  { id: 'engineering', name: '⚙️ Engineering Concepts' },
  { id: 'otherstem', name: '🧪 Other STEM Topics!' },
];

export const availableTimes = [
  { timestamp: 1577898000000 },
  { timestamp: 1577908800000 },
  { timestamp: 1577919600000 },
];

export const daysOfWeek = [
  { id: 'monday', name: 'Mondays' },
  { id: 'tuesday', name: 'Tuesdays' },
  { id: 'wednesday', name: 'Wednesdays' },
  // { id: 'thursday', name: 'Thursdays' },
  // { id: 'friday', name: 'Fridays' },
];

export const childAgeOptions = [
  // { id: '3', name: '3', bracket: '3-6' },
  { id: '4', name: '4', bracket: '3-6' },
  { id: '5', name: '5', bracket: '3-6' },
  { id: '6', name: '6', bracket: '3-6' },
  { id: '7', name: '7', bracket: '7-9' },
  { id: '8', name: '8', bracket: '7-9' },
  { id: '9', name: '9', bracket: '7-9' },
  { id: '10', name: '10', bracket: '10-12' },
  { id: '11', name: '11', bracket: '10-12' },
  { id: '12', name: '12', bracket: '10-12' },
];

export const scheduleOptions = [
  {
    imageUrl: 'https://media.giphy.com/media/cwesftWHff9gsP4lbs/giphy.gif',
    title: 'Sea Explorer: Coral Reefs, Clownfish and Sea Horses',
    subject: 'Marine Biology',
    subjectIcon: '🐠',
    description:
      'The coral reef is a beautiful place where thousands of interesting species live. Take out your scuba gear, let’s dive in, and let’s see what we find!',
  },
  {
    imageUrl: 'https://media.giphy.com/media/tunjsgpLwJNAt1bFcU/giphy.gif',
    title: 'Sea Explorer: Kelp Forests, Leopard Sharks and Otters',
    subject: 'Marine Biology',
    subjectIcon: '🐠',
    description:
      'Kelp forests are kind of like forests, but, underwater! It’s home to many species that live peacefully together which we’ll be exploring in this class.',
  },
  {
    imageUrl: 'https://media0.giphy.com/media/mkQKMRT8Ri63ln4cMY/giphy.gif',
    title: 'Sea Explorer: The Deep Sea, Anglerfish and Vampire Squid',
    subject: 'Marine Biology',
    subjectIcon: '🐠',
    description:
      'The deep sea is an extremely mysterious place. Let’s take our submarine as far down as we can go and see what we find!',
  },
  {
    imageUrl: 'https://media.giphy.com/media/HMOFXwvLwiavRLdPFN/giphy.gif',
    title: 'Space Explorer: Blasting Off',
    subject: 'Astronomy',
    subjectIcon: '🚀',
    description:
      'Hmmmm. We’re trying to blast off to space, but, our rocket isn’t working. Lets learn about how rockets work so we can fix it and blast off!',
  },
  {
    imageUrl: 'https://media.giphy.com/media/nRGv2Vl0W7BLlP4TVA/giphy.gif',
    title: 'Space Explorer: Our Sun and Stars',
    subject: 'Astronomy',
    subjectIcon: '🚀',
    description:
      'Our sky is full of stars, but, what exactly are they? And - why is the sun the most important star of all? Let’s explore the properties of stars and what makes our sun special.',
  },
  {
    imageUrl:
      'https://media1.giphy.com/media/FWtVYDHIxgGgE/giphy.gif?cid=ecf05e47s4ge5unkdbh57b5pbs03zl1flgletmx2kbh9o3kh&rid=giphy.gif',
    title: 'Space Explorer: Our Moon and Satellites',
    subject: 'Astronomy',
    subjectIcon: '🚀',
    description:
      'Let’s visit our neighbor not to far from us, the moon! Let’s see what its like on its surface and explore it using our special space car!',
  },
  {
    imageUrl: 'https://media.giphy.com/media/XcS16xRRA0l89CSSUb/giphy.gif',
    title: 'Amazing Animal Adaptations: Deserts',
    subject: 'Bioscience',
    subjectIcon: '🐪',
    description:
      'The desert is a difficult place to live! Let’s learn about some fascinating animals that have adaptations that allow them to live in the desert.',
  },
  {
    imageUrl: 'https://media.giphy.com/media/cmLSBYTm9v5pLZgttX/giphy.gif',
    title: 'Amazing Animal Adaptations: Rainforests',
    subject: 'Bioscience',
    subjectIcon: '🌱',
    description:
      'Imagine living in a jungle! It’s always hot, rainy, and there are tons of trees. Let’s learn about how a few special rainforest animals have adapted to their habitat.',
  },
  {
    imageUrl: 'https://media.giphy.com/media/IEZ8Xt1Kw1b84eBPeH/giphy.gif',
    title: 'Doctor Training: Our Noses and How We Smell',
    subject: 'Medicine',
    subjectIcon: '👩‍⚕️',
    description:
      "We have a patient that needs out help, he can't taste anything! In this class, your child will join our team of Junior Doctors to learn more about the tounge and help cure the patient.",
  },
  {
    imageUrl: 'https://media.giphy.com/media/42pEB0uxfjnyRvKCtp/giphy.gif',
    title: 'Doctor Training: Our Tongues and How We Taste',
    subject: 'Medicine',
    subjectIcon: '👩‍⚕️',
    description:
      'Junior Doctors, we need your help once again! We have a patient who’s having a hard time smelling things!! He can’t even smell his stinky sock. Let’s investigate our noses!',
  },
  {
    imageUrl:
      'https://media4.giphy.com/media/iwabqMCS6kY2k/giphy.gif?cid=ecf05e47y4kjb4p4le8ji4vgqw4iuckya0pdg978bvnjox6e&rid=giphy.gif',
    title: 'How Things Are Made: Cars',
    subject: 'Engineering',
    subjectIcon: '🛠',
    description:
      'Cars are marvels of modern engineering and we use one almost everyday, but, how are they made? Let’s talk all about factories and robots!',
  },
  {
    imageUrl: 'https://media.giphy.com/media/O8BHTJSx2Lb70eovbx/giphy.gif',
    title: 'How Things Are Made: Chocolate',
    subject: 'Engineering',
    subjectIcon: '🛠',
    description:
      "We’re taking a trip to the rainforest where there are special beans that make chocolate! Let's travel there and learn about the life of a chocolate bar from growing the cocoa plant, to factory, to store!",
  },
];
