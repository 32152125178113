/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Image as ChakraImage,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import actions from '@actions';
import { showErrorToast, analytics, AVATAR } from '@utils';
import videoCameraImg from '@assets/icons/video_camera.svg';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import useUserMedia from '../../useUserMedia';
import AvatarModal from '../../../Profile/componenets/AvatarModal';

const VIDEO_OPTIONS = {
  AVATAR: 'avatar',
  CAMERA: 'camera',
};

const SetupView = ({
  setCurrentRoomState,
  roomStateProps,
  classStartTime,
  setMediaStream,
  setStudent,
  student,
  timeDifference,
  style,
  videoRef,
  videoWidth,
  videoHeight,
  zipClass,
}) => {
  // Hooks
  const toast = useToast();
  const { mediaStream, triggerPermissionsCheckAction } = useUserMedia({
    video: { width: videoWidth, height: videoHeight },
  });

  // State
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  // Actions
  const updateChild = async () => {
    try {
      await actions.updateChild(student, 'update');
      analytics.t('updated emoji from setup view');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const useCameraAction = () => {
    triggerPermissionsCheckAction(true);
    setSelectedOption(VIDEO_OPTIONS.CAMERA);
    analytics.t('selected camera in setup view');
  };

  const changeAvatarAction = () => {
    setAvatarModalOpen(true);
    setSelectedOption(VIDEO_OPTIONS.AVATAR);
    analytics.t('changed to avatar in setup view');
  };

  const enterClassroomAction = () => {
    try {
      if (selectedOption === VIDEO_OPTIONS.AVATAR && mediaStream) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(track => {
          track.stop();
        });

        videoRef.current.srcObject = null;
        setMediaStream(null);
      }

      // If we have a null time difference just push to play
      if (!timeDifference || Date.now() - timeDifference > classStartTime) {
        setCurrentRoomState(roomStateProps.PLAY);
      } else {
        setCurrentRoomState(roomStateProps.WAITING);
      }

      analytics.t('clicked enter classroom button', { selectedOption });
    } catch (error) {
      console.warn('SetupView Error: ', error);
      showErrorToast(toast, 'Something went wrong. Please contact ZipSchool support!');
    }
  };

  // Render Methods
  const renderAvatarModal = () => {
    return (
      <AvatarModal
        onChange={setStudent}
        onClose={() => setAvatarModalOpen(false)}
        child={student}
        onSubmit={() => {
          updateChild();
          setAvatarModalOpen(false);
        }}
      />
    );
  };

  const renderEmoji = () => {
    const emoji = AVATAR.EMOJIS.find(e => e.title === student.emoji);
    if (emoji) return emoji.value;

    return AVATAR.EMOJIS[0].value;
  };

  const renderCamera = () => {
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = mediaStream;
    }

    const handleCanPlay = () => {
      try {
        videoRef.current.play();
      } catch (error) {
        console.warn('SetupView Error: ', error);
      }
    };

    return (
      <Flex
        w={videoWidth}
        h={videoHeight}
        bg={(student && student.color) || 'purple.300'}
        justifyContent="center"
        alignItems="center"
        position="relative"
        overflow="hidden"
        borderWidth={4}
        borderColor={
          !selectedOption || selectedOption === VIDEO_OPTIONS.AVATAR ? 'gray.400' : student.color
        }
        rounded="lg"
        shadow="md"
      >
        <video
          ref={videoRef}
          onCanPlay={handleCanPlay}
          autoPlay
          playsInline
          muted
          height={videoHeight}
          width={videoWidth}
          style={{
            display: mediaStream && selectedOption === VIDEO_OPTIONS.CAMERA ? 'block' : 'none',
          }}
        />

        {selectedOption === null && (
          <Flex justifyContent="center" alignItems="center">
            <ChakraImage w={12} h={12} src={videoCameraImg} />
          </Flex>
        )}
        {selectedOption === VIDEO_OPTIONS.AVATAR && (
          <Flex flexDir="column" alignItems="center" onClick={changeAvatarAction} cursor="pointer">
            <Text fontSize={['4xl', '5xl', '6xl', '6xl']} textAlign="center">
              {student && renderEmoji()}
            </Text>
            <Text
              fontWeight="bold"
              fontSize={['xs', 'xs', 'sm', 'md']}
              textAlign="center"
              color="gray.700"
            >
              Tap to change your Avatar
            </Text>
          </Flex>
        )}
      </Flex>
    );
  };

  const renderHeader = () => (
    <Stack alignItems="left" w="100%" h="100%">
      <Text color="#383838" fontSize={['2xl', '3xl']} fontWeight="bold" textAlign="center">
        🎥 Turn On Camera?
      </Text>
      <Divider />

      <Text>✅ We recommend using your camera for a more immersive experience.</Text>
      <Text>
        ✅ Your child’s video and audio feeds are kept 100% private and are not sent anywhere.
      </Text>
    </Stack>
  );

  const renderActionButtons = () => {
    return (
      <Stack w="100%" spacing={1} alignItems="center" justifyContent="center" pt={3}>
        <Button
          w="100%"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          shadow="md"
          fontSize="lg"
          fontWeight="bold"
          color="white"
          bg="#4621AD"
          backgroundImage="linear-gradient(98.17deg, 
                            rgba(255, 255, 255, 0.88) 43.72%, rgba(255, 255, 255, 0) 93.72%)"
          style={{ backgroundBlendMode: 'soft-light, normal' }}
          _hover={{ backgroundColor: '#4621AD' }}
          onClick={useCameraAction}
        >
          Turn On Camera
        </Button>
      </Stack>
    );
  };

  const renderDontUseCameraButton = () => (
    <Stack w="100%" spacing={1} alignItems="center" justifyContent="center" pt={3}>
      <Button
        w="100%"
        h={['45px', '50px', '55px', '60px']}
        rounded="lg"
        shadow="md"
        borderWidth="1px"
        fontSize="lg"
        color="gray.700"
        fontWeight="bold"
        alignItems="center"
        onClick={() => {
          if (selectedOption === VIDEO_OPTIONS.AVATAR) {
            setSelectedOption(null);
          } else {
            setSelectedOption(VIDEO_OPTIONS.AVATAR);
          }

          analytics.t('clicked dont use camera');
        }}
      >
        {selectedOption === VIDEO_OPTIONS.AVATAR && <ChevronLeftIcon w={6} h={6} />}
        {selectedOption === VIDEO_OPTIONS.AVATAR ? 'Go Back' : "Don't Use Camera"}
      </Button>
    </Stack>
  );

  const renderEnterClassroomButton = () => (
    <Stack spacing={2} w="100%">
      <Button
        w="100%"
        h={['45px', '50px', '55px', '60px']}
        rounded="lg"
        shadow="md"
        mt={5}
        fontSize="lg"
        fontWeight="bold"
        color="gray.700"
        bg="#ffc60b"
        backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
        style={{ backgroundBlendMode: 'soft-light, normal', animation: 'fadeIn 0.5s' }}
        _hover={{ backgroundColor: '#ffc60b' }}
        onClick={enterClassroomAction}
      >
        Enter Classroom
      </Button>
    </Stack>
  );

  // UseEffects
  useEffect(() => {
    try {
      setCurrentRoomState(roomStateProps.SETUP);
    } catch (error) {
      console.warn('SetupView Error: ', error);
      showErrorToast(toast, 'Something went wrong. Please contact ZipSchool support!');
    }
  }, []);

  useEffect(() => {
    try {
      setMediaStream(mediaStream);
    } catch (error) {
      console.warn('SetupView Error: ', error);
      showErrorToast(toast, 'Something went wrong. Please contact ZipSchool support!');
    }
  }, [mediaStream]);

  return (
    <Flex style={style}>
      {avatarModalOpen && renderAvatarModal()}
      <Flex flexDir="column" alignItems="center">
        <Flex alignItems="center">
          <Box width="100%" px={[5, 8]}>
            <Text
              py={2}
              color="white"
              fontWeight="bold"
              fontSize={['2xl', '4xl']}
              textAlign="center"
            >
              {zipClass &&
                zipClass.zipClass &&
                `${zipClass.zipClass.subject_icon || ''} ${zipClass.zipClass.title}`}
            </Text>
          </Box>
        </Flex>
        <Stack
          mt={2}
          spacing={5}
          color="black"
          w={['xs', 'md', 'md', 'lg']}
          align="center"
          bg="white"
          p={10}
          rounded="lg"
        >
          {renderHeader()}
          {renderCamera()}
          <Box w="100%">
            {!selectedOption && renderActionButtons()}
            {selectedOption && renderEnterClassroomButton()}
            {selectedOption !== VIDEO_OPTIONS.CAMERA && renderDontUseCameraButton()}
          </Box>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default SetupView;
