/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Flex, Text, Stack, HStack, Button, Box } from '@chakra-ui/react';
import { analytics } from '@utils';

const PetSelect = ({ pets = [], selectedPetId, setSelectedPetId }) => {
  const renderPet = pet => {
    return (
      <Box
        color="black"
        w="100%"
        alignSelf="center"
        bg={pet.id === selectedPetId ? 'rgb(255, 197, 3, 1.0)' : 'white'}
        p={4}
        rounded="lg"
        onClick={() => setSelectedPetId(pet.id)}
      >
        <Stack>
          <Text fontWeight="bold">{pet.title}</Text>
          <video style={{ height: '300px' }} loop muted autoPlay playsInline src={pet.url}></video>
        </Stack>
      </Box>
    );
  };

  // UseEffects
  useEffect(() => {
    analytics.t('viewed Pet Select in native onboarding');
    window.scroll(0, 0);
  }, []);

  return (
    <Stack
      maxW={['100%', '100%', '80%', '75%']}
      h="100%"
      spacing={2}
      overflow="auto"
      margin="auto"
      animation="fadeIn 0.4s"
    >
      {pets.map(renderPet)}
    </Stack>
  );
};

export default PetSelect;
