/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@chakra-ui/react';
import { onboardingSteps } from '../constants';
import AddTopics from './components/AddTopics';
import PetSelect from './components/PetSelect';

import PushNotificationModal from './components/PushNotificationModal';
import Signup from './components/Signup';
import EmailAndPassword from './components/EmailAndPassword';

const NativeOnboarding = ({
  currentStep,
  setCurrentStep,
  selectedTopics,
  setSelectedTopics,
  selectedPetId,
  setSelectedPetId,
  pets,
  form,
}) => {
  // Actions
  const shouldRenderTopicsList = () => {
    return (
      currentStep === onboardingSteps.ADD_TOPICS ||
      currentStep === onboardingSteps.PUSH_NOTIFICATION_MODAL
    );
  };

  return (
    <>
      {/* Push Notification Modal */}
      {currentStep === onboardingSteps.PUSH_NOTIFICATION_MODAL && (
        <PushNotificationModal setCurrentStep={setCurrentStep} />
      )}

      <Box w="100%" h="100%" bg="purple.500">
        {/* Add Topics */}
        {shouldRenderTopicsList() && (
          <AddTopics selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics} />
        )}

        {/* Select Pet */}
        {currentStep === onboardingSteps.PET_SELECT && (
          <PetSelect
            pets={pets}
            selectedPetId={selectedPetId}
            setSelectedPetId={setSelectedPetId}
          />
        )}

        {/* Sign Up */}
        {currentStep === onboardingSteps.SIGNUP && <Signup setCurrentStep={setCurrentStep} />}

        {/* Email and Password */}
        {currentStep === onboardingSteps.ADD_EMAIL_PASSWORD && <EmailAndPassword form={form} />}
      </Box>
    </>
  );
};

export default NativeOnboarding;
