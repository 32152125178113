/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Stack,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Text,
  Flex,
  Select,
} from '@chakra-ui/react';
import actions from '@actions';
import { showErrorToast, showSuccessToast, analytics } from '@utils';
import * as constants from '../../../OnboardingFlows/constants';

const AddChildModal = ({
  setChildren,
  setAddChildModalOpen,
  addChildModalOpen,
  toast,
  validateName,
  fetchProfile,
}) => {
  // Local Properties
  const form = useForm();

  // State
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Actions
  const runChildUpdate = async data => {
    setIsSubmitting(true);

    try {
      const child = await actions.createChild(data);

      if (child) {
        setChildren(prevState => [...prevState, child]);
        analytics.t('added child', child);
      }

      setIsSubmitting(false);
      setAddChildModalOpen(false);

      // We run another fetchProfile here to make sure
      // we have the latest child count to show header items
      //   const { profile: _ } = await actions.fetchProfile(false);
      //   setHeaderProfile(_);

      await fetchProfile(true);

      showSuccessToast(toast, '✅ New child added!');
    } catch (error) {
      showErrorToast(toast, error.message);
      setIsSubmitting(false);
    }
  };

  // Render Methods
  const renderFirstNameInput = () => (
    <FormControl mt={4} isInvalid={form.errors.first_name}>
      <Text fontWeight="bold">Child First Name</Text>
      <Input
        name="first_name"
        placeholder="First name"
        type="text"
        rounded="lg"
        fontSize="md"
        isRequired
        ref={form.register({ validate: validateName('First name') })}
      />
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        {form.errors.first_name && form.errors.first_name.message}
      </FormErrorMessage>
    </FormControl>
  );

  const renderAgeSelector = () => (
    <FormControl mt={4} isInvalid={form.errors.age}>
      <Flex flexDir="column">
        <Text fontWeight="bold" fontSize={['md', 'lg']} pb={2}>
          Child Age
        </Text>
        <Select
          name="age"
          placeholder="Select age"
          variant="filled"
          rounded="lg"
          spacing={3}
          cursor="pointer"
          ref={form.register({ required: true })}
        >
          {constants.childAgeOptions.map(t => (
            <option key={t.id}>{t.name}</option>
          ))}
        </Select>
      </Flex>
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        Child's age required
      </FormErrorMessage>
    </FormControl>
  );

  return (
    <Modal isOpen={addChildModalOpen} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color="#383838" fontSize={['2xl', '3xl']} fontWeight="bold" textAlign="center">
            🦄 Add Child
          </Text>
        </ModalHeader>
        <ModalBody>
          <Stack justifyContent="center" flexDir="column" w="100%" spacing={3}>
            {renderFirstNameInput()}
            {renderAgeSelector()}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            fontWeight="medium"
            mr={3}
            onClick={() => setAddChildModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={form.handleSubmit(runChildUpdate)}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddChildModal;
