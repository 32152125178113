/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import './App.css';
import { useToast, Box, Spinner, useBreakpointValue } from '@chakra-ui/react';
import Amplify, { Auth } from 'aws-amplify';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  showErrorToast,
  setupIntercom,
  signOutIntercom,
  hideCrispLauncher,
  analytics,
  isiOSNativeDevice,
  showCrispLauncher,
  navigateTo,
  sendDataToNative,
  nativeActionTransaction,
} from '@utils';
import { AUTH_STATES } from '@constants';
import actions from '@actions';
import ClassListNative from '@native/ClassListNative';
import { useNativeCallback } from '@hooks';
import awsconfig from './aws-exports';
import Header from './Header';
import SignIn from './SignIn';
import ClassList from './ClassList';
import Booked from './Booked';
import Schedule from './Saved';
import Profile from './Profile';
import ForgotPassword from './ForgotPassword';
import VODRoom from './VOD';
import OnboardingSteps from './OnboardingFlows/OnboardingSteps';
import Classmash from './OnboardingFlows/Classmash';
import VideoViewer from './VideoViewer';
import VideoPlayerModal from './Modals/VideoPlayerModal';
import VideoPlayerModalContext from './Context/VideoPlayerModalContext';
import ProfileContext from './Context/ProfileContext';
import ZipClass from './ZipClass';
import Footer from './Footer';
import ZipOauth from './ZipOauth';
import Rewards from './Rewards';

import BookingModal from './Modals/BookingModal';
import NativeOnboarding from './OnboardingFlows/NativeOnboarding';
import { PUSH_AUTHORIZATION_STATUS } from './OnboardingFlows/constants';

// V2
import Courses from './v2/Courses';

Amplify.configure(awsconfig);

const App = () => {
  const [authState, setAuthState] = useState(AUTH_STATES.LOADING);
  const [profile, setProfile] = useState(null);
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [footerBGColor, setFooterBGColor] = useState('purple.500');
  const [footerDarkMode, setFootDarkMode] = useState(true);
  const [allItems, setAllItems] = useState([]);
  const [showBookingSuccess, setShowBookingSuccess] = useState(false);
  const [resourceData, setVideoPlayerModalResourceData] = useState(null);
  const [currentBookingClassData, setCurrentBookingClassData] = useState(null);
  const [bookingCompletionHandler, setBookingCompletionHandler] = useState(null);
  const toast = useToast();
  const nativeCallback = useNativeCallback();

  const queryParams = new URLSearchParams(window.location.search);
  const device = queryParams.get('device');

  if (device) {
    localStorage.setItem('zipschool_device', device);
  }

  const deviceType = useBreakpointValue({
    base: 'mobile',
    md: 'mobile',
    lg: 'desktop',
    xl: 'desktop',
  });

  const fetchProfile = async () => {
    try {
      const {
        profile: profileData,
        crisp = {},
        intro_video_asset: introVideoAsset,
        redeemables,
      } = await actions.fetchProfile();
      if (profileData) {
        setProfile({ ...profileData, redeemables, introVideoAsset });
        const { email, hmac } = crisp;
        setupIntercom({ email, hmac });
        return profileData;
      }
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  useEffect(() => {
    if (nativeCallback) {
      const { action } = nativeCallback;

      if (action == PUSH_AUTHORIZATION_STATUS.AUTHORIZED) {
        sendDataToNative({
          action: 'push',
          data: {},
        });
      }
    }
  }, [nativeCallback]);

  const fetchAuthState = async () => {
    try {
      const res = await Auth.currentAuthenticatedUser();
      if (res) {
        nativeActionTransaction(true, 'landscape');

        const params = new URLSearchParams(window.location.search);
        const redirectUri = params.get('redirectUri');
        const bookingSuccess = params.get('bookingSuccess');

        if (!profile) await fetchProfile();

        if (redirectUri) {
          window.location = decodeURIComponent(redirectUri);
        } else if (bookingSuccess === 'true') {
          setShowBookingSuccess(true);
          params.delete('bookingSuccess');
          window.history.replaceState(null, '', '/courses');
        }

        sendDataToNative({ action: 'pushstate', data: {} });
        setAuthState(AUTH_STATES.AUTHENTICATED);
      } else {
        nativeActionTransaction(true, 'portrait');
        setAuthState(AUTH_STATES.NEEDS_AUTH);
      }
    } catch (err) {
      let redirectUri;
      if (window.location.pathname.startsWith('/live')) {
        redirectUri = encodeURIComponent(`/live/${window.location.href.split('/').pop()}`);
      } else if (window.location.pathname === '/classes') {
        redirectUri = encodeURIComponent(`/classes`);
      }

      if (redirectUri) {
        window.location = `/login?redirectUri=${redirectUri}`;
      }
      setAuthState(AUTH_STATES.NEEDS_AUTH);
    }
  };

  const onSignIn = async ({ email, password }) => {
    try {
      const { exists } = await actions.validateItem({ email });
      if (!exists) {
        analytics.t('No profile exists with this email', { email });
        throw { status: 400, message: 'No profile exists with this email' };
      }

      const u = await Auth.signIn(email.trim(), password.trim());
      sendDataToNative({
        action: 'branch',
        data: { event: 'LOGIN', data: {}, user_id: u.attributes.sub },
      });
      analytics.setUserId(u.attributes.sub);
      analytics.t('signed in', { email });
      await fetchAuthState();

      if (isiOSNativeDevice()) {
        navigateTo(null, '/classes-mobile');
      }
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const onSignOut = async () => {
    try {
      await Auth.signOut();
      setAuthState(AUTH_STATES.NEEDS_AUTH);
      signOutIntercom();
      nativeActionTransaction(true, 'portrait');

      sendDataToNative({ action: 'signout', data: {} });
    } catch (err) {
      showErrorToast(toast, 'There was a problem logging you out.');
      console.warn(err);
    }
  };

  const handleRouteChange = pathname => {
    const parts = pathname.split('/').filter(val => val !== '');
    if (
      parts.includes('review') ||
      parts.includes('signup-trial') ||
      parts.includes('subscribe') ||
      parts.includes('video') ||
      parts.includes('signup-native') ||
      pathname.startsWith('/noauth') ||
      pathname.startsWith('/classes') ||
      (parts.includes('live') && !parts.includes('next'))
    ) {
      setShowHeader(false);
      setShowFooter(false);
    } else if (parts.includes('signup') && !parts.includes('courses')) {
      setShowHeader(false);
    } else if (parts.includes('signup-swipe')) {
      setShowFooter(false);
    } else if (isiOSNativeDevice()) {
      setShowHeader(false);
      setShowFooter(false);
      // showCrispLauncher();
    } else if (pathname.startsWith('/courses')) {
      if (pathname.includes('signup')) {
        setShowHeader(false);
        setShowFooter(false);
      } else {
        setShowHeader(true);
        setFootDarkMode(false);
        setFooterBGColor('#F5F5F5');
      }
    } else {
      setFootDarkMode(true);
      setFooterBGColor('purple.500');
    }
  };

  const setResourceData = data => {
    setVideoPlayerModalResourceData(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAuthState();
    };

    fetchData();
  }, []);

  useEffect(() => {
    analytics.t('app loaded');
  }, []);

  useEffect(() => {
    if (currentBookingClassData) {
      hideCrispLauncher();
    } else {
      setShowBookingSuccess(false);
      showCrispLauncher();
      if (authState === AUTH_STATES.NEEDS_AUTH && fetchAuthState) {
        fetchAuthState();
      }
    }
  }, [currentBookingClassData]);

  return (
    <div
      className="App"
      style={{
        backgroundColor:
          window.location.pathname.startsWith('/classes') ||
          window.location.pathname.startsWith('/courses')
            ? '#F5F5F5'
            : '#805AD5',
      }}
    >
      <VideoPlayerModalContext.Provider value={{ resourceData, setResourceData }}>
        {resourceData && <VideoPlayerModal resourceData={resourceData} />}
        <Router>
          {showHeader && (
            <Header authState={authState} onRouteChange={handleRouteChange} profile={profile} />
          )}
          {authState === AUTH_STATES.LOADING && (
            <Box d="flex" p={5} justifyContent="center">
              <Spinner
                mt={4}
                color={window.location.pathname.startsWith('/classes') ? 'black' : 'purple.500'}
              />
            </Box>
          )}
          {authState === AUTH_STATES.AUTHENTICATED && (
            <ProfileContext.Provider value={{ data: profile, setProfile }}>
              <BookingModal
                deviceType={deviceType}
                setCurrentBookingClassData={setCurrentBookingClassData}
                classData={currentBookingClassData}
                profile={profile}
                fetchProfile={fetchProfile}
                fetchAuthState={fetchAuthState}
                authState={authState}
                completionHandler={bookingCompletionHandler}
              />
              <Switch>
                <Route
                  path="/live/:booking_id"
                  render={props => <VODRoom authState={authState} {...props} />}
                />
                <Route
                  exact
                  path="/classes"
                  render={props => (
                    <ClassList
                      fetchProfile={fetchProfile}
                      profile={profile}
                      authState={authState}
                      fetchAuthState={fetchAuthState}
                      allItems={allItems}
                      setAllItems={setAllItems}
                      setCurrentBookingClassData={setCurrentBookingClassData}
                      setBookingCompletionHandler={setBookingCompletionHandler}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/profile"
                  render={props => (
                    <Profile
                      fetchProfile={fetchProfile}
                      profile={profile}
                      authState={authState}
                      fetchAuthState={fetchAuthState}
                      handleSignOut={onSignOut}
                      setHeaderProfile={setProfile}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/classes-mobile"
                  render={props => <ClassListNative fetchProfile={fetchProfile} {...props} />}
                />
                <Route
                  exact
                  path="/noauth/classes"
                  render={props => (
                    <ClassList
                      fetchProfile={fetchProfile}
                      profile={profile}
                      authState={authState}
                      fetchAuthState={fetchAuthState}
                      allItems={allItems}
                      setAllItems={setAllItems}
                      showBookingSuccess={showBookingSuccess}
                      setShowBookingSuccess={setShowBookingSuccess}
                      setBookingCompletionHandler={setBookingCompletionHandler}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/rewards"
                  render={props => (
                    <Rewards
                      authState={authState}
                      handleSignOut={onSignOut}
                      setHeaderProfile={setProfile}
                      onProfileUpdate={fetchProfile}
                      profile={profile}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/booked"
                  render={props => (
                    <Booked
                      authState={authState}
                      onProfileUpdate={fetchProfile}
                      profile={profile}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/schedule"
                  render={props => (
                    <Schedule
                      authState={authState}
                      onProfileUpdate={fetchProfile}
                      profile={profile}
                      setCurrentBookingClassData={setCurrentBookingClassData}
                      setBookingCompletionHandler={setBookingCompletionHandler}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/video/:embed_id"
                  render={props => <VideoViewer isAuthenticated {...props} />}
                />
                <Route
                  path="/courses"
                  render={props => (
                    <Courses fetchAuthState={fetchAuthState} authState={authState} />
                  )}
                />

                <Redirect from="/noauth/classes/:class_id" to="/live/:class_id" />
                {isiOSNativeDevice() ? (
                  <Redirect exact to="/classes-mobile" />
                ) : (
                  <Redirect exact to="/courses" />
                )}
              </Switch>
            </ProfileContext.Provider>
          )}
          {authState === AUTH_STATES.NEEDS_AUTH && (
            <Switch>
              <Route
                exact
                path="/login"
                render={props => <SignIn handleSignIn={onSignIn} {...props} />}
              />
              {/* <Route
              path="/signup"
              exact
              render={props => <Onboarding onAuthStateChange={fetchAuthState} {...props} />}
            /> */}
              <Route
                path="/signup"
                exact
                render={props => <OnboardingSteps fetchAuthState={fetchAuthState} {...props} />}
              />
              <Route
                path="/signup-swipe"
                render={props => <Classmash onAuthStateChange={fetchAuthState} {...props} />}
              />
              <Route
                path="/signup-native"
                render={props => <NativeOnboarding fetchAuthState={fetchAuthState} {...props} />}
              />
              <Route
                path="/forgot"
                render={props => <ForgotPassword onAuthStateChange={fetchAuthState} {...props} />}
              />
              <Route
                path="/oauth"
                render={props => <ZipOauth fetchAuthState={fetchAuthState} {...props} />}
              />
              <Route path="/noauth/live/:booking_id" render={props => <VODRoom {...props} />} />
              <Route
                exact
                path="/noauth/classes"
                render={props => (
                  <ClassList
                    fetchProfile={fetchProfile}
                    profile={profile}
                    authState={authState}
                    fetchAuthState={fetchAuthState}
                    allItems={allItems}
                    setAllItems={setAllItems}
                    showBookingSuccess={showBookingSuccess}
                    setShowBookingSuccess={setShowBookingSuccess}
                    setBookingCompletionHandler={setBookingCompletionHandler}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/noauth/classes/:class_id"
                render={props => <ZipClass {...props} />}
              />
              <Route path="/video/:embed_id" render={props => <VideoViewer {...props} />} />
              <Route
                path="/courses"
                render={props => <Courses fetchAuthState={fetchAuthState} authState={authState} />}
              />
              <Redirect exact to="/login" />
            </Switch>
          )}
        </Router>
        {showFooter && <Footer bg={footerBGColor} darkBackground={footerDarkMode} />}
      </VideoPlayerModalContext.Provider>
    </div>
  );
};

export default Sentry.withProfiler(App);
