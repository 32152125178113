/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { memo, useEffect } from 'react';
import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { analytics } from '@utils';
import * as constants from '../../../constants';

const AddTopicsFlow = ({ setSelectedTopics, selectedTopics }) => {
  // Actions
  const addTopicAction = topic => () => {
    setSelectedTopics(prevState => {
      const found = prevState.find(({ id }) => id === topic.id);

      if (found) {
        return prevState.filter(({ id }) => id !== topic.id);
      }

      return [...prevState, topic];
    });
  };

  // Render Methods
  const renderTopicChoices = () => {
    return (
      <HStack width="100%" flexWrap="wrap" justifyContent="center">
        {constants.TOPICS_LIST.map(topic => (
          <Text
            key={topic.id}
            textAlign="center"
            py={2}
            px={3}
            my={1}
            cursor="pointer"
            rounded="lg"
            shadow="md"
            border={
              selectedTopics.find(({ id }) => id === topic.id)
                ? 'solid 1px transparent'
                : 'solid 1px rgb(160, 174, 192)'
            }
            bg={selectedTopics.find(({ id }) => id === topic.id) ? 'purple.500' : 'transparent'}
            color={selectedTopics.find(({ id }) => id === topic.id) ? 'white' : 'black'}
            fontWeight={selectedTopics.find(({ id }) => id === topic.id) ? 'bold' : 'normal'}
            onClick={addTopicAction(topic)}
          >
            {topic.name}
          </Text>
        ))}
      </HStack>
    );
  };

  // UseEffects
  useEffect(() => {
    analytics.t('hit topics selector page');
  }, []);

  return (
    <VStack w="100%" px={[0, 4]} spacing={4} animation="fadeIn 0.4s">
      <Flex flexDir="column" justifyContent="center">
        <Text color="#383838" fontSize={['xl', '3xl']} fontWeight="bold" textAlign="center">
          🎨 Choose topics!
        </Text>
        <Text
          color="gray.400"
          fontWeight="bold"
          fontSize={['sm', 'md']}
          textAlign="center"
          pt={[1, 3]}
        >
          We have a wide selection of topics kids love
        </Text>
      </Flex>
      {renderTopicChoices()}
    </VStack>
  );
};

export default memo(AddTopicsFlow);
