/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Text,
  Stack,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react';
import { analytics } from '@utils';

const CompletedFilterModal = ({ setCompletedFilter, comnpletedFilter, onClose, onApply }) => (
  <Modal isCentered isOpen onClose={onClose} closeOnOverlayClick>
    <ModalOverlay />
    <ModalContent m={3} rounded="lg">
      <ModalHeader>
        <Stack isInline alignItems="center" spacing={2}>
          <Text fontWeight="bold" fontSize="xl">
            Completed Classes
          </Text>
        </Stack>
      </ModalHeader>
      <ModalBody>
        <Stack mt={2} spacing={2}>
          <Box
            onClick={() =>
              comnpletedFilter === 'show-completed'
                ? setCompletedFilter('hide-completed')
                : setCompletedFilter('show-completed')
            }
            borderWidth="1px"
            borderColor="gray.300"
            rounded="lg"
            w="100%"
            key="start-time"
            h="50px"
            d="flex"
            alignItems="center"
            justifyContent="space-between"
            cursor="pointer"
            bg={comnpletedFilter === 'show-completed' ? '#FFC503' : 'white'}
            color="black"
            p={3}
          >
            <Text fontWeight={comnpletedFilter === 'show-completed' ? 'bold' : 'normal'}>
              Show classes I've completed
            </Text>
            {comnpletedFilter === 'show-completed' && <Text>✅</Text>}
          </Box>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          ml={2}
          bg="#FFC503"
          fontWeight="medium"
          color="black"
          onClick={() => {
            analytics.t('changed filter', {
              type: 'completed classes',
              selected: comnpletedFilter,
            });
            onApply();
          }}
        >
          Apply
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default CompletedFilterModal;
