import React from 'react';
import { useForm } from 'react-hook-form';
import '../App.css';
import { FormErrorMessage, FormControl, Input, Button, Text, Box } from '@chakra-ui/react';

const Email = ({ onNext, ...rest }) => {
  const { handleSubmit, errors, register, formState } = useForm();

  const validateEmail = value => {
    let error;
    if (!value) {
      error = 'Email is required';
    }
    return error || true;
  };

  return (
    <Box {...rest}>
      <Text fontSize="lg" fontWeight="bold">
        Please enter your email below 🙂
      </Text>
      <form onSubmit={handleSubmit(onNext)}>
        <FormControl mt={10} isInvalid={errors.email}>
          <Input
            name="email"
            placeholder="Email"
            autoFocus
            borderRadius="4px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            type="email"
            ref={register({ validate: validateEmail })}
          />
          <Text mt={1} fontSize="xs" color="gray.500">
            Enter the email you used to create your profile
          </Text>
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        <Button
          mt={10}
          isLoading={formState.isSubmitting}
          type="submit"
          h="50px"
          borderRadius="4px"
          w="100%"
          fontSize="lg"
          fontWeight="bold"
          bg="purple.500"
          color="white"
          style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={handleSubmit(onNext)}
        >
          Next
        </Button>
      </form>
    </Box>
  );
};

export default Email;
