/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useToast, Spinner, Text, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { isiOSNativeDevice, showErrorToast, analytics, navigateTo } from '@utils';
import actions from '@actions';

const ZipOauth = ({ fetchAuthState }) => {
  const toast = useToast();
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const oauthCode = queryParams.get('code');

  useEffect(() => {
    analytics.t('hit oauth code');
    const fetchData = async () => {
      try {
        await actions.exchangeCodeForTokens(oauthCode);
        console.log('Successful exchange');
        await fetchAuthState();
        navigateTo(history, isiOSNativeDevice() ? '/classes-mobile' : '/classes');
      } catch (err) {
        navigateTo(history, '/login');
        showErrorToast(toast, err.message);
      }
    };

    fetchData();
  }, []);

  return (
    <VStack d="flex" p={5} mt={10}>
      <Text fontWeight="bolf" color="white" fontSize="lg">
        Signing In...
      </Text>
      <Spinner mt={4} color={window.location.pathname.startsWith('/classes') ? 'black' : 'white'} />
    </VStack>
  );
};

export default ZipOauth;
