/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { analytics } from '@utils';

const EmailAndPassword = ({ form }) => {
  // Actions
  const validateEmail = value => {
    // eslint-disable-next-line max-len
    const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error;

    if (!value) {
      error = 'Valid email required';
    } else if (!emailExpression.test(value)) {
      error = 'Email format is not correct';
    }

    return error || true;
  };

  const validateName = fieldName => value => {
    if (!value) {
      return `${fieldName} is required`;
    }

    return true;
  };

  const validatePassword = value => {
    let error;
    if (!value) {
      error = 'Password is required';
    } else if (value.length < 8) {
      error = 'Password must be at least 8 characters';
    }

    return error || true;
  };

  // Render Methods
  const renderFormFields = () => (
    <VStack spacing={3}>
      <FormControl isRequired>
        <FormLabel fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          First Name
        </FormLabel>
        <Input
          name="first_name"
          placeholder="Your First Name"
          type="text"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          fontSize="md"
          isRequired
          ref={form.register({ validate: validateName })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.first_name && 'First name required'}
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired>
        <FormLabel fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          Last Name
        </FormLabel>

        <Input
          name="last_name"
          placeholder="Your Last Name"
          type="text"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          fontSize="md"
          isRequired
          ref={form.register({ validate: validateName })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.last_name && 'Last name required'}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={form.errors.email} isRequired>
        <FormLabel fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          Email
        </FormLabel>
        <Input
          name="email"
          placeholder="Email"
          type="email"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          fontSize="md"
          ref={form.register({ validate: validateEmail })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.email && form.errors.email.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={form.errors.password} isRequired>
        <FormLabel fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          Password
        </FormLabel>
        <Text my={1} fontSize="sm" color="gray.600">
          Make sure this is at least 8 characters
        </Text>
        <Input
          name="password"
          placeholder="Password"
          type="password"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          fontSize="md"
          ref={form.register({ validate: validatePassword })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.password && form.errors.password.message}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  );

  useEffect(() => {
    analytics.t('viewed signup form in native onboarding');
    window.scroll(0, 0);
  }, []);

  return (
    <VStack maxW={['100%', '100%', '80%', '75%']} animation="fadeIn 0.4s" margin="auto">
      <Flex w="100%" flexDir="column" justifyContent="center" p={4} bg="white" rounded="lg">
        {renderFormFields()}
      </Flex>
    </VStack>
  );
};

export default EmailAndPassword;
