/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { AUTH_STATES, TIMES } from '@constants';
import { analytics, convertTZ, startOfNextWeek } from '@utils';
import ClassList from './ClassList';
import Header from '../Header';
import Footer from '../Footer';

const SORT_MODE = {
  START_TIME: 'start-time',
  RECENTLY_ADDED: 'recently-added',
};

const COMPLETED_FILTER = {
  SHOW_COMPLETED: 'show-completed',
  HIDE_COMPLETED: 'hide-completed',
};

const BG_COLOR = '#F5F5F5';
const ClassListContainer = ({
  profile,
  fetchProfile,
  authState,
  fetchAuthState,
  allItems,
  setAllItems,
  setCurrentBookingClassData,
  setBookingCompletionHandler,
}) => {
  // Sate
  const [displayItems, setDisplayItems] = useState([]);
  // const [inAllClassesSection, setInAllClassesSection] = useState(false);

  // Filter State
  const [dateFilter, setDateFilter] = useState(null);
  const [timeFilters, setTimeFilters] = useState([0, TIMES.length - 1]);
  const [dayFilters, setDayFilters] = useState([]);
  const [subjectFilters, setSubjectFilters] = useState([]);
  const [ageFilters, setAgeFilters] = useState([]);
  const [sortMode, setSortMode] = useState(SORT_MODE.START_TIME);
  const [completedFilter, setCompletedFilter] = useState(COMPLETED_FILTER.HIDE_COMPLETED);

  // Dirty Filter State
  const [_dateFilter, _setDateFilter] = useState(null);
  const [_timeFilters, _setTimeFilters] = useState([0, TIMES.length - 1]);
  const [_dayFilters, _setDayFilters] = useState([]);
  const [_subjectFilters, _setSubjectFilters] = useState([]);
  const [_ageFilters, _setAgeFilters] = useState([]);
  const [_sortMode, _setSortMode] = useState(SORT_MODE.START_TIME);
  const [_completedFilter, _setCompletedFilter] = useState(COMPLETED_FILTER.HIDE_COMPLETED);

  // Filter Modal State
  const [filterDateModal, setFilterDateModal] = useState(null);
  const [filterTimesModal, setFilterTimesModal] = useState(null);
  const [filterSubjectsModal, setFilterSubjectsModal] = useState(null);
  const [filterAgeModal, setFilterAgeModal] = useState(null);
  const [sortModal, setSortModal] = useState(null);
  const [completedFilterModal, setCompletedFilterModal] = useState(null);

  // References
  const allClassesHeaderRef = useRef(null);
  const headerRef = useRef(null);

  // Actions
  const compileFilterPredicate = () => {
    let retval = JSON.parse(JSON.stringify(allItems));

    const useSubjectFilters = _subjectFilters || subjectFilters;
    const useAgeFilters = _ageFilters || ageFilters;
    const useDateFilter = _dateFilter || dateFilter;
    const useDayFilters = _dayFilters || dayFilters;
    const useTimeFilters = _timeFilters || timeFilters;
    const useSortMode = _sortMode || sortMode;
    const useCompletedFilter = _completedFilter || completedFilter;

    if (useSubjectFilters && useSubjectFilters.length > 0) {
      retval = retval.filter(i => useSubjectFilters.includes(i.subject));
    }

    if (useAgeFilters && useAgeFilters.length > 0) {
      retval = retval.filter(i => {
        const ages = [];
        const ageParts = i.age_range.split('-').map(num => parseInt(num));
        for (let age = ageParts[0]; age <= ageParts[1]; age++) {
          ages.push(parseInt(age));
        }

        const filteredArray = useAgeFilters.filter(value => ages.includes(value));
        return filteredArray.length > 0;
      });
    }

    if (useDateFilter === 'next-week') {
      const nextWeek = startOfNextWeek();
      retval.forEach(r => {
        r.slots = r.slots.filter(s => s.timestamp > nextWeek);
      });
    }

    if (useDayFilters && useDayFilters.length > 0) {
      retval.forEach(r => {
        r.slots = r.slots.filter(s => {
          const day = convertTZ(s.timestamp, { mode: 'day' }).toLowerCase();
          return useDayFilters.includes(day);
        });
      });
    }

    if (useTimeFilters && useTimeFilters[0] > 0) {
      const minHour = TIMES[useTimeFilters[0]].value;
      retval.forEach(
        r =>
          (r.slots = r.slots.filter(s => {
            const hour = convertTZ(s.timestamp, { mode: 'hour' });
            return hour >= minHour;
          })),
      );
    }

    if (useTimeFilters && useTimeFilters[1] < TIMES.length - 1) {
      const maxHour = TIMES[useTimeFilters[1]].value;
      retval.forEach(
        r =>
          (r.slots = r.slots.filter(s => {
            const hour = convertTZ(s.timestamp, { mode: 'hour' });
            return hour <= maxHour;
          })),
      );
    }

    retval = retval.filter(r => r.type !== 'pack');
    // retval.sort((a, b) => a.completed - b.completed);

    if (retval.length === 0 && allItems.length > 0) {
      analytics.t('no filter results', {
        subjectFilters: _subjectFilters,
        dateFilter: _dateFilter,
        timeFilters: _timeFilters,
        dayFilters: _dayFilters,
      });
    }

    if (useSortMode === SORT_MODE.RECENTLY_ADDED) {
      retval.sort((a, b) => b.created_at - a.created_at);
    } else if (useSortMode === SORT_MODE.START_TIME) {
      retval.sort(
        (a, b) =>
          a.slots.length > 0 && b.slots.length > 0 && a.slots[0].timestamp - b.slots[0].timestamp,
      );
    }

    if (useCompletedFilter === COMPLETED_FILTER.SHOW_COMPLETED) {
      retval = retval.filter(r => r.completed);
    } else {
      retval = retval.filter(r => !r.completed);
    }

    // If we are scrolling to an item, make sure to make it the first index in our list
    // Else we will never be able to scroll to the item because it's not in the dom
    setDisplayItems(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const classId = urlParams.get('class_id');

      if (classId && retval.length > 0) {
        const decodedClassId = classId ? decodeURIComponent(classId) : undefined;
        const item = retval.find(obj => obj.class_id === decodedClassId);

        if (item) {
          const itemIndex = retval.indexOf(item);
          [retval[0], retval[itemIndex]] = [retval[itemIndex], retval[0]];
        }
      }

      return retval;
    });

    if (_ageFilters) {
      setAgeFilters(_ageFilters);
      _setAgeFilters(null);
    }

    if (_timeFilters) {
      setTimeFilters(_timeFilters);
      _setTimeFilters(null);
    }

    if (_dayFilters) {
      setDayFilters(_dayFilters);
      _setDayFilters(null);
    }

    if (_subjectFilters) {
      setSubjectFilters(_subjectFilters);
      _setSubjectFilters(null);
    }

    if (_sortMode) {
      setSortMode(_sortMode);
      _setSortMode(null);
    }

    if (_completedFilter) {
      setCompletedFilter(_completedFilter);
      _setCompletedFilter(null);
    }
  };

  // Render Methods
  const renderBanner = () => (
    <Flex w="100%" h="60px" py={2} bg="#EBEBEB" alignItems="center" flexWrap="wrap">
      <Text fontWeight="medium" color="#8F8F8F" pl={[5, 8]} fontSize={['xs', 'sm', 'lg']}>
        HI 👋 WE'RE ZIPSCHOOL
      </Text>

      <Text fontWeight="medium" pl={[5, 8]} fontSize={['md', 'lg', 'xl']}>
        Free science and art videos kids love ✨
      </Text>
    </Flex>
  );

  // UseEffects
  useEffect(() => {
    if (authState === AUTH_STATES.AUTHENTICATED) {
      analytics.t('viewed schedule');
    } else if (authState === AUTH_STATES.NEEDS_AUTH) {
      analytics.t('hit classes no auth');
    }
  }, [authState]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (fbq) {
      // eslint-disable-next-line no-undef
      fbq('track', 'PageView');
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ageParam = params.get('age');

    if (ageParam && allItems.length > 0) {
      setAgeFilters([parseInt(ageParam, 10)]);
    } else if (allItems.length > 0) {
      compileFilterPredicate();
    }
  }, [allItems]);

  // Since setAgeFilters is async, wait until it completes before compiling predicate
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ageParam = params.get('age');

    if (ageParam && ageFilters.length > 0) {
      compileFilterPredicate();
    }
  }, [ageFilters]);

  return (
    <Box maxW="100%" minH="100%" bg={BG_COLOR}>
      <Header profile={profile} authState={authState} headerRef={headerRef} />
      {renderBanner()}
      <Flex flexDir="column" alignItems="center" mx="auto">
        <ClassList
          profile={profile}
          fetchProfile={fetchProfile}
          authState={authState}
          fetchAuthState={fetchAuthState}
          setDateFilter={_setDateFilter}
          dateFilter={_dateFilter || dateFilter}
          setFilterDateModal={setFilterDateModal}
          filterDateModal={filterDateModal}
          timeFilters={_timeFilters || timeFilters}
          setTimeFilters={_setTimeFilters}
          dayFilters={_dayFilters || dayFilters}
          setDayFilters={_setDayFilters}
          subjectFilters={_subjectFilters || subjectFilters}
          setSubjectFilters={_setSubjectFilters}
          ageFilters={_ageFilters || ageFilters}
          setAgeFilters={_setAgeFilters}
          sortMode={_sortMode || sortMode}
          setSortMode={_setSortMode}
          completedFilter={_completedFilter || completedFilter}
          setCompletedFilter={_setCompletedFilter}
          displayItems={displayItems}
          setDisplayItems={setDisplayItems}
          allItems={allItems}
          setAllItems={setAllItems}
          filterTimesModal={filterTimesModal}
          setFilterTimesModal={setFilterTimesModal}
          filterSubjectsModal={filterSubjectsModal}
          setFilterSubjectsModal={setFilterSubjectsModal}
          filterAgeModal={filterAgeModal}
          setFilterAgeModal={setFilterAgeModal}
          sortModal={sortModal}
          setSortModal={setSortModal}
          completedFilterModal={completedFilterModal}
          setCompletedFilterModal={setCompletedFilterModal}
          compileFilterPredicate={compileFilterPredicate}
          headerRef={headerRef}
          allClassesHeaderRef={allClassesHeaderRef}
          setCurrentBookingClassData={setCurrentBookingClassData}
          setBookingCompletionHandler={setBookingCompletionHandler}
        />
      </Flex>
    </Box>
  );
};

export default ClassListContainer;
