/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import * as typeformEmbed from '@typeform/embed';

const ReviewScreen = ({ currentUser }) => {
  // Local Properties
  const params = new URLSearchParams(window.location.search);
  const feedbackId = 'yJGfNqt1';

  // References
  const typeformRef = useRef(null);

  // UseEffects
  useEffect(() => {
    if (typeformRef && typeformRef.current && currentUser) {
      let typeformUri = `https://zipschool.typeform.com/to/${feedbackId}`;

      if (currentUser && currentUser.idToken) {
        const { payload } = currentUser.idToken;
        typeformUri = `${typeformUri}#email=${payload.email}`;
      }

      typeformEmbed.makeWidget(typeformRef.current, encodeURI(typeformUri), {
        hideHeaders: true,
        hideFooter: true,
        opacity: 0,
        transferableUrlParameters: [
          'classtitle',
          'teachername',
          'childage',
          'classtime',
          'classsubject',
        ],
      });
    }
  }, [currentUser]);

  return <Box ref={typeformRef} bg="white" style={{ height: '100vh', width: '100%' }} />;
};

export default ReviewScreen;
