/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Flex } from '@chakra-ui/react';
import OnboardingSteps from './OnboardingSteps';

const OnboardingStepsContainer = props => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      pt={5}
      style={{ backgroundBlendMode: 'soft-light, normal' }}
    >
      <OnboardingSteps {...props} />
    </Flex>
  );
};

export default OnboardingStepsContainer;
