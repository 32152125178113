/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Flex,
  IconButton,
  Text,
  Stack,
  Spinner,
  VStack,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { analytics, hideCrispLauncher, nativeActionTransaction } from '@utils';
import { useNativeCallback } from '@hooks';
import constants from '@native/constants';
import ClassListItemNative from '../ClassListItemNative';

const TrackDetailPageNative = ({ fetchCampaigns, item }) => {
  // Local Properties
  const channelHeight = useBreakpointValue({
    base: 175,
    md: 200,
    lg: 250,
    xl: 300,
  });

  const channelItemWidth = useBreakpointValue({
    base: 275,
    md: 250,
    lg: 275,
    xl: 300,
  });

  // Hooks
  const history = useHistory();
  const nativeCallback = useNativeCallback();

  // State
  const [loading, setLoading] = useState(false);

  // Actions
  const fetchCampaignsAction = async () => {
    setLoading(true);
    await fetchCampaigns();
    setLoading(false);
  };

  const onBack = () => {
    analytics.t('clicked back on track details page');
    history.push('/classes-mobile');
  };

  // Render Methods
  const renderBadge = badgeTitle => (
    <Badge
      variant="filled"
      textTransform="capitalize"
      rounded="full"
      fontWeight="bold"
      paddingX={2}
      paddingY={1}
      background={constants.zipSchoolGold}
    >
      {badgeTitle}
    </Badge>
  );

  const renderSpinner = () => (
    <Box my={['20%', '10%']} d="flex" justifyContent="center">
      <VStack>
        <Spinner fontSize={['2xl', '2xl', '2xl', '3xl']} mt={4} color="white" />
      </VStack>
    </Box>
  );

  const renderHeader = () => (
    <Stack isInline>
      <IconButton
        aria-label="Back button"
        icon={<ChevronLeftIcon />}
        size={('md', 'lg')}
        fontSize={['25px', '30px']}
        isRound
        bg="gray.300"
        color="gray.700"
        onClick={onBack}
      />
      <HStack>
        <Text
          className="backdrop-frost"
          rounded="full"
          py={1}
          px={3}
          fontWeight="bold"
          fontSize="xl"
          color="white"
        >
          {item.title}
        </Text>
      </HStack>
      {/* <Stack isInline>
          {renderBadge(item.grade)}
          {renderBadge(item.subject_name)}
        </Stack> */}
    </Stack>
  );

  const renderCards = () => (
    <HStack
      alignItems="flex-start"
      justifyContent={item.classes.length > 1 ? 'flex-start' : 'center'}
      w="100%"
      h="100%"
      pt={4}
      spacing={0}
      className="no-scroll-bar"
      overflowX={item.classes.length > 1 ? 'auto' : null}
    >
      {item.classes.map((classItem, index) => {
        const currentItem = item.classes[index];
        let unlocked = false;
        let isCurrentLesson = false;

        // If first item, it's always unlocked
        // If first item, check to see if the next item is already completed or not
        if (index === 0) {
          unlocked = true;

          const nextClass = item.classes[index + 1];
          if (!currentItem.completed && !nextClass.completed) {
            isCurrentLesson = true;
          }
        } else if (index > 0) {
          // Get the class before the current class and check if it's completed
          const prevClass = item.classes[index - 1];
          unlocked = prevClass.completed;

          // Check to see if this item is the current lesson to complete
          if (!currentItem.completed && prevClass.completed) {
            isCurrentLesson = true;
          }
        }

        const updatedClassItem = { ...classItem, unlocked, isCurrentLesson };

        return (
          <React.Fragment key={updatedClassItem.id}>
            <VStack pr={index === item.classes.length - 1 ? '2rem' : 0}>
              <ClassListItemNative
                style={{
                  minWidth: channelItemWidth,
                  height: channelHeight,
                }}
                channelTitle={`${item.campaign_id}_class_list`}
                item={updatedClassItem}
                mode="details"
                index={index}
                campaignId={item.id}
              />

              {updatedClassItem.learning_goal && (
                <Box w="100%" className="backdrop-frost" rounded="lg" p={2}>
                  <Text color="white" fontWeight="bold" fontSize={['xs', 'sm', 'sm', 'md']}>
                    {`👉 Learn: ${updatedClassItem.learning_goal}`}
                  </Text>
                </Box>
              )}
            </VStack>
            {index !== item.classes.length - 1 && (
              <HStack h={channelHeight} px={2}>
                <ChevronRightIcon
                  className="backdrop-frost"
                  rounded="full"
                  color={constants.zipSchoolGold}
                  w="34px"
                  h="34px"
                />
              </HStack>
            )}
          </React.Fragment>
        );
      })}
    </HStack>
  );

  // UseEffects
  useEffect(() => {
    // Hide Crisp Launcher
    // hideCrispLauncher();

    // Native trigger
    nativeActionTransaction(true, 'landscape');

    // Fetch
    fetchCampaignsAction();
  }, []);

  useEffect(() => {
    if (nativeCallback) {
      const { action, data = {} } = nativeCallback;
      const { type } = data;
      if (
        action === constants.NATIVE_ACTIONS.LIFECYCLE &&
        type === constants.LIFECYCLE_EVENTS.VIEW_WILL_APPEAR
      ) {
        nativeActionTransaction(true, 'landscape');
        fetchCampaignsAction();
      }
    }
  }, [nativeCallback]);

  return (
    <Flex flexDir="column" className="native-inset-left" w="100%" h="100%">
      {loading && renderSpinner()}
      {!loading && item && renderHeader()}
      {!loading && item && renderCards()}
    </Flex>
  );
};

export default TrackDetailPageNative;
