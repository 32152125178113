/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/accessible-emoji */

import React from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  Select,
  FormLabel,
  VStack,
} from '@chakra-ui/react';

const CHILD_AGE_OPTIONS = [
  { id: '3', name: '3', bracket: '3-6' },
  { id: '4', name: '4', bracket: '3-6' },
  { id: '5', name: '5', bracket: '3-6' },
  { id: '6', name: '6', bracket: '3-6' },
  { id: '7', name: '7', bracket: '7-9' },
  { id: '8', name: '8', bracket: '7-9' },
  { id: '9', name: '9', bracket: '7-9' },
  { id: '10', name: '10', bracket: '10-12' },
  { id: '11', name: '11', bracket: '10-12' },
  { id: '12', name: '12', bracket: '10-12' },
];

const AddChildForm = ({ form }) => {
  // Actions
  const validateName = fieldName => value => {
    if (!value) {
      return `${fieldName} is required`;
    }

    return true;
  };

  // Render Methods
  const renderFirstNameInput = () => (
    <FormControl isInvalid={form.errors.child_first_name} isRequired>
      <FormLabel fontSize={['md', 'lg']}>Child's First Name</FormLabel>
      <Input
        name="first_name"
        placeholder="First name"
        type="text"
        h={['45px', '50px', '55px', '60px']}
        rounded="lg"
        fontSize="md"
        isRequired
        ref={form.register({ validate: validateName('First name') })}
      />
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        {form.errors.child_first_name && form.errors.child_first_name.message}
      </FormErrorMessage>
    </FormControl>
  );
  const renderAgeSelector = () => (
    <FormControl isInvalid={form.errors.child_age} isRequired>
      <Flex flexDir="column">
        <FormLabel fontSize={['md', 'lg']} pb={2}>
          Child's Age
        </FormLabel>
        <Select
          name="age"
          placeholder="Select age"
          variant="filled"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          spacing={3}
          cursor="pointer"
          ref={form.register()}
        >
          {CHILD_AGE_OPTIONS.map(t => (
            <option key={t.id}>{t.name}</option>
          ))}
        </Select>
      </Flex>
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        Child age required
      </FormErrorMessage>
    </FormControl>
  );

  return (
    <VStack w="100%" px={4} animation="fadeIn 0.4s">
      <VStack justifyContent="center" w="100%" spacing={3}>
        {renderFirstNameInput()}
        {renderAgeSelector()}
      </VStack>
    </VStack>
  );
};

export default AddChildForm;
