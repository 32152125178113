import React, { useEffect } from 'react';
import '../App.css';
import { Box, useToast } from '@chakra-ui/react';
import { Route, Switch, useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Email from './Email';
import Password from './Password';
import Sent from './Sent';
import { showErrorToast, showSuccessToast, analytics } from '@utils';
import actions from '@actions';

const ForgotPassword = props => {
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const toast = useToast();

  useEffect(() => {
    analytics.t('viewed forgot password');
  }, []);

  const onNext = async (data = {}) => {
    const { email, password } = data;

    switch (pathname) {
      case '/forgot/email':
        {
          try {
            const { exists } = await actions.validateItem({ email });
            if (!exists) throw { status: 400, message: 'No profile exists with this email' };
            await Auth.forgotPassword(email.trim());
            analytics.t('submitted forgot password email');
            history.push(`/forgot/sent?email=${encodeURIComponent(email)}`);
          } catch (err) {
            showErrorToast(toast, err.message);
          }
        }
        break;
      case '/forgot/password':
        {
          try {
            const urlParams = new URLSearchParams(window.location.search);
            const resetEmail = decodeURIComponent(urlParams.get('email'));
            const resetCode = decodeURIComponent(urlParams.get('code'));

            await Auth.forgotPasswordSubmit(resetEmail.trim(), resetCode.trim(), password.trim());
            analytics.t('submitted forgot password reset');
            showSuccessToast(toast, 'Password reset. Please log in with your new password.');
            history.push('/login');
          } catch (err) {
            showErrorToast(toast, err.message);
          }
        }
        break;
    }
  };

  return (
    <Box mx="auto" mt={3} p={[3, 3, 0]} maxW="md" textAlign="center">
      <Box p={[4, 4, 10]} borderWidth="1px" rounded="lg" bg="white">
        <Switch>
          <Route
            path={`${match.path}/email`}
            render={props => <Email onNext={onNext} {...props} />}
          />
          <Route path={`${match.path}/sent`} render={() => <Sent />} />
          <Route
            path={`${match.path}/password`}
            render={props => <Password onNext={onNext} {...props} />}
          />
          <Redirect to={`${match.path}/email`} />
        </Switch>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
