/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import TinderCard from 'react-tinder-card';
import { analytics } from '@utils';
import ClassListItem from '@components/ClassListItem';
import { modalActions } from '../constants';

const MAX_BATCH_NUM = 5;

const Classmash = ({
  items,
  onCardRef,
  setCurrentCardId,
  setOpenMatchModal,
  setModalAction,
  classMatch,
  setClassMatch,
  setIsSwiping,
}) => {
  // State
  const [displayItems, setDisplayItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fetchingCards, setFetchingCards] = useState(false);

  // Actions
  const fetchMoreItems = () => {
    const classItems = [];
    const itemMax = currentIndex + MAX_BATCH_NUM;

    for (let i = itemMax; i > itemMax - MAX_BATCH_NUM; i -= 1) {
      classItems.push(items[i]);
    }

    setCurrentIndex(itemMax);
    setDisplayItems(classItems);
    setFetchingCards(false);
  };

  const onCardLeftScreen = () => {
    if (displayItems.length > 0) {
      setDisplayItems(prevState => {
        prevState.pop();
        if (prevState.length === 0) {
          fetchMoreItems();
        }
        return [...prevState];
      });
    }
  };

  const onSwipeAction = dir => {
    setIsSwiping(true);

    const currentCard = displayItems[displayItems.length - 1];

    if (dir === 'right') {
      setClassMatch(true);
      setModalAction(modalActions.MATCH);
      setOpenMatchModal(true);
      analytics.t('swiped right', { title: currentCard.title, ageRange: currentCard.age_range });
    } else {
      analytics.t('swiped left', { title: currentCard.title, ageRange: currentCard.age_range });
    }
  };

  // UseEffect
  useEffect(() => {
    if (items && items.length > 0) {
      fetchMoreItems();
    }
  }, [items]);

  useEffect(() => {
    let index = 0;
    if (displayItems.length > 0 && !classMatch) {
      index = displayItems.length - 1;
      const nextCard = displayItems[index];
      setCurrentCardId(nextCard.class_id);
    }
  }, [displayItems]);

  useEffect(() => {
    // We need to set the next card if user decides to explore more
    if (classMatch !== null && !classMatch) {
      let index = 0;
      index = displayItems.length - 1;
      const nextCard = displayItems[index];
      setCurrentCardId(nextCard.class_id);
    }
  }, [classMatch]);

  return (
    <>
      {fetchingCards && <Spinner fontSize={['xl', 'xl', 'xl', '2xl']} color="wite" />}
      {displayItems.map(item => (
        <Box p={[5, 8]} key={item.class_id} w="100%" position="absolute">
          <TinderCard
            ref={ref => onCardRef(item.class_id, ref)}
            flickOnSwipe
            onCardLeftScreen={onCardLeftScreen}
            onSwipe={onSwipeAction}
          >
            <ClassListItem key={item.class_id} item={item} mode="classmash" />
          </TinderCard>
        </Box>
      ))}
    </>
  );
};

export default Classmash;
