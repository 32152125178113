/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { allCountries } from 'country-telephone-data';
import { countryCodeEmoji } from 'country-code-emoji';

const AddParentName = ({
  form,
  validateName,
  currentFirstName,
  currentLastName,
  currentPhoneNumber,
  decodedEmail,
  validateEmail,
}) => {
  // Actions
  const sortCountryCodes = () => {
    return allCountries.sort((a, b) => {
      return a.dialCode - b.dialCode;
    });
  };

  // Render Methods
  const renderNameFields = () => {
    return (
      <VStack justifyContent="space-between" spacing={3}>
        <HStack>
          <FormControl isInvalid={form.errors.first_name} w="100%" isRequired>
            <FormLabel fontSize={['md', 'lg']}>First Name</FormLabel>
            <Input
              name="first_name"
              placeholder="First Name"
              defaultValue={currentFirstName}
              type="text"
              h={['45px', '50px', '55px', '60px']}
              rounded="lg"
              fontSize="md"
              isRequired
              ref={form.register({ validate: validateName('First name') })}
            />
            <FormErrorMessage bg="#EDF2F7" rounded="full" p={2}>
              <Text ml={3}>{form.errors.first_name && form.errors.first_name.message}</Text>
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={form.errors.last_name} w="100%" isRequired>
            <FormLabel fontSize={['md', 'lg']}>Last Name</FormLabel>
            <Input
              name="last_name"
              placeholder="Last Name"
              defaultValue={currentLastName}
              type="text"
              w="100%"
              h={['45px', '50px', '55px', '60px']}
              rounded="lg"
              fontSize="md"
              isRequired
              ref={form.register({ validate: validateName('Last name') })}
            />
            <FormErrorMessage bg="#EDF2F7" rounded="full" p={2}>
              <Text ml={3}>{form.errors.last_name && form.errors.last_name.message}</Text>
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <FormControl isInvalid={form.errors.email} isRequired>
          <FormLabel fontSize={['md', 'lg']}>Email</FormLabel>
          <Input
            name="email"
            placeholder="Email"
            defaultValue={decodedEmail || ''}
            type="email"
            h={['45px', '50px', '55px', '60px']}
            rounded="lg"
            fontSize="md"
            ref={form.register({ validate: validateEmail })}
          />
          <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
            {form.errors.email && form.errors.email.message}
          </FormErrorMessage>
        </FormControl>
        {/*<FormControl>
          <Text fontSize={['md', 'lg']}>Phone Number</Text>
          <Text fontSize="sm" color="gray.600">
            This is optional! (Msg & data rates may apply)
          </Text>
          <HStack>
            <Select
              name="country_code"
              h={['45px', '50px', '55px', '60px']}
              rounded="lg"
              fontSize="md"
              ref={form.register()}
              maxW={['45%', '35%', '30%', '25%']}
            >
              {sortCountryCodes().map(country => (
                <option
                  key={country.name}
                  value={country.dialCode}
                  selected={country.name === 'United States'}
                >
                  {countryCodeEmoji(country.iso2)} +{country.dialCode}
                </option>
              ))}
            </Select>
            <Input
              name="phone"
              placeholder="Phone number"
              defaultValue={currentPhoneNumber}
              type="tel"
              h={['45px', '50px', '55px', '60px']}
              rounded="lg"
              fontSize="md"
              ref={form.register()}
            />
          </HStack>
          <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
            {form.errors.phone && form.errors.phone.message}
          </FormErrorMessage>
              </FormControl>*/}
      </VStack>
    );
  };

  return (
    <VStack w="100%" px={[0, 4]} spacing={4} animation="fadeIn 0.4s">
      <Flex flexDir="column" justifyContent="center">
        <Text color="#383838" fontSize={['xl', '3xl']} fontWeight="bold" textAlign="center">
          🚀 Just The Basics
        </Text>
        <Text
          color="gray.400"
          fontWeight="bold"
          fontSize={['sm', 'md']}
          textAlign="center"
          pt={[1, 3]}
        >
          We just need some basic info here to get started
        </Text>
      </Flex>
      {renderNameFields()}
    </VStack>
  );
};

export default AddParentName;
