import { Auth, API } from 'aws-amplify';
import { analytics, sendDataToNative, parseJWTPayload } from '@utils';
import axios from 'axios';
import awsconfig from '../aws-exports';

const fetchSchedule = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return fetchAuthSchedule();
  } catch (err) {
    return fetchNoAuthSchedule();
  }
};

const fetchAuthSchedule = async () => {
  try {
    const { result = {} } = await API.get('ZipParentAPI', '/schedule');
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchNoAuthSchedule = async () => {
  try {
    const { result = {} } = await API.get('ZipParentAPI', '/noauth/classes');
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchNoAuthClass = async class_id => {
  try {
    const { result = {} } = await API.get('ZipParentAPI', `/noauth/classes/${class_id}`);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchZipClasses = async () => {
  try {
    const { items, childrenCount } = await API.get('ZipParentAPI', '/classes');
    return { zipClasses: items, childrenCount };
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchCampaigns = async () => {
  try {
    const { result } = await API.get('ZipParentAPI', '/campaigns');
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchCohorts = async () => {
  try {
    const { result } = await API.get('ZipParentAPI', '/courses');
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchProfile = async () => {
  try {
    const { result = {} } = await API.get('ZipParentAPI', '/profile');
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchZipClassroom = async (booking_id, view_id, progress = '0.0') => {
  try {
    let path = `/rooms/${booking_id}`;

    try {
      await Auth.currentAuthenticatedUser();
    } catch (err) {
      path = `/noauth/rooms/${booking_id}`;
    }

    const params = new URLSearchParams(window.location.search);
    if (view_id) {
      params.set('view_id', view_id);
    }

    params.set('progress', progress);
    path += `?${params.toString()}`;

    const { result } = await API.get('ZipParentAPI', path);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchZipWaitingRoom = async booking_id => {
  try {
    const { result } = await API.get('ZipParentAPI', `/rooms/${booking_id}/waiting`);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchChildren = async () => {
  try {
    const { items } = await API.get('ZipParentAPI', '/profile/children');
    return { items };
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchZipClass = async class_id => {
  try {
    const { result } = await API.get('ZipParentAPI', `/classes/${class_id}`);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchReferralCode = async code_id => {
  try {
    const { result } = await API.get('ZipParentAPI', `/lead/${code_id}`);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createProfile = async (profile = {}, referralCode, mode) => {
  const params = {
    body: { profile, code: referralCode },
  };

  try {
    const { result = {} } = await API.post('ZipParentAPI', `/profile`, params);

    // eslint-disable-next-line no-undef
    fbq('track', 'CompleteRegistration');

    analytics.t('successful new account created', {
      data: { email: profile.email, full_name: `${profile.first_name} ${profile.last_name}`, mode },
      source: 'email',
    });

    const { session = {}, user_id, student_id } = result;
    // Set studentId in local storage
    localStorage.setItem('student_id', student_id);

    sendDataToNative({
      action: 'branch',
      data: { event: 'COMPLETE_REGISTRATION', data: {}, user_id },
    });

    const { IdToken, AccessToken, RefreshToken } = session;
    const clientId = awsconfig.Auth.userPoolWebClientId;
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${clientId}.${user_id}.accessToken`,
      AccessToken,
    );
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${clientId}.${user_id}.refreshToken`,
      RefreshToken,
    );
    localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${user_id}.idToken`, IdToken);
    localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${user_id}.clockDrift`, 0);
    localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`, user_id);
    localStorage.setItem('amplify-signin-with-hostedUI', false);
    const cu = await Auth.currentSession();
    analytics.setUserId(cu.accessToken.payload.sub);
    return { user_id, student_id };
  } catch (err) {
    if (err && err.response && err.response.status === 400) {
      analytics.t('error creating account', { message: err.response.data.message });
    }
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createClassroomInteraction = async (class_id, booking_id, resource_id, answer_choice) => {
  const searchParams = new URLSearchParams(window.location.search);
  const view_id = searchParams.get('view_id');
  const cohort_id = searchParams.get('cohort_id');

  const params = {
    body: {
      action: 'poll_answer',
      data: { view_id, booking_id, resource_id, cohort_id, answer_choice },
    },
  };

  try {
    const { result = {} } = await API.post(
      'ZipParentAPI',
      `/classes/${class_id}/interaction`,
      params,
    );
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const exchangeCodeForTokens = async code => {
  const clientId = awsconfig.Auth.userPoolWebClientId;

  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('client_id', clientId);
  params.append('code', code);
  params.append('redirect_uri', `https://${window.location.host}/oauth`);

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    const res = await axios.post(
      `https://${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/token`,
      params,
      config,
    );

    const { id_token, access_token, refresh_token } = res.data;
    const payload = parseJWTPayload(id_token);
    const { sub: user_id, new_account } = payload;

    if (new_account) {
      // eslint-disable-next-line no-undef
      fbq('track', 'CompleteRegistration');

      sendDataToNative({
        action: 'branch',
        data: { event: 'COMPLETE_REGISTRATION', data: {}, user_id },
      });

      analytics.t('successful new account created', { source: 'apple' });
    }

    const clientId = awsconfig.Auth.userPoolWebClientId;
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${clientId}.${user_id}.accessToken`,
      access_token,
    );
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${clientId}.${user_id}.refreshToken`,
      refresh_token,
    );
    localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${user_id}.idToken`, id_token);
    localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${user_id}.clockDrift`, 0);
    localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`, user_id);
    localStorage.setItem('amplify-signin-with-hostedUI', false);
    const cu = await Auth.currentSession();
    analytics.setUserId(cu.accessToken.payload.sub);

    return user_id;
  } catch (err) {
    if (err && err.response && err.response.status === 400) {
      analytics.t('error creating account', { message: err.response.data.message });
    }
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const validateItem = async ({ email, phone }) => {
  const params = {
    body: { email, phone },
  };

  try {
    const { result = {} } = await API.post('ZipParentAPI', `/validate`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const updateProfile = async (profile = {}, action) => {
  const params = {
    body: { profile, action },
  };

  try {
    await API.put('ZipParentAPI', `/profile`, params);
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const updateTopics = async (topics = []) => {
  const params = {
    body: { data: { topics }, action: 'topics' },
  };

  try {
    await API.put('ZipParentAPI', `/profile`, params);
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const updateRedemption = async (action, data = {}) => {
  const params = {
    body: { action, data },
  };

  try {
    await API.put('ZipParentAPI', `/profile`, params);
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const toggleLike = async class_id => {
  const params = {
    body: { action: 'like', data: { class_id } },
  };

  try {
    const { result = {} } = await API.put('ZipParentAPI', `/profile`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const updateChild = async (child = {}, action) => {
  // const params = {
  //   body: { updates: deleteChild ? { deleteChild } : { first_name, age, color, emoji } },
  // };

  const params = {
    body: { child, action },
  };

  try {
    await API.put('ZipParentAPI', `/profile/children/${child.id}`, params);
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createChild = async (profile = {}) => {
  const params = {
    body: { profile },
  };

  try {
    const { result = {} } = await API.post('ZipParentAPI', `/profile/children`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createLead = async email => {
  const params = {
    body: { profile: { email } },
  };

  try {
    const { result = {} } = await API.post('ZipParentAPI', `/lead`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createBooking = async (class_id, token, paymentMethod) => {
  const params = {
    body: { booking: { token, paymentMethod } },
  };

  try {
    const { result = {} } = await API.post('ZipParentAPI', `/classes/${class_id}/book`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createCohortBooking = async cohortId => {
  const params = {
    headers: {
      'zip-student-id': localStorage.getItem('student_id'),
    },
    body: {},
  };

  try {
    const { result = {} } = await API.post('ZipParentAPI', `/cohorts/${cohortId}/enroll`, params);
    analytics.t('courses: successfully enrolled', {
      title: result.title,
      courseTitle: result.title,
      startDate: result.start_date,
    });

    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createCheckoutSessionForCohort = async (cohort_id, booking_id) => {
  const params = {
    headers: {
      'zip-student-id': localStorage.getItem('student_id'),
    },
    body: {
      booking_id,
      mode: 'checkout',
    },
  };

  try {
    const { result = {} } = await API.post('ZipParentAPI', `/cohorts/${cohort_id}/enroll`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const cancelBooking = async booking_id => {
  const params = {
    body: { action: 'delete' },
  };
  try {
    const { result = {} } = await API.put('ZipParentAPI', `/schedule/${booking_id}`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createCheckoutSession = async (class_id, slot_id, student_id) => {
  const params = {
    body: { class_id, slot_id, student_id },
  };

  try {
    const { result = {} } = await API.post('ZipParentAPI', `/checkout`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

/**
 * Books a free drop in class for the current user
 * @param {string} unitId
 * @param {string} slotId
 * @param {string} classId
 */
const bookDropInClass = async (classId, request) => {
  const params = {
    body: request,
  };

  try {
    const { result = {} } = await API.post('ZipParentAPI', `/classes/${classId}/book`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const rescheduleBooking = async (bookingId, request) => {
  const params = {
    body: { action: 'reschedule', data: request },
  };

  try {
    const { result = {} } = await API.put('ZipParentAPI', `/schedule/${bookingId}`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchConfig = async () => {
  try {
    const { response = {} } = await API.get('ZipParentAPI', `/config`);
    return response;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchCourse = async course_id => {
  let path = `/courses/${course_id}`;
  try {
    await Auth.currentAuthenticatedUser();
  } catch (err) {
    path = `/noauth/courses/${course_id}`;
  }

  try {
    const { result = {} } = await API.get('ZipParentAPI', path);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

export default {
  fetchSchedule,
  fetchZipClasses,
  fetchZipClass,
  fetchCohorts,
  fetchCampaigns,
  fetchZipClassroom,
  createProfile,
  createChild,
  createBooking,
  bookDropInClass,
  fetchChildren,
  fetchZipWaitingRoom,
  fetchProfile,
  cancelBooking,
  createCheckoutSession,
  createCheckoutSessionForCohort,
  updateProfile,
  createLead,
  fetchReferralCode,
  updateChild,
  validateItem,
  fetchNoAuthClass,
  toggleLike,
  rescheduleBooking,
  exchangeCodeForTokens,
  updateRedemption,
  updateTopics,
  fetchConfig,
  fetchCourse,
  createCohortBooking,
  createClassroomInteraction,
};
