import { Auth, Cache } from 'aws-amplify';
import { currentTz } from '@utils';

export default {
  Auth: {
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_SRP_AUTH',
    /*=oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['openid', 'email', 'profile'],
      redirectSignIn: 'https://zipschool.ngrok.io',
      redirectSignOut: 'https://zipschool.ngrok.io',
      responseType: 'code',
    },*/
  },
  API: {
    endpoints: [
      {
        name: 'ZipParentAPI',
        endpoint: process.env.REACT_APP_ENDPOINT,
        custom_header: async () => {
          let authToken;
          try {
            authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
          } catch (err) {
            authToken = Cache.getItem('idToken');
          }

          return {
            Authorization: authToken,
            'x-api-key': process.env.REACT_APP_API_KEY,
            'zip-tz-offset': new Date().getTimezoneOffset(),
            'zip-tz-iso': currentTz(),
          };
        },
      },
    ],
  },
};
