/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { analytics } from '@utils';

const BG_COLOR = '#F7F7F7';
const SELECTED_BG_COLOR = '#FFC503';

const ChildItem = ({ child, selectedChildId, setSelectedChildId }) => {
  // Local Properties
  const selected = child.id === selectedChildId;

  // Actions
  const onSelect = () => {
    setSelectedChildId(child.id);
    analytics.t('clicked child on booking flow');
  };

  return (
    <Flex
      bg={selected ? SELECTED_BG_COLOR : BG_COLOR}
      color="black"
      fontWeight={selected ? 'bold' : ''}
      px={[3, 4, 5]}
      py={[4, 5, 6]}
      borderRadius="16px"
      shadow="sm"
      justifyContent="space-between"
      alignItems="center"
      cursor="pointer"
      onClick={onSelect}
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%" h="100%">
        <Text fontSize={['sm', 'md', 'lg']}>{child.first_name}</Text>
        {selected && <Text fontSize={['sm', 'md', 'lg']}>✅</Text>}
      </Flex>
    </Flex>
  );
};

export default memo(ChildItem);
