/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useContext, useMemo, memo } from 'react';
import { useHistory } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import {
  AspectRatio,
  Badge,
  Collapse,
  Image,
  Text,
  Stack,
  Box,
  Button,
  Avatar,
  Flex,
  Wrap,
  WrapItem,
  HStack,
  useClipboard,
  useToast,
  IconButton,
} from '@chakra-ui/react';

import { Waypoint } from 'react-waypoint';
import { analytics, convertTZ, isiOSNativeDevice, navigateTo, showErrorToast } from '@utils';
import actions from '@actions';
import { FaRegHeart, FaHeart, FaShareSquare, FaTimes } from 'react-icons/fa';
import VideoPlayerModalContext from '../../Context/VideoPlayerModalContext';
import ProfileContext from '../../Context/ProfileContext';

const BADGE_BORDER_COLOR = '#A0AEC0';
const CTA_BG_COLOR = 'purple.500';

const ClassListItem = ({
  style,
  channelTitle = '',
  onRef,
  item,
  mode,
  isChannelItem,
  scrollToItemId,
  setCurrentBookingClassData,
  onCancelBooking,
}) => {
  // Local Properties
  const {
    class_id: classId,
    description,
    duration,
    teacher,
    title,
    subject,
    subject_icon: subjectIcon,
    age_range: ageRange,
    completed,
    liked,
    scheduled,
    poster,
    type,
    classes = [],
    resource_data: resourceData,
    booking_id: bookingId,
    share,
    timestamp,
  } = item;
  const cardMinWidth = ['290px', '300px', '350px', '380px'];
  const cardMaxWidth = ['340px', '350px', '400px', '400px'];
  const history = useHistory();

  // State
  const [runScrollaAnimation, setRunScrollAnimation] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const [loadingSaveForLater, setLoadingSaveForLater] = useState(false);
  const [loadingAddToSchedule, setLoadingAddToSchedule] = useState(false);
  const [loadingCancelBooking, setLoadingCancelBooking] = useState(false);

  const [likedState, setLikedState] = useState(liked);

  // Hooks
  const toast = useToast();
  const { onCopy } = useClipboard(share && share.url);

  // Context
  // const videoPreviewModal = useContext(VideoPlayerModalContext);

  // References
  const videoRef = useRef(null);
  const origin = window.location.href.split('/').pop();

  // Actions
  const getCardHeight = useMemo(() => {
    // SimpleGrid Cards
    if (isChannelItem && mode !== 'classmash') {
      return '210px';
    }
    if (setCurrentBookingClassData) {
      return ['245px', '245px', '245px', '270px'];
    }

    if (mode === 'classmash') {
      return '100%';
    }

    return null;
  });

  const openBookClassView = () => {
    const formattedChannelTitle = channelTitle.includes('Classes for')
      ? 'Classes for'
      : channelTitle;

    analytics.t('clicked watch now', {
      title,
      classId,
      teacher,
      origin,
      channelTitle: formattedChannelTitle,
    });

    navigateTo(history, `/live/${bookingId || classId}?origin=${origin}`);
  };

  const handleEnterViewport = () => {
    try {
      if (videoRef && videoRef.current) {
        if (!videoRef.current.src) {
          // videoRef.current.src = poster.url;
          // videoRef.current.load();
        }
      }
    } catch (error) {
      console.warn('ClassListItem Error: ', error);
    }
  };

  const handleExitViewport = () => {
    try {
      if (videoRef && videoRef.current) {
        // videoRef.current.pause();
        // videoRef.current.removeAttribute('src');
        // videoRef.current.load();
      }
    } catch (error) {
      console.warn('ClassListItem Error: ', error);
    }
  };

  // Render Methods
  const renderCardBadge = (badgeContent, fontWeight) => (
    <Badge
      variant="subtle"
      textTransform="capitalize"
      rounded="full"
      fontSize={['xs', 'sm', 'sm', 'sm']}
      fontWeight={fontWeight}
      paddingX={2}
      paddingY={1}
      background="white"
      borderWidth="1px"
      borderColor={BADGE_BORDER_COLOR}
    >
      {badgeContent}
    </Badge>
  );

  const renderTeacher = () => (
    <Flex alignItems="center">
      <Avatar size="xs" src={teacher.image_url} />

      <Text fontSize="md" fontWeight="bold" color="#383838" pl={2}>
        {teacher.name}
      </Text>
    </Flex>
  );

  const renderStars = () => (
    <Flex alignItems="center">
      <Text fontSize="md" fontWeight="bold" color="#383838" pl={2}>
        ⭐️⭐️⭐️⭐️⭐️
      </Text>
    </Flex>
  );

  const handleLikeClass = async () => {
    analytics.t('clicked save for later', { title, classId, teacher, origin });
    setLoadingSaveForLater(true);

    try {
      const res = await actions.toggleLike(classId);
      setLikedState(res.liked);
    } catch (err) {
      console.warn(err);
      showErrorToast(toast, err.message);
    }
    setLoadingSaveForLater(false);
  };

  const onAddToSchedule = async () => {
    if (scheduled && origin !== 'schedule') {
      history.push('/schedule');
      analytics.t('clicked in your schedule', { title, classId, teacher, origin });
    } else {
      setLoadingAddToSchedule(true);
      const res = await actions.fetchZipClass(classId);
      if (bookingId) {
        analytics.t('clicked reschedule', { title, classId, teacher, origin });
      } else {
        analytics.t('clicked add to schedule', { title, classId, teacher, origin });
      }
      res.booking_id = bookingId;
      res.mode = mode;
      console.log(setCurrentBookingClassData);
      setCurrentBookingClassData(res);
      setLoadingAddToSchedule(false);
    }
  };

  const onLocalCancelBooking = async () => {
    analytics.t('clicked delete from schedule', { title, classId, teacher, origin });

    setLoadingCancelBooking(true);
    await onCancelBooking(item);
    setLoadingCancelBooking(false);
  };

  const renderAddToSchedule = () => (
    <Stack spacing={3} px={3}>
      <Button
        isLoading={loadingAddToSchedule}
        colorScheme={scheduled ? 'green' : 'purple'}
        onClick={onAddToSchedule}
        shadow="md"
        w="100%"
        h={['45px', '55px']}
        rounded="full"
        borderWidth="1px"
        fontSize={['md', 'lg']}
        fontWeight="bold"
        bg={scheduled ? 'green.500' : CTA_BG_COLOR}
        color="white"
      >
        <Text>{scheduled ? '✅ In Your Schedule' : '⏰ Add To Schedule'}</Text>
      </Button>
    </Stack>
  );

  const renderWatchNow = () => (
    <Stack spacing={3} px={3}>
      <Button
        onClick={openBookClassView}
        shadow="md"
        w="100%"
        h={mode === 'schedule' ? ['45px', '55px'] : ['35px', '45px']}
        color={mode === 'schedule' ? 'white' : 'black'}
        rounded="full"
        textAlign="center"
        fontWeight="bold"
        fontSize={mode === 'schedule' ? ['md', 'lg'] : 'md'}
        bg={mode === 'schedule' ? CTA_BG_COLOR : 'gray.200'}
        colorScheme={mode === 'schedule' ? 'purple' : 'gray'}
        _hover={{ backgroundColor: 'gray.300' }}
      >
        <Text>📺 Watch Now</Text>
      </Button>
    </Stack>
  );

  const renderReschedule = () => (
    <Stack spacing={3} px={3}>
      <Button
        isLoading={loadingAddToSchedule}
        onClick={onAddToSchedule}
        shadow="md"
        w="100%"
        h={['35px', '45px']}
        bg="gray.200"
        color="black"
        rounded="full"
        textAlign="center"
        fontWeight="bold"
        fontSize={['sm', 'md']}
        _hover={{ backgroundColor: 'gray.300' }}
      >
        <Text>🔄 Reschedule</Text>
      </Button>
    </Stack>
  );

  const renderTime = () => (
    <Stack>
      <Text textAlign="center" fontWeight="bold" fontSize="lg">
        {convertTZ(timestamp, { showTimeZone: true })}
      </Text>
    </Stack>
  );

  const renderVideoAsset = () => (
    <Box
      w="100%"
      h="100%"
      backgroundImage="url('https://assets.zipschool.com/posters/gadgets.png')"
      backgroundRepeat="repeat"
      overflow="hidden"
    >
      <AspectRatio roundedTop="lg">
        <video
          ref={videoRef}
          muted
          playsInline
          autoPlay={false}
          poster={poster.thumbnail_url}
          loop
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </AspectRatio>
    </Box>
  );

  const renderImageAsset = () => (
    <AspectRatio>
      <Image height="100%" roundedTop="lg" src={poster.url} objectFit="cover" />
    </AspectRatio>
  );

  const renderCompletedBadge = () => (
    <Stack position="absolute">
      {completed && type !== 'pack' && (
        <Box>
          <Badge
            px={2}
            py={1}
            textTransform="capitalize"
            fontSize={['sm', 'md']}
            fontWeight="bold"
            shadow="md"
            rounded="lg"
            variant="solid"
            colorScheme="purple"
          >
            ⭐️ Completed
          </Badge>
        </Box>
      )}
    </Stack>
  );

  const renderShareButton = () => (
    <HStack w="100%" spacing={2} justifyContent="flex-end">
      {share && mode !== 'classmash' && (
        <IconButton
          onClick={() => {
            onCopy();
            setHasCopied(true);
          }}
          px={3}
          fontSize={['sm', 'md']}
          fontWeight="bold"
          shadow="md"
          rounded="lg"
          color="white"
          colorScheme={hasCopied ? 'green' : 'purple'}
          aria-label="Search database"
          icon={hasCopied ? <Text px={2}>✅ Link Copied!</Text> : <FaShareSquare />}
        />
      )}
      <IconButton
        bg="purple.500"
        isLoading={loadingSaveForLater}
        onClick={handleLikeClass}
        colorScheme="purple"
        borderWidth={likedState ? '2px' : null}
        borderColor="#FFC503"
        icon={likedState ? <FaHeart color="#FFC503" /> : <FaRegHeart color="white" />}
      />
    </HStack>
  );

  const renderCancelButton = () => (
    <HStack w="100%" spacing={2} justifyContent="flex-start">
      <IconButton
        bg="gray.300"
        isLoading={loadingCancelBooking}
        onClick={onLocalCancelBooking}
        icon={<FaTimes />}
      />
    </HStack>
  );

  const renderMediaAndBages = () => (
    <Box
      w="100%"
      h={
        mode === 'classmash'
          ? ['140px', '140px', '140px', '220px']
          : ['150px', '150px', '150px', '180px']
      }
      position="relative"
      roundedTop="lg"
      className="mobile-border-radius"
    >
      <Waypoint
        fireOnRapidScroll
        onEnter={handleEnterViewport}
        onLeave={handleExitViewport}
        horizontal={isChannelItem}
      >
        {poster.type === 'video' ? renderVideoAsset() : renderImageAsset()}
      </Waypoint>
      <Flex w="100%" position="absolute" p={3} top="0" left="0" justifyContent="space-between">
        {mode === 'schedule' && renderCancelButton()}
        {mode !== 'schedule' && renderCompletedBadge()}

        {mode !== 'singleclass' && renderShareButton()}
      </Flex>
    </Box>
  );

  const renderBadgeSection = () => (
    <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap">
      <Wrap>
        {classes.length > 1 && (
          <WrapItem>{renderCardBadge(`${classes.length} class pack`)}</WrapItem>
        )}

        <WrapItem>{renderCardBadge(`Recommended Ages: ${ageRange}`)}</WrapItem>
        <WrapItem>{renderCardBadge(`${subject}`, 'regular')}</WrapItem>
        <WrapItem>{type !== 'pack' && renderCardBadge(`${duration} mins`)}</WrapItem>
      </Wrap>
    </Flex>
  );

  const renderDescriptionSection = () => (
    <Box>
      <Collapse
        startingHeight={60}
        in={descriptionOpen}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setDescriptionOpen(!descriptionOpen);
        }}
      >
        <Text color="gray.500" fontSize={['sm', 'md']}>
          {description}
        </Text>
      </Collapse>
      <Text
        fontSize={['sm', 'md']}
        textAlign="right"
        color="gray.600"
        onClick={() => {
          setDescriptionOpen(!descriptionOpen);
        }}
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
      >
        {descriptionOpen ? 'Show less' : 'Show more'}
      </Text>
    </Box>
  );

  const renderTitleAndDescription = () => (
    <Stack spacing={1}>
      <Text color="#383838" fontSize={['lg', 'lg', 'lg', 'xl']} noOfLines={3} fontWeight="black">
        {`${subjectIcon || ''} ${title}`}
      </Text>
      {!isChannelItem && description && setCurrentBookingClassData && (
        <Text
          opacity="0.6"
          color="gray.700"
          fontSize={['sm', 'md']}
          noOfLines={setCurrentBookingClassData ? 2 : null}
        >
          {description}
        </Text>
      )}

      {!isChannelItem &&
        mode !== 'classmash' &&
        description &&
        !setCurrentBookingClassData &&
        renderDescriptionSection()}
    </Stack>
  );

  // UseEffects
  useEffect(() => {
    if (hasCopied) {
      analytics.t('clicked share class', { title });
      setTimeout(() => {
        setHasCopied(false);
      }, 5000);
    }
  }, [hasCopied]);

  useEffect(() => {
    setTimeout(() => {
      if (scrollToItemId && classId === scrollToItemId && !runScrollaAnimation) {
        setRunScrollAnimation(true);
      }
    }, 1000);
  }, [scrollToItemId]);

  return (
    <Box
      ref={e => (onRef ? onRef(item.class_id, e) : null)}
      id={item.class_id}
      minW={cardMinWidth}
      maxW={setCurrentBookingClassData ? cardMaxWidth : null}
      h="100%"
      rounded="lg"
      cursor={mode === 'classmash' ? 'pointer' : 'default'}
      style={style}
      px={2}
    >
      <Stack
        bg="white"
        rounded="lg"
        shadow="lg"
        className={runScrollaAnimation ? 'animate-selected-item' : ''}
      >
        {poster && renderMediaAndBages()}
        <Stack
          spacing={3}
          h={getCardHeight}
          minH={mode === 'singleclass' ? ['225px', '225px', '225px', '250px'] : '0'}
          pt={4}
          pb={mode === 'singleclass' ? 3 : 4}
          px={6}
        >
          {renderTitleAndDescription()}
          {mode === 'singleclass' && (
            <HStack>
              {renderTeacher()}
              {renderStars()}
            </HStack>
          )}
          {type !== 'pack' && setCurrentBookingClassData && renderTeacher()}
          {renderBadgeSection()}
        </Stack>
        {mode === 'schedule' ? (
          <Stack py={3}>
            {renderTime()}
            {renderWatchNow()}
            {renderReschedule()}
          </Stack>
        ) : (
          <Stack py={3}>
            {mode !== 'singleclass' && renderAddToSchedule()}
            {mode !== 'singleclass' && renderWatchNow()}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ClassListItem;
