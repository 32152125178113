/* eslint-disable react/prop-types */
import React, { memo, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AspectRatio, Button, Box, Flex, Text } from '@chakra-ui/react';
import Footer from '../Footer';
import { analytics } from '@utils';

const VideoViewer = ({ isAuthenticated }) => {
  // Local Properties
  const { embed_id: embedId } = useParams();

  // Render Methods
  const renderAuthButtons = () => (
    <Box>
      <Link to="/schedule">
        <Button
          mr={3}
          bg="transparent"
          border="1px"
          borderColor="white"
          color="white"
          fontWeight="normal"
          rounded="full"
          _hover={{ color: 'black', backgroundColor: 'whitesmoke', fontWeight: 'bold' }}
        >
          <Text fontSize={['xs', 'md']}>Classes</Text>
        </Button>
      </Link>
      <Link to="/booked">
        <Button
          mr={3}
          bg="transparent"
          border="1px"
          borderColor="white"
          color="white"
          fontWeight="normal"
          rounded="full"
          _hover={{ color: 'black', backgroundColor: 'whitesmoke', fontWeight: 'bold' }}
        >
          <Text fontSize={['xs', 'md']}>Booked</Text>
        </Button>
      </Link>
      <Link to="/profile">
        <Button
          bg="transparent"
          border="1px"
          borderColor="white"
          color="white"
          fontWeight="normal"
          rounded="full"
          _hover={{ color: 'black', backgroundColor: 'whitesmoke', fontWeight: 'bold' }}
        >
          <Text fontSize={['xs', 'md']}>Profile</Text>
        </Button>
      </Link>
    </Box>
  );

  const renderSignupButton = () => (
    <a href="https://zipschool.com" target="_blank" rel="noreferrer">
      <Button
        mr={3}
        bg="transparent"
        border="1px"
        borderColor="white"
        color="white"
        fontWeight="normal"
        rounded="full"
        _hover={{ color: 'black', backgroundColor: 'whitesmoke', fontWeight: 'bold' }}
        onClick={() => analytics.t('clicked signup button on video', { embedId })}
      >
        <Text fontSize={['xs', 'md']}>Signup Now</Text>
      </Button>
    </a>
  );

  // UseEffects
  useEffect(() => {
    analytics.t('hit video viewer page on video', {
      embedId,
      isAuthenticated: isAuthenticated || false,
    });
  }, []);

  return (
    <Box
      w="100%"
      minH="100%"
      bg="#4621AD"
      backgroundImage="linear-gradient(98.17deg, 
        rgba(255, 255, 255, 0.88) 43.72%, rgba(255, 255, 255, 0) 93.72%)"
      style={{ backgroundBlendMode: 'soft-light, normal' }}
    >
      <Flex as="nav" wrap="wrap" w="100%" p={10} justifyContent="space-between">
        <a href="https://zipschool.com" target="_blank" rel="noreferrer">
          <Text
            color="white"
            fontWeight="bold"
            fontSize="3xl"
            textAlign="center"
            onClick={() => analytics.t('clicked zipschool logo on video', { embedId })}
          >
            ZipSchool 🚀
          </Text>
        </a>
        {isAuthenticated && renderAuthButtons()}
        {!isAuthenticated && renderSignupButton()}
      </Flex>
      <AspectRatio maxW="80%" m="auto">
        <Box
          as="iframe"
          title={`vimeo_video_${embedId}`}
          src={`https://player.vimeo.com/video/${embedId}`}
          allowFullScreen
        />
      </AspectRatio>
      <Footer darkBackground bg="transparent" />
    </Box>
  );
};

export default memo(VideoViewer);
