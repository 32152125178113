/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Flex, IconButton, Text, Button, useToast, VStack, Image } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { showErrorToast, analytics, isiOSNativeDevice, navigateTo } from '@utils';
import actions from '@actions';
import { CDN_HOSTNAME } from '@constants';
import AddTopicsFlow from './components/AddTopicsFlow';
import AddParentNameAndPhone from './components/AddParentNameAndPhone';
import AddChildFlow from './components/AddChildFlow';
import AddEmailAndPassword from './components/AddEmailAndPassword';
import * as constants from '../constants';

const OnboardingSteps = ({ fetchAuthState }) => {
  // Local Properties
  const urlParams = new URLSearchParams(window.location.search);
  const queryEmail = urlParams.get('email');
  const decodedEmail = queryEmail ? decodeURIComponent(queryEmail) : '';

  const referralCode = urlParams.get('code');
  const decodedReferralCode = referralCode ? decodeURIComponent(referralCode) : undefined;

  const queryStep = urlParams.get('step');
  const class_id = urlParams.get('class_id');
  const slot_id = urlParams.get('slot_id');

  // State
  const [currentStep, setCurrentStep] = useState(queryStep || constants.onboardingSteps.ADD_TOPICS);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const toast = useToast();
  const history = useHistory();
  const form = useForm({
    defaultValues: {
      email: decodedEmail,
    },
  });

  // Actions
  const createLead = async () => {
    if (decodedEmail.length > 0) {
      try {
        await actions.createLead(decodedEmail);
      } catch (err) {
        console.warn(err.message);
      }
    }
  };

  const validateEmail = value => {
    // eslint-disable-next-line max-len
    const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error;

    if (!value) {
      error = 'Valid email required';
    } else if (!emailExpression.test(value)) {
      error = 'Email format is not correct';
    }

    return error || true;
  };

  const validateName = fieldName => value => {
    if (!value) {
      return `${fieldName} is required`;
    }

    return true;
  };

  const validatePassword = value => {
    let error;
    if (!value) {
      error = 'Password is required';
    } else if (value.length < 8) {
      error = 'Password must be at least 8 characters';
    }

    return error || true;
  };

  const onBack = () => {
    if (currentStep === constants.onboardingSteps.ADD_TOPICS) {
      window.history.back();
    } else if (currentStep === constants.onboardingSteps.ADD_PARENT_NAME_PHONE) {
      if (queryStep) {
        history.push('/login');
      } else {
        setCurrentStep(constants.onboardingSteps.ADD_TOPICS);
      }
    } else if (currentStep === constants.onboardingSteps.ADD_CHILD) {
      setCurrentStep(constants.onboardingSteps.ADD_PARENT_NAME_PHONE);
    } else if (currentStep === constants.onboardingSteps.ADD_EMAIL_PASSWORD) {
      setCurrentStep(constants.onboardingSteps.ADD_CHILD);
    }
  };

  const onNext = async data => {
    if (currentStep === constants.onboardingSteps.ADD_TOPICS) {
      setCurrentStep(constants.onboardingSteps.ADD_PARENT_NAME_PHONE);

      analytics.t('clicked next on topics list', {
        step: '1/4',
        selectedTopics: selectedTopics.map(topic => topic.name),
      });
    } else if (currentStep === constants.onboardingSteps.ADD_PARENT_NAME_PHONE) {
      setProfile(prevState => {
        return {
          ...prevState,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
        };
      });

      setCurrentStep(constants.onboardingSteps.ADD_CHILD);

      analytics.t('clicked next on parent name and email', {
        step: '2/4',
        countryCode: data.phone ? data.country_code : null,
      });
    } else if (currentStep === constants.onboardingSteps.ADD_CHILD) {
      console.log(data);

      setProfile(prevState => {
        return {
          ...prevState,
          child_age: data.child_age,
          child_first_name: data.child_first_name,
        };
      });

      setCurrentStep(constants.onboardingSteps.ADD_EMAIL_PASSWORD);

      analytics.t('clicked next on add child', { step: '3/4', childAge: data.child_age });
    } else if (currentStep === constants.onboardingSteps.ADD_EMAIL_PASSWORD) {
      setLoading(true);
      analytics.t('clicked next on password', { step: '4/4' });

      try {
        const req = {
          ...profile,
          password: data.password,
        };

        await actions.createProfile(req, decodedReferralCode, 'noauth');

        await fetchAuthState();
        if (class_id) {
          navigateTo(history, `/live/${class_id}`);
        } else {
          navigateTo(history, `/classes`);
        }
      } catch (err) {
        setLoading(false);
        showErrorToast(toast, err.message);
      }
    }
  };

  // Render Methods
  const renderContinueButton = () => (
    <Button
      w="100%"
      h={['45px', '50px', '55px', '60px']}
      borderRadius={80}
      mt={5}
      fontSize="lg"
      fontWeight="bold"
      color="white"
      bg="#4621AD"
      isLoading={isLoading}
      backgroundImage="linear-gradient(98.17deg, 
                            rgba(255, 255, 255, 0.88) 43.72%, rgba(255, 255, 255, 0) 93.72%)"
      style={{ backgroundBlendMode: 'soft-light, normal' }}
      _hover={{ backgroundColor: '#4621AD' }}
      onClick={form.handleSubmit(onNext)}
    >
      Continue
    </Button>
  );

  // UseEffects
  useEffect(() => {
    createLead();
  }, []);

  return (
    <Flex flexDir="column" animation="fadeIn 0.6s">
      <Flex justifyContent="space-between" position="relative" alignItems="center" marginY={4}>
        {(currentStep !== constants.onboardingSteps.ADD_TOPICS || class_id) && (
          <Box zIndex="2" animation="fadeIn 1s">
            <IconButton
              aria-label="Back button"
              icon={<ChevronLeftIcon />}
              size={('md', 'lg')}
              fontSize={['25px', '30px']}
              isRound
              onClick={onBack}
            />
          </Box>
        )}
        <Box zIndex="1" d="flex" justifyContent="center" position="absolute" width="100%">
          <Image
            height="30px"
            size="lg"
            cursor="pointer"
            onClick={() => (window.location = 'https://zipschool.com')}
            src={`${CDN_HOSTNAME}/icons/zipschool-logo-light.svg`}
          />
        </Box>
        <Box display="none">
          <IconButton
            aria-label="Back button"
            icon={<ChevronLeftIcon />}
            size={('md', 'lg')}
            fontSize={['25px', '30px']}
            isRound
            onClick={onBack}
          />
        </Box>
      </Flex>
      <VStack
        color="black"
        w={['xs', 'md', 'md', 'lg']}
        minH="md"
        justifyContent="space-around"
        bg="white"
        p={4}
        rounded="lg"
      >
        <Flex w="100%" h="100%">
          {currentStep === constants.onboardingSteps.ADD_TOPICS && (
            <AddTopicsFlow setSelectedTopics={setSelectedTopics} selectedTopics={selectedTopics} />
          )}

          {currentStep === constants.onboardingSteps.ADD_PARENT_NAME_PHONE && (
            <AddParentNameAndPhone
              form={form}
              validateName={validateName}
              currentFirstName={profile.first_name}
              currentLastName={profile.last_name}
              currentPhoneNumber={profile.phone}
              validateEmail={validateEmail}
              currentEmail={decodedEmail}
            />
          )}

          {currentStep === constants.onboardingSteps.ADD_CHILD && (
            <AddChildFlow
              form={form}
              childAge={profile.child_age}
              childFirstName={profile.child_first_name}
              validateName={validateName}
            />
          )}

          {currentStep === constants.onboardingSteps.ADD_EMAIL_PASSWORD && (
            <AddEmailAndPassword
              form={form}
              validateEmail={validateEmail}
              validatePassword={validatePassword}
              currentEmail={decodedEmail}
            />
          )}
        </Flex>
        {renderContinueButton()}
      </VStack>
    </Flex>
  );
};

export default OnboardingSteps;
