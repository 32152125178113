const MODES = {
  DETAILS: 'details',
  NEXT_CLASS_ACTION: 'next_class_action',
};

const NATIVE_ACTIONS = {
  AUTHORIZED: 'authorized',
  DENIED: 'denied',
  LIFECYCLE: 'lifecycle',
};

const LIFECYCLE_EVENTS = {
  VIEW_WILL_APPEAR: 'viewWillAppear',
  VIEW_DID_APPEAR: 'viewDidAppear',
  VIEW_WILL_DISAPPEAR: 'viewWillDisappear',
  VIEW_DID_DISAPPEAR: 'viewDidDisappear',
};

const gradientPurpleBg = 'linear-gradient(163.47deg, #6935D5 27.2%, #6F27FF 99.46%)';
const zipSchoolGold = 'rgb(255, 197, 3)';

export default {
  MODES,
  NATIVE_ACTIONS,
  LIFECYCLE_EVENTS,
  gradientPurpleBg,
  zipSchoolGold,
};
