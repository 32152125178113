/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Text, Stack, Box, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { isMobileDevice } from '@utils';

import ClassListItem from '../ClassListItem';

const GUTTER_SIZE = 10;

const ClassChannel = ({
  title,
  subtitle,
  items,
  numMaxItems,
  isDark,
  isPackChannel,
  mode,
  setOpenBookClassView,
  setCurrentBookingClassData,
  onLikeClass,
  onCancelBooking,
}) => {
  // Local Properties
  const styleProps = {
    arrowColor: 'white',
    textColor: isDark ? 'white' : 'black',
    textOpacity: isDark ? '0.7' : '0.6',
    borderColor: isDark ? 'gray.700' : 'transparent',
    buttonBGColor: isDark ? 'purple.400' : 'purple.400', // '#FFC503',
    buttonHoverColor: isDark ? 'purple.200' : 'gray.300',
  };

  // State
  const [didSeekForward, setDidSeekForward] = useState(false);
  const [maxItems, setMaxItems] = useState([]);

  // Hooks
  const channelHeight = useBreakpointValue({
    base: 550,
    md: 550,
    lg: 550,
    xl: 600,
  });

  const channelItemWidth = useBreakpointValue({
    base: 300,
    md: 400,
    lg: 400,
    xl: 400,
  });

  // References
  const channelItemContainer = useRef(null);

  // Render Methods

  const renderHeader = () => (
    <Stack mx={mode === 'native' ? 0 : [5, 8]} mt={mode === 'native' ? 0 : 3} spacing={1}>
      <Text color={styleProps.textColor} fontWeight="bold" fontSize={['xl', 'xl', 'xl', '2xl']}>
        {title}
      </Text>
      <Text
        opacity={styleProps.textOpacity}
        color={styleProps.textColor}
        fontSize={['md', 'lg']}
        noOfLines={2}
      >
        {subtitle}
      </Text>
    </Stack>
  );

  // Actions
  const runFilters = () => {
    const params = new URLSearchParams(window.location.search);
    const ageParam = params.get('age');
    let filteredItems = [];

    // Filter out completed Items
    if (!isPackChannel) {
      filteredItems = items.filter(item => !item.completed);
    } else {
      filteredItems = items;
    }

    // Filter any other items
    if (ageParam) {
      const ageFilter = [ageParam];

      filteredItems = filteredItems.filter(i => {
        const ages = [];
        const ageParts = i.age_range.split('-').map(num => parseInt(num, 10));
        for (let age = ageParts[0]; age <= ageParts[1]; age += 1) {
          ages.push(parseInt(age, 10));
        }

        const filteredArray = ageFilter.filter(value => ages.includes(parseInt(value, 10)));
        return filteredArray.length > 0;
      });
    }

    if (filteredItems.length !== 0) {
      setMaxItems(filteredItems.slice(0, numMaxItems));
    } else {
      setMaxItems(items.slice(0, numMaxItems));
    }
  };

  const handleNav = direction => () => {
    if (channelItemContainer && channelItemContainer.current) {
      const cardWidth =
        channelItemContainer.current.children[0].children[0].offsetWidth + GUTTER_SIZE;

      if (direction === 'back') {
        channelItemContainer.current.scrollTo({
          left: channelItemContainer.current.scrollLeft - cardWidth,
          behavior: 'smooth',
        });
      } else {
        if (!didSeekForward) {
          setDidSeekForward(true);
        }

        channelItemContainer.current.scrollTo({
          left: channelItemContainer.current.scrollLeft + cardWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  const renderForwardButton = () => (
    <IconButton
      position="absolute"
      top="40%"
      right="0"
      mr={5}
      zIndex="1"
      w="72px"
      h="72px"
      fontSize="28px"
      rounded="full"
      shadow="lg"
      variant="solid"
      bg={styleProps.buttonBGColor}
      color={styleProps.arrowColor}
      borderColor={styleProps.borderColor}
      border="1px solid"
      _hover={{ backgroundColor: styleProps.buttonHoverColor }}
      aria-label="Move forward through class list"
      icon={<ArrowForwardIcon />}
      onClick={handleNav('forward')}
    />
  );

  const renderBackButton = () => (
    <IconButton
      position="absolute"
      top="40%"
      left="0"
      ml={5}
      zIndex="1"
      w="72px"
      h="72px"
      fontSize="28px"
      rounded="full"
      shadow="lg"
      variant="solid"
      bg={styleProps.buttonBGColor}
      color={styleProps.arrowColor}
      borderColor={styleProps.borderColor}
      border="1px solid"
      _hover={{ backgroundColor: styleProps.buttonHoverColor }}
      icon={<ArrowBackIcon />}
      onClick={handleNav('back')}
    />
  );

  const virtualizedCellItem = ({ index, style }) => {
    const item = items[index];

    return (
      <ClassListItem
        key={item.class_id}
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          width: style.width - GUTTER_SIZE,
        }}
        channelTitle={title}
        item={item}
        mode={mode}
        isChannelItem
        setOpenBookClassView={setOpenBookClassView}
        setCurrentBookingClassData={setCurrentBookingClassData}
        onLikeClass={onLikeClass}
        onCancelBooking={onCancelBooking}
      />
    );
  };

  const renderVirtualizedList = () => (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <List
            className="no-scroll-bar"
            outerRef={channelItemContainer}
            useIsScrolling
            itemCount={items.length}
            layout="horizontal"
            width={width}
            height={height}
            itemSize={channelItemWidth}
          >
            {virtualizedCellItem}
          </List>
        );
      }}
    </AutoSizer>
  );

  const renderShadow = () => (
    <Box
      position="absolute"
      h={channelHeight}
      right="0"
      top="0"
      zIndex="4"
      width="40px"
      style={{ boxShadow: ' inset -10px 0px 12px -10px #000000' }}
    />
  );

  // UseEffects
  useEffect(() => {
    if (items.length > 0) {
      // runFilters();
    }
  }, [items]);

  return (
    <Box position="relative" w="100%" h="100%">
      {!isMobileDevice() && items.length > 1 && renderBackButton()}
      {renderHeader()}
      <Box h={channelHeight}>
        {renderVirtualizedList()}
        {/* items.length > 1 && renderShadow() */}
      </Box>

      {!isMobileDevice() && items.length > 1 && renderForwardButton()}
    </Box>
  );
};

export default ClassChannel;
