/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Box,
  Divider,
  Text,
  Flex,
  Stack,
  HStack,
  Image,
  VStack,
  Badge,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
} from '@chakra-ui/react';
import { analytics, convertTZ, showErrorToast } from '@utils';
import { useParams, useHistory } from 'react-router-dom';
import actions from '@actions';
import PlayButton from '@assets/icons/play-button.svg';
import { loadStripe } from '@stripe/stripe-js';
import { AUTH_STATES } from '@constants';
import Sticky from 'react-sticky-el';
import { Waypoint } from 'react-waypoint';
import { zipWhiteBG } from '../../../constants';
import ProfileContext from '../../../../Context/ProfileContext';
import CourseDetailPreview from './components/CourseDetailPreview';
import CourseListItem from '../CourseListItem';

const CourseDetail = ({ authState }) => {
  // Local Properties
  const queryParams = new URLSearchParams(window.location.search);
  const enrolledStatus = queryParams.get('enrolled');

  // State
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [enrolled, setEnrolled] = useState(true);
  const [showEnrolledModal, setShowEnrolledModal] = useState(enrolledStatus === 'success');
  const [showPaymentSucessModal, setShowPaymentSucessModal] = useState(enrolledStatus === 'paid');

  const [showCountdown, setShowCountdown] = useState(false);
  const [countdownTime, setCountdownTime] = useState(null);
  const [loadingCheckoutSession, setLoadingCheckoutSession] = useState(false);

  // Context
  const profileContext = useContext(ProfileContext);

  // Hooks
  const { course_id: courseId } = useParams();
  const history = useHistory();
  const toast = useToast();

  // References
  const showCountdownRef = useRef(showCountdown);
  const countdownTimeRef = useRef(countdownTime);

  // Actions
  const onLessonClick = (id, title, isQuickStart, index, previewClass) => () => {
    if (isQuickStart) {
      analytics.t('courses: clicked quick start', { courseTitle: title, lessonIndex: index });
    } else if (previewClass) {
      analytics.t('courses: clicked preview lesson', { courseTitle: title, lessonIndex: index });
    } else {
      analytics.t('courses: clicked view lesson', { courseTitle: title, lessonIndex: index });
    }

    if (previewClass && authState === AUTH_STATES.NEEDS_AUTH) {
      history.push(
        `/noauth/live/${id}?cohort_id=${course.cohort_id}&preview_class=${previewClass}`,
      );
    } else {
      history.push(
        `/live/${id}?cohort_id=${course.cohort_id}&booking_id=${enrolled && enrolled.booking_id}`,
      );
    }
  };

  const fetchItem = async silent => {
    if (!silent) {
      setLoading(true);
    }

    try {
      const { course: c = {}, enrolled: e } = await actions.fetchCourse(courseId);
      setCourse(c);
      setEnrolled(e);
    } catch (err) {
      showErrorToast(toast, err.message);
    }

    setLoading(false);
  };

  const fetchData = async () => {
    await fetchItem(false);
  };

  const redirectToStripe = async sessionId => {
    const stripeClient = await loadStripe(process.env.REACT_APP_STRIPE_PK);
    stripeClient.redirectToCheckout({ sessionId });
  };

  const runBooking = async () => {
    setLoading(true);
    try {
      const { stripe_session_id } = await actions.createCohortBooking(course.cohort_id);
      if (stripe_session_id) {
        await redirectToStripe(stripe_session_id);
      } else {
        await fetchItem();
        setShowEnrolledModal(true);
      }
    } catch (err) {
      showErrorToast(toast, err.message);
      analytics.t('course: error on enrollment', {
        message: err.message,
        title: course.title,
        courseTitle: course.title,
      });
    }
    setLoading(false);
  };

  const createCheckoutSession = async () => {
    analytics.t('clicked pay now button', { courseTitle: course.title });
    setLoadingCheckoutSession(true);

    if (authState === AUTH_STATES.AUTHENTICATED) {
      try {
        const { stripe_session_id } = await actions.createCohortBooking(course.cohort_id);
        if (stripe_session_id) {
          await redirectToStripe(stripe_session_id);
        } else {
          throw { status: 400, message: 'Could not create checkout session' };
        }
      } catch (err) {
        showErrorToast(toast, err.message);
      }
    } else {
      history.push(`/courses/signup?cohort_id=${course.cohort_id}`);
    }

    setLoadingCheckoutSession(false);
  };

  const seeCourseAction = async mode => {
    if (mode === 'paid') {
      analytics.t('courses: paid for course', {
        courseTitle: course.title,
      });
    } else if (mode === 'enrolled') {
    }

    await fetchItem();
    setShowEnrolledModal(false);
    setShowPaymentSucessModal(false);
  };

  const getPreviewLesson = () => {
    if (course.sections && course.sections.length > 0) {
      if (course.sections[0].lessons[0] && course.sections[0].lessons[0].preview_class) {
        // eslint-disable-next-line prefer-destructuring
        return course.sections[0].lessons[0];
      }
    }

    return null;
  };

  const setCountdownObj = () => {
    const { days, hours, minutes, seconds } = convertTZ(course.start_date, {
      mode: 'countdown_verbose',
    });
    setCountdownTime({ days, hours, minutes, seconds });
  };

  // Render Methods
  const renderEnrollModal = () => (
    <Modal isOpen autoFocus={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent m={3} rounded="lg">
        <ModalHeader>
          <Stack isInline alignItems="center" spacing={2}>
            <Text fontWeight="black" fontSize={['xl', '2xl']}>
              🦄 Ready to enroll?
            </Text>
          </Stack>
        </ModalHeader>
        <ModalBody>
          <Text>
            👋 Hey{' '}
            {profileContext && profileContext.data && profileContext.data.first_name
              ? profileContext.data.first_name
              : 'there'}
            ! If you'd like to enroll your child in the "<b>{course.title}</b>" course, just tap
            Enroll Now below!
          </Text>
        </ModalBody>
        <ModalFooter>
          {/* <Button
            rounded="lg"
            color="gray.800"
            isLoading={loading}
            onClick={() => history.push('/courses')}
          >
            Go Back
          </Button> */}
          <Button
            ml={3}
            colorScheme="purple"
            bg="purple.500"
            fontSize={['md', 'lg']}
            rounded="lg"
            color="white"
            isLoading={loading}
            onClick={runBooking}
          >
            {course.price ? `🚀 Enroll Now` : `🚀 Enroll Now For Free`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderEnrollSuccessModal = () => {
    const startDate = convertTZ(course.start_date, { mode: 'date' });
    let childName = 'Your Child';
    if (profileContext.data.children && profileContext.data.children.length > 0) {
      childName = profileContext.data.children[0].first_name;
    }

    return (
      <Modal isOpen autoFocus={false} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent m={3} rounded="lg">
          <ModalHeader>
            <Stack isInline alignItems="center" spacing={2}>
              <Text fontWeight="black" fontSize={['xl', '2xl']}>
                🎉 You're enrolled!
              </Text>
            </Stack>
          </ModalHeader>
          <ModalBody>
            <Text>{`${childName} is all set! This course begins on ${startDate}`}</Text>
            <Text>
              Each week your child will be assigned new lessons that will be accessible at any time
              through their course dashboard!
            </Text>
            {course.price && (
              <Text mt={3}>
                You'll get the first lesson free when this course starts, then pay{' '}
                <b>${course.price}</b> to unlock the rest of the lessons in this course ✨
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="purple"
              h={['50px', '55px']}
              bg="purple.500"
              fontSize={['lg', 'xl']}
              rounded="lg"
              color="white"
              isLoading={loading}
              onClick={() => seeCourseAction('enrolled')}
            >
              👀 See Course
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const renderPaymentSuccessModal = () => {
    const startDate = convertTZ(course.start_date, { mode: 'date' });
    let childName = 'Your Child';
    if (profileContext.data.children && profileContext.data.children.length > 0) {
      childName = profileContext.data.children[0].first_name;
    }

    return (
      <Modal isOpen autoFocus={false} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent m={3} rounded="lg">
          <ModalHeader>
            <Stack isInline alignItems="center" spacing={2}>
              <Text fontWeight="black" fontSize={['xl', '2xl']}>
                🎉 You're all set!
              </Text>
            </Stack>
          </ModalHeader>
          <ModalBody>
            <Text>{`${childName} is all set for this course!`}</Text>
            <Text>
              Each week your child will be assigned new lessons that will be accessible at any time
              through their course dashboard!
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="purple"
              h={['50px', '55px']}
              bg="purple.500"
              fontSize={['lg', 'xl']}
              rounded="lg"
              color="white"
              isLoading={loading}
              onClick={() => seeCourseAction('paid')}
            >
              👀 See Course
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const renderPayHeader = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
      <Box bg="rgb(255, 197, 3)" rounded="lg" p={4}>
        <Stack justifyContent="space-between">
          <Text color="black" fontSize="xl" fontWeight="bold">
            This course is available for ${course.price} 🔥
          </Text>
          <Text color="black" fontSize="lg">
            Price will increase to ${course.price + 50} tomorrow,{' '}
            {convertTZ(tomorrow.getTime(), { mode: 'date-short' })}
          </Text>
        </Stack>
        <Stack mt={4}>
          <Text color="gray.800" fontSize="lg" fontWeight="bold">
            ✅ {course.sections.length} week course. ~1 hour of lessons per week.
          </Text>
          <Text color="gray.800" fontSize="lg" fontWeight="bold">
            ✅ Not a good fit for your kid? We'll refund 100% of your purchase.
          </Text>
          <Text color="gray.800" fontSize="lg" fontWeight="bold">
            ✅ These courses are made up of recorded lessons that you'll be able to access anytime
            during and after the course, like Netflix!
          </Text>
        </Stack>
        <VStack my={5}>
          <Box w="50%">
            <Divider borderColor="black" />
          </Box>
        </VStack>
        <Box>{renderHeader('gray.900')}</Box>
        <Button
          w="100%"
          fontSize="lg"
          fontWeight="bold"
          bg="purple.500"
          color="white"
          h="50px"
          mt={3}
          isLoading={loadingCheckoutSession}
          onClick={createCheckoutSession}
        >
          Checkout for ${course.price} 🪄
        </Button>
      </Box>
    );
  };

  const renderHeader = color => {
    let childName = 'your child';
    const startDate = convertTZ(course.start_date, { mode: 'date' });
    if (
      profileContext.data &&
      profileContext.data.children &&
      profileContext.data.children.length > 0
    ) {
      childName = profileContext.data.children[0].first_name;
    }

    return (
      <Box>
        <Stack my={3}>
          <Text textAlign="center" color={color || 'gray.500'}>
            {`On ${startDate} this course will begin and the first week's lessons will be unlocked!`}
          </Text>
        </Stack>
      </Box>
    );
  };

  const renderLessonItem = (lesson, isQuickStart, index) => (
    <Box
      key={lesson.id}
      w="100%"
      opacity={lesson.enabled || isQuickStart ? '100%' : '40%'}
      p={!isQuickStart ? 3 : 0}
      border={!isQuickStart ? '1px solid #DDDDDD' : 'none'}
      shadow={!isQuickStart ? 'lg' : 'none'}
      rounded="lg"
      cursor={lesson.enabled ? 'pointer' : 'auto'}
      onClick={
        lesson.enabled
          ? onLessonClick(lesson.id, lesson.title, isQuickStart, index, lesson.preview_class)
          : null
      }
    >
      {lesson.preview_class && (
        <VStack bg="green.300" rounded="lg" shadow="lg" spacing={1} p={2} mb={2}>
          <Text fontWeight="bold">👀 Want to see what a lesson looks like?</Text>
          <Text fontSize="sm">
            Lessons are made up of interactive videos with built in quizzes.
          </Text>
        </VStack>
      )}
      <HStack w="100%">
        {/* <Box w="20%">
          <video src={lesson.poster_url} autoPlay muted playsInline />
        </Box> */}
        <Stack w="100%" display="inline-block" color={!isQuickStart ? 'black' : 'white'}>
          <Text>{lesson.title}</Text>
          <Flex justifyContent="space-between">
            <HStack>
              <Badge
                style={{ textShadow: 'none' }}
                variant="subtle"
                rounded="full"
                fontSize={['xs', 'sm', 'sm', 'sm']}
                paddingX={2}
                paddingY={1}
                bg="#ffc60b"
              >
                {`${lesson.duration} mins`}
              </Badge>
              {lesson.completed && (
                <Badge
                  variant="subtle"
                  rounded="full"
                  fontSize={['xs', 'sm', 'sm', 'sm']}
                  paddingX={2}
                  paddingY={1}
                  bg="green.300"
                >
                  Completed
                </Badge>
              )}
            </HStack>
            {lesson.enabled && (
              <Button bg="#805AD5BF" rounded="full" w="42px" h="42px" shadow="lg">
                <Image src={PlayButton} />
              </Button>
            )}
          </Flex>
        </Stack>
      </HStack>
    </Box>
  );

  const renderQuizItem = (lesson, isQuickStart, index) => (
    <Box
      key={lesson.id}
      w="100%"
      opacity={lesson.enabled || isQuickStart ? '100%' : '40%'}
      p={!isQuickStart ? 3 : 0}
      border={!isQuickStart ? '1px solid #DDDDDD' : 'none'}
      shadow={!isQuickStart ? 'lg' : 'none'}
      rounded="lg"
      cursor={lesson.enabled ? 'pointer' : 'auto'}
      onClick={lesson.enabled ? onLessonClick(lesson.id, lesson.title, isQuickStart, index) : null}
    >
      <HStack w="100%">
        {/* <Box w="20%">
          <video src={lesson.poster_url} autoPlay muted playsInline />
        </Box> */}
        <Stack w="100%" display="inline-block" color={!isQuickStart ? 'black' : 'white'}>
          <Text>{lesson.title}</Text>
          <Flex justifyContent="space-between">
            <HStack spacing={0} wrap="wrap">
              <Badge
                style={{ textShadow: 'none' }}
                variant="subtle"
                rounded="full"
                fontSize={['xs', 'sm', 'sm', 'sm']}
                paddingX={2}
                paddingY={1}
                mr={2}
                my={1}
                bg="#ffc60b"
              >
                {`${lesson.question_count} questions`}
              </Badge>
              {lesson.completed && (
                <Badge
                  variant="subtle"
                  rounded="full"
                  fontSize={['xs', 'sm', 'sm', 'sm']}
                  paddingX={2}
                  paddingY={1}
                  bg="green.300"
                >
                  {lesson.completed === true ? 'Completed' : lesson.completed}
                </Badge>
              )}
            </HStack>
            {lesson.enabled && (
              <Button bg="#805AD5BF" color="white" fontWeight="bold" shadow="lg">
                Start Quiz
              </Button>
            )}
          </Flex>
        </Stack>
      </HStack>
    </Box>
  );

  const renderWeekBlock = section => {
    return (
      <Box key={section.label} w="100%" bg="white" rounded="lg" border="1px solid #DDDDDD" p={3}>
        <Stack>
          <Text textTransform="uppercase" fontSize="sm" color="#9D9D9D">
            {section.label}
          </Text>
          <Text fontWeight="bold" fontSize={['lg', 'lg', 'xl']}>
            {section.title}
          </Text>
          {section.subtitle && <Text fontSize={['xs', 'sm', 'sm']}>{section.subtitle}</Text>}
          <Divider />
          <VStack>
            {section.lessons.map((lesson, index) => renderLessonItem(lesson, false, index + 1))}
          </VStack>
          {section.quizzes.length > 0 && (
            <>
              <Divider />
              <Text fontWeight="bold">End Of Week Quiz</Text>
              <VStack>
                {section.quizzes.map((lesson, index) => renderQuizItem(lesson, false, index + 1))}
              </VStack>
            </>
          )}
        </Stack>
      </Box>
    );
  };

  const renderQuickStartBlock = () => {
    const isQuickStart = true;
    let lessonFlatMap;
    let lessonIndex;

    if (!showCountdownRef.current && course.enabled && course.next_lesson) {
      lessonFlatMap = course.sections.flatMap(s => s.lessons);
      lessonIndex = lessonFlatMap.findIndex(l => l.id === course.next_lesson);
    }

    return (
      <Stack
        flexDir="column"
        style={{
          backgroundBlendMode: 'soft-light, normal',
          backgroundImage: 'linear-gradient(137deg, #4621ad, #E1AE00, #48bb78)',
          textShadow: '0 -1px 0 rgba(0, 0, 0, .35)',
        }}
        bg="transparent"
        backgroundSize="600% 600%"
        animation="gradientFlow 35s infinite"
        rounded="lg"
        p={3}
      >
        {!showCountdownRef.current && course.enabled && course.next_lesson ? (
          <>
            <Text color="white" fontWeight="bold">
              NEXT LESSON
            </Text>
            {renderLessonItem(lessonFlatMap[lessonIndex], isQuickStart, lessonIndex + 1)}
          </>
        ) : enrolled && enrolled.status === 'TRIAL' && course.enabled ? (
          <HStack justifyContent="space-between">
            <Text color="white" fontWeight="bold">
              Unlock the rest of this course for ${course.price}
            </Text>
            <Button isLoading={loadingCheckoutSession} onClick={createCheckoutSession}>
              Pay Now
            </Button>
          </HStack>
        ) : (
          countdownTimeRef &&
          countdownTimeRef.current && (
            <VStack>
              <Text color="white" fontSize={['lg', 'lg', 'xl']} fontWeight="bold">
                ⏰ Course Starts In
              </Text>
              <HStack w="100%" h="100%" justifyContent="space-around">
                {/* Days */}
                <VStack>
                  <Text color="white" fontSize={['lg', 'lg', '2xl']}>
                    {countdownTimeRef && countdownTimeRef.current.days}
                  </Text>
                  <Text color="white" fontWeight="bold" fontSize="sm">
                    DAYS
                  </Text>
                </VStack>

                {/* Hours */}
                <VStack>
                  <Text color="white" fontSize={['lg', 'lg', '2xl']}>
                    {countdownTimeRef && countdownTimeRef.current.hours}
                  </Text>
                  <Text color="white" fontWeight="bold" fontSize="sm">
                    HOURS
                  </Text>
                </VStack>

                {/* Minutes */}
                <VStack>
                  <Text color="white" fontSize={['lg', 'lg', '2xl']}>
                    {countdownTimeRef && countdownTimeRef.current.minutes}
                  </Text>
                  <Text color="white" fontWeight="bold" fontSize="sm">
                    MINUTES
                  </Text>
                </VStack>

                {/* Seconds */}
                <VStack>
                  <Text color="white" fontSize={['lg', 'lg', '2xl']}>
                    {countdownTimeRef && countdownTimeRef.current.seconds}
                  </Text>
                  <Text color="white" fontWeight="bold" fontSize="sm">
                    SECONDS
                  </Text>
                </VStack>
              </HStack>
            </VStack>
          )
        )}
      </Stack>
    );
  };

  const renderContent = () => {
    if (!enrolled) {
      return <CourseDetailPreview authState={authState} course={course} />;
    }

    return (
      <Flex w="100%" h="100%" flexDir="column" alignItems="center">
        {course && (
          <Stack maxW="90%" h="100%">
            <Text fontWeight="bold" fontSize="2xl">
              {course.title}
            </Text>
            {/* {!course.enabled && authState === AUTH_STATES.AUTHENTICATED && renderHeader()} */}
            {course && !enrolled && renderPayHeader()}
            {authState === AUTH_STATES.AUTHENTICATED && enrolled && renderQuickStartBlock()}
            <VStack spacing={3}>{course.sections.map(section => renderWeekBlock(section))}</VStack>
          </Stack>
        )}
      </Flex>
    );
  };

  const renderRelatedCourses = () => {
    return (
      <Box>
        <Divider />
        <VStack w="100%" p={3}>
          <Text w="100%" textAlign="left" fontSize="lg" fontWeight="bold">
            Other ZipSchool Courses
          </Text>
          <Text w="100%" textAlign="left" color="gray.500">
            ZipSchool offers many types of math courses. Find the right one for your child below!
          </Text>
          <VStack w="100%" mt={5}>
            {course.related_courses.map(c => (
              <CourseListItem key={c.id} course={c} mode="other-courses" />
            ))}
          </VStack>
        </VStack>
      </Box>
    );
  };

  const renderStickyCTA = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
      <Sticky
        mode="bottom"
        stickyStyle={{
          backgroundColor: zipWhiteBG,
        }}
      >
        <>
          <Divider borderColor="gray.300" />
          <VStack bg={zipWhiteBG} w="100%" minH="60px" spacing={3} p={[5, 8]}>
            <Button
              w="100%"
              maxW="xl"
              minH="45px"
              colorScheme="green"
              bg="green.400"
              fontWeight="bold"
              fontSize={['md', 'lg']}
              rounded="lg"
              color="white"
              isLoading={loading}
              onClick={authState === AUTH_STATES.AUTHENTICATED ? runBooking : createCheckoutSession}
            >
              {course.price ? `🚀 Enroll Now for $${course.price}` : `🚀 Enroll Now For Free`}
            </Button>
            {course.price && (
              <Text textAlign="center">
                {`🚨 Price will increase to $${course.price + 50} tomorrow, ${convertTZ(
                  tomorrow.getTime(),
                  { mode: 'date-short' },
                )}.`}
              </Text>
            )}
          </VStack>
        </>
      </Sticky>
    );
  };

  const renderLessonPreviewSection = () => {
    const previewLessonData = getPreviewLesson();

    const watchPreviewLessonAction = () => {
      if (previewLessonData) {
        analytics.t('courses: clicked preview lesson', {
          courseTitle: course.title,
          lessonIndex: 1,
        });
        if (authState === AUTH_STATES.NEEDS_AUTH) {
          history.push(`/noauth/live/${previewLessonData.id}?preview_class=true`);
        } else {
          history.push(`/live/${previewLessonData.id}?preview_class=true`);
        }
      } else {
        analytics.t('courses: clicked preview lesson', {
          courseTitle: 'fallback lesson',
          lessonIndex: 1,
        });
        if (authState === AUTH_STATES.NEEDS_AUTH) {
          history.push(`/noauth/live/CL5d817eb6-1b1f-40f1-b501-37ab93cb16ce?preview_class=true`);
        } else {
          history.push(`/live/CL5d817eb6-1b1f-40f1-b501-37ab93cb16ce?preview_class=true`);
        }
      }
    };

    return (
      <Waypoint
        onEnter={() => {
          analytics.t('courses: reached end of course preview page', {
            courseTitle: course.title,
          });
        }}
      >
        <VStack w="100%" bg="rgb(255, 197, 3)" p={4} onClick={watchPreviewLessonAction}>
          <Text textAlign="center" fontWeight="bold" fontSize={['xl', 'xl', '2xl']}>
            See ZipSchool in Action
          </Text>
          <Text textAlign="center" fontSize={['lg', 'lg', 'xl']}>
            Try out our interactive quizzes right now in this lesson!
          </Text>
          <Flex
            className="mobile-border-radius"
            position="relative"
            flexDir="column"
            shadow="lg"
            rounded="lg"
            cursor="pointer"
          >
            <Flex position="absolute" w="100%" h="100%" justifyContent="center" alignItems="center">
              <Button bg="#805AD5BF" rounded="full" w="56px" h="56px" shadow="lg">
                <Image ml={1} src={PlayButton} />
              </Button>
            </Flex>
            {previewLessonData ? (
              <video
                autoPlay
                loop
                muted
                playsInline
                w="100%"
                h="100%"
                src={
                  previewLessonData
                    ? previewLessonData.poster_url
                    : 'https://media.giphy.com/media/cXblnKXr2BQOaYnTni/giphy.mp4'
                }
              />
            ) : (
              <Image src="https://assets.zipschool.com/posters/gadgets.png" />
            )}
            <Text bg="purple.500" textAlign="center" color="white" p={1}>
              🎉 Preview The First Lesson
            </Text>
          </Flex>
        </VStack>
      </Waypoint>
    );
  };

  // UseEffects
  useEffect(() => {
    window.scroll(0, 0);
    analytics.t('courses: viewed course detail');
    fetchData();
  }, []);

  useEffect(() => {
    let interval;
    if (course) {
      if (enrolledStatus) {
        if (enrolledStatus === 'paid' && course.price) {
          // eslint-disable-next-line no-undef
          fbq('track', 'Purchase', { currency: 'USD', value: course.price });
        }

        history.replace(`/courses/${courseId}`);
      }

      // On render check to see if we should render countdown or not
      if (!course.enabled) {
        setCountdownObj();
        setShowCountdown(true);
      }

      interval = setInterval(() => {
        if (course.enabled) {
          setShowCountdown(false);
          clearInterval(interval);
        } else {
          setCountdownObj();
          setShowCountdown(true);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [course]);

  useEffect(() => {
    showCountdownRef.current = showCountdown;
    countdownTimeRef.current = countdownTime;
  }, [showCountdown, countdownTime]);

  return (
    <>
      <Flex maxW="100%" minH="100%" bg={zipWhiteBG} flexDir="column" p={[5, 8]}>
        {course && showEnrolledModal && renderEnrollSuccessModal()}
        {course && showPaymentSucessModal && renderPaymentSuccessModal()}
        {loading && (
          <Flex h="100%" justifyContent="center" alignItems="center">
            <Spinner fontSize={['2xl', '2xl', '2xl', '3xl']} mt={4} color="purple.500" />
          </Flex>
        )}
        {renderContent()}
      </Flex>
      {!enrolled && renderLessonPreviewSection()}
      {!enrolled && course.related_courses && course.related_courses.length > 0 && (
        <Flex maxW="100%" bg={zipWhiteBG} flexDir="column" p={[5, 8]}>
          <Flex w="100%" flexDir="column" alignItems="center">
            <Stack maxW="90%" h="100%">
              {renderRelatedCourses()}
            </Stack>
          </Flex>
        </Flex>
      )}
      {!enrolled && renderStickyCTA()}
    </>
  );
};

export default CourseDetail;
