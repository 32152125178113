/* eslint-disable react/prop-types */
import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Text,
  Stack,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { analytics, showErrorToast } from '@utils';
import actions from '@actions';

import AddChildForm from './components/AddChildForm';

const AddChildModal = ({ fetchProfile, childToUpdate }) => {
  // Hooks
  const { isOpen, onOpen } = useDisclosure();
  const form = useForm();
  const toast = useToast();

  // State
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Actions
  const submitForm = async data => {
    setIsSubmitting(true);

    try {
      if (childToUpdate) {
        await actions.updateChild(
          { first_name: data.first_name, age: data.age, id: childToUpdate.id },
          'update',
        );

        await fetchProfile();
        analytics.t('added child in native onboarding', { age: data.age });
        setIsSubmitting(false);
        window.scroll(0, 0);
      }
    } catch (error) {
      showErrorToast(toast, error.message);
      setIsSubmitting(false);
    }
  };

  // UseEffects
  useEffect(() => {
    analytics.t('viewed add child modal after native onboarding');
    onOpen();
  }, []);

  return (
    <Modal isOpen={isOpen} autoFocus={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent m={3} rounded="lg">
        <ModalHeader>
          <Stack isInline alignItems="center" spacing={2}>
            <Text fontWeight="black" fontSize={['xl', '2xl']}>
              🦄 Just one more thing!
            </Text>
          </Stack>
        </ModalHeader>
        <ModalBody>
          <AddChildForm form={form} />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="purple"
            h={['50px', '55px']}
            bg="purple.500"
            fontSize={['lg', 'xl']}
            rounded="lg"
            color="white"
            isLoading={isSubmitting}
            onClick={form.handleSubmit(submitForm)}
          >
            🚀 Let's go
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(AddChildModal);
