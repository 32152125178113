/* eslint-disable no-console */
/* eslint-disable no-alert */
import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { showIntercom, showErrorToast } from '@utils';

const CAPTURE_OPTIONS = {
  // audio: true,
  video: true,
};

const useUserMedia = options => {
  // State
  const [mediaStream, setMediaStream] = useState(null);
  const [triggerPermissionsRequest, setTriggerPermissionsRequest] = useState(false);
  const toast = useToast();

  // Actions
  const enableStream = async () => {
    try {
      // On non-Safari on iOS devices, navigator.mediaDevices doesn't exist.
      // If using ngrok, make sure to use
      // https as media devices are not available on non-secure routes
      if (navigator && navigator.mediaDevices) {
        // Fire the permission request after we have someone interact with the initial modal
        if (triggerPermissionsRequest) {
          const stream = await navigator.mediaDevices.getUserMedia(options || CAPTURE_OPTIONS);
          setMediaStream(stream);
        }
      }
    } catch (err) {
      /**
       * If either video or mic are denied the entire request fails
       * Chrome: DOMException: Permission denied
       * Safari: NotAllowedError: The request is not allowed by the user agent or the platform in
       * the current context, possibly because the user denied permission
       */
      if (err.name === 'DOMException' || err.name === 'NotAllowedError') {
        window.alert(
          `Looks like you may not have allowed access to your camera! Please click 'Enter Classroom' button to continue with an Avatar ✨`,
        );
      } else {
        showErrorToast(toast, 'Looks like something went wrong!');
      }

      // showIntercom();
      console.warn(err);
    }
  };

  // UseEffects
  useEffect(() => {
    if (!mediaStream) {
      enableStream();
    }

    return function cleanup() {
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => {
          track.stop();
        });
      }
    };
  }, [mediaStream, triggerPermissionsRequest]);

  return { mediaStream, triggerPermissionsCheckAction: setTriggerPermissionsRequest };
};

export default useUserMedia;
