/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { CDN_HOSTNAME } from '@constants';

const ProgressRing = ({ radius, stroke, strokeColor, progress, ...rest }) => {
  // Local Properties
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <Box position="relative" height={radius * 2} width={radius * 2}>
      <Flex position="absolute" w="100%" h="100%" justifyContent="center" alignItems="center">
        <Image w="24px" h="24px" src={`${CDN_HOSTNAME}/icons/zipschool-z-logo.svg`} />
      </Flex>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke={strokeColor}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          {...rest}
        />
      </svg>
    </Box>
  );
};

export default ProgressRing;
