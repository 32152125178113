/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Stack,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import actions from '@actions';
import { analytics, showErrorToast } from '@utils';

const PetSelect = ({ setSelectedPet }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [pets, setPets] = useState(null);
  const [petLoading, setPetLoading] = useState(false);
  const [selectedRedeemableIndex, setSelectedRedeemableIndex] = useState(0);

  // Hooks
  const toast = useToast();
  const avatarHeight = useBreakpointValue({
    base: '200px',
    md: '150px',
    lg: '200px',
    xl: '200px',
  });

  // Actions
  const fetchConfigAction = async () => {
    setLoading(true);

    try {
      const { redeemables = [] } = await actions.fetchConfig();
      setPets(redeemables);
    } catch (err) {
      showErrorToast(toast, err.message);
    }

    setLoading(false);
  };

  const fetchConfig = () => {
    fetchConfigAction();
  };

  const onNext = () => {
    analytics.t('courses: navigate next pet');

    setSelectedRedeemableIndex(prevIndex => {
      if (prevIndex === pets.length - 1) return prevIndex;
      return prevIndex + 1;
    });
  };

  const onPrevious = () => {
    analytics.t('courses: navigate previous pet');

    setSelectedRedeemableIndex(prevIndex => {
      if (prevIndex === 0) return prevIndex;
      return prevIndex - 1;
    });
  };

  // Render Methods
  const renderCharacter = () => {
    const redeemable = pets[selectedRedeemableIndex];
    if (!redeemable) return null;

    return (
      <Box w="100%" p={2} rounded="lg">
        <Stack>
          <Box
            w="60%"
            py={2}
            mx="auto"
            bg="gray.300"
            shadow="lg"
            animation="fadeIn 0.4s ease"
            rounded="full"
          >
            <Text textAlign="center" fontWeight="bold">
              {redeemable.title}
            </Text>
          </Box>
          <video
            onLoadStart={() => setPetLoading(true)}
            onPlay={() => setPetLoading(false)}
            loop
            muted
            autoPlay
            playsInline
            src={redeemable.url}
            style={{ display: petLoading ? 'none' : 'block', height: avatarHeight }}
          />
          {petLoading && (
            <Flex h={avatarHeight} justifyContent="center" alignItems="center">
              <Spinner fontSize={['2xl', '2xl', '2xl', '3xl']} mt={4} color="purple.500" />
            </Flex>
          )}
        </Stack>
      </Box>
    );
  };

  // UseEffects
  useEffect(() => {
    window.scroll(0, 0);
    analytics.t('courses: viewed pet select');
    fetchConfig();
  }, []);

  useEffect(() => {
    if (pets) {
      // Once redeemables are available, set the first pet
      setSelectedPet(pets[selectedRedeemableIndex]);
    }
  }, [pets]);

  useEffect(() => {
    if (pets) {
      setSelectedPet(pets[selectedRedeemableIndex]);
    }
  }, [selectedRedeemableIndex]);

  return (
    <Box w="100%" minH={avatarHeight} h="100%">
      {!loading && pets && (
        <HStack spacing={5}>
          <IconButton
            disabled={loading || selectedRedeemableIndex === 0}
            zIndex="2"
            aria-label="Back button"
            icon={<ChevronLeftIcon />}
            size={('md', 'lg')}
            fontSize={['25px', '30px']}
            isRound
            bg="gray.300"
            color="gray.700"
            onClick={onPrevious}
          />
          {renderCharacter()}
          <IconButton
            disabled={loading || selectedRedeemableIndex === pets.length - 1}
            zIndex="2"
            aria-label="Forward button"
            icon={<ChevronRightIcon />}
            size={('md', 'lg')}
            fontSize={['25px', '30px']}
            isRound
            bg="gray.300"
            color="gray.700"
            onClick={onNext}
          />
        </HStack>
      )}
    </Box>
  );
};

export default PetSelect;
