/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/prop-types */
import React from 'react';
import { Flex } from '@chakra-ui/react';
import Profile from './Profile';
import Header from '../Header';
import Footer from '../Footer';

const ProfileContainer = ({ handleSignOut, setHeaderProfile, profile, authState }) => {
  return (
    <Flex maxW="100%" minH="100%" flexDir="column" bg="purple.500">
      <Flex w="100%" flexDir="column" alignItems="center" p={[5, 8]}>
        <Profile handleSignOut={handleSignOut} setHeaderProfile={setHeaderProfile} />
      </Flex>
    </Flex>
  );
};

export default ProfileContainer;
