/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box, Flex, Image, Stack } from '@chakra-ui/react';
import { CDN_HOSTNAME } from '@constants';
import CoursesSignup from './CoursesSignup';
import { onboardingSteps } from '../constants';

const CoursesSignupContainer = ({ fetchAuthState }) => {
  // State
  const [currentStep, setCurrentStep] = useState(onboardingSteps.LOGIN_OR_SIGNUP);

  // Render Methods
  const renderHeader = () => {
    return (
      <Flex justifyContent="center" width="100%">
        <Image
          height="30px"
          size="lg"
          cursor="pointer"
          src={`${CDN_HOSTNAME}/icons/zipschool-logo-light.svg`}
        />
      </Flex>
    );
  };

  return (
    <Stack w="100%" minH="100%" spacing={3} p={[5, 8]} bg="purple.500" overflowY="auto">
      {renderHeader()}
      <Box w="100%" h="100%">
        <CoursesSignup
          fetchAuthState={fetchAuthState}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </Box>
    </Stack>
  );
};

export default CoursesSignupContainer;
