/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Avatar,
  Box,
  Divider,
  Text,
  Flex,
  Stack,
  HStack,
  Image,
  VStack,
  Badge,
  Button,
  SimpleGrid,
} from '@chakra-ui/react';
import PlayButton from '@assets/icons/play-button.svg';
import { useParams, useHistory } from 'react-router-dom';
import { AUTH_STATES, CDN_HOSTNAME } from '@constants';
import ProgressBar from '@components/ProgressBar';
import { Waypoint } from 'react-waypoint';
import confetti from 'canvas-confetti';
import PartyEmoji from '@assets/party-emoji.png';
import CheckmarkEmoji from '@assets/checkmark-emoji.png';
import { analytics, convertTZ, hideCrispLauncher, showCrispLauncher, showIntercom } from '@utils';

/**
 * We are here if we are not enrolled in a course yet
 */
const CourseDetailPreview = ({ authState, course }) => {
  // Local Properties
  const { grade_details: gradeDetails, sections, title } = course;
  const borderColor = '#D2D2D2';

  // References
  const finalWeekContainerRef = useRef(null);

  // Hooks
  const { course_id: courseId } = useParams();
  const history = useHistory();

  // State
  const [gradeDetail, setGradeDetail] = useState(null);

  // Actions
  const getGradeDetail = gradeLevel => () => {
    const gradeDetailObj = gradeDetails.find(detail => detail.grade === gradeLevel);

    if (gradeDetailObj) {
      setGradeDetail(gradeDetailObj);
      analytics.t('courses: clicked grade details option', { grade: gradeLevel });
    }
  };

  // Render Methods
  const renderLessonItem = lesson => (
    <Box key={lesson.id} w="100%" p={4} border="1px solid #DDDDDD" shadow="lg" rounded="lg">
      <Stack w="100%" display="inline-block" color="black">
        <Text>{lesson.title}</Text>
        <Flex justifyContent="space-between">
          <HStack>
            <Badge
              style={{ textShadow: 'none' }}
              variant="subtle"
              rounded="full"
              fontSize={['xs', 'sm', 'sm', 'sm']}
              paddingX={2}
              paddingY={1}
              bg="#ffc60b"
            >
              {`${lesson.duration} mins`}
            </Badge>
          </HStack>
        </Flex>
      </Stack>
    </Box>
  );

  const renderWeekDetailToggle = (lessons, sectionTitle) => {
    return (
      <Accordion
        onChange={event => {
          if (event === 0) {
            analytics.t('courses: open lesson plan details button', {
              courseTitle: title,
              section: sectionTitle,
            });
          }
        }}
        borderColor="white"
        allowToggle
      >
        <AccordionItem>
          <AccordionButton px="0" _expanded={{ bg: 'white' }}>
            <Box flex="1" textAlign="left">
              Lesson Plan Details
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel px={0} py={3}>
            <Stack w="100%">{lessons.map(lesson => renderLessonItem(lesson))}</Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  const getAvailableDateForSection = section => {
    const sectionIndex = course.sections.indexOf(section);
    if (sectionIndex >= 0) {
      const date = new Date(course.start_date);
      date.setDate(date.getDate() + sectionIndex * 7);

      return date;
    }
  };

  const renderWeekBlock = section => {
    const isVideo = section.poster_url && section.poster_url.includes('mp4');

    return (
      <Box key={section.label} w="100%" bg="white" rounded="lg" border="1px solid #DDDDDD" p={4}>
        <Stack>
          {isVideo ? (
            <video autoPlay loop muted playsInline src={section.poster_url} />
          ) : (
            <Image src={section.poster_url} />
          )}
          <HStack>
            <Badge
              w="fit-content"
              variant="outline"
              rounded="full"
              fontSize={['xs', 'sm']}
              paddingX={2}
              paddingY={1}
            >
              {section.label}
            </Badge>
            {!course.enabled && (
              <Text fontSize="sm" color="gray.500">
                Available On{' '}
                {convertTZ(getAvailableDateForSection(section).getTime(), { mode: 'date-short' })}
              </Text>
            )}
          </HStack>

          <Text fontWeight="bold" fontSize={['lg', 'lg', 'xl']}>
            {section.title}
          </Text>
          <Text fontSize="sm">{section.goals_subtitle}</Text>
          {renderWeekDetailToggle(section.lessons, section.title)}
        </Stack>
      </Box>
    );
  };

  const renderFinalWeekBlock = () => {
    const intitiateConfettiCannonAsync = () => {
      const myConfetti = confetti.create(finalWeekContainerRef.current, {
        useWorker: true,
      });

      setTimeout(() => {
        myConfetti({
          particleCount: 75,
          gravity: 0.6,
          spread: 70,
          startVelocity: 20,
          scalar: 0.8,
          origin: { y: 1.0 },
        });
      }, 600);
    };

    const handleEnterViewport = () => {
      try {
        if (finalWeekContainerRef && finalWeekContainerRef.current) {
          intitiateConfettiCannonAsync();
        }
      } catch (error) {
        console.warn('handleEnterViewport Error: ', error);
      }
    };

    return (
      <Waypoint onEnter={handleEnterViewport}>
        <Flex
          key="final_week"
          position="relative"
          w="100%"
          minH="200px"
          animation="gradientFlow 30s infinite"
          backgroundSize="600% 600%"
          backgroundImage="linear-gradient(98.17deg, #5C30BF 20.72%, #FB8261 93.72%)"
          style={{
            backgroundBlendMode: 'soft-light, normal',
          }}
          rounded="lg"
          shadow="lg"
          border="1px solid #DDDDDD"
          p={4}
          justifyContent="center"
          alignItems="center"
        >
          <canvas
            ref={finalWeekContainerRef}
            style={{
              position: 'absolute',
              width: '100%',
              zIndex: '3',
            }}
          />
          <Stack>
            <VStack spacing={4}>
              <Image w="50px" h="50px" mt={2} mb={4} src={PartyEmoji} />
              <ProgressBar percent={1.0} steps={sections.length} />
              <Text color="white" textAlign="center" fontSize="lg">
                {`After ${sections.length} weeks your child will master these skills!`}
              </Text>
              <Divider />
              <Stack w="100%" spacing={3} color="white">
                <Stack w="100%" isInline alignItems="center">
                  <Image w="24px" h="24px" src={CheckmarkEmoji} />
                  <Text>
                    {`This is a ${sections.length}-week course with lessons that last for 45-min twice per week.`}
                  </Text>
                </Stack>
                <Stack w="100%" isInline alignItems="center">
                  <Image w="24px" h="24px" src={CheckmarkEmoji} />
                  <Text>
                    All the lessons are recorded and are accesible at anytime, like Netflix!
                  </Text>
                </Stack>
                <Stack w="100%" isInline alignItems="center">
                  <Image w="24px" h="24px" src={CheckmarkEmoji} />
                  <Text>
                    If you don't absolutely love this course, we'll give you a 100% refund.
                  </Text>
                </Stack>
              </Stack>
            </VStack>
          </Stack>
        </Flex>
      </Waypoint>
    );
  };

  const renderGradeDetailSection = () => {
    return (
      <VStack
        w="100%"
        minH="100px"
        bg="white"
        rounded="lg"
        spacing={2}
        p={[3, 5]}
        border={`1px solid ${borderColor}`}
        shadow="lg"
        justifyContent="center"
      >
        <Flex w="100%" h="100%" justifyContent="space-around">
          {gradeDetails &&
            gradeDetails.map(detail => (
              <Flex
                w="50px"
                h="50px"
                key={detail.grade}
                rounded="lg"
                shadow="md"
                cursor="pointer"
                bg={
                  gradeDetail && gradeDetail.grade === detail.grade ? 'rgb(255, 197, 3)' : 'white'
                }
                border={
                  gradeDetail && gradeDetail.grade === detail.grade
                    ? `2px solid transparent`
                    : `2px solid #805AD5`
                }
                onClick={getGradeDetail(detail.grade)}
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  fontWeight={gradeDetail && gradeDetail.grade === detail.grade ? 'bold' : ''}
                  fontSize="lg"
                >
                  {detail.grade}
                </Text>
              </Flex>
            ))}
        </Flex>
        {gradeDetail && <Text>{gradeDetail.description}</Text>}
      </VStack>
    );
  };

  const renderTeacherSection = () => {
    // Get Teacher
    let teacher;
    if (sections.length) {
      teacher = sections[0].lessons[0].teacher;
    }

    if (!teacher) return null;

    return (
      <Box w="100%" bg="white" rounded="lg" border="1px solid #DDDDDD" p={3}>
        <Stack>
          <Stack>
            <Flex justifyContent="space-between">
              {/* Avatar */}
              <VStack justifyContent="center">
                <Avatar size="2xl" src={`${CDN_HOSTNAME}/teachers/${teacher.id}.png`} />
              </VStack>

              {/* Name & Details */}
              <Stack maxW="75%" p={3}>
                <Text fontWeight="bold" fontSize="lg">
                  {`Teacher ${teacher.first_name}`}
                </Text>
                {teacher.bio && <Text>{teacher.bio}</Text>}
              </Stack>
            </Flex>

            {/* CTA */}
            <VStack>
              <Button
                w="100%"
                colorScheme="purple"
                bg="purple.500"
                rounded="lg"
                onClick={() => {
                  showCrispLauncher();
                  showIntercom();
                  analytics.t('courses: clicked ask teacher a question', {
                    courseTitle: title,
                  });
                }}
              >
                {`✉️ Ask Teacher ${teacher.first_name} a Question`}
              </Button>
            </VStack>
          </Stack>
        </Stack>
      </Box>
    );
  };

  const renderReviewStarCount = () => {
    if (!course.review_star_count) return null;
    const stars = [];

    for (let i = 0; i < course.review_star_count; i += 1) {
      stars.push(<Text key={`star_${i}`}>⭐️</Text>);
    }

    return (
      <HStack spacing={0} pr={1}>
        {stars}
      </HStack>
    );
  };

  const renderReviewSection = () => {
    // Get Teacher
    let teacher;
    if (sections.length) {
      teacher = sections[0].lessons[0].teacher;
    }

    return (
      <Stack spacing={1}>
        {teacher && (
          <HStack py={2}>
            <Avatar size="sm" src={`${CDN_HOSTNAME}/teachers/${teacher.id}.png`} />
            <Text>{`Teacher ${teacher.first_name}`}</Text>
          </HStack>
        )}
        <Flex>
          {renderReviewStarCount()}
          {course.review_count && (
            <Text fontSize={['sm', 'sm', 'md']} pt="2px">
              {`${course.review_count} reviews for this course`}
            </Text>
          )}
        </Flex>
        {course.completed_count && <Text>{`Completed by ${course.completed_count} students`}</Text>}
      </Stack>
    );
  };

  // UseEffects
  useEffect(() => {
    window.scroll(0, 0);
    analytics.t('courses: viewed course detail preview');
    hideCrispLauncher();
  }, []);

  return (
    <Stack w="100%" maxW="xl" mx="auto" h="100%" spacing={3}>
      <Stack h="100%">
        <Text fontWeight="bold" fontSize="2xl">
          {course.title}
        </Text>
        {!course.enabled && (
          <Badge
            w="fit-content"
            variant="subtle"
            rounded="full"
            paddingX={2}
            paddingY={1}
            bg="purple.500"
            color="white"
          >
            {`Course Starts ${convertTZ(new Date(course.start_date || 0).getTime(), {
              mode: 'date-short',
            })}`}
          </Badge>
        )}
        <Text color="gray.500">{course.description}</Text>
        {renderReviewSection()}
      </Stack>

      <Divider py={2} borderColor="gray.300" />

      {/* Course Section */}
      <Stack w="100%" spacing={1}>
        <Text fontWeight="bold" fontSize="xl">
          Course Curriculumn
        </Text>
        <Text color="gray.500">{`This course will last ${sections.length} weeks! Checkout what your child will learn each week.`}</Text>
      </Stack>
      <VStack spacing={4}>
        {course.sections.map(section => renderWeekBlock(section))}
        {renderFinalWeekBlock()}
      </VStack>

      <Divider py={2} borderColor="gray.300" />

      {/* Teacher Section */}
      <Stack spacing={4}>
        <Stack>
          <Text fontWeight="bold" fontSize={['lg', 'lg', 'xl']}>
            Course Teacher
          </Text>
          {renderTeacherSection()}
        </Stack>

        <VStack spacing={4}>
          {/* Grade Details */}
          <Stack w="100%" spacing={1}>
            <Text fontWeight="bold" fontSize={['lg', 'lg', 'xl']}>
              🤔 Is this course right for your child?
            </Text>
            <Text color="gray.500" fontSize="sm">
              Select your child's grade level to find out!
            </Text>
            {renderGradeDetailSection()}
          </Stack>
        </VStack>
      </Stack>
    </Stack>
  );
};

export default CourseDetailPreview;
