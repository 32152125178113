/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { analytics } from '@utils';
import { onboardingSteps } from '../../constants';

const LoginOrSignup = ({ setCurrentStep }) => {
  // Hooks
  const history = useHistory();

  // Actions
  const ctaAction = actionType => () => {
    if (actionType === 'login') {
      const queryParams = new URLSearchParams(window.location.search);
      const cohortId = queryParams.get('cohort_id');
      analytics.t('courses: clicked login');
      history.push(`/login?redirectUri=courses/${cohortId}`);
    } else if (actionType === 'signup') {
      analytics.t('courses: clicked signup');
      setCurrentStep(onboardingSteps.ADD_CHILD);
    }
  };

  // Render Methods
  const renderLoginButton = () => (
    <Button
      w="100%"
      h={['50px', '60px']}
      bg="gray.300"
      color="black"
      colorScheme="green"
      fontSize="lg"
      rounded="full"
      mt={2}
      onClick={ctaAction('login')}
    >
      👍 Have an account? Login
    </Button>
  );

  const renderSignupButton = () => (
    <Button
      w="100%"
      h={['50px', '60px']}
      bg="rgb(255, 197, 3)"
      colorScheme="yellow"
      fontSize="lg"
      rounded="full"
      mt={2}
      onClick={ctaAction('signup')}
    >
      😎 New to Zip? Signup
    </Button>
  );

  // UseEffects
  useEffect(() => {
    window.scroll(0, 0);
    analytics.t('courses: viewed login or signup page');
  }, []);

  return (
    <Flex flexDir="column" w="100%" h="100%" animation="fadeIn 0.4s">
      <Text
        color="#383838"
        fontSize={['xl', 'xl', '2xl', '3xl']}
        fontWeight="bold"
        textAlign="center"
      >
        👋 Let's get you enrolled!
      </Text>
      <VStack w="100%" spacing={3}>
        {renderSignupButton()}
        {renderLoginButton()}
      </VStack>
    </Flex>
  );
};

export default LoginOrSignup;
