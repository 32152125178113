/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Box,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { analytics } from '@utils';
import { allCountries } from 'country-telephone-data';
import { countryCodeEmoji } from 'country-code-emoji';

const CredsForm = ({ form }) => {
  // Actions
  const sortCountryCodes = () => {
    return allCountries.sort((a, b) => {
      return a.dialCode - b.dialCode;
    });
  };

  const validateEmail = value => {
    // eslint-disable-next-line max-len
    const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error;

    if (!value) {
      error = 'Valid email required';
    } else if (!emailExpression.test(value)) {
      error = 'Email format is not correct';
    }

    return error || true;
  };

  const validateName = fieldName => value => {
    if (!value) {
      return `${fieldName} is required`;
    }

    return true;
  };

  const validatePassword = value => {
    let error;
    if (!value) {
      error = 'Password is required';
    } else if (value.length < 8) {
      error = 'Password must be at least 8 characters';
    }

    return error || true;
  };

  // Render Methods
  const renderFormFields = () => (
    <VStack spacing={3}>
      {/* First Name */}
      <FormControl isInvalid={form.errors.first_name} isRequired>
        <FormLabel fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          Your First Name
        </FormLabel>
        <Input
          name="first_name"
          placeholder="Your First Name"
          type="text"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          fontSize="md"
          isRequired
          ref={form.register({ validate: validateName('First Name') })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.first_name && 'First name required'}
        </FormErrorMessage>
      </FormControl>

      {/* Last Name */}
      <FormControl isInvalid={form.errors.last_name} isRequired>
        <FormLabel fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          Your Last Name
        </FormLabel>

        <Input
          name="last_name"
          placeholder="Your Last Name"
          type="text"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          fontSize="md"
          isRequired
          ref={form.register({ validate: validateName('Last Name') })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.last_name && 'Last name required'}
        </FormErrorMessage>
      </FormControl>

      {/* Phone Number */}
      <FormControl isInvalid={form.errors.country_code || form.errors.phone} isRequired>
        <FormLabel fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          Your Phone Number
        </FormLabel>
        <Text fontSize="sm" color="gray.600">
          We need this to send you course updates
        </Text>
        <HStack>
          <Select
            ref={form.register({ required: true })}
            name="country_code"
            h={['45px', '50px', '55px', '60px']}
            rounded="lg"
            fontSize="md"
            maxW={['45%', '35%', '30%', '25%']}
          >
            {sortCountryCodes().map(country => (
              <option
                key={country.name}
                value={country.dialCode}
                selected={country.name === 'United States'}
              >
                {countryCodeEmoji(country.iso2)}+{country.dialCode}
              </option>
            ))}
          </Select>
          <Input
            ref={form.register({ required: true })}
            name="phone"
            placeholder="Phone number"
            type="tel"
            h={['45px', '50px', '55px', '60px']}
            rounded="lg"
            fontSize="md"
          />
        </HStack>
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          Phone Number is required
        </FormErrorMessage>
      </FormControl>

      {/* Email */}
      <FormControl isInvalid={form.errors.email} isRequired>
        <FormLabel fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          Email
        </FormLabel>
        <Input
          name="email"
          placeholder="Email"
          type="email"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          fontSize="md"
          ref={form.register({ validate: validateEmail })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.email && form.errors.email.message}
        </FormErrorMessage>
      </FormControl>

      {/* Password */}
      <FormControl isInvalid={form.errors.password} isRequired>
        <FormLabel fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          Password
        </FormLabel>
        <Text my={1} fontSize="sm" color="gray.600">
          Make sure this is at least 8 characters
        </Text>
        <Input
          name="password"
          placeholder="Password"
          type="password"
          h={['45px', '50px', '55px', '60px']}
          rounded="lg"
          fontSize="md"
          ref={form.register({ validate: validatePassword })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.password && form.errors.password.message}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  );

  // UseEffects
  useEffect(() => {
    window.scroll(0, 0);
    analytics.t('courses: viewed signup form');
  }, []);

  return (
    <Box w="100%" h="100%" animation="fadeIn 0.4s">
      {renderFormFields()}
    </Box>
  );
};

export default CredsForm;
