/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { Box, Text, Flex, VStack } from '@chakra-ui/react';
import { Waypoint } from 'react-waypoint';

const ProgressBar = ({ percent, steps }) => {
  // References
  const containerRef = useRef(null);

  // State
  const [progress, setProgress] = useState(0);
  const [runAnimation, setRunAnimation] = useState(false);

  // Actions
  const handleEnterViewport = () => {
    if (!runAnimation && containerRef.current) {
      setProgress(containerRef.current.offsetWidth * percent);
    }
    setRunAnimation(true);
  };

  // Render Methods
  const renderSteps = () => {
    const stepComponents = [];

    for (let i = 0; i < steps; i += 1) {
      stepComponents.push(
        <VStack key={i}>
          <Flex
            w="44px"
            h="44px"
            justifyContent="center"
            alignItems="center"
            bg="rgb(255, 197, 3)"
            rounded="full"
            shadow="md"
          >
            <Text fontWeight="bold" fontSize="sm">{`W${i + 1}`}</Text>
          </Flex>
        </VStack>,
      );
    }

    return stepComponents.map(component => component);
  };

  return (
    <Waypoint onEnter={handleEnterViewport}>
      <Box ref={containerRef} position="relative" w="100%" bg="gray.500" rounded="lg">
        <Flex position="absolute" top="-20px" w="100%" rounded="lg" justifyContent="space-between">
          {renderSteps()}
        </Flex>
        <Box
          style={{
            transition: runAnimation ? '4s ease' : '',
          }}
          w={`${progress}px`}
          h="10px"
          bg="green.300"
          rounded="lg"
        />
      </Box>
    </Waypoint>
  );
};

export default ProgressBar;
