/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import {
  Text,
  Box,
  Button,
  Modal,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import { AVATAR } from '@utils';

const AvatarModal = ({ onChange, onClose, child, loading, onSubmit }) => (
  <Modal
    isOpen
    scrollBehavior="inside"
    onClose={onClose || (() => onChange(null))}
    closeOnOverlayClick={false}
  >
    <ModalOverlay />
    <ModalContent
      borderWidth="2px"
      borderColor="black"
      boxShadow="2px 2px 3px #444444"
      borderRadius="5px"
      p={5}
    >
      <ModalHeader>
        <Text as="h1" fontWeight="bold">
          {child.first_name}
        </Text>
      </ModalHeader>
      <ModalBody borderWidth="1px" borderRadius="4px" borderColor="gray.100">
        <Text mt={3} fontWeight="bold">
          Choose Avatar
        </Text>
        <Text mb={3} fontSize="sm" color="gray.600">
          This will show to the left of your child's name in class
        </Text>
        <SimpleGrid minChildWidth="100px" spacing="20px">
          {AVATAR.EMOJIS.map(emoji => (
            <Box
              key={emoji.title}
              p={3}
              borderWidth={child.emoji === emoji.title ? '3px' : '1px'}
              borderRadius="4px"
              borderColor={child.emoji === emoji.title ? 'purple.300' : 'gray.300'}
              d="flex"
              alignItems="center"
              flexDir="column"
              cursor="pointer"
              onClick={() => {
                onChange(prevChild => {
                  return { ...prevChild, emoji: emoji.title };
                });
              }}
            >
              <Text fontSize="4xl">{emoji.value}</Text>
              <Text mt={2} fontWeight={child.emoji === emoji.title ? 'bold' : 'normal'}>
                {emoji.title.charAt(0).toUpperCase() + emoji.title.slice(1)}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
        <Divider />
        <Text mt={3} fontWeight="bold">
          Choose Border Color
        </Text>
        <Text mb={3} fontSize="sm" color="gray.600">
          This shows as a border around your child for others to see in class
        </Text>
        <SimpleGrid minChildWidth="100px" spacing="20px">
          {AVATAR.COLORS.map(color => (
            <Box
              key={color.value}
              p={3}
              borderWidth={child.color === color.value ? '3px' : '1px'}
              borderRadius="4px"
              borderColor={child.color === color.value ? color.value : 'gray.300'}
              d="flex"
              alignItems="center"
              flexDir="column"
              cursor="pointer"
              onClick={() => {
                onChange(prevChild => {
                  return { ...prevChild, color: color.value };
                });
              }}
            >
              <Box w="4rem" h="4rem" borderRadius="50%" bg={color.value} />
              <Text mt={2} fontWeight={child.color === color.value ? 'bold' : 'normal'}>
                {color.title}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </ModalBody>
      <ModalFooter>
        <Button isDisabled={loading} onClick={onClose || (() => onChange(null))}>
          Cancel
        </Button>
        <Button
          isLoading={loading}
          isDisabled={!child.color || !child.emoji}
          onClick={onSubmit}
          color="white"
          bg="purple.500"
          fontSize="lg"
          fontWeight="bold"
          ml={3}
        >
          Save
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default memo(AvatarModal);

// eslint-disable-next-line no-lone-blocks
{
  /*  <Box
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      key={id}
      bg="gray.100"
      p={4}
      borderRadius="4px"
    >
      <Box>
        <Text fontWeight="medium">{title}</Text>
        <Stack isInline spacing={2}>
          <Box>
            <Text>{convertTZ(timestamp, { mode: 'date' })}</Text>
            <Text fontSize="xs">{convertTZ(timestamp, { showTimeZone: true, mode: 'time' })}</Text>
            {cancelEnabled && (
              <Box style={{ cursor: 'pointer' }} onClick={() => promptCancelBooking(item)}>
                <Text fontSize="sm" style={{ textDecoration: 'underline' }} color="red.700">
                  Cancel Reservation
                </Text>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
      <Stack spacing={1} alignItems="flex-end">
        <Box p={0} m={0}>
          <Link
            to={`/rooms/${id}/waiting`}
            onClick={() => analytics.t('clicked join waiting room', item)}
          >
            <Button isLoading={isLoading} bg="purple.500" fontWeight="bold" color="white">
              Join
            </Button>
          </Link>
        </Box>
      </Stack>
    </Box> */
}
