/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Button, useToast, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import actions from '@actions';
import {
  analytics,
  hideCrispLauncher,
  showErrorToast,
  sendDataToNative,
  navigateTo,
  lockLandscapeNative,
  lockPortraitNative,
} from '@utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useNativeCallback } from '@hooks';
import NativeOnboarding from './NativeOnboarding';
import { onboardingSteps, PUSH_AUTHORIZATION_STATUS } from '../constants';
import awsconfig from '../../aws-exports';

const NativeOnboardingContainer = ({ fetchAuthState }) => {
  // State
  const [currentStep, setCurrentStep] = useState(null);
  const [pushState, setPushState] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedPetId, setSelectedPetId] = useState(null);
  const [pets, setPets] = useState([]);
  const [introLessonsId, setIntroLessonId] = useState(null);
  const [isLoading, setLoading] = useState(false);

  // Hooks
  const form = useForm();
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();
  const nativeCallback = useNativeCallback();

  // Actions
  const fetchConfig = async () => {
    setLoading(true);

    try {
      const { redeemables = [], intro_lesson_id: introLessonId } = await actions.fetchConfig();
      setPets(redeemables);
      setIntroLessonId(introLessonId);
    } catch (err) {
      showErrorToast(toast, err.message);
    }

    setLoading(false);
  };

  const fetchData = async () => {
    await fetchConfig();
  };

  const onNext = async data => {
    if (currentStep === onboardingSteps.ADD_TOPICS) {
      if (pushState !== PUSH_AUTHORIZATION_STATUS.AUTHORIZED) {
        setCurrentStep(onboardingSteps.PUSH_NOTIFICATION_MODAL);
      } else {
        setCurrentStep(onboardingSteps.PET_SELECT);
      }

      analytics.t('clicked continue topics list in native onboarding', {
        selectedTopics: selectedTopics.map(topic => topic.name),
      });
    } else if (currentStep === onboardingSteps.PET_SELECT) {
      analytics.t('clicked continue pet select in native onboarding', {
        id: selectedPetId,
      });

      let topicIds = [];

      selectedTopics.forEach(topic => {
        const topicSplit = topic.id.split('_');
        topicIds = [...topicIds, ...topicSplit];
      });

      const clientId = awsconfig.Auth.userPoolWebClientId;
      localStorage.setItem(`${clientId}.topics`, topicIds);
      localStorage.setItem(`${clientId}.redeemable_id`, selectedPetId);
      setCurrentStep(onboardingSteps.PARENT_LESSON);
    } else if (currentStep === onboardingSteps.ADD_EMAIL_PASSWORD) {
      setLoading(true);
      analytics.t('clicked submit sign up form native onboarding');

      try {
        let topicIds = [];

        selectedTopics.forEach(topic => {
          const topicSplit = topic.id.split('_');
          topicIds = [...topicIds, ...topicSplit];
        });

        const req = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password,
          topics: topicIds,
        };

        await actions.createProfile(req);
        await actions.updateTopics(topicIds);
        await actions.updateRedemption('redeem', { redeemable_id: selectedPetId });
        await fetchAuthState();
        navigateTo(history, '/classes-mobile');
      } catch (err) {
        setLoading(false);
        showErrorToast(toast, err.message);
      }
    }
  };

  // Render Methods
  const renderHeader = () => {
    if (currentStep === onboardingSteps.SIGNUP) {
      return null;
    }

    let title;
    let subtitle;
    if (
      currentStep === onboardingSteps.ADD_TOPICS ||
      currentStep === onboardingSteps.PUSH_NOTIFICATION_MODAL
    ) {
      title = 'Fancy seeing you here ✨';
      subtitle = 'Which subjects would you like your child to explore?';
    } else if (currentStep === onboardingSteps.PET_SELECT) {
      title = `Select your child's pet 🐶`;
      subtitle =
        'As your child takes lessons, their pet levels up! You can change their pet later 👍';
    } else if (currentStep === onboardingSteps.ADD_EMAIL_PASSWORD) {
      title = `We only need a few things ✍️`;
      subtitle = 'By having a ZipSchool account, you will have access to all videos for FREE.';
    }

    return (
      <Stack className="native-inset-top" spacing={1} color="white" pb={3}>
        <Text fontSize="2xl" fontWeight="bold">
          {title}
        </Text>
        <Text fontSize="md">{subtitle}</Text>
      </Stack>
    );
  };

  const renderCTA = () => {
    if (currentStep === onboardingSteps.SIGNUP) {
      return null;
    }

    let title;
    if (
      currentStep === onboardingSteps.ADD_TOPICS ||
      currentStep === onboardingSteps.PUSH_NOTIFICATION_MODAL
    ) {
      title = `🎸 Let's Rock`;
    } else if (currentStep === onboardingSteps.ADD_EMAIL_PASSWORD) {
      title = `👏 Sign up`;
    } else if (currentStep === onboardingSteps.PET_SELECT) {
      title = `🎯 Looks Great!`;
    }

    return (
      <Flex
        w="100%"
        justifyContent="center"
        position="fixed"
        left="0"
        bottom="0"
        pb={2}
        bg="purple.500"
        margin="auto"
        boxShadow="0px -1px 5px rgba(0,0,0,0.2)"
      >
        <Button
          w={['85%', '85%', '75%', '70%']}
          h="60px"
          m={4}
          bg="rgb(255, 197, 3)"
          colorScheme="yellow"
          fontSize="lg"
          rounded="full"
          isDisabled={
            selectedTopics.length === 0 ||
            (currentStep === onboardingSteps.PET_SELECT && !selectedPetId)
          }
          isLoading={isLoading}
          onClick={form.handleSubmit(onNext)}
          _hover={{ backgroundColor: 'rgb(255, 197, 3)' }}
        >
          {title}
        </Button>
      </Flex>
    );
  };

  // UseEffects
  useEffect(() => {
    analytics.t('viewed topics selector screen');
    fetchData();
    hideCrispLauncher();

    sendDataToNative({
      action: 'pushstate',
      data: {},
    });
  }, []);

  useEffect(() => {
    if (nativeCallback) {
      const { action } = nativeCallback;

      // There could be some other events happening here
      // Just make sure this is a push authorization event
      if (
        action === PUSH_AUTHORIZATION_STATUS.AUTHORIZED ||
        action === PUSH_AUTHORIZATION_STATUS.DENIED
      ) {
        setPushState(action);
      }
    }
  }, [nativeCallback]);

  useEffect(() => {
    if (currentStep === onboardingSteps.PARENT_LESSON) {
      lockLandscapeNative();
      history.push(`/noauth/live/${introLessonsId}?origin=${origin}`);
    }
  }, [currentStep]);

  useEffect(() => {
    // If we have a location.step
    if (location && location.state && location.state.step) {
      lockPortraitNative();
      setCurrentStep(location.state.step);
    } else {
      setCurrentStep(onboardingSteps.ADD_TOPICS);
    }
  }, [location]);

  return (
    <Flex w="100%" flexDir="column" justifyContent="space-around" p={[5, 8]} bg="purple.500">
      {renderHeader()}
      <Box w="100%" h="100%" pb={['100px', '80px']}>
        <NativeOnboarding
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          selectedTopics={selectedTopics}
          setSelectedTopics={setSelectedTopics}
          selectedPetId={selectedPetId}
          setSelectedPetId={setSelectedPetId}
          pets={pets}
          form={form}
        />
      </Box>
      {renderCTA()}
    </Flex>
  );
};

export default NativeOnboardingContainer;
