/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Spinner,
  Text,
  useToast,
  VStack,
  Image,
  HStack,
  useClipboard,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import BookedItem from './components/BookedItem';
import actions from '@actions';
import { analytics, navigateTo, showErrorToast } from '@utils';
import { CDN_HOSTNAME } from '@constants';
import { isiOSNativeDevice } from '@utils/index';

const Booked = ({ profile }) => {
  // Local Properties
  const toast = useToast();
  const history = useHistory();
  const { onCopy, hasCopied } = useClipboard(profile && profile.referral && profile.referral.url);

  // State
  const [bookedItems, setBookedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showReferral, setShowReferral] = useState(false);

  // Actions
  const fetchSchedule = async isPublic => {
    if (!isPublic) {
      setIsLoading(true);
    }

    try {
      const {
        booked_items: bookedItemsResp = [],
        show_referral: showReferralResp,
      } = await actions.fetchSchedule();

      setBookedItems(bookedItemsResp);
      setShowReferral(showReferralResp);
    } catch (err) {
      showErrorToast(toast, err.message);
      setIsLoading(false);
    }

    if (!isPublic) {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    await fetchSchedule(false);
  };

  const setBookingRef = useCallback((id, node) => {
    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      const urlParams = new URLSearchParams(window.location.search);
      const bookingId = urlParams.get('booking_id');
      const decodedBookingId = bookingId ? decodeURIComponent(bookingId) : undefined;

      if (decodedBookingId) {
        if (id === decodedBookingId) {
          const dimensions = node.getBoundingClientRect();
          window.scrollTo({
            top: dimensions.top,
            behavior: 'smooth',
          });
          window.history.pushState(null, null, '/booked');
        }
      }
    }
  }, []);

  // Render Methods
  const renderSchedulePageButton = () => (
    <Button
      w="100%"
      h={['40px', '45px', '50px', '55px']}
      rounded="full"
      boxShadow="md"
      fontSize={['md', 'lg', 'xl', 'xl']}
      fontWeight="bold"
      bg="#ffc60b"
      backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                            rgba(255, 255, 255, 0) 93.72%)"
      style={{
        backgroundBlendMode: 'soft-light, normal',
      }}
      _hover={{ backgroundColor: '#FFC503' }}
      onClick={() => {
        navigateTo(history, '/schedule');
        analytics.t('clicked take me to schedule cta');
      }}
    >
      <Box>
        <Text>🚀 Go To Classes Page</Text>
      </Box>
    </Button>
  );

  const renderNoItems = () => (
    <VStack bg="white" rounded="lg" p={5}>
      <Text color="#383838" fontSize={['xl', '2xl']} fontWeight="bold" textAlign="center">
        😯 No Classes Scheduled Yet
      </Text>
      <Text
        color="gray.400"
        fontWeight="bold"
        fontSize={['sm', 'md']}
        textAlign="center"
        pt={[1, 3]}
      >
        Head over to the Classes page to book a class today!
      </Text>
      {renderSchedulePageButton()}
    </VStack>
  );

  const renderReferralBlock = () => (
    <Box
      width="100%"
      bg="white"
      rounded="lg"
      p={6}
      mt={4}
      borderWidth="2px"
      borderTopWidth="2px"
      borderColor="gray.700"
    >
      <Image
        rounded="lg"
        src={`${CDN_HOSTNAME}/promotion/swag1.png`}
        w="100%"
        h="auto"
        shadow="md"
      />
      <Text color="#383838" fontSize={['lg', 'xl']} fontWeight="black" mt={3}>
        Giveaway: Win a ZipSchool t-shirt, backpack, and mug for your child! ✨
      </Text>
      <Text color="gray.500" fontSize={['sm', 'md']} pt={[1, 3]}>
        Share ZipSchool with your friends on Facebook or in a group chat using your custom link
        below. Every time someone uses your link to signup, you'll receive one entry for the
        giveaway!
      </Text>

      <HStack
        p={3}
        mt={4}
        bg="gray.100"
        borderRadius="md"
        color="black"
        shadow="md"
        justify="space-between"
      >
        <Text fontSize={['sm', 'md']}>
          {profile.referral ? profile.referral.url : 'No referral code found!'}
        </Text>
        <Button
          fontSize={['xs', 'sm']}
          bg={hasCopied ? 'green.500' : 'purple.500'}
          color="white"
          fontWeight="bold"
          onClick={e => {
            analytics.t('referral link copied');
            onCopy(e);
          }}
        >
          {hasCopied ? 'Copied!' : 'Copy Link'}
        </Button>
      </HStack>
    </Box>
  );

  // UseEffects
  useEffect(() => {
    analytics.t('viewed booked screen');
    fetchData();
  }, []);

  return (
    <Box w="100%">
      {!isiOSNativeDevice() && (
        <>
          <Text
            color="white"
            fontWeight="black"
            fontSize={['2xl', '2xl', '2xl', '3xl']}
            animation="fadeIn 0.5s"
          >
            Booked Classes
          </Text>
          <Divider borderWidth="1px" opacity={1.0} borderColor="white" animation="fadeIn 0.5s" />
        </>
      )}
      {isLoading && (
        <Box d="flex" justifyContent="center">
          <Spinner fontSize={['2xl', '2xl', '2xl', '3xl']} mt={4} color="white" />
        </Box>
      )}

      {!isLoading && bookedItems.length === 0 && (
        <Box pt={2} animation="fadeIn 0.7s">
          {renderNoItems()}
        </Box>
      )}
      {bookedItems.length > 0 && !isLoading && (
        <Box pt={5} animation="fadeIn 0.7s">
          <Box pb={5}>{showReferral && profile && profile.referral && renderReferralBlock()}</Box>
          {bookedItems.map(item => (
            <BookedItem
              onRef={setBookingRef}
              key={item.booking_id}
              item={item}
              fetchSchedule={fetchSchedule}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Booked;
