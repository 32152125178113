/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect, Suspense } from 'react';
import '../App.css';
import {
  Avatar,
  Flex,
  useToast,
  Text,
  Stack,
  Box,
  Button,
  Spinner,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
  SimpleGrid,
  HStack,
  Wrap,
  WrapItem,
  VStack,
  Select,
  Skeleton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import SearchBar from '@components/SearchBar';
import actions from '@actions';

import { showErrorToast, analytics, convertTZ, showCrispLauncher, hideCrispLauncher } from '@utils';
import { AGES, AUTH_STATES, TIMES } from '@constants';
import ClassListItem from '@components/ClassListItem';
// import ClassChannel from '@components/ClassChannel';
import SortModal from '../Modals/SortModal';
import CompletedFilterModal from '../Modals/CompletedFilterModal';
import WelcomeModal from '../Modals/WelcomeModal';

const CTA_BG_COLOR = '#FFC503';
const LOAD_ITEMS_NUM = 5;
const INITIAL_ITEMS_NUM = 15;
const MIN_CHANNEL_ITEMS = 1;

const LazyClassChannel = React.lazy(() => import('@components/ClassChannel'));

const ClassList = ({
  profile,
  fetchProfile,
  authState,
  fetchAuthState,
  setDateFilter,
  dateFilter,
  setFilterDateModal,
  filterDateModal,
  timeFilters,
  dayFilters,
  setDayFilters,
  subjectFilters,
  setSubjectFilters,
  ageFilters,
  setAgeFilters,
  displayItems,
  setDisplayItems,
  allItems,
  setAllItems,
  filterTimesModal,
  setFilterTimesModal,
  filterSubjectsModal,
  setFilterSubjectsModal,
  filterAgeModal,
  setFilterAgeModal,
  setTimeFilters,
  sortModal,
  setSortModal,
  completedFilterModal,
  setCompletedFilterModal,
  sortMode,
  setSortMode,
  completedFilter,
  setCompletedFilter,
  headerRef,
  compileFilterPredicate,
  setCurrentBookingClassData,
  setBookingCompletionHandler,
}) => {
  // Hooks

  // State
  const [isLoading, setLoading] = useState(false);
  const [bookingClass, setBookingClass] = useState(false);
  const [maxCarouselItems, setMaxCarouselItems] = useState(10);
  const [numLoadedItems, setNumLoadedItems] = useState(0);
  const [loadedItems, setLoadedItems] = useState(null);

  // const [showReferral, setShowReferral] = useState(false);
  const [allSubjects, setAllSubjects] = useState([]);
  const [popularMathItems, setPopularMathItems] = useState([]);
  const [popularAnimalItems, setPopularAnimalItems] = useState([]);
  const [popularSpaceItems, setPopularSpaceItems] = useState([]);
  const [popularDinosaurItems, setPopularDinosaurItems] = useState([]);
  const [popularEngineeringItems, setPopularEngineeringItems] = useState([]);
  const [popularSpanishItems, setPopularSpanishItems] = useState([]);
  const [popularMarineScienceItems, setPopularMarineScienceItems] = useState([]);
  const [popularMedicineItems, setPopularMediceItems] = useState([]);
  const [popularEarthScienceItems, setPopularEarthScienceItems] = useState([]);
  const [popularPhysicsChemistryItems, setPopularPhysicsChemistryItems] = useState([]);
  const [popularCultureSSHistoryItems, setPopularCultureSSHistoryItems] = useState([]);
  const [popularReadingItems, setPopularReadingItems] = useState([]);
  const [popularArtItems, setPopularArtItems] = useState([]);

  const [popularArt_Advanced, setPopularArt_Advanced] = useState([]);
  const [popularArt_Animals, setPopularArt_Animals] = useState([]);
  const [popularArt_CreateYourOwn, setPopularArt_CreateYourOwn] = useState([]);
  const [popularArt_MythicalCreature, setPopularArt_MythicalCreature] = useState([]);
  const [popularArt_Pokemon, setPopularArt_Pokemon] = useState([]);
  const [popularArt_VideoGame, setPopularArt_VideoGame] = useState([]);
  const [popularArt_TVShowMovie, setPopularArt_TVShowMovie] = useState([]);
  const [popularArt_Dinosaurs, setPopularArt_Dinosaurs] = useState([]);
  const [allCompletedItems, setAllCompletedItems] = useState([]);

  const [popularScienceItems, setPopularScienceItems] = useState([]);
  const [childItems, setChildItems] = useState({});

  const [scrollToItem, setScrollToItem] = useState(null);
  const toast = useToast();

  const deviceType = useBreakpointValue({
    base: 'mobile',
    md: 'mobile',
    lg: 'desktop',
    xl: 'desktop',
  });

  // Actions
  const loadMoreItemsAction = action => {
    if (displayItems.length === 0 && loadedItems !== null) {
      setLoadedItems([]);
      setNumLoadedItems(0);
      return;
    }
    if (displayItems.length === 0 && loadedItems === null) {
      return;
    }

    const items = [];
    let totalItems = numLoadedItems + LOAD_ITEMS_NUM;
    let startingIndex = numLoadedItems;

    if (action === 'initial') {
      totalItems = INITIAL_ITEMS_NUM;
      startingIndex = 0;
    }

    for (let i = startingIndex; i < totalItems; i += 1) {
      if (displayItems[i]) {
        items.push(displayItems[i]);
      }
    }

    setLoadedItems(prevState => (action === 'initial' ? items : [...prevState, ...items]));
    setNumLoadedItems(prevState =>
      action === 'initial' ? totalItems : prevState + LOAD_ITEMS_NUM,
    );
  };

  const setClassRef = useCallback(
    (id, node) => {
      if (!isLoading && node) {
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        const urlParams = new URLSearchParams(window.location.search);
        const classId = urlParams.get('class_id');
        const decodedClassId = classId ? decodeURIComponent(classId) : undefined;

        if (decodedClassId && decodedClassId === id) {
          const dimensions = node.getBoundingClientRect();
          const headerOffset = headerRef && headerRef.current ? headerRef.current.offsetHeight : 0;

          window.scrollTo({
            top: dimensions.top - headerOffset,
            behavior: 'smooth',
          });

          setScrollToItem(decodedClassId);
          window.history.replaceState(null, null, '/schedule');
        }
      }
    },
    [isLoading],
  );

  // Filter On Change Handlers
  const onSubjectSelect = subject => {
    const subjectFiltersCopy = subjectFilters.slice();
    if (subjectFiltersCopy.includes(subject)) {
      const index = subjectFiltersCopy.indexOf(subject);
      if (index >= 0) {
        subjectFiltersCopy.splice(index, 1);
      }
    } else {
      subjectFiltersCopy.push(subject);
    }

    setSubjectFilters(subjectFiltersCopy);
  };

  const onDaySelect = day => {
    const dayFiltersCopy = dayFilters.slice();
    if (dayFiltersCopy.includes(day)) {
      const index = dayFiltersCopy.indexOf(day);
      if (index >= 0) {
        dayFiltersCopy.splice(index, 1);
      }
    } else {
      dayFiltersCopy.push(day);
    }

    setDayFilters(dayFiltersCopy);
  };

  const onAgeSelect = age => {
    const ageFiltersCopy = ageFilters.slice();
    if (ageFiltersCopy.includes(age)) {
      const index = ageFiltersCopy.indexOf(age);
      if (index >= 0) {
        ageFiltersCopy.splice(index, 1);
      }
    } else {
      ageFiltersCopy.push(age);
    }

    ageFiltersCopy.sort((a, b) => a - b);
    setAgeFilters(ageFiltersCopy);
  };

  const onDateSelect = mode => {
    if (mode === dateFilter) setDateFilter(null);
    else setDateFilter(mode);
  };

  const fetchSchedule = async (silent, cancelledClassId) => {
    if (!silent) setLoading(true);

    try {
      const { items = [], max_carousel_items: _maxCarouselItems } = await actions.fetchSchedule();

      // Filter out classes that don't have a preview here.
      // At this point there are no resources associated which means you can't watch the class now
      if (allItems && allItems.length > 0) {
        let shouldRefresh = false;
        for (const serverItem of items) {
          const localItem = allItems.find(i => i.class_id === serverItem.class_id);
          if (
            !localItem ||
            localItem.liked !== serverItem.liked ||
            localItem.completed !== serverItem.completed ||
            localItem.scheduled !== serverItem.scheduled
          ) {
            shouldRefresh = true;
            break;
          }
        }

        if (shouldRefresh) {
          setAllItems(items);
        }
      } else {
        setAllItems(items);
      }

      if (_maxCarouselItems) {
        setMaxCarouselItems(_maxCarouselItems);
      }

      if (cancelledClassId) {
        const item = items
          .filter(respItem => {
            return respItem.class_id === cancelledClassId;
          })
          .pop();
        setCurrentBookingClassData(prevState => {
          return { ...prevState, ...item };
        });
      }

      const tempSubjects = [...new Set(items.map(i => i.subject))];
      tempSubjects.sort();
      if (tempSubjects.length !== allSubjects.length) {
        setAllSubjects(tempSubjects);
      }
    } catch (err) {
      showErrorToast(toast, err.message);
    }

    if (!silent) setLoading(false);
  };

  useEffect(() => {
    loadMoreItemsAction('initial');
  }, [displayItems]);

  // Render Methods
  const renderSubjectFilterModal = () => (
    <Modal
      scrollBehavior="inside"
      blockScrollOnMount
      preserveScrollBarGap={false}
      isOpen
      onClose={() => setFilterSubjectsModal(null)}
      closeOnOverlayClick
    >
      <ModalOverlay />
      <ModalContent m={3} rounded="lg">
        <ModalHeader>
          <Stack isInline alignItems="center" spacing={2}>
            <Text fontWeight="bold" fontSize="xl">
              Subjects
            </Text>
          </Stack>
        </ModalHeader>
        <ModalBody>
          <Stack mt={2} spacing={2}>
            {allSubjects.map(s => (
              <Box
                onClick={() => onSubjectSelect(s)}
                borderWidth="1px"
                borderColor="gray.300"
                rounded="lg"
                w="100%"
                key={s}
                h="50px"
                d="flex"
                alignItems="center"
                justifyContent="space-between"
                cursor="pointer"
                bg={subjectFilters.includes(s) ? CTA_BG_COLOR : 'white'}
                color="black"
                p={3}
              >
                <Text fontWeight={subjectFilters.includes(s) ? 'bold' : 'normal'}>{s}</Text>
                {subjectFilters.includes(s) && <Text>✅</Text>}
              </Box>
            ))}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            tabIndex="-1"
            onClick={() => {
              setSubjectFilters(null);
              setFilterSubjectsModal(null);
            }}
          >
            Cancel
          </Button>

          <Button
            tabIndex="-1"
            ml={2}
            bg={CTA_BG_COLOR}
            fontWeight="medium"
            color="black"
            onClick={() => {
              analytics.t('changed filter', { type: 'subject', selected: subjectFilters });
              compileFilterPredicate();
              setFilterSubjectsModal(null);
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderSortModal = () => (
    <SortModal
      sortMode={sortMode}
      setSortMode={setSortMode}
      onApply={() => {
        compileFilterPredicate();
        setSortModal(null);
      }}
      onClose={() => {
        setSortMode(null);
        setSortModal(null);
      }}
    />
  );

  const renderCompletedFilterModal = () => (
    <CompletedFilterModal
      comnpletedFilter={completedFilter}
      setCompletedFilter={setCompletedFilter}
      onApply={() => {
        compileFilterPredicate();
        setCompletedFilterModal(null);
      }}
      onClose={() => {
        setCompletedFilter(null);
        setCompletedFilterModal(null);
      }}
    />
  );

  const renderFilterAgeModal = () => (
    <Modal
      scrollBehavior="inside"
      isOpen
      onClose={() => setFilterAgeModal(null)}
      closeOnOverlayClick
    >
      <ModalOverlay />
      <ModalContent m={3} rounded="lg">
        <ModalHeader>
          <Stack isInline alignItems="center" spacing={2}>
            <Text fontWeight="bold" fontSize="xl">
              Ages
            </Text>
            <Text fontSize="sm">(4-12)</Text>
          </Stack>
        </ModalHeader>
        <ModalBody>
          <Stack mt={2} spacing={2}>
            {AGES.map(s => (
              <Box
                onClick={() => onAgeSelect(s)}
                borderWidth="1px"
                borderColor="gray.300"
                rounded="lg"
                w="100%"
                key={s}
                h="50px"
                d="flex"
                alignItems="center"
                justifyContent="space-between"
                cursor="pointer"
                bg={ageFilters.includes(s) ? CTA_BG_COLOR : 'white'}
                color="black"
                p={3}
              >
                <Text fontWeight={ageFilters.includes(s) ? 'bold' : 'normal'}>{s}</Text>
                {ageFilters.includes(s) && <Text>✅</Text>}
              </Box>
            ))}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            tabIndex="-1"
            onClick={() => {
              setAgeFilters(null);
              setFilterAgeModal(null);
            }}
          >
            Cancel
          </Button>

          <Button
            tabIndex="-1"
            ml={2}
            bg={CTA_BG_COLOR}
            fontWeight="medium"
            color="black"
            onClick={() => {
              analytics.t('changed filter', { type: 'age', selected: ageFilters });
              compileFilterPredicate();
              setFilterAgeModal(null);
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderFilterDateModal = () => (
    <Modal isOpen onClose={() => setFilterDateModal(null)} closeOnOverlayClick>
      <ModalOverlay />
      <ModalContent m={3} rounded="lg">
        <ModalHeader>
          <Stack isInline alignItems="center" spacing={2}>
            <Text fontWeight="bold" fontSize="xl">
              Date
            </Text>
          </Stack>
        </ModalHeader>
        <ModalBody>
          <Stack mt={2} spacing={2}>
            <Box
              onClick={() => onDateSelect('this-week')}
              borderWidth="1px"
              borderColor="gray.300"
              rounded="lg"
              w="100%"
              key="this-week"
              h="50px"
              d="flex"
              alignItems="center"
              justifyContent="space-between"
              cursor="pointer"
              bg={dateFilter === 'this-week' ? 'purple.500' : 'white'}
              color={dateFilter === 'this-week' ? 'white' : 'black'}
              p={3}
            >
              <Text fontWeight={dateFilter === 'this-week' ? 'bold' : 'normal'}>
                Starting this week or later
              </Text>
              {dateFilter === 'this-week' && <Text>✅</Text>}
            </Box>
            <Box
              onClick={() => onDateSelect('next-week')}
              borderWidth="1px"
              borderColor="gray.300"
              rounded="lg"
              w="100%"
              key="next-week"
              h="50px"
              d="flex"
              alignItems="center"
              justifyContent="space-between"
              cursor="pointer"
              bg={dateFilter === 'next-week' ? CTA_BG_COLOR : 'white'}
              color="black"
              p={3}
            >
              <Text fontWeight={dateFilter === 'next-week' ? 'bold' : 'normal'}>
                Starting next week or later
              </Text>
              {dateFilter === 'next-week' && <Text>✅</Text>}
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              setFilterDateModal(null);
            }}
          >
            Cancel
          </Button>

          <Button
            ml={2}
            bg={CTA_BG_COLOR}
            fontWeight="medium"
            color="black"
            onClick={() => {
              analytics.t('changed filter', { type: 'date', selected: dateFilter });
              compileFilterPredicate();
              setFilterDateModal(null);
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderFilterTimesModal = () => (
    <Modal
      trapFocus={false}
      isOpen
      onClose={() => setFilterTimesModal(null)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent m={3} rounded="lg">
        <ModalHeader>
          <Stack isInline alignItems="center" spacing={2}>
            <Text fontWeight="bold" fontSize="xl">
              Time
            </Text>
            <Text fontSize="sm">({convertTZ(Date.now(), { mode: 'timezone' })})</Text>
          </Stack>
        </ModalHeader>
        <ModalBody>
          <Wrap>
            <WrapItem>
              <Avatar
                onClick={() => onDaySelect('mon')}
                cursor="pointer"
                borderWidth="2px"
                borderColor={dayFilters.includes('mon') ? '' : 'gray.300'}
                name="M"
                bg={dayFilters.includes('mon') ? CTA_BG_COLOR : 'white'}
                color="black"
                fontWeight={dayFilters.includes('mon') ? 'bold' : 'normal'}
                my={2}
              />
            </WrapItem>
            <WrapItem>
              <Avatar
                onClick={() => onDaySelect('tue')}
                cursor="pointer"
                borderWidth="2px"
                borderColor={dayFilters.includes('tue') ? '' : 'gray.300'}
                name="T"
                bg={dayFilters.includes('tue') ? CTA_BG_COLOR : 'white'}
                color="black"
                fontWeight={dayFilters.includes('tue') ? 'bold' : 'normal'}
                my={2}
              />
            </WrapItem>
            <WrapItem>
              <Avatar
                onClick={() => onDaySelect('wed')}
                cursor="pointer"
                borderWidth="2px"
                borderColor={dayFilters.includes('wed') ? '' : 'gray.300'}
                name="W"
                bg={dayFilters.includes('wed') ? CTA_BG_COLOR : 'white'}
                color="black"
                fontWeight={dayFilters.includes('wed') ? 'bold' : 'normal'}
                my={2}
              />
            </WrapItem>
            <WrapItem>
              <Avatar
                onClick={() => onDaySelect('thu')}
                cursor="pointer"
                borderWidth="2px"
                borderColor={dayFilters.includes('thu') ? '' : 'gray.300'}
                name="T"
                bg={dayFilters.includes('thu') ? CTA_BG_COLOR : 'white'}
                color="black"
                fontWeight={dayFilters.includes('thu') ? 'bold' : 'normal'}
                my={2}
              />
            </WrapItem>
            <WrapItem>
              <Avatar
                onClick={() => onDaySelect('fri')}
                cursor="pointer"
                borderWidth="2px"
                borderColor={dayFilters.includes('fri') ? '' : 'gray.300'}
                name="F"
                bg={dayFilters.includes('fri') ? CTA_BG_COLOR : 'white'}
                color="black"
                fontWeight={dayFilters.includes('fri') ? 'bold' : 'normal'}
                my={2}
              />
            </WrapItem>
            <WrapItem>
              <Avatar
                onClick={() => onDaySelect('sat')}
                cursor="pointer"
                borderWidth="2px"
                borderColor={dayFilters.includes('sat') ? '' : 'gray.300'}
                name="S"
                bg={dayFilters.includes('sat') ? CTA_BG_COLOR : 'white'}
                color="black"
                fontWeight={dayFilters.includes('sat') ? 'bold' : 'normal'}
                my={2}
              />
            </WrapItem>
            <WrapItem>
              <Avatar
                onClick={() => onDaySelect('sun')}
                cursor="pointer"
                borderWidth="2px"
                borderColor={dayFilters.includes('sun') ? '' : 'gray.300'}
                name="S"
                bg={dayFilters.includes('sun') ? CTA_BG_COLOR : 'white'}
                color="black"
                fontWeight={dayFilters.includes('sun') ? 'bold' : 'normal'}
                my={2}
              />
            </WrapItem>
          </Wrap>
          <Box py={5}>
            <HStack>
              <Select
                defaultValue={
                  timeFilters ? TIMES[timeFilters[0]].value.toString() : TIMES[0].value.toString()
                }
                onChange={e => {
                  const { value } = e.target;
                  const index = TIMES.map(t => t.value.toString()).indexOf(value);
                  const newFilters = timeFilters || [0, TIMES.length - 1];
                  newFilters[0] = index;
                  setTimeFilters([...newFilters]);
                }}
              >
                {TIMES.map(t => (
                  <option key={t.title} value={t.value}>
                    {t.title}
                  </option>
                ))}
              </Select>
              <Text>to</Text>
              <Select
                defaultValue={
                  timeFilters
                    ? TIMES[timeFilters[1]].value.toString()
                    : TIMES[TIMES.length - 1].value.toString()
                }
                onChange={e => {
                  const { value } = e.target;
                  const index = TIMES.map(t => t.value.toString()).indexOf(value);
                  const newFilters = timeFilters || [0, TIMES.length - 1];
                  newFilters[1] = index;
                  setTimeFilters([...newFilters]);
                }}
              >
                {TIMES.map(t => (
                  <option key={t.title} value={t.value}>
                    {t.title}
                  </option>
                ))}
              </Select>
            </HStack>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              setFilterTimesModal(null);
              setDayFilters(null);
              setTimeFilters(null);
            }}
          >
            Cancel
          </Button>

          <Button
            ml={2}
            bg={CTA_BG_COLOR}
            fontWeight="medium"
            color="black"
            onClick={() => {
              analytics.t('changed filter', {
                type: 'day',
                selected: dayFilters,
                times: timeFilters,
              });
              compileFilterPredicate();
              setFilterTimesModal(null);
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderSchedule = () => {
    return (
      <Flex w="100%" flexDir="column">
        <SimpleGrid
          w="100%"
          minChildWidth={['250px', '300px', '380px', '380px']}
          mt={5}
          columnGap={2}
          rowGap={4}
        >
          {loadedItems &&
            loadedItems.map(item => {
              return (
                <Flex key={item.unit_id} justifyContent="center">
                  <ClassListItem
                    channelTitle="All Classes"
                    onRef={setClassRef}
                    item={item}
                    scrollToItemId={scrollToItem}
                    setCurrentBookingClassData={setCurrentBookingClassData}
                  />
                </Flex>
              );
            })}
        </SimpleGrid>
        {loadedItems && loadedItems.length < displayItems.length && (
          <Button
            style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
            w={['90%', '90%', '90%', '40%']}
            maxW="500px"
            h={['50px', '60px']}
            my={12}
            fontSize="xl"
            alignSelf="center"
            bg="green.400"
            rounded="full"
            fontWeight="bold"
            shadow="lg"
            color="white"
            _hover={{ backgroundColor: 'green.400' }}
            onClick={() => {
              loadMoreItemsAction();
              analytics.t('clicked show more classes button', { loadedItems });
            }}
          >
            {`Show ${LOAD_ITEMS_NUM} More Classes`}
          </Button>
        )}
      </Flex>
    );
  };

  const renderAgeFilterString = () => {
    if (!ageFilters) return null;

    const ageFiltersCount = ageFilters.length;
    if (ageFiltersCount > 3) return `${ageFiltersCount} ages`;
    if (ageFiltersCount > 0 && ageFiltersCount <= 3) {
      let retval = 'Ages ';
      ageFilters.forEach(s => {
        if (ageFilters.indexOf(s) === 0) retval += s;
        else retval += `, ${s}`;
      });
      return retval;
    }
    return 'Any Age';
  };

  const renderSubjectFilterString = () => {
    if (!subjectFilters) return null;

    const subjectFiltersCount = subjectFilters.length;
    if (subjectFiltersCount > 2) return `${subjectFiltersCount} subjects`;
    if (subjectFiltersCount > 0 && subjectFiltersCount <= 2) {
      let retval = '';
      subjectFilters.forEach(s => {
        if (subjectFilters.indexOf(s) === 0) retval += s;
        else retval += `, ${s}`;
      });
      return retval;
    }
    return 'Any Subject';
  };

  const renderTimeFilterString = () => {
    if (!timeFilters) return null;

    let retval = '';

    if (dayFilters.length > 2) retval += `${dayFilters.length} days`;
    else if (dayFilters.length > 0 && dayFilters.length <= 2) {
      dayFilters.forEach(d => {
        if (dayFilters.indexOf(d) === 0) retval += d;
        else retval += `, ${d}`;
      });
    } else {
      retval += 'Any Time';
    }

    if (timeFilters[0] > 0 || timeFilters[1] < TIMES.length - 1) {
      let minTitle = TIMES[timeFilters[0]].title;
      let maxTitle = TIMES[timeFilters[1]].title;

      const minTitleParts = minTitle.split(':00');
      const maxTitleParts = maxTitle.split(':00');

      if (minTitleParts.length === 2) {
        minTitle = minTitleParts[0] + minTitleParts[1];
      }

      if (maxTitleParts.length === 2) {
        maxTitle = maxTitleParts[0] + maxTitleParts[1];
      }

      retval += ` ${minTitle} - ${maxTitle}`;
    }

    return retval;
  };

  // const renderDateFilterString = () => {
  //   if (dateFilter && dateFilter === 'this-week') return 'This week or later';
  //   if (dateFilter && dateFilter === 'next-week') return 'Next week or later';

  //   return 'Any Date';
  // };

  const renderSpinner = () => (
    <Box my={['20%', '10%']} d="flex" justifyContent="center">
      <VStack>
        <Spinner fontSize={['2xl', '2xl', '2xl', '3xl']} mt={4} color="black" />
      </VStack>
    </Box>
  );

  const renderFilterOptions = () => {
    const timeFilteredSelected =
      timeFilters &&
      (timeFilters[0] > 0 || timeFilters[1] < TIMES.length - 1 || dayFilters.length > 0);
    const subjectFilterSelected = subjectFilters && subjectFilters.length > 0;
    const ageFilterSelected = ageFilters && ageFilters.length > 0;

    return (
      <Box overflow="auto" whiteSpace="nowrap" py={3} className="no-scroll-bar">
        <Button
          bg={ageFilterSelected ? '#FFC503' : 'transparent'}
          borderWidth={ageFilterSelected ? '0px' : '1px'}
          borderColor="black"
          color="black"
          fontWeight="bold"
          onClick={() => setFilterAgeModal(true)}
          rounded="full"
          px={4}
          mr={2}
          fontSize={['sm', 'lg']}
          _hover={{
            backgroundColor: '#FFC503',
          }}
        >
          <Text>{renderAgeFilterString()}</Text>
        </Button>
        <Button
          bg={subjectFilterSelected ? '#FFC503' : 'transparent'}
          borderWidth={subjectFilterSelected ? '0px' : '1px'}
          borderColor="black"
          color="black"
          fontWeight="bold"
          onClick={() => setFilterSubjectsModal(true)}
          rounded="full"
          px={4}
          mr={2}
          fontSize={['sm', 'lg']}
          _hover={{
            backgroundColor: '#FFC503',
          }}
        >
          {renderSubjectFilterString()}
        </Button>
        {/* <Button
          bg={timeFilteredSelected ? '#FFC503' : 'transparent'}
          borderWidth={timeFilteredSelected ? '0px' : '1px'}
          borderColor="black"
          color="black"
          fontWeight="bold"
          onClick={() => setFilterTimesModal(true)}
          rounded="full"
          px={4}
          mr={2}
          fontSize={['sm', 'lg']}
          _hover={{
            backgroundColor: '#FFC503',
          }}
        >
          <Text textTransform="capitalize">{renderTimeFilterString()}</Text>
        </Button> */}
        {/* <Button
          bg={dateFilter ? 'white' : 'transparent'}
          borderWidth={dateFilter ? '0px' : '2px'}
          color={dateFilter ? 'purple.500' : 'white'}
          fontWeight="bold"
          onClick={() => setFilterDateModal(true)}
          rounded="full"
          px={4}
          fontSize={['sm', 'lg']}
        >
          {renderDateFilterString()}
        </Button> */}
      </Box>
    );
  };

  const renderPopularMathClasses = () => (
    <LazyClassChannel
      title="🧮 Most Popular Math Classes"
      subtitle="Math classes kids are watching the most"
      items={popularMathItems}
      numMaxItems={maxCarouselItems}
      setCurrentBookingClassData={setCurrentBookingClassData}
    />
  );

  const renderSubjectCarousels = (items, title, subtitle) => (
    <LazyClassChannel
      title={title}
      subtitle={subtitle}
      items={items}
      numMaxItems={maxCarouselItems}
      setCurrentBookingClassData={setCurrentBookingClassData}
    />
  );

  const renderChildClasses = () =>
    childItems.map(item => (
      <LazyClassChannel
        key={item.id}
        title={item.title}
        subtitle={item.subtitle}
        items={item.items}
        numMaxItems={maxCarouselItems}
        setCurrentBookingClassData={setCurrentBookingClassData}
      />
    ));

  const renderClassChannels = () => (
    <Stack my={5} spacing={0} h="100%">
      <Suspense fallback={renderSpinner()}>
        {childItems.length > 0 && renderChildClasses()}
        {popularMathItems.length >= MIN_CHANNEL_ITEMS && renderPopularMathClasses()}
        {popularAnimalItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularAnimalItems,
            '🦒 Popular Animal Classes',
            'Animal classes kids are watching the most',
          )}

        {popularArtItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularArtItems,
            '🎨 Popular Art Classes',
            'Art classes kids are watching the most',
          )}
        {popularSpaceItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularSpaceItems,
            '🚀 Popular Space Classes',
            'Space classes kids are watching the most',
          )}
        {popularDinosaurItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularDinosaurItems,
            '🦖 Popular Dinosaur Classes',
            'Dinosaur classes kids are watching the most',
          )}
        {popularEngineeringItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularEngineeringItems,
            '🛠 Popular Engineering Classes',
            'Engineering classes kids are watching the most',
          )}
        {popularSpanishItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularSpanishItems,
            '🗣 Popular Spanish Classes',
            'Spanish classes kids are watching the most',
          )}
        {popularMarineScienceItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularMarineScienceItems,
            '🐬 Popular Marine Science Classes',
            'Marine science classes kids are watching the most',
          )}
        {popularMedicineItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularMedicineItems,
            '🩺 Popular Medicine Classes',
            'Medicine classes kids are watching the most',
          )}

        {popularEarthScienceItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularEarthScienceItems,
            '🌎 Popular Earth Science Classes',
            'Earth science classes kids are watching the most',
          )}

        {popularCultureSSHistoryItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularCultureSSHistoryItems,
            '👨‍🏫 Popular Social Studies Classes',
            'Social Studies classes kids are watching the most',
          )}

        {popularScienceItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularScienceItems,
            '👩‍🔬 Popular Classes',
            'Classes kids are watching the most',
          )}

        {popularPhysicsChemistryItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularPhysicsChemistryItems,
            '🧪 Popular Physics, Chemistry, and Biology',
            'Popular Physics, Chemistry, and Biology classes kids are watching the most',
          )}

        {popularReadingItems.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularReadingItems,
            '📚 Popular Reading Classes',
            'Popular reading classes kids are watching the most',
          )}

        {popularArt_Advanced.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularArt_Advanced,
            '🖌 Popular Advanced Art Classes',
            'Popular advanced art classes kids are watching the most',
          )}

        {popularArt_Animals.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularArt_Animals,
            '🖌 Popular Animal Art Classes',
            'Popular animal art classes kids are watching the most',
          )}

        {popularArt_CreateYourOwn.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularArt_CreateYourOwn,
            '🖌 Popular Create Your Own Art Classes',
            'Popular create your own art classes kids are watching the most',
          )}

        {popularArt_MythicalCreature.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularArt_MythicalCreature,
            '🖌 Popular Mythical Creature Art Classes',
            'Popular mythical creature art classes kids are watching the most',
          )}

        {popularArt_Pokemon.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularArt_Pokemon,
            '🖌 Popular Pokeman Art Classes',
            'Popular pokeman art classes kids are watching the most',
          )}

        {popularArt_VideoGame.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularArt_VideoGame,
            '🖌 Popular Video Game Art Classes',
            'Popular video game art classes kids are watching the most',
          )}

        {popularArt_Dinosaurs.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularArt_Dinosaurs,
            '🖌 Popular Dinosaur Art Classes',
            'Popular dinosaur art classes kids are watching the most',
          )}

        {popularArt_TVShowMovie.length >= MIN_CHANNEL_ITEMS &&
          renderSubjectCarousels(
            popularArt_TVShowMovie,
            '🖌 Popular TV Show/Movie Art Classes',
            'Popular TV Show/Movie art classes kids are watching the most',
          )}

        {displayItems && renderAllClassesChannel()}
        {!isLoading && displayItems.length === 0 && renderNoItems()}
        {/* {showReferral && profile.referral && renderGiveawaysChannel()} */}
        {/* {mostPopularItems.length > 0 && renderMostPopularChannel()} */}
        {/* {recentlyAddedItems.length > 0 && renderRecentlyAddedClasses()} */}
        {/* {startingSoonItems.length > 0 && renderStartingSoonClasses()} */}
      </Suspense>
    </Stack>
  );

  const renderAllClassesChannel = () => (
    <Box>
      {!isLoading && (
        <>
          <Stack mx={[5, 8]} mt={3} spacing={1}>
            <Text color="black" fontWeight="bold" fontSize={['xl', 'xl', 'xl', '2xl']}>
              ⭐️ All Classes
            </Text>
            <Text opacity="0.6" color="black" fontSize={['md', 'lg']} noOfLines={2}>
              Check out all the classes we offer
            </Text>
          </Stack>
          <Flex px={5} alignItems="center" w="100%" justifyContent="space-between" flexWrap="wrap">
            {renderFilterOptions()}
            <Stack alignItems="center" isInline spacing={2}>
              {/* <Button
                bg="transparent"
                borderWidth="1px"
                rounded="full"
                fontWeight="bold"
                borderColor="black"
                color="black"
                fontSize={['sm', 'lg']}
                my={2}
                onClick={() => setSortModal(true)}
                _hover={{
                  backgroundColor: '#FFC503',
                }}
              >
                <Text fontSize={['sm', 'lg']}>
                  Sort By: {sortMode === 'start-time' ? 'Start Time' : 'Recently Added'}
                </Text>
              </Button> */}
              {authState === AUTH_STATES.AUTHENTICATED && (
                <Button
                  bg={completedFilter === 'show-completed' ? '#FFC503' : 'transparent'}
                  borderWidth={completedFilter === 'show-completed' ? '0px' : '1px'}
                  borderColor="black"
                  color="black"
                  fontWeight="bold"
                  onClick={() => setCompletedFilterModal(true)}
                  rounded="full"
                  fontSize={['sm', 'lg']}
                  _hover={{
                    backgroundColor: '#FFC503',
                  }}
                >
                  <Text>
                    {completedFilter === 'show-completed' ? 'Completed Classes' : 'All Classes'}
                  </Text>
                </Button>
              )}
            </Stack>
          </Flex>
          <Box px={5} w={['100%', '100%', '100%', '50%']}>
            {/* If the show completed filter is on, just return the completed items */}
            <SearchBar
              items={
                completedFilter === 'show-completed'
                  ? displayItems
                  : displayItems.concat(allCompletedItems)
              }
              runFilters={compileFilterPredicate}
              onResult={result => setDisplayItems(result)}
            />
          </Box>
        </>
      )}
      <Flex px={[4, 4, 4, 20]} margin="auto">
        {renderSchedule()}
      </Flex>
    </Box>
  );

  const renderNoItems = () => (
    <VStack>
      <Box bg="white" rounded="lg" p={5}>
        <Text color="#383838" fontSize={['xl', '2xl']} fontWeight="bold" textAlign="center">
          🤔 No Matches
        </Text>
        <Text
          color="gray.400"
          fontWeight="bold"
          fontSize={['sm', 'md']}
          textAlign="center"
          pt={[1, 3]}
        >
          Try changing your filters to see more classes!
        </Text>
      </Box>
    </VStack>
  );

  // UseEffects
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const classId = urlParams.get('class_id');
    if (!classId) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchSchedule(allItems.length > 0);
    };

    fetchData();

    const handleRefreshRequest = (params = {}) => {
      const { silent } = params;
      fetchSchedule();
    };

    window.zip_requestRefresh = handleRefreshRequest;

    const handleRefreshData = async () => {
      await fetchSchedule(true);
    };

    setBookingCompletionHandler(() => handleRefreshData);
  }, []);

  const filterChannelItems = () => {
    // Most Popular
    /* const filteredMostPopular = allItems.filter(
      item => item.rank >= 0 && !item.completed && item.type !== 'pack',
    );

    const mostPopular = filteredMostPopular.sort((a, b) => a.rank - b.rank);
    setMostPopularItems(mostPopular); */

    let ageRange = [];
    if (profile && profile.children) {
      const ages = profile.children.map(c => parseInt(c.age));

      const minAge = Math.min(...ages);
      const maxAge = Math.max(...ages);

      if (minAge && maxAge) {
        ageRange = [minAge, maxAge];
      }
    }

    const sortedItems =
      ageRange.length === 2
        ? allItems
            .sort((a, b) => a.rank - b.rank)
            .filter(i => {
              const itemRange = i.age_range.split('-').map(r => parseInt(r));
              return (
                ((ageRange[0] >= itemRange[0] && ageRange[0] <= itemRange[1]) ||
                  (ageRange[1] >= itemRange[0] && ageRange[1] <= itemRange[1])) &&
                !i.completed
              );
            })
        : allItems.sort((a, b) => a.rank - b.rank).filter(i => !i.completed);

    if (profile && profile.children && allItems.length > 0) {
      const payload = [];
      for (const student of profile.children) {
        const studentClasses = sortedItems.filter(i => {
          const age_range = i.age_range.split('-').flatMap(i => parseInt(i));
          const age = parseInt(student.age);
          return age >= age_range[0] && age <= age_range[1];
        });

        if (studentClasses.length > 0) {
          payload.push({
            id: student.id,
            items: studentClasses.slice(0, maxCarouselItems),
            title: `🧒 Classes For ${student.first_name}`,
            subtitle: "We know they'll love them!",
          });
        }
      }
      setChildItems(payload);
    }

    if (
      profile &&
      profile.children &&
      profile.children.length === 1 &&
      parseInt(profile.children[0].age) >= 10
    ) {
      const filteredPopularScience = sortedItems.filter(item => item.subject !== 'Art');
      const filteredPopularArt = sortedItems.filter(item => item.subject === 'Art');

      setPopularScienceItems(
        filteredPopularScience.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularScience.slice(0, maxCarouselItems)
          : [],
      );

      setPopularArtItems(
        filteredPopularArt.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularArt.slice(0, maxCarouselItems)
          : [],
      );
    } else {
      const filteredPopularMath = sortedItems.filter(
        item => item.type !== 'pack' && item.subject === 'Math',
      );

      const filteredPopularAnimal = sortedItems.filter(
        item => item.subject === 'Marine Science' || item.subject === 'Animals',
      );

      const filteredPopularSpace = sortedItems.filter(item => item.subject === 'Space');
      const filteredPopularDinosaurs = sortedItems.filter(item => item.subject === 'Dinosaurs');
      const filteredPopularEngineering = sortedItems.filter(item => item.subject === 'Engineering');
      const filteredPopularSpanish = sortedItems.filter(
        item => item.subject === 'Spanish' && !item.title.includes('Immersion'),
      );

      const filteredPopularMedicine = sortedItems.filter(item => item.subject === 'Medicine');
      const filteredPopularMarineScience = sortedItems.filter(
        item => item.subject === 'Marine Science',
      );

      const filteredPopularEarthScience = sortedItems.filter(
        item =>
          item.subject === 'Earth Science' ||
          item.subject === 'Plants' ||
          item.subject === 'Insects' ||
          item.subject === 'Geology',
      );

      const filteredPopularPhysicsChemistry = sortedItems.filter(
        item =>
          item.subject === 'Chemistry' || item.subject === 'Physics' || item.subject === 'Biology',
      );

      const filteredPopularCultureSSHistory = sortedItems.filter(
        item =>
          item.subject === 'Culture' ||
          item.subject === 'Social Studies' ||
          item.subject === 'History',
      );

      const filteredPopularReadingClasses = sortedItems.filter(item => item.subject === 'Reading');

      const filteredPopularArt_Animals = sortedItems.filter(
        item => item.subject === 'Art' && item.subcategory === 'Animals',
      );
      const filteredPopularArt_Advanced = sortedItems.filter(
        item => item.subject === 'Art' && item.subcategory === 'Advanced',
      );
      const filteredPopularArt_CreateYourOwn = sortedItems.filter(
        item => item.subject === 'Art' && item.subcategory === 'Create Your Own',
      );
      const filteredPopularArt_Pokemon = sortedItems.filter(
        item => item.subject === 'Art' && item.subcategory === 'Pokemon',
      );
      const filteredPopularArt_TVShowMovie = sortedItems.filter(
        item => item.subject === 'Art' && item.subcategory === 'TV Show/Movie',
      );
      const filteredPopularArt_VideoGame = sortedItems.filter(
        item => item.subject === 'Art' && item.subcategory === 'Video Game',
      );
      const filteredPopularArt_MythicalCreature = sortedItems.filter(
        item => item.subject === 'Art' && item.subcategory === 'Mythical Creatures',
      );
      const filteredPopularArt_Dinosaurs = sortedItems.filter(
        item => item.subject === 'Art' && item.subcategory === 'Dinosaurs',
      );

      setPopularMathItems(
        filteredPopularMath.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularMath.slice(0, maxCarouselItems)
          : [],
      );

      setPopularAnimalItems(
        filteredPopularAnimal.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularAnimal.slice(0, maxCarouselItems)
          : [],
      );
      setPopularSpaceItems(
        filteredPopularSpace.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularSpace.slice(0, maxCarouselItems)
          : [],
      );
      setPopularDinosaurItems(
        filteredPopularDinosaurs.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularDinosaurs.slice(0, maxCarouselItems)
          : [],
      );
      setPopularEngineeringItems(
        filteredPopularEngineering.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularEngineering.slice(0, maxCarouselItems)
          : [],
      );
      setPopularSpanishItems(
        filteredPopularSpanish.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularSpanish.slice(0, maxCarouselItems)
          : [],
      );

      setPopularMarineScienceItems(
        filteredPopularMarineScience.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularMarineScience.slice(0, maxCarouselItems)
          : [],
      );

      setPopularMediceItems(
        filteredPopularMedicine.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularMedicine.slice(0, maxCarouselItems)
          : [],
      );

      setPopularEarthScienceItems(
        filteredPopularEarthScience.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularEarthScience.slice(0, maxCarouselItems)
          : [],
      );

      setPopularPhysicsChemistryItems(
        filteredPopularPhysicsChemistry.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularPhysicsChemistry.slice(0, maxCarouselItems)
          : [],
      );

      setPopularCultureSSHistoryItems(
        filteredPopularCultureSSHistory.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularCultureSSHistory.slice(0, maxCarouselItems)
          : [],
      );

      setPopularReadingItems(
        filteredPopularReadingClasses.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularReadingClasses.slice(0, maxCarouselItems)
          : [],
      );

      setPopularArt_CreateYourOwn(
        filteredPopularArt_CreateYourOwn.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularArt_CreateYourOwn.slice(0, maxCarouselItems)
          : [],
      );

      setPopularArt_Advanced(
        filteredPopularArt_Advanced.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularArt_Advanced.slice(0, maxCarouselItems)
          : [],
      );

      setPopularArt_TVShowMovie(
        filteredPopularArt_TVShowMovie.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularArt_TVShowMovie.slice(0, maxCarouselItems)
          : [],
      );

      setPopularArt_VideoGame(
        filteredPopularArt_VideoGame.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularArt_VideoGame.slice(0, maxCarouselItems)
          : [],
      );

      setPopularArt_MythicalCreature(
        filteredPopularArt_MythicalCreature.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularArt_MythicalCreature.slice(0, maxCarouselItems)
          : [],
      );

      setPopularArt_Pokemon(
        filteredPopularArt_Pokemon.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularArt_Pokemon.slice(0, maxCarouselItems)
          : [],
      );

      setPopularArt_Animals(
        filteredPopularArt_Animals.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularArt_Animals.slice(0, maxCarouselItems)
          : [],
      );

      setPopularArt_Dinosaurs(
        filteredPopularArt_Dinosaurs.length >= MIN_CHANNEL_ITEMS
          ? filteredPopularArt_Dinosaurs.slice(0, maxCarouselItems)
          : [],
      );
    }
  };

  useEffect(() => {
    filterChannelItems();

    // Get all completed items and
    setAllCompletedItems(allItems.filter(item => item.completed));
  }, [allItems]);

  return (
    <>
      {filterSubjectsModal && renderSubjectFilterModal()}
      {filterDateModal && renderFilterDateModal()}
      {filterAgeModal && renderFilterAgeModal()}
      {filterTimesModal && renderFilterTimesModal()}
      {sortModal && renderSortModal()}
      {completedFilterModal && renderCompletedFilterModal()}
      <Box w="100%">
        <Box w="100%">
          {isLoading && renderSpinner()}
          {renderClassChannels()}
        </Box>
      </Box>
    </>
  );
};

export default ClassList;
