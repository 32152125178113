/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Booked from './Booked';
import Header from '../Header';
import Footer from '../Footer';

const BookedContainer = ({ onProfileUpdate, profile, authState }) => {
  return (
    <Box maxW="100%" minH="100%" bg="purple.500">
      <Flex maxW={['xs', 'sm', 'md', 'lg']} flexDir="column" alignItems="center" mx="auto">
        <Booked onProfileUpdate={onProfileUpdate} profile={profile} />
      </Flex>
    </Box>
  );
};

export default BookedContainer;
