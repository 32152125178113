import { useState, useEffect } from 'react';
import { isiOSNativeDevice } from '@utils';

const useNativeCallback = () => {
  // State
  const [response, setResponse] = useState(null);

  // Actions
  const zipSchoolCallbackAction = res => {
    setResponse(res);
  };

  // UseEffects
  useEffect(() => {
    if (isiOSNativeDevice) {
      window.zipschool_callback = zipSchoolCallbackAction;
    }
  }, []);

  return response;
};

export default useNativeCallback;
