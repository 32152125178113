/* eslint-disable react/prop-types */
import React, { useEffect, useState, Suspense } from 'react';
import { Text, Stack, Box, Spinner, HStack, VStack, useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import actions from '@actions';
import { analytics, shuffle, showErrorToast, showSuccessToast } from '@utils';

const LazyClassChannel = React.lazy(() => import('@components/ClassChannel'));

const NextClass = ({ zipClass, student = {} }) => {
  // Local Properties
  const zipClassTitle = zipClass && zipClass.zipClass ? zipClass.zipClass.title : 'class';

  // State Properties
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState([]);
  const [maxCarouselItems, setMaxCarouselItems] = useState(10);
  const [popularClasses, setPopularClasses] = useState(null);
  const [teacherClasses, setTeacherClasses] = useState(null);
  const [subjectClasses, setSubjectClasses] = useState(null);
  const [startingSoonClasses, setStartingSoonClasses] = useState(null);

  // Hooks
  const toast = useToast();
  const history = useHistory();

  // Actions
  const onBookClass = ({ class_id, title }) => {
    analytics.t('clicked book on post class', { class_id, title });
    history.push(`/schedule?class_id=${class_id}`);
  };

  const handleLikeClass = async classId => {
    try {
      const liked = await actions.toggleLike(classId);
      showSuccessToast(toast, 'Nice! Your class is ready to watch later 🎉');
      return liked;
    } catch (err) {
      console.warn(err);
      showErrorToast(toast, err.message);
    }
  };

  // Render Methods
  const renderSpinner = () => (
    <VStack pt={8} alignItems="center" justifyContent="center" h="40vh" textAlign="center">
      <Spinner size="xl" thickness={4} speed="0.95s" emptyColor="gray.300" color="purple.500" />
    </VStack>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await actions.fetchSchedule();
        let { items = [] } = response;
        items = items.filter(i => !i.completed);

        // Filter out classes that don't have a preview here.
        // At this point there are no resources associated which means you can't watch the class now
        items = items.filter(item => item.resource_data);

        const { max_carousel_items: _maxCarouselItems = 15 } = response;
        if (_maxCarouselItems) {
          setMaxCarouselItems(_maxCarouselItems);
        }

        setPopularClasses(
          shuffle(items.sort((a, b) => a.rank - b.rank)).slice(0, _maxCarouselItems),
        );
        setTeacherClasses(
          shuffle(
            items.filter(
              i => i.teacher && zipClass.teacher && i.teacher.id === zipClass.teacher.id,
            ),
          ),
        );
        setSubjectClasses(shuffle(items.filter(i => i.subject === zipClass.subject)));
        setStartingSoonClasses(items.filter(i => i.starting_soon));
        setClasses(items);
      } catch (err) {
        history.push('/schedule');
      }
      setLoading(false);
    };

    fetchData();
    analytics.t('viewed post class');
  }, [zipClass]);

  return (
    <Box w="100%" color="white">
      <VStack w="100%" h="94%">
        {loading && renderSpinner()}
        {!loading && zipClass && (
          <Box h="100%" w="100%">
            <Box py={[5, 20]} px={4} minH="30%" textAlign="center">
              <VStack w="100%">
                <HStack maxW="800px">
                  <Text
                    style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
                    fontSize={['xl', '4xl']}
                    fontWeight="bold"
                  >
                    <span style={{ textShadow: 'none', marginRight: '8px' }}>✅</span>
                    Thanks for attending "{zipClassTitle}
                    ".
                  </Text>
                </HStack>
                <HStack maxW="600px">
                  <Text
                    style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
                    color="gray.200"
                    fontSize={['md', 'lg']}
                  >
                    ZipSchool offers 100's of classes, but not all of them are available to watch
                    every week.
                  </Text>
                </HStack>
              </VStack>
            </Box>
            <Box w="100%" minH="70%" mx="auto" bg="purple.500">
              {classes.length === 0 && (
                <Text
                  color="white"
                  fontSize={['xl', '2xl']}
                  animation="fadeIn 0.5s"
                  textAlign="center"
                  mt={5}
                  maxW="800px"
                  mx="auto"
                >
                  Great job! You've taken all of our classes. Check back soon for more classes in
                  the future 🚀
                </Text>
              )}
              {classes.length > 0 && (
                <Stack spacing={4}>
                  <Suspense fallback={renderSpinner()}>
                    {popularClasses.length > 0 && (
                      <LazyClassChannel
                        title={`👋  Popular Classes ${student.first_name} Will Enjoy`}
                        subtitle={`Handpicked classes specifically for ${student.first_name}!`}
                        isDark
                        items={popularClasses}
                        numMaxItems={maxCarouselItems}
                        mode="afterClass"
                        setCurrentBookingClassData={onBookClass}
                        onLikeClass={handleLikeClass}
                      />
                    )}

                    {teacherClasses.length > 0 && (
                      <LazyClassChannel
                        title={`🎉 More Classes With ${
                          zipClass.teacher
                            ? `Teacher ${zipClass.teacher.first_name}`
                            : 'This Teacher'
                        }`}
                        subtitle={`Had fun in class with ${
                          zipClass.teacher
                            ? `Teacher ${zipClass.teacher.first_name}`
                            : 'This Teacher'
                        }? See more classes they are teaching!`}
                        isDark
                        items={teacherClasses}
                        numMaxItems={maxCarouselItems}
                        mode="afterClass"
                        setCurrentBookingClassData={onBookClass}
                        onLikeClass={handleLikeClass}
                      />
                    )}

                    {subjectClasses.length > 0 && (
                      <LazyClassChannel
                        title={`🔎 More Classes Related To ${zipClass.subject}`}
                        subtitle={`Check out more classes related to ${zipClass.subject}`}
                        isDark
                        items={subjectClasses}
                        numMaxItems={maxCarouselItems}
                        mode="afterClass"
                        setCurrentBookingClassData={onBookClass}
                        onLikeClass={handleLikeClass}
                      />
                    )}
                  </Suspense>
                </Stack>
              )}
            </Box>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default NextClass;
