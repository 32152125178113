/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  Text,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react';
import { HamburgerIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import { AUTH_STATES, CDN_HOSTNAME } from '@constants';
import { analytics, isiOSNativeDevice } from '@utils';

const Header = ({ onRouteChange, profile, authState, onBack, headerRef, ...props }) => {
  // Hooks
  const location = useLocation();
  const history = useHistory();
  const [isMobileDevice] = useMediaQuery('(max-width: 768px)');

  // Local Properties
  const isNoAuthSignup =
    location.pathname.startsWith('/noauth') && authState === AUTH_STATES.AUTHENTICATED;

  // Actions
  const shouldRenderNavItems = () => {
    if (isiOSNativeDevice()) return false;

    const hasChildren =
      location.pathname.startsWith('/rewards') &&
      profile &&
      profile.children.length !== 0 &&
      profile.children[0].age;

    const shouldRender =
      hasChildren ||
      location.pathname.endsWith('/waiting') ||
      location.pathname.endsWith('/classes') ||
      location.pathname.endsWith('/schedule') ||
      location.pathname.endsWith('/profile') ||
      location.pathname.endsWith('/saved') ||
      (location.pathname.includes('live') && location.pathname.includes('next')) ||
      (location.pathname.startsWith('/courses') && authState === AUTH_STATES.AUTHENTICATED);
    return shouldRender;
  };

  const getButtonStyle = () => {
    // We are on schedule page
    if (
      location.pathname.startsWith('/classes') ||
      location.pathname.startsWith('/courses') ||
      isNoAuthSignup
    ) {
      return {
        activeBG: '#FFC503',
        inActiveBG: '#EBEBEB',
        borderColor: 'black',
        textColor: 'black',
      };
    }

    return { activeBG: 'white' };
  };

  const openCoursesLink = () => {
    analytics.t('courses: clicked open courses link in banner');
    window.open('https://zipschool.com');
  };

  const openAndroidWaitlist = () => {
    analytics.t('clicked join android waitlist');
    window.open('https://zipschool.typeform.com/to/jFXkfY3j', '_blank');
  };

  // Render Methods
  const renderScheduleItem = () => (
    <Link to="/courses">
      <Button
        bg={
          location.pathname.startsWith('/classes') ||
          location.pathname.startsWith('/courses') ||
          isNoAuthSignup
            ? '#FFC503'
            : 'transparent'
        }
        border="1px"
        borderColor={getButtonStyle().borderColor}
        color={
          location.pathname.startsWith('/classes') ||
          location.pathname.startsWith('/courses') ||
          isNoAuthSignup
            ? 'black'
            : 'white'
        }
        fontWeight={
          location.pathname.startsWith('/classes') ||
          location.pathname.startsWith('/courses') ||
          isNoAuthSignup
            ? 'bold'
            : 'normal'
        }
        rounded="full"
        _hover={{
          backgroundColor:
            location.pathname.startsWith('/classes') ||
            location.pathname.startsWith('/courses') ||
            isNoAuthSignup
              ? '#FFC503'
              : '#EBEBEB',
        }}
      >
        <Text fontSize={['xs', 'md']}>Courses</Text>
      </Button>
    </Link>
  );

  const renderBookedItem = () => (
    <Link to="/booked">
      <Button
        bg={location.pathname.startsWith('/booked') ? 'white' : 'transparent'}
        border={location.pathname.startsWith('/booked') ? 'none' : '1px'}
        borderColor={getButtonStyle().borderColor}
        color={location.pathname.startsWith('/booked') ? 'black' : getButtonStyle().textColor}
        fontWeight={location.pathname.startsWith('/booked') ? 'bold' : 'normal'}
        rounded="full"
        _hover={{
          backgroundColor: location.pathname.startsWith('/booked') ? '#FFC503' : '#EBEBEB',
        }}
      >
        <Text fontSize={['xs', 'md']}>Booked</Text>
      </Button>
    </Link>
  );

  const renderSavedItem = () => (
    <Link to="/schedule">
      <Button
        bg={location.pathname.startsWith('/schedule') ? 'white' : 'transparent'}
        border={location.pathname.startsWith('/schedule') ? 'none' : '1px'}
        borderColor={getButtonStyle().borderColor}
        color={location.pathname.startsWith('/schedule') ? 'black' : getButtonStyle().textColor}
        fontWeight={location.pathname.startsWith('/schedule') ? 'bold' : 'normal'}
        rounded="full"
        _hover={{
          backgroundColor: location.pathname.startsWith('/schedule') ? '#FFC503' : '#EBEBEB',
        }}
      >
        <Text fontSize={['xs', 'md']}>Schedule</Text>
      </Button>
    </Link>
  );

  const renderRewardsItem = () => (
    <Link to="/rewards">
      <Button
        bg={location.pathname.startsWith('/rewards') ? 'white' : 'transparent'}
        border={location.pathname.startsWith('/rewards') ? 'none' : '1px'}
        borderColor={
          location.pathname.startsWith('/rewards') ? 'none' : getButtonStyle().borderColor
        }
        color={location.pathname.startsWith('/rewards') ? 'black' : getButtonStyle().textColor}
        fontWeight={location.pathname.startsWith('/rewards') ? 'bold' : 'normal'}
        rounded="full"
        _hover={{
          backgroundColor: location.pathname.startsWith('/rewards') ? '#FFC503' : '#EBEBEB',
        }}
      >
        <Text fontSize={['xs', 'md']}>Rewards</Text>
      </Button>
    </Link>
  );

  const renderProfileItem = () => (
    <Link to="/profile">
      <Button
        bg={location.pathname.startsWith('/profile') ? 'white' : 'transparent'}
        border={location.pathname.startsWith('/profile') ? 'none' : '1px'}
        borderColor={
          location.pathname.startsWith('/profile') ? 'none' : getButtonStyle().borderColor
        }
        color={location.pathname.startsWith('/profile') ? 'black' : getButtonStyle().textColor}
        fontWeight={location.pathname.startsWith('/profile') ? 'bold' : 'normal'}
        rounded="full"
        _hover={{
          backgroundColor: location.pathname.startsWith('/profile') ? '#FFC503' : '#EBEBEB',
        }}
      >
        <Text fontSize={['xs', 'md']}>Profile</Text>
      </Button>
    </Link>
  );

  const renderHamburgerMenu = () => (
    <Menu size="2xl">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        size="lg"
        borderRadius="md"
        variant="outline"
        color={
          location.pathname.includes('/classes') ||
          location.pathname.includes('/courses') ||
          isNoAuthSignup
            ? 'black'
            : 'white'
        }
        _hover={{ color: 'black', backgroundColor: 'gray.100' }}
        _active={{ color: 'black', backgroundColor: 'gray.100' }}
      />
      <MenuList zIndex="3">
        {/* Classes */}
        {/* <MenuItem onClick={() => history.push('/classes') || isNoAuthSignup}>
          <Text
            fontWeight={
              location.pathname.startsWith('/classes') || isNoAuthSignup ? 'bold' : 'normal'
            }
            color="black"
          >
            Classes
          </Text>
        </MenuItem> */}

        {/* Courses */}
        <MenuItem onClick={() => history.push('/courses')}>
          <Text
            fontWeight={
              location.pathname.startsWith('/courses') || isNoAuthSignup ? 'bold' : 'normal'
            }
            color="black"
          >
            Courses
          </Text>
        </MenuItem>

        {/* Profile / Rewards  */}
        {isiOSNativeDevice() ? (
          <MenuItem onClick={() => history.push('/rewards')}>
            <Text
              fontWeight={location.pathname.startsWith('/rewards') ? 'bold' : 'normal'}
              color="black"
            >
              Rewards
            </Text>
          </MenuItem>
        ) : (
          <MenuItem onClick={() => history.push('/profile')}>
            <Text
              fontWeight={location.pathname.startsWith('/profile') ? 'bold' : 'normal'}
              color="black"
            >
              Profile
            </Text>
          </MenuItem>
        )}
      </MenuList>

      {/* Schedule */}
      {/* <MenuItem onClick={() => history.push('/schedule')}>
          <Text
            fontWeight={location.pathname.startsWith('/schedule') ? 'bold' : 'normal'}
            color="black"
          >
            Schedule
          </Text>
        </MenuItem> */}
    </Menu>
  );

  const renderDownloadBanner = () => (
    <Flex
      bg="#FFC503"
      w="100%"
      minH="60px"
      flexDir={isMobileDevice ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems="center"
      p={[3, 5]}
    >
      <Stack
        textAlign={isMobileDevice ? 'center' : 'inherit'}
        maxW={isMobileDevice ? '100%' : '60%'}
      >
        <Text fontWeight="bold" fontSize={['md', 'md', 'lg']}>
          📚 Say Hello to ZipSchool Courses!
        </Text>
        <Text fontSize={['md', 'md', 'lg']}>
          Enroll in amazing, multi-week courses to get the most out of ZipSchool! Available for all
          devices.
        </Text>
      </Stack>
      <Stack isInline pt={isMobileDevice ? 3 : 0}>
        <Button
          size={isMobileDevice ? 'sm' : 'md'}
          colorScheme="purple"
          shadow="lg"
          onClick={openCoursesLink}
        >
          Enroll Now!
        </Button>
      </Stack>
    </Flex>
  );

  // UseEffects
  useEffect(() => {
    history.listen(() => {
      if (onRouteChange) {
        onRouteChange(history.location.pathname);
      }
    });
  }, []);

  useEffect(() => {
    if (onRouteChange) {
      onRouteChange(history.location.pathname);
    }
  }, []);

  return (
    <Box position="relative" zIndex="3" ref={headerRef}>
      <Sticky
        disabled={
          location.pathname.includes('/live') || location.pathname.includes('/signup-swipe')
        }
        stickyStyle={{
          zIndex: 1,
          backgroundColor:
            location.pathname === '/classes' ||
            location.pathname === '/noauth/classes' ||
            location.pathname.includes('/courses')
              ? '#F5F5F5'
              : '#805ad5',
          boxShadow: '0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%)',
        }}
      >
        <Flex
          as="nav"
          bg={location.pathname.includes('/courses') ? '#F5F5F5' : 'transparent'}
          align="center"
          justify="space-between"
          wrap="wrap"
          pt={[5, 8]}
          pb={[3, 4]}
          px={[5, 8]}
          color="white"
          {...props}
        >
          <HStack>
            {onBack && (
              <Box zIndex="2">
                <IconButton
                  aria-label="Back button"
                  icon={<ChevronLeftIcon />}
                  size={('md', 'lg')}
                  fontSize={['25px', '30px']}
                  isRound
                  bg="gray.300"
                  color="gray.700"
                  onClick={onBack}
                />
              </Box>
            )}
            <Image
              maxW="75%"
              cursor="pointer"
              onClick={() => {
                if (!isiOSNativeDevice()) {
                  if (authState === AUTH_STATES.AUTHENTICATED) {
                    history.push('/courses');
                  } else {
                    window.location = 'https://zipschool.com';
                  }
                }
              }}
              src={`${CDN_HOSTNAME}/icons/${
                location.pathname === '/classes' ||
                location.pathname === '/noauth/classes' ||
                location.pathname.includes('/courses')
                  ? 'zipschool-logo-dark.svg'
                  : 'zipschool-logo-light.svg'
              }`}
            />
          </HStack>

          {shouldRenderNavItems() && !isMobileDevice && (
            <HStack spacing={[1, 2]} mt={2}>
              {renderScheduleItem()}
              {/* {renderSavedItem()} */}
              {renderProfileItem()}
            </HStack>
          )}
          {shouldRenderNavItems() && isMobileDevice && renderHamburgerMenu()}
        </Flex>
        {location.pathname.includes('/classes') && renderDownloadBanner()}
        {/* <Flex w="100%" justifyContent="center" pt={1} pb={3}>
          {enableFilters && inAllClassesSection && renderFilterOptions()}
        </Flex> */}
      </Sticky>
    </Box>
  );
};

export default Header;
