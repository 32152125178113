/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Saved from './Saved';

const SavedContainer = ({
  onProfileUpdate,
  profile,
  authState,
  setCurrentBookingClassData,
  setBookingCompletionHandler,
}) => {
  return (
    <Box maxW="100%" minH="100%" bg="purple.500">
      <Flex maxW={['xs', 'sm', 'md', 'lg']} flexDir="column" alignItems="center" mx="auto">
        <Saved
          onProfileUpdate={onProfileUpdate}
          profile={profile}
          setCurrentBookingClassData={setCurrentBookingClassData}
          setBookingCompletionHandler={setBookingCompletionHandler}
        />
      </Flex>
    </Box>
  );
};

export default SavedContainer;
