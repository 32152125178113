/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import { Box, Flex, Text, Button, Spinner, useToast, useBreakpointValue } from '@chakra-ui/react';
import actions from '@actions';
import { analytics, showErrorToast } from '@utils';
import Classmash from './Classmash';
import ActionModal from './components/ActionModal';
import { modalActions } from '../constants';

const ClassmashContainer = ({ onAuthStateChange }) => {
  // Hooks
  const toast = useToast();
  const deviceType = useBreakpointValue({
    base: 'mobile',
    md: 'mobile',
    lg: 'desktop',
    xl: 'desktop',
  });

  // State
  const [fetchingClasses, setFetchingClasses] = useState(false);
  const [classItems, setClassItems] = useState(null);
  const [cardRefMap, setCardRefMap] = useState({});
  const [currentCardId, setCurrentCardId] = useState('');
  const [openActionModal, setOpenActionModal] = useState(false);
  const [modalAction, setModalAction] = useState(modalActions.WELCOME);
  const [classMatch, setClassMatch] = useState(null);
  const [isSwiping, setIsSwiping] = useState(false);

  // Actions
  const fetchClassesAction = async () => {
    setFetchingClasses(true);

    try {
      const { items = [] } = await actions.fetchSchedule();

      // Sort Most popular items
      const allClasses = items.filter(item => item.type !== 'pack');
      let mostPopularClasses = allClasses.sort((a, b) => a.rank - b.rank);

      // Grab all the items starting soon and remove from current array
      const filteredStartingSoon = mostPopularClasses.filter((c, index) => {
        if (c.starting_soon) {
          mostPopularClasses.splice(index, 1);
        }

        return c.starting_soon;
      });

      // Move all starting soon items to the beginning of the list
      mostPopularClasses = [...filteredStartingSoon, ...mostPopularClasses];
      setClassItems(mostPopularClasses);
      setFetchingClasses(false);
    } catch (error) {
      console.warn('Error Fetching Classes: ', error);
      showErrorToast(toast, error.message);
    }
  };

  const fetchClasses = async () => {
    await fetchClassesAction();
  };

  const getCurrentItem = () => {
    return classItems ? classItems.find(c => c.class_id === currentCardId) : null;
  };

  const onCardRef = useCallback(
    (id, ref) => {
      if (ref) {
        if (!cardRefMap[id]) {
          setCardRefMap(prevState => ({ ...prevState, [id]: ref }));
        }
      }
    },
    [cardRefMap],
  );

  const swipeAction = direction => async () => {
    if (cardRefMap[currentCardId]) {
      try {
        await cardRefMap[currentCardId].swipe(direction);
      } catch (error) {
        console.warn(error);
      }
    }
  };

  // RenderMethods
  const renderSwipeButtons = () => (
    <Flex
      mx="auto"
      maxW="550px"
      position="relative"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Button
        w="72px"
        h="72px"
        bg="#566573CC"
        border="2px"
        borderColor="white"
        rounded="full"
        _hover={{ color: 'black', backgroundColor: '#566573B3', fontWeight: 'bold' }}
        onClick={swipeAction('left')}
        isLoading={isSwiping && !classMatch}
      >
        <Text fontSize={['3xl', '3xl', '3xl', '4xl']}>👎</Text>
      </Button>
      <Button
        w="72px"
        h="72px"
        bg="#566573CC"
        border="2px"
        borderColor="white"
        rounded="full"
        _hover={{ color: 'black', backgroundColor: '#566573B3', fontWeight: 'bold' }}
        onClick={swipeAction('right')}
        isLoading={isSwiping && !classMatch}
      >
        <Text fontSize={['3xl', '3xl', '3xl', '4xl']}>👍</Text>
      </Button>
    </Flex>
  );

  const renderSpinner = () => (
    <Flex w="100%" height="100%" justifyContent="center" alignItems="center">
      <Spinner size="xl" color="white" />
    </Flex>
  );

  // UseEffects
  useEffect(() => {
    analytics.t('hit swipe onboarding page');

    // Fetch Classes
    fetchClasses();

    // Open Welcome Modal
    setOpenActionModal(true);
  }, []);

  useEffect(() => {
    if (currentCardId) {
      setIsSwiping(false);
    }
  }, [currentCardId]);

  useEffect(() => {
    if (!classMatch) {
      setIsSwiping(false);
    }
  }, [classMatch]);

  return (
    <>
      <ActionModal
        deviceType={deviceType}
        action={modalAction}
        isOpen={openActionModal}
        onClose={setOpenActionModal}
        setClassMatch={setClassMatch}
        classData={getCurrentItem()}
      />
      <Box mx="auto" w="100%" position="relative">
        <Box mx="auto" maxW="450px" h={['410px', '450px', '500px', '550px']} position="relative">
          {fetchingClasses && renderSpinner()}
          {!fetchingClasses && (
            <Classmash
              onAuthStateChange={onAuthStateChange}
              items={classItems}
              onCardRef={onCardRef}
              setCurrentCardId={setCurrentCardId}
              setOpenMatchModal={setOpenActionModal}
              setModalAction={setModalAction}
              classMatch={classMatch}
              setClassMatch={setClassMatch}
              setIsSwiping={setIsSwiping}
            />
          )}
        </Box>
        {renderSwipeButtons()}
      </Box>
    </>
  );
};

export default ClassmashContainer;
