/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Flex } from '@chakra-ui/react';

const PopupModal = ({ children }) => {
  return (
    <Flex
      style={{
        position: 'fixed',
        bottom: '0px',
        left: '0px',
        zIndex: '2',
      }}
      bg="#D2D2D2BF"
      w="100%"
      h="100%"
      alignItems="flex-end"
    >
      <Flex bg="white" roundedTop="xl" h={['80%', '80%', '85%', '95%']} w="100%" overflow="scroll">
        {children}
      </Flex>
    </Flex>
  );
};

export default memo(PopupModal);
