export const onboardingSteps = {
  LOGIN_OR_SIGNUP: 'login_or_signup',
  ADD_CHILD: 'add_child',
  PET_SELECT: 'pet_select',
  CREDS: 'creds',
};

export const childAgeOptions = [
  { id: '3', name: '3', bracket: '3-6' },
  { id: '4', name: '4', bracket: '3-6' },
  { id: '5', name: '5', bracket: '3-6' },
  { id: '6', name: '6', bracket: '3-6' },
  { id: '7', name: '7', bracket: '7-9' },
  { id: '8', name: '8', bracket: '7-9' },
  { id: '9', name: '9', bracket: '7-9' },
  { id: '10', name: '10', bracket: '10-12' },
  { id: '11', name: '11', bracket: '10-12' },
  { id: '12', name: '12', bracket: '10-12' },
];

export const zipWhiteBG = '#F5F5F5';

export const cardMaxWidth = ['340px', '350px', '400px', '400px'];
