/* eslint-disable consistent-return */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { ExtraErrorData } from '@sentry/integrations';
import './index.css';
import 'video.js/dist/video-js.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from './App';
import * as serviceWorker from './serviceWorker';

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        fontFamily: 'Circular, sans-serif',
      },
    },
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 700,
    black: 800,
  },
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    autoSessionTracking: true,
    dsn: 'https://0b9bd1d1c447458e993f8f6be75603a0@o462836.ingest.sentry.io/5466798',
    release: process.env.REACT_APP_VERSION,
    integrations: [new Integrations.BrowserTracing(), new ExtraErrorData()],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'Failed to load Stripe.js',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    beforeSend(event, hint) {
      // filter out UnhandledRejection errors that have no information
      if (
        event !== undefined &&
        event.exception !== undefined &&
        event.exception.values !== undefined &&
        event.exception.values.length === 1
      ) {
        const e = event.exception.values[0];
        if (
          e.type === 'UnhandledRejection' &&
          e.value === 'Non-Error promise rejection captured with value: '
        ) {
          return null;
        }
      }

      return event;
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
