/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import '../App.css';
import {
  Spinner,
  Button,
  Text,
  Stack,
  HStack,
  Box,
  useToast,
  Flex,
  IconButton,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import actions from '@actions';
import { showErrorToast, analytics } from '@utils';
import { useHistory } from 'react-router-dom';
import { CDN_HOSTNAME } from '@constants';
import ProfileContext from '../Context/ProfileContext';

const Rewards = ({ setHeaderProfile }) => {
  // State
  // const { handleSubmit, errors, register, formState } = useForm();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [redeemables, setRedeemables] = useState([]);
  const [selectedRedeemableIndex, setSelectedRedeemableIndex] = useState(0);
  const [avatarLoading, setAvatarLoading] = useState(false);

  const toast = useToast();
  const history = useHistory();
  const profileContext = useContext(ProfileContext);
  const avatarWidth = useBreakpointValue({
    base: '200px',
    md: '150px',
    lg: '200px',
    xl: '200px',
  });

  // Actions
  const fetchProfile = async updatingProfile => {
    if (!updatingProfile) {
      setLoading(true);
    }

    try {
      const { profile: fetchedProfile = {}, redeemables = [] } = await actions.fetchProfile(false);
      setHeaderProfile(fetchedProfile);
      setProfile(fetchedProfile);
      setRedeemables(redeemables);
    } catch (err) {
      showErrorToast(toast, err.message);
    }

    setLoading(false);
  };

  const fetchData = async () => {
    await fetchProfile(false);
  };

  const onRedeem = async () => {
    const redeemable = redeemables[selectedRedeemableIndex];
    setLoading(true);

    if (redeemable.redeemed) {
      try {
        analytics.t('equipped redeemable', { id: redeemable.id });
        await actions.updateRedemption('equip', { redeemable_id: redeemable.id });
        await fetchProfile(true);
      } catch (err) {
        showErrorToast(toast, err.message);
      }
    } else {
      try {
        analytics.t('redeemed redeemable', { id: redeemable.id });
        await actions.updateRedemption('redeem', { redeemable_id: redeemable.id });
        await fetchProfile(true);
      } catch (err) {
        showErrorToast(toast, err.message);
      }
    }

    setLoading(false);
  };

  const onNext = () => {
    analytics.t('navigate next pet');

    setSelectedRedeemableIndex(prevIndex => {
      if (prevIndex === redeemables.length - 1) return prevIndex;
      return prevIndex + 1;
    });
  };

  const onPrevious = () => {
    analytics.t('navigate previous pet');

    setSelectedRedeemableIndex(prevIndex => {
      if (prevIndex === 0) return prevIndex;
      return prevIndex - 1;
    });
  };

  const renderCharacter = () => {
    const redeemable = redeemables[selectedRedeemableIndex];
    if (!redeemable) return null;

    return (
      <Box color="black" w="100%" alignSelf="center" p={4} rounded="lg" animation="fadeIn 0.4s">
        <Stack>
          <video
            onLoadStart={() => setAvatarLoading(true)}
            onPlay={() => setAvatarLoading(false)}
            loop
            muted
            autoPlay
            playsInline
            src={redeemable.url}
            style={{ display: avatarLoading ? 'none' : 'block', height: avatarWidth }}
          />
          {avatarLoading && (
            <Box d="flex" h={avatarWidth} justifyContent="center">
              <Spinner fontSize={['2xl', '2xl', '2xl', '3xl']} mt={4} color="white" />
            </Box>
          )}
          <Stack>
            {redeemable.redeemed && (
              <Button
                isLoading={loading}
                disabled={loading || redeemable.active}
                onClick={onRedeem}
                color="white"
                rounded="lg"
                h="50px"
                bg={redeemable.active ? 'green.500' : 'purple.500'}
              >
                {redeemable.active ? '✅ Current Pet' : 'Choose Pet'}
              </Button>
            )}
            {!redeemable.redeemed && (
              <Button
                isLoading={loading}
                disabled={loading}
                onClick={onRedeem}
                rounded="lg"
                color={redeemable.amount === 0 ? 'white' : 'black'}
                h="50px"
                bg={redeemable.amount === 0 ? 'purple.500' : 'rgb(255, 197, 3)'}
              >
                {redeemable.amount === 0
                  ? 'Choose Pet'
                  : `🔒 Unlock for ${redeemable.amount} stars`}
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>
    );
  };

  // UseEffects
  useEffect(() => {
    analytics.t('viewed rewards');
    fetchData();
  }, []);

  const renderControlBar = () => (
    <Flex
      className="native-inset-left"
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      position="absolute"
    >
      <IconButton
        aria-label="Back button"
        icon={<ChevronLeftIcon />}
        size={('md', 'lg')}
        fontSize={['25px', '30px']}
        isRound
        bg="gray.300"
        color="gray.700"
        onClick={() => history.push('/classes-mobile')}
      />
    </Flex>
  );

  return (
    <VStack
      backgroundImage={`url(${CDN_HOSTNAME}/native/background-images/main.jpeg)`}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      w="100%"
      h="100vh"
      overflow="scroll"
      pt={8}
      pl={3}
    >
      {/* Content */}
      {renderControlBar()}

      <Stack w={['xs', 'md', 'md', 'lg']}>
        <Box>
          <Text textAlign="center" color="white" fontWeight="bold" fontSize="2xl">
            {profileContext &&
            profileContext.data &&
            profileContext.data.rewards &&
            profileContext.data.rewards.balance > 0
              ? `⭐️ Hey ${profileContext.data.children[0].first_name}, you have ${profileContext.data.rewards.balance} stars!`
              : `⭐️ Hey ${profileContext.data.children[0].first_name}, change your pet!`}
          </Text>
          <Text textAlign="center" color="white">
            Earn stars by completing lessons
          </Text>
        </Box>

        <HStack spacing={5}>
          <IconButton
            disabled={loading || selectedRedeemableIndex === 0}
            zIndex="3"
            aria-label="Back button"
            icon={<ChevronLeftIcon />}
            size={('md', 'lg')}
            fontSize={['25px', '30px']}
            isRound
            bg="gray.300"
            color="gray.700"
            onClick={onPrevious}
          />
          {renderCharacter()}
          <IconButton
            disabled={loading || selectedRedeemableIndex === redeemables.length - 1}
            zIndex="3"
            aria-label="Back button"
            icon={<ChevronRightIcon />}
            size={('md', 'lg')}
            fontSize={['25px', '30px']}
            isRound
            bg="gray.300"
            color="gray.700"
            onClick={onNext}
          />
        </HStack>
      </Stack>
    </VStack>
  );
};

export default Rewards;
