import React, { useEffect } from 'react';
import {
  Box,
  Text,
  Button,
  Input,
  FormErrorMessage,
  FormControl,
  useToast,
  Stack,
  VStack,
  Divider,
} from '@chakra-ui/react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { showErrorToast, analytics, isiOSNativeDevice, navigateTo } from '@utils';
import { Auth } from 'aws-amplify';

const SignIn = ({ handleSignIn }) => {
  const { handleSubmit, errors, register, formState } = useForm();
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    analytics.t('viewed sign-in');
  }, []);

  const validateEmail = value => {
    let error;
    if (!value) {
      error = 'Email is required';
    }
    return error || true;
  };

  const validatePassword = value => {
    let error;
    if (!value) {
      error = 'Password is required';
    }
    return error || true;
  };

  const signUp = e => {
    e.preventDefault();
    history.push('/signup');
  };

  const onLoginErr = err => {
    if (err) showErrorToast(toast, err.message);
  };

  return (
    <Stack
      className="native-inset-top"
      mx="auto"
      mt={3}
      px={[3, 3, 0]}
      maxW="md"
      textAlign="center"
    >
      <Box p={[4, 4, 10]} rounded="lg" borderWidth="1px" bg="white">
        <Text fontSize="lg">Sign-In Below</Text>
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit(data => handleSignIn(data, onLoginErr))}
        >
          <FormControl mt="2rem" isInvalid={errors.email}>
            <Text fontWeight="bold">Email</Text>
            <Input
              disabled={formState.isSubmitting}
              name="email"
              placeholder="Email"
              borderRadius="4px"
              h="70px"
              fontSize="lg"
              textAlign="center"
              ref={register({ validate: validateEmail })}
            />
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>
          <FormControl mt={5} isInvalid={errors.password}>
            <Text fontWeight="bold">Password</Text>
            <Input
              disabled={formState.isSubmitting}
              name="password"
              placeholder="Password"
              type="password"
              textAlign="center"
              borderRadius="4px"
              h="70px"
              ref={register({ validate: validatePassword })}
            />
            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
          </FormControl>
          <Text mt={2} fontSize="xs" textAlign="right">
            Forgot your password?{' '}
            <Link style={{ textDecoration: 'underline' }} to="/forgot">
              Reset
            </Link>
          </Text>
          <VStack>
            <Button
              mt={4}
              backgroundColor="green.500"
              color="white"
              isLoading={formState.isSubmitting}
              type="submit"
              onClick={handleSubmit(data => handleSignIn(data, onLoginErr))}
              w="100%"
              fontSize="lg"
              fontWeight="bold"
              rounded="lg"
              h="50px"
              style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
            >
              Log In
            </Button>

            {/* <Button
              mt={6}
              backgroundColor="purple.500"
              isLoading={formState.isSubmitting}
              color="white"
              onClick={() => {
                if (isiOSNativeDevice()) {
                  navigateTo(history, '/signup-native');
                } else {
                  navigateTo(history, '/signup?step=add_parent_name_phone');
                }
              }}
              w="100%"
              fontSize="lg"
              fontWeight="bold"
              rounded="lg"
              h="50px"
              style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
            >
              Sign Up
            </Button> */}
            {/* <Divider />
            <Button
              mt={6}
              colorScheme="black"
              w="233px"
              h="50px"
              backgroundImage={
                'url(https://appleid.cdn-apple.com/appleid/button?&type=continue&scale=4&border_radius=3)'
              }
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              onClick={() => {
                analytics.t('clicked continue with apple');
                window.location.href = `https://${
                  process.env.REACT_APP_COGNITO_DOMAIN
                }/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=${encodeURIComponent(
                  `https://${window.location.host}/oauth`,
                )}&response_type=code&client_id=${
                  process.env.REACT_APP_COGNITO_CLIENT_ID
                }&scope=${encodeURIComponent('email openid')}`;
              }}
            /> */}
          </VStack>
        </form>
      </Box>
    </Stack>
  );
};

export default SignIn;
