/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Image, Text, Button, VStack } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { CDN_HOSTNAME } from '@constants';
import { analytics, navigateTo } from '@utils';
import { onboardingSteps } from '../../constants';

const Signup = ({ setCurrentStep }) => {
  // Hooks
  const history = useHistory();

  // Actions
  const navigateToLogin = () => {
    analytics.t('clicked log in on native onboarding');
    navigateTo(history, '/login');
  };

  const signupWithAppleAction = () => {
    analytics.t('clicked cotinue with apple in native onboarding');
    window.location = `https://${
      process.env.REACT_APP_COGNITO_DOMAIN
    }/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=${encodeURIComponent(
      `https://${window.location.host}/oauth`,
    )}&response_type=code&client_id=${
      process.env.REACT_APP_COGNITO_CLIENT_ID
    }&scope=${encodeURIComponent('email openid')}`;
  };

  const signupWithEmailAction = () => {
    analytics.t('clicked signup with email in native onboarding');
    setCurrentStep(onboardingSteps.ADD_EMAIL_PASSWORD);
  };

  // UseEffects
  useEffect(() => {
    analytics.t('viewed signup page on native onboarding');
    window.scroll(0, 0);
  }, []);

  return (
    <VStack className="native-inset-top" px={[3, 5]} pb={[3, 5]} animation="fadeIn 0.4s">
      <Box p={3}>
        <Image cursor="pointer" src={`${CDN_HOSTNAME}/icons/zipschool-logo-light.svg`} />
      </Box>
      <Text fontSize="xl" fontWeight="bold" color="white" textAlign="center">
        Let your child explore. Watch their knowledge grow ✨
      </Text>
      <Box className="mobile-border-radius" rounded="xl">
        <video playsInline autoPlay loop style={{ width: '100%', height: '100%' }}>
          <source src="https://s3.amazonaws.com/assets.zipschool.com/videos/signup-video.mp4" />
        </video>
      </Box>
      <VStack py={4} w="100%" color="white">
        <Text>✅ Recorded mini-courses for kids K-5.</Text>
        <Text>✅ Topics they'll go nuts for 🚀🐬🐯🦄.</Text>
        <Text>✅ Math and reading skills bundled in.</Text>
      </VStack>
      <VStack w="100%">
        <Button
          colorScheme="yellow"
          w={['85%', '85%', '75%', '70%']}
          h="50px"
          bg="rgb(255, 197, 3)"
          fontSize="lg"
          leftIcon={<EmailIcon />}
          rounded="full"
          onClick={signupWithEmailAction}
        >
          Sign up with email
        </Button>
        <Button
          colorScheme="black"
          w="233px"
          h="50px"
          mt={6}
          backgroundImage="url(https://appleid.cdn-apple.com/appleid/button?&type=continue&scale=4)"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          rounded="full"
          onClick={signupWithAppleAction}
        />
      </VStack>
      <Box w="100%" p={3}>
        <Text textAlign="center" color="white" onClick={navigateToLogin}>
          Already have an account?{' '}
          <Box
            as="span"
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Log in.
          </Box>
        </Text>
      </Box>
    </VStack>
  );
};

export default Signup;
