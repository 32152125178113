/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { Flex, Text, Stack, Box, useBreakpointValue } from '@chakra-ui/react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import ClassListItemNative from '../ClassListItemNative';
import constants from '../constants';

const ClassChannelNative = ({ title, subtitle, items, isDark, mode, child }) => {
  // Local Properties
  const styleProps = {
    textColor: isDark ? 'white' : 'black',
    textOpacity: isDark ? '0.7' : '0.6',
    borderColor: isDark ? 'gray.700' : 'transparent',
    buttonBGColor: isDark ? 'purple.400' : 'purple.400',
    buttonHoverColor: isDark ? 'purple.200' : 'gray.300',
  };

  // Hooks
  const channelHeight = useBreakpointValue({
    base: 200,
    md: 200,
    lg: 350,
    xl: 350,
  });

  const channelItemWidth = useBreakpointValue({
    base: 220,
    md: 220,
    lg: 270,
    xl: 320,
  });

  // References
  const channelItemContainer = useRef(null);

  // Render Methods
  const renderHeader = () => (
    <Stack spacing={1}>
      <Text color={styleProps.textColor} fontWeight="bold" fontSize={['xl', 'xl', 'xl', '2xl']}>
        {title}
      </Text>
      <Text
        opacity={styleProps.textOpacity}
        color={styleProps.textColor}
        fontSize={['md', 'lg']}
        noOfLines={2}
      >
        {subtitle}
      </Text>
    </Stack>
  );

  const virtualizedCellItem = ({ index, style }) => {
    const item = items[index];

    return (
      <ClassListItemNative
        key={item.id}
        style={style}
        channelTitle={title}
        item={item}
        // If the item is a nextClassAction use that mode
        mode={item.mode ? item.mode : mode}
        isChannelItem
        campaignId={item.mode === constants.MODES.NEXT_CLASS_ACTION ? item.campaign_id : item.id}
        child={child}
      />
    );
  };

  const renderVirtualizedList = () => (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <List
            className="no-scroll-bar"
            outerRef={channelItemContainer}
            useIsScrolling
            width={width}
            height={height}
            itemCount={items.length}
            itemSize={channelItemWidth}
            layout="horizontal"
          >
            {virtualizedCellItem}
          </List>
        );
      }}
    </AutoSizer>
  );

  const renderShadow = () => (
    <Box
      position="absolute"
      h={channelHeight}
      right="0"
      top="0"
      zIndex="4"
      width="40px"
      style={{ boxShadow: ' inset -10px 0px 12px -10px #000000' }}
    />
  );

  return (
    <Box w="100%" h="100%" animation="fadeIn 0.4s">
      {renderHeader()}
      <Box h={channelHeight}>
        {items.length > 0 && renderVirtualizedList()}
        {items.length > 1 && renderShadow()}
      </Box>
    </Box>
  );
};

export default ClassChannelNative;
