/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Flex,
  useToast,
  Text,
  Stack,
  IconButton,
  Button,
  Divider,
  Wrap,
  WrapItem,
  HStack,
  Select,
  Input,
  FormErrorMessage,
  FormControl,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { allCountries } from 'country-telephone-data';
import { countryCodeEmoji } from 'country-code-emoji';
import { useForm } from 'react-hook-form';
import Sticky from 'react-sticky-el';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import actions from '@actions';
import { analytics, navigateTo, showErrorToast, showSuccessToast } from '@utils';
import { AUTH_STATES, AGES } from '@constants';
import ChildItem from './components/ChildItem';
import BookingItem from './components/BookingItem';
import { isiOSNativeDevice } from '@utils/index';

const STEPS = {
  WATCH_NOW_OR_BOOK: 'watch_now_or_book',
  PACK_OVERVIEW: 'pack_overview',
  SELECT_TIMESLOTS: 'select_timeslots',
  MULTI_CHILD_SELECT: 'multi_child_select',
  BOOKING_SUCCESS: 'booking_success',
  ADD_CHILD: 'add_child',
  CREATE_PROFILE: 'create_profile',
};
const BADGE_BORDER_COLOR = '#A0AEC0';
const BUTTON_BG_COLOR = '#F0F0F0';
const HOVER_COLOR = 'gray.300';

const BookingModal = ({
  deviceType,
  setCurrentBookingClassData,
  classData,
  profile,
  fetchProfile,
  fetchSchedule,
  authState,
  showBookingSuccess,
  fetchAuthState,
  completionHandler,
}) => {
  // Local Properties
  const isDesktop = deviceType === 'desktop';
  const styleProps = {
    headerFontSize: isDesktop ? '3xl' : ['lg', 'lg', 'xl'],
    closeButtonSize: isDesktop ? '50px' : '48px',
    closeButtonsFontSize: isDesktop ? '18px' : ['12px', '12px', '14px'],
    timeSlotContainerMaxHeight: ['250px', '275px'],
    listPadding: isDesktop ? 0 : 20,
    scrollBottomPadding: isDesktop ? '50px' : '60px',
  };
  const { metadata = {}, times = [], days = [], timezone, booking_id: bookingId } = classData || {};

  const {
    description,
    id: classId,
    teacher,
    slots = [],
    title,
    subject,
    subject_icon: subjectIcon,
    age_range: ageRange,
    rank: classRank,
    channelTitle,
    type,
    classes = [],
    step,
  } = metadata;

  const urlParams = new URLSearchParams(window.location.search);
  const queryEmail = urlParams.get('email');
  const decodedEmail = queryEmail ? decodeURIComponent(queryEmail) : '';
  const referralCode = urlParams.get('code');
  const decodedReferralCode = referralCode ? decodeURIComponent(referralCode) : undefined;

  // Hooks
  const history = useHistory();

  // State
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDay, setSelectedDay] = useState(days.length > 0 ? days[0].value : null);

  const [selectedChildId, setSelectedChildId] = useState(null);
  const [bookingResults, setBookingsResults] = useState(null);
  const [bookingClass, setBookingClass] = useState(false);
  const [addingPhoneNumber, setAddingPhoneNumber] = useState(false);
  const [filteredTimes, setFilteredTimes] = useState(times);

  const [profileData, setProfileData] = useState({});
  const [currentStep, setCurrentStep] = useState(
    type === 'pack' ? STEPS.PACK_OVERVIEW : STEPS.WATCH_NOW_OR_BOOK,
  );

  // References
  const scrollContainer = useRef(null);

  // UseEffects
  useEffect(() => {
    if (deviceType !== 'desktop' && scrollContainer && scrollContainer.current) {
      disableBodyScroll(scrollContainer.current);
    }

    if (step) {
      setCurrentStep(step);
    }

    return () => clearAllBodyScrollLocks();
  }, []);

  useEffect(() => {
    if (selectedDay) {
      const day = days.find(d => d.value === selectedDay);
      setFilteredTimes(day.times || times);
    }
  }, [selectedDay]);

  // Hooks
  const toast = useToast();
  const form = useForm({ defaultValues: { email: decodedEmail } });
  const watchAllFields = form.watch();

  // Actions
  const handleBookClass = async () => {
    setBookingClass(true);

    try {
      if (authState === AUTH_STATES.NEEDS_AUTH) {
        await actions.createProfile(
          {
            ...profileData,
          },
          decodedReferralCode,
          'noauth',
        );
      }

      let res;
      if (bookingId) {
        const { booking } = await actions.rescheduleBooking(bookingId, {
          student_id: selectedChildId,
          time: selectedTime,
          day: selectedDay,
        });
        res = booking;
      } else {
        const { booking } = await actions.bookDropInClass(classId, {
          student_id: selectedChildId,
          time: selectedTime,
          day: selectedDay,
        });
        res = booking;

        analytics.t('added to schedule', {
          classTitle: booking.class_title,
          classTime: booking.timestamp_str,
          teacherName: booking.teacher_name,
          unitId: booking.unit_booking_id,
          classId: booking.class_id,
          slotId: booking.slot_id,
          childAge: booking.child_age,
          subject,
          channel: { rank: classRank + 1, type: channelTitle },
        });
      }

      setCurrentStep(STEPS.BOOKING_SUCCESS);

      if (completionHandler) {
        await completionHandler(res);
      }
    } catch (err) {
      console.warn(err);
      showErrorToast(toast, err.message);
    }
    setBookingClass(false);
  };

  const onViewSchedule = () => {
    // Push to live with ClassId
    setCurrentBookingClassData(null);
    navigateTo(history, `/schedule`);
  };

  useEffect(() => {
    if (showBookingSuccess) setCurrentStep(STEPS.BOOKING_SUCCESS);
  }, [showBookingSuccess]);

  useEffect(() => {
    if (selectedTime) {
      analytics.t('selected time', { title, time: selectedTime });
    }
  }, [selectedTime]);

  useEffect(() => {
    if (selectedDay) {
      analytics.t('selected day', { title, day: selectedDay });
    }
  }, [selectedDay]);

  const confirmBookingsAction = data => {
    // Check to see if we need to move onto the child select view
    if (authState === AUTH_STATES.NEEDS_AUTH) {
      if (currentStep === STEPS.PACK_OVERVIEW) {
        analytics.t('clicked next on pack overview noauth');
        setCurrentStep(STEPS.SELECT_TIMESLOTS);
      } else if (currentStep === STEPS.SELECT_TIMESLOTS) {
        analytics.t('clicked next on times no auth');
        setCurrentStep(STEPS.ADD_CHILD);
      } else if (currentStep === STEPS.ADD_CHILD) {
        const { child_age: childAge, child_first_name: childFirstName } = data;
        analytics.t('clicked next on add child no auth', { age: childAge });
        profileData.child_age = childAge;
        profileData.child_first_name = childFirstName;
        setProfileData({ ...profileData });
        setCurrentStep(STEPS.CREATE_PROFILE);
      } else if (currentStep === STEPS.CREATE_PROFILE) {
        const { email, password, first_name: firstName, last_name: lastName } = data;
        analytics.t('clicked next create parent profile no auth', { email });
        profileData.email = email;
        profileData.password = password;
        profileData.first_name = firstName;
        profileData.last_name = lastName;
        setProfileData({ ...profileData });
        handleBookClass();
      }
    } else if (profile && profile.children.length > 1 && currentStep === STEPS.SELECT_TIMESLOTS) {
      analytics.t('navigated to student selection in booking flow');
      setCurrentStep(STEPS.MULTI_CHILD_SELECT);
    } else if (currentStep === STEPS.PACK_OVERVIEW) {
      analytics.t('clicked next on pack overview');
      setCurrentStep(STEPS.SELECT_TIMESLOTS);
    } else if (currentStep === STEPS.SELECT_TIMESLOTS || currentStep === STEPS.MULTI_CHILD_SELECT) {
      analytics.t('clicked confirm bookings', { numberOfTimes: 1 });
      handleBookClass();
    }
  };

  const sortAndFilterCountryCodes = () => {
    const filteredCountries = allCountries.filter(country => {
      if (country.dialCode === '1' && country.name !== 'United States') return false;

      return true;
    });

    return filteredCountries.sort((a, b) => {
      return a.dialCode - b.dialCode;
    });
  };

  const onSavePhone = async data => {
    setAddingPhoneNumber(true);

    try {
      await actions.updateProfile(data, 'update');
      analytics.t('added phone from booking confirmation');

      await fetchProfile();
      setCurrentBookingClassData(null);
      showSuccessToast(toast, '✅ Phone number updated!');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setAddingPhoneNumber(false);
  };

  // eslint-disable-next-line consistent-return
  const isNextButtonDisabled = () => {
    if (
      currentStep === STEPS.ADD_CHILD &&
      (!watchAllFields.child_first_name || !watchAllFields.child_age)
    )
      return true;
    if (
      currentStep === STEPS.CREATE_PROFILE &&
      (!watchAllFields.first_name ||
        !watchAllFields.last_name ||
        !watchAllFields.password ||
        !watchAllFields.email)
    )
      return true;
    if (currentStep === STEPS.MULTI_CHILD_SELECT) return !selectedChildId;
  };

  // Render Methods
  const renderCardBadge = (badgeContent, fontWeight) => (
    <Badge
      variant="subtle"
      textTransform="capitalize"
      rounded="full"
      fontSize={['xs', 'sm', 'sm', 'sm']}
      fontWeight={fontWeight}
      paddingX={3}
      paddingY={2}
      background="white"
      borderWidth="1px"
      borderColor={BADGE_BORDER_COLOR}
    >
      {badgeContent}
    </Badge>
  );

  const renderTeacher = () => (
    <Badge
      borderColor={BADGE_BORDER_COLOR}
      borderWidth="1px"
      rounded="full"
      bg="white"
      variant="subtle"
      textTransform="capitalize"
      px={3}
      py={2}
    >
      <Flex alignItems="center" justifyContent="center">
        <Avatar size="xs" src={teacher.image_url} />
        <Text fontSize="xs" fontWeight="bold" color="black" pl={2}>
          {teacher.name}
        </Text>
      </Flex>
    </Badge>
  );

  const renderBadgeSection = () => (
    <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap" pt={2}>
      <Wrap mt={2}>
        <WrapItem>{renderCardBadge(`Ages: ${ageRange}`)}</WrapItem>
        <WrapItem>{renderCardBadge(`${subject}`, 'regular')}</WrapItem>
      </Wrap>
      <Box mt={2}>{teacher && renderTeacher()}</Box>
    </Flex>
  );

  const renderTitleAndCloseButton = () => {
    let modalTitle;
    let descripton;
    if (currentStep === STEPS.WATCH_NOW_OR_BOOK) {
      modalTitle = '⏰ Choose A Time';
      descripton =
        'Life is busy! Add this video to your schedule for later when your kiddo is available to watch.';
    } else if (currentStep === STEPS.PACK_OVERVIEW) {
      modalTitle = `${subjectIcon} ${title}`;
    } else if (currentStep === STEPS.SELECT_TIMESLOTS) {
      modalTitle = '🤔 Any time work for you?';
      descripton =
        type === 'pack'
          ? '⏰ Twice a week for 2 weeks. Easily reschedule if needed.'
          : `${slots.length} times available for this class. You can always cancel!`;
    } else if (currentStep === STEPS.MULTI_CHILD_SELECT) {
      modalTitle = '🦄 Just one more thing!';
      descripton =
        "You can have as many students as you'd like watch the class, but we just need to know the name of one!";
    } else if (currentStep === STEPS.BOOKING_SUCCESS) {
      modalTitle = bookingId ? '✅ Rescheduled' : '✅ Added To Your Schedule!';
      descripton = bookingId
        ? `You're all set! Your schedule is now updated.`
        : `Nice! We sent a confirmation email to ${profile ? profile.email : profileData.email}`;
    } else if (currentStep === STEPS.CREATE_PROFILE) {
      modalTitle = '🚀 Finish Your Parent Profile';
      descripton = 'All your bookings are under one account. No card required. Free.';
    } else if (currentStep === STEPS.ADD_CHILD) {
      modalTitle = '⭐️ Who is attending class?';
      descripton =
        "We just need to know your child's first name and age to book them in this class.";
    }

    return (
      <>
        <Stack spacing={1}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text w="80%" fontSize={styleProps.headerFontSize} fontWeight="bold">
              {modalTitle}
            </Text>
            <IconButton
              w={styleProps.closeButtonSize}
              h={styleProps.closeButtonSize}
              mt={1}
              mr={1}
              fontSize={styleProps.closeButtonsFontSize}
              rounded="full"
              shadow="lg"
              variant="solid"
              bg={BUTTON_BG_COLOR}
              aria-label="Close Button"
              icon={<CloseIcon />}
              onClick={() => {
                setCurrentBookingClassData(null);
              }}
            />
          </Flex>
          <Text w="85%" opacity="0.6" fontSize={['sm', 'md']}>
            {descripton}
          </Text>
        </Stack>
        {currentStep === STEPS.BOOKING_SUCCESS && <Divider />}
      </>
    );
  };

  const renderTitleAndDescription = () => (
    <Box my={3}>
      <Stack spacing={2}>
        {type !== 'pack' && (
          <Text fontSize={styleProps.headerFontSize}>
            {subjectIcon || ''} {title}
          </Text>
        )}
        {deviceType === 'desktop' && renderBadgeSection()}
        {description && deviceType === 'desktop' && (
          <Text opacity="0.6" fontSize={['sm', 'md']}>
            {description}
          </Text>
        )}
      </Stack>
    </Box>
  );

  const renderPackOverView = () => (
    <Box my={3}>
      <Text w="85%" fontSize={['sm', 'md']}>
        This pack has <b>{classes.length} classes</b> that each last 30 minutes and happen{' '}
        <b>twice a week for 2 weeks.</b>
      </Text>
      <UnorderedList mt={4} spacing={2}>
        {classes.map(c => {
          return (
            <ListItem key={c.order}>
              <HStack>
                <Text w={c.completed ? '55%' : '100%'} fontSize={['sm', 'md']} key={c.title}>
                  Class {c.order + 1}: {c.subject_icon} {c.title}
                </Text>
                {c.completed && (
                  <Text
                    w="45%"
                    fontSize={['sm', 'md']}
                    fontWeight="bold"
                    color="gray.600"
                    textAlign="right"
                  >
                    ( ✅ Completed )
                  </Text>
                )}
              </HStack>
            </ListItem>
          );
        })}
      </UnorderedList>
    </Box>
  );

  const renderMultiChildSelect = () => (
    <Stack spacing={2} pb={styleProps.listPadding}>
      <Stack className="no-scroll-bar" overflowY="scroll" spacing={2}>
        {profile.children.map(child => (
          <ChildItem
            key={child.id}
            child={child}
            selectedChildId={selectedChildId}
            setSelectedChildId={setSelectedChildId}
          />
        ))}
      </Stack>
    </Stack>
  );

  const renderConfirmBookingSection = () => {
    let ctaText = 'Next';

    if (authState === AUTH_STATES.NEEDS_AUTH && currentStep === STEPS.CREATE_PROFILE) {
      ctaText = 'Finish!';
    } else if (
      authState === AUTH_STATES.AUTHENTICATED &&
      currentStep === STEPS.SELECT_TIMESLOTS &&
      profile &&
      profile.children.length === 1
    ) {
      ctaText = 'Book!';
    } else if (
      authState === AUTH_STATES.AUTHENTICATED &&
      currentStep === STEPS.MULTI_CHILD_SELECT
    ) {
      ctaText = 'Watch Now!';
    }
    return (
      <Stack
        className="sticky-bottom-bar no-scroll-bar"
        isInline
        spacing={2}
        justifyContent="flex-end"
        py={3}
      >
        {/* {currentStep === STEPS.MULTI_CHILD_SELECT && (
          <Button
            onClick={() => {
              analytics.t('clicked back button in booking flow');
              setCurrentStep(STEPS.WATCH_NOW_OR_BOOK);
            }}
            h={['55px', '60px']}
            rounded="full"
            borderWidth="1px"
            fontWeight="bold"
            color="white"
            bg="gray.600"
            style={{
              backgroundBlendMode: 'soft-light, normal',
            }}
            _hover={{ backgroundColor: isDesktop ? HOVER_COLOR : 'gray.600' }}
          >
            <Text fontSize={['md', 'md', 'lg']}>Back</Text>
          </Button>
        )} */}
        <Button
          onClick={handleBookClass}
          // onClick={form.handleSubmit(confirmBookingsAction)}
          h={['55px', '60px']}
          minW="100px"
          rounded="full"
          borderWidth="1px"
          fontWeight="bold"
          fontSize={['md', 'md', 'lg']}
          color="black"
          bg="#FFC503"
          isDisabled={isNextButtonDisabled()}
          isLoading={bookingClass}
          style={{
            backgroundBlendMode: 'soft-light, normal',
          }}
          _hover={{ backgroundColor: isDesktop ? HOVER_COLOR : '#FFC503' }}
        >
          <Text>{ctaText}</Text>
        </Button>
      </Stack>
    );
  };

  const renderPhoneNumberInput = () => (
    <Stack spacing={2} pt={3}>
      <Text fontSize={['lg', 'xl']}>Want to receive text message reminders?</Text>
      <Text opacity="0.6" fontSize={['sm', 'md']}>
        We only use your phone number to send you updates about your booked classes! Msg & data
        rates may apply
      </Text>

      {/* Phone Form  */}
      <Flex w="100%" flexDir="column">
        <Text fontWeight="medium">Phone number</Text>
        <HStack>
          <Select
            name="country_code"
            rounded="lg"
            fontSize="md"
            ref={form.register()}
            maxW={['45%', '35%', '30%', '25%']}
          >
            {sortAndFilterCountryCodes().map(country => {
              if (country.dialCode === '1' && country.name !== 'United States') return null;

              return (
                <option key={country.name} value={country.dialCode}>
                  {countryCodeEmoji(country.iso2)} +{country.dialCode}
                </option>
              );
            })}
          </Select>
          <Input
            name="phone"
            placeholder="Phone number"
            type="tel"
            rounded="lg"
            fontSize="md"
            ref={form.register()}
          />
        </HStack>
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.phone && form.errors.phone.message}
        </FormErrorMessage>
      </Flex>

      {/* Action Buttons */}
      <Stack isInline spacing={2} my={4} justifyContent="flex-end">
        <Button
          onClick={() => {
            setCurrentBookingClassData(null);
          }}
          isDisabled={addingPhoneNumber}
          h={['45px', '55px']}
          rounded="full"
          borderWidth="1px"
          fontSize="lg"
          fontWeight="bold"
          color="white"
          bg="gray.600"
          style={{
            backgroundBlendMode: 'soft-light, normal',
          }}
          _hover={{ backgroundColor: isDesktop ? HOVER_COLOR : 'gray.600' }}
        >
          <Text fontSize={['sm', 'sm', 'md', 'lg']}>No thanks</Text>
        </Button>
        <Button
          onClick={form.handleSubmit(onSavePhone)}
          isDisabled={!watchAllFields.phone}
          isLoading={addingPhoneNumber}
          h={['45px', '55px']}
          rounded="full"
          borderWidth="1px"
          fontSize="lg"
          fontWeight="bold"
          color="black"
          bg="#FFC503"
          style={{
            backgroundBlendMode: 'soft-light, normal',
          }}
          _hover={{ backgroundColor: isDesktop ? HOVER_COLOR : '#FFC503' }}
        >
          <Text fontSize={['sm', 'sm', 'md', 'lg']}>Save</Text>
        </Button>
      </Stack>
    </Stack>
  );

  const renderCompleteButtons = () => (
    <Stack spacing={2} pt={3}>
      {/* <Text fontSize={['sm', 'lg']}>Click the "See Bookings" button below to join class.</Text> */}
      <Stack isInline spacing={2} justifyContent="flex-end">
        <Button
          onClick={onViewSchedule}
          h={['45px', '55px']}
          rounded="full"
          borderWidth="1px"
          fontSize="lg"
          fontWeight="bold"
          color="white"
          bg="gray.600"
          style={{
            backgroundBlendMode: 'soft-light, normal',
          }}
          _hover={{ backgroundColor: HOVER_COLOR }}
        >
          <Text fontSize={['sm', 'sm', 'md', 'lg']}>View Schedule</Text>
        </Button>
        <Button
          onClick={() => {
            setCurrentBookingClassData(null);
          }}
          h={['45px', '55px']}
          rounded="full"
          borderWidth="1px"
          fontSize="lg"
          fontWeight="bold"
          color="black"
          bg="#FFC503"
          style={{
            backgroundBlendMode: 'soft-light, normal',
          }}
          _hover={{ backgroundColor: isDesktop ? HOVER_COLOR : '#FFC503' }}
        >
          <Text fontSize={['sm', 'sm', 'md', 'lg']}>Got it!</Text>
        </Button>
      </Stack>
    </Stack>
  );

  const renderCompleteBookingContent = () => (
    <Stack spacing={1}>
      {/* <Stack overflowY="scroll" maxH={styleProps.timeSlotContainerMaxHeight} spacing={2}>
        {bookingResults.map(booking => (
          <BookingItem
            key={booking.id}
            id={booking.id}
            timeslot={booking.timestamp}
            isBookingResult
          />
        ))}
      </Stack> */}
      {authState === AUTH_STATES.NEEDS_AUTH ||
        (profile && !profile.phone && renderPhoneNumberInput())}
      {profile && profile.phone && renderCompleteButtons()}
    </Stack>
  );

  const renderAddChildContent = () => (
    <Stack spacing={2} my={3}>
      {/* Phone Form  */}
      <FormControl isInvalid={form.errors.child_first_name}>
        <Text fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          Child First Name
        </Text>
        <Input
          name="child_first_name"
          placeholder="Child First Name"
          type="text"
          h="60px"
          rounded="lg"
          fontSize="md"
          isRequired
          ref={form.register({ required: true })}
        />
        <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
          {form.errors.child_first_name && 'First name required'}
        </FormErrorMessage>
      </FormControl>
      <FormControl>
        <Text fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
          Child Age
        </Text>
        <Select
          name="child_age"
          placeholder="Select age"
          variant="filled"
          rounded="lg"
          h="60px"
          spacing={3}
          cursor="pointer"
          ref={form.register({ required: true })}
        >
          {AGES.map(t => (
            <option key={t.toString()}>{t}</option>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );

  const renderCreateProfileContent = () => (
    <Stack spacing={2} pb={styleProps.listPadding}>
      {/* Phone Form  */}
      <Stack py={4} spacing={3}>
        <FormControl>
          <Text fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
            Your First Name
          </Text>

          <Input
            name="first_name"
            placeholder="Your First Name"
            type="text"
            h="60px"
            rounded="lg"
            fontSize="md"
            isRequired
            ref={form.register({ required: true })}
          />
          <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
            {form.errors.first_name && 'First name required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <Text fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
            Your Last Name
          </Text>

          <Input
            name="last_name"
            placeholder="Your Last Name"
            type="text"
            h="60px"
            rounded="lg"
            fontSize="md"
            isRequired
            ref={form.register({ required: true })}
          />
          <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
            {form.errors.last_name && 'Last name required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <Text fontSize={['md', 'lg']} fontWeight="bold" pb={1}>
            Email
          </Text>

          <Input
            name="email"
            placeholder="Email"
            type="text"
            h="60px"
            rounded="lg"
            fontSize="md"
            isRequired
            ref={form.register({ required: true })}
          />
          <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
            {form.errors.email && 'Email required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <Text fontSize={['md', 'lg']} fontWeight="bold">
            Password
          </Text>
          <Text fontSize="sm" color="gray.600" pb={1}>
            Must be at least 8 characters
          </Text>

          <Input
            name="password"
            placeholder="Password"
            type="password"
            h="60px"
            rounded="lg"
            fontSize="md"
            isRequired
            ref={form.register({ required: true })}
          />
          <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
            {form.errors.password && 'Password required'}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </Stack>
  );

  const renderDaySelect = () => (
    <Box w="100%">
      <Text fontWeight="bold">Day</Text>
      <Select
        h={['45px', '50px', '55px', '60px']}
        rounded="lg"
        onChange={e => setSelectedDay(e.target.value)}
      >
        {days.map(time => (
          <option key={time.value} value={time.value}>
            {time.title}
          </option>
        ))}
      </Select>
    </Box>
  );

  const renderTimeSelect = () => (
    <Box w="100%">
      <HStack>
        <Text fontWeight="bold">Time</Text>
        <Text fontSize="sm" color="gray.500">
          ({timezone})
        </Text>
      </HStack>
      <Select
        h={['45px', '50px', '55px', '60px']}
        rounded="lg"
        onChange={e => setSelectedTime(e.target.value)}
      >
        <option selected disabled>
          Select A Time
        </option>
        {filteredTimes.map(time => (
          <option key={time.value} value={time.value}>
            {time.title}
          </option>
        ))}
      </Select>
    </Box>
  );

  const renderWatchOrBook = () => (
    <VStack w="100%" h="100%" p={6} spacing={5}>
      {renderDaySelect()}
      {renderTimeSelect()}
      <VStack w="100%">
        <Button
          isDisabled={!selectedDay || !selectedTime}
          onClick={handleBookClass}
          colorScheme="purple"
          shadow="md"
          w="75%"
          h={['45px', '55px']}
          bg="purple.500"
          color="white"
          rounded="full"
          textAlign="center"
          fontWeight="bold"
          isLoading={bookingClass}
        >
          <Text fontSize={['md', 'lg']}>✅ Confirm</Text>
        </Button>
        <Text textAlign="center" opacity="0.6" fontSize={['sm', 'md']}>
          We'll send you a reminder 1 hour, and 10 minutes before class
        </Text>
      </VStack>
      {/* <VStack w="100%">
        <Button
          colorScheme="gray"
          shadow="md"
          w="75%"
          h={['35px', '45px']}
          bg="gray.200"
          color="black"
          rounded="full"
          textAlign="center"
          fontWeight="bold"
          fontSize={['sm', 'md']}
        >
          <Text>Watch Later</Text>
        </Button>
        <Text textAlign="center" opacity="0.6" fontSize={['sm', 'md']}>
          Kiddo busy? Schedule this class for a later time!
        </Text>
      </VStack> */}
    </VStack>
  );

  const renderBottomCTA = () =>
    deviceType === 'desktop' ? (
      <Sticky
        mode="bottom"
        stickyStyle={{
          backgroundColor: 'white',
        }}
      >
        {currentStep !== STEPS.BOOKING_SUCCESS && <Divider />}
        {currentStep !== STEPS.BOOKING_SUCCESS && renderConfirmBookingSection()}
      </Sticky>
    ) : (
      <Box
        zIndex="2"
        className="list-shadow"
        w="100%"
        bg="white"
        position="fixed"
        bottom="0"
        left="0"
      >
        <Divider borderColor="gray.600" />
        <Box bg="white" px={[5, 8]}>
          {currentStep !== STEPS.BOOKING_SUCCESS && renderConfirmBookingSection()}
        </Box>
      </Box>
    );

  return (
    <Box w="100%" p={[4, 4, 5, 3]}>
      <Stack h="100%" spacing={3}>
        {renderTitleAndCloseButton()}

        <Box ref={scrollContainer} w="100%" h="100%" overflowY="scroll" className="no-scroll-bar">
          {currentStep === STEPS.WATCH_NOW_OR_BOOK && renderWatchOrBook()}

          {/* [NOAUTH] Add Child View */}
          {currentStep === STEPS.ADD_CHILD && renderAddChildContent()}

          {/* [NOAUTH] Create Profile View */}
          {currentStep === STEPS.CREATE_PROFILE && renderCreateProfileContent()}

          {/* MultiChild Select View */}
          {currentStep === STEPS.MULTI_CHILD_SELECT && renderMultiChildSelect()}

          {/* Successful Booking */}
          {currentStep === STEPS.BOOKING_SUCCESS && renderCompleteBookingContent()}
        </Box>
      </Stack>
      {currentStep !== STEPS.WATCH_NOW_OR_BOOK && renderBottomCTA()}
    </Box>
  );
};

export default BookingModal;
