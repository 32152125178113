/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { allCountries } from 'country-telephone-data';
import { countryCodeEmoji } from 'country-code-emoji';
import '../App.css';
import {
  FormErrorMessage,
  FormControl,
  Spinner,
  Input,
  Button,
  Text,
  Divider,
  Stack,
  HStack,
  Box,
  useToast,
  Flex,
  Select,
  Switch,
  Image,
  useClipboard,
  IconButton,
} from '@chakra-ui/react';
import actions from '@actions';
import { showErrorToast, showSuccessToast, analytics, AVATAR, navigateTo } from '@utils';
// import AvatarModal from './componenets/AvatarModal';
import { isiOSNativeDevice } from '@utils/index';
import { CDN_HOSTNAME } from '@constants';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router';
import AddChildModal from './componenets/AddChildModal';
import EditChildModal from './componenets/EditChildModal';
// import InitialChildForm from './componenets/InitialChildForm';

const Profile = ({ handleSignOut, setHeaderProfile }) => {
  // State
  // const { handleSubmit, errors, register, formState } = useForm();
  const [children, setChildren] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  // const [manageLoading, setManageLoading] = useState(false);
  // const [editingChild, setEditingChild] = useState(null);
  const [submittingUpdate, setSubmittingUpdate] = useState(false);
  const { onCopy, hasCopied } = useClipboard(profile && profile.referral && profile.referral.url);

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  // const { onCopy, hasCopied } = useClipboard(profile && profile.referral && profile.referral.url);

  const [addChildModalOpen, setAddChildModalOpen] = useState(false);
  const [editChildModalOpen, setEditChildModalOpen] = useState(false);
  const [childToEdit, setChildToEdit] = useState(null);
  const [editingAccountInfo, setEditingAccountInfo] = useState(false);
  const form = useForm();
  const history = useHistory();

  // Actions
  const fetchProfile = async updatingProfile => {
    if (!updatingProfile) {
      setLoading(true);
    }

    try {
      const { profile: fetchedProfile = {} } = await actions.fetchProfile(false);
      if (fetchedProfile.children) setChildren(fetchedProfile.children);
      setHeaderProfile(fetchedProfile);
      setProfile(fetchedProfile);
    } catch (err) {
      showErrorToast(toast, err.message);
    }

    setLoading(false);
  };

  const fetchData = async () => {
    await fetchProfile(false);
  };

  const onUpdateSubmit = async data => {
    try {
      analytics.t('submitting profile update');

      setSubmittingUpdate(true);
      await actions.updateProfile(data, 'update');

      await fetchProfile(true);
      setSubmittingUpdate(false);
      setEditingAccountInfo(false);
      showSuccessToast(toast, '✅ Your account has been updated!');
    } catch (error) {
      showErrorToast(toast, error.message);
      setSubmittingUpdate(false);
    }
  };

  const sortAndFilterCountryCodes = () => {
    const filteredCountries = allCountries.filter(country => {
      if (country.dialCode === '1' && country.name !== 'United States') return false;

      return true;
    });

    return filteredCountries.sort((a, b) => {
      return a.dialCode - b.dialCode;
    });
  };

  const validateName = fieldName => value => {
    if (!value) {
      return `${fieldName} is required`;
    }

    return true;
  };

  const validateEmail = value => {
    // eslint-disable-next-line max-len
    const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error;

    if (!value) {
      error = 'Valid email required';
    } else if (!emailExpression.test(value)) {
      error = 'Email format is not correct';
    }

    return error || true;
  };

  // We only want to show child info if they don't have any ghost children
  const showChildInfo = () => {
    return (
      (profile.children &&
        profile.children.filter(child => child.first_name && child.age).length > 0) ||
      (profile.children && profile.children.length === 0)
    );
  };

  const renderReferralBlock = () => (
    <Box
      width="100%"
      bg="white"
      rounded="lg"
      p={6}
      mt={4}
      borderWidth="2px"
      borderTopWidth="2px"
      borderColor="gray.700"
    >
      <Image
        rounded="lg"
        src={`${CDN_HOSTNAME}/promotion/swag1.png`}
        w="100%"
        h="auto"
        shadow="md"
      />
      <Text color="#383838" fontSize={['lg', 'xl']} fontWeight="black" mt={3}>
        Giveaway: Win a ZipSchool t-shirt, backpack, and mug for your child! ✨
      </Text>
      <Text color="gray.500" fontSize={['sm', 'md']} pt={[1, 3]}>
        Share ZipSchool with your friends on Facebook or in a group chat using your custom link
        below. Every time someone uses your link to signup, you'll receive one entry for the
        giveaway!
      </Text>

      <HStack
        p={3}
        mt={4}
        bg="gray.100"
        borderRadius="md"
        color="black"
        shadow="md"
        justify="space-between"
      >
        <Text fontSize={['sm', 'md']}>
          {profile.referral ? profile.referral.url : 'No referral code found!'}
        </Text>
        <Button
          fontSize={['xs', 'sm']}
          bg={hasCopied ? 'green.500' : 'purple.500'}
          color="white"
          fontWeight="bold"
          onClick={e => {
            analytics.t('referral link copied');
            onCopy(e);
          }}
        >
          {hasCopied ? 'Copied!' : 'Copy Link'}
        </Button>
      </HStack>
    </Box>
  );

  // Render Methods
  const renderChild = child => {
    const { first_name: firstName, emoji, color, id } = child;
    const renderedEmoji = AVATAR.EMOJIS.find(e => e.title === emoji);
    return (
      <HStack key={id} justifyContent="space-between">
        <Text
          fontWeight="medium"
          color={color ? 'black' : 'black'}
          p={3}
          rounded="lg"
          shadow="md"
          border="solid 1px rgb(160, 174, 192)"
        >
          {`${renderedEmoji ? renderedEmoji.value : '🐯'} ${firstName}`}
        </Text>
        <Text
          cursor="pointer"
          onClick={() => {
            analytics.t('clicked edit child');
            setChildToEdit(child);
            setEditChildModalOpen(true);
          }}
        >
          Edit
        </Text>
      </HStack>
    );
  };

  const renderAddChildButton = () => (
    <Button
      w="100%"
      h={['40px', '45px', '50px', '55px']}
      borderRadius={5}
      mt={5}
      boxShadow="md"
      fontSize={['lg', 'lg', 'xl', 'xl']}
      fontWeight="bold"
      color="white"
      bg="purple.500"
      backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
      style={{
        backgroundBlendMode: 'multiply, normal',
        textShadow: '0 -1px 0 rgba(0, 0, 0, .35)',
      }}
      _hover={{ backgroundColor: 'purple.500' }}
      onClick={() => {
        analytics.t('clicked add child');
        setAddChildModalOpen(true);
      }}
    >
      Add Child
    </Button>
  );

  const renderChildList = () => (
    <Stack mt={3} spacing={2}>
      {!loading && children.length === 0 && (
        <Text py={4} fontWeight="sm" textAlign="center" fontSize="lg">
          To begin booking classes, please add a child to your profile by clicking the button below
          🙂.
        </Text>
      )}
      {children.map(renderChild)}
      {!loading && renderAddChildButton()}
    </Stack>
  );

  const renderAccountInfoEditForm = () => {
    return (
      <>
        <Flex flexDir="column">
          <Text mt={3} fontWeight="medium">
            Name
          </Text>
          <HStack>
            <FormControl isInvalid={form.errors.first_name} w="100%" isRequired>
              <Input
                name="first_name"
                placeholder="First name"
                defaultValue={profile.first_name}
                type="text"
                rounded="lg"
                fontSize="md"
                isRequired
                ref={form.register({ validate: validateName('First name') })}
              />
              <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
                {form.errors.first_name && form.errors.first_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={form.errors.last_name} w="100%">
              <Input
                name="last_name"
                placeholder="Last name"
                defaultValue={profile.last_name}
                type="text"
                w="100%"
                rounded="lg"
                fontSize="md"
                isRequired
                ref={form.register({ validate: validateName('Last name') })}
              />
              <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
                {form.errors.last_name && form.errors.last_name.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
        </Flex>
        <Flex flexDir="column">
          <Text mt={3} fontWeight="medium">
            Email
          </Text>
          <FormControl isInvalid={form.errors.email}>
            <Input
              name="email"
              placeholder="Email"
              defaultValue={profile.email}
              type="email"
              rounded="lg"
              fontSize="md"
              ref={form.register({ validate: validateEmail })}
            />
            <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
              {form.errors.email && form.errors.email.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex flexDir="column">
          <Text mt={3} fontWeight="medium">
            Phone number
          </Text>
          <HStack>
            <Select
              name="country_code"
              rounded="lg"
              fontSize="md"
              ref={form.register()}
              maxW={['45%', '35%', '30%', '25%']}
            >
              {sortAndFilterCountryCodes().map(country => {
                if (country.dialCode === '1' && country.name !== 'United States') return null;

                return (
                  <option
                    key={country.name}
                    value={country.dialCode}
                    selected={country.dialCode === profile.country_code}
                  >
                    {countryCodeEmoji(country.iso2)} +{country.dialCode}
                  </option>
                );
              })}
            </Select>
            <Input
              name="phone"
              placeholder="Phone number"
              defaultValue={profile.phone}
              type="tel"
              rounded="lg"
              fontSize="md"
              ref={form.register()}
            />
          </HStack>
          <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
            {form.errors.phone && form.errors.phone.message}
          </FormErrorMessage>
        </Flex>
      </>
    );
  };

  const renderAccountInfo = () => (
    <>
      <Flex flexDir="column">
        <Text mt={3} fontWeight="medium">
          Name
        </Text>
        <Text>{`${profile.first_name} ${profile.last_name}`}</Text>
      </Flex>
      <Flex flexDir="column">
        <Text mt={3} fontWeight="medium">
          Email
        </Text>
        <Text>{profile.email}</Text>
      </Flex>
      <Flex flexDir="column">
        <Text mt={3} fontWeight="medium">
          Phone number
        </Text>
        {profile.phone ? (
          <Text>
            {profile.country_code === '1'
              ? profile.phone
              : `+${profile.country_code}${profile.phone}`}
          </Text>
        ) : (
          <Text fontSize="sm" color="gray.600">
            Add your phone number to get text reminders about your classes!
          </Text>
        )}
      </Flex>
    </>
  );

  const renderChildInfo = () => (
    <Stack
      color="black"
      w="100%"
      alignSelf="center"
      bg="white"
      p={4}
      rounded="lg"
      animation="fadeIn 0.8s"
    >
      <Stack spacing={2}>
        <Text fontSize="xl" fontWeight="bold">
          Your Children
        </Text>
        {renderChildList()}
      </Stack>
    </Stack>
  );

  const renderEditAccountButton = () => (
    <Button
      w="100%"
      h={['40px', '45px', '50px', '55px']}
      borderRadius={5}
      mt={5}
      boxShadow="md"
      fontSize={['lg', 'lg', 'xl', 'xl']}
      fontWeight="bold"
      color="white"
      bg="purple.500"
      backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
      style={{
        backgroundBlendMode: 'multiply, normal',
        textShadow: '0 -1px 0 rgba(0, 0, 0, .35)',
      }}
      _hover={{ backgroundColor: 'purple.500' }}
      onClick={() => {
        analytics.t('clicked edit account');
        setEditingAccountInfo(true);
      }}
    >
      Edit Account
    </Button>
  );

  const renderProfileHeader = () => (
    <Stack w="100%">
      <Text color="white" fontWeight="black" fontSize={['2xl', '2xl', '2xl', '3xl']}>
        Profile
      </Text>
      <Divider borderWidth="1px" opacity={1.0} borderColor="white" />
    </Stack>
  );

  const renderSignOutButton = () => (
    <Button
      w="100%"
      h={['40px', '45px', '50px', '55px']}
      borderRadius={5}
      mt={5}
      boxShadow="md"
      fontSize={['lg', 'lg', 'xl', 'xl']}
      fontWeight="bold"
      color="white"
      bg="gray.400"
      backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
      style={{
        backgroundBlendMode: 'multiply, normal',
        textShadow: '0 -1px 0 rgba(0, 0, 0, .35)',
      }}
      _hover={{ backgroundColor: 'gray.400' }}
      onClick={() => {
        analytics.t('clicked sign out');
        handleSignOut();
      }}
    >
      Sign Out
    </Button>
  );

  const renderAccountInfoSection = () => (
    <Stack
      spacing={4}
      color="black"
      w="100%"
      alignSelf="center"
      bg="white"
      p={4}
      rounded="lg"
      animation="fadeIn 0.4s"
    >
      <Stack spacing={2}>
        <Text fontSize="xl" fontWeight="bold">
          Account Information
        </Text>
        {editingAccountInfo ? renderAccountInfoEditForm() : renderAccountInfo()}
      </Stack>
      {editingAccountInfo ? (
        <HStack justifyContent="flex-end">
          <Button
            fontWeight="bold"
            bg="purple.500"
            color="white"
            h="50px"
            fontSize="lg"
            style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
            onClick={() => {
              analytics.t('clicked cancel edit');
              setEditingAccountInfo(false);
            }}
          >
            Cancel
          </Button>
          <Button
            fontWeight="bold"
            color="black"
            h="50px"
            fontSize="lg"
            bg="#ffc60b"
            backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                            rgba(255, 255, 255, 0) 93.72%)"
            style={{
              backgroundBlendMode: 'soft-light, normal',
            }}
            _hover={{ backgroundColor: '#FFC503' }}
            isLoading={submittingUpdate}
            onClick={form.handleSubmit(onUpdateSubmit)}
          >
            Save
          </Button>
        </HStack>
      ) : (
        renderEditAccountButton()
      )}
    </Stack>
  );

  const onNotificationToggle = async (e, channel) => {
    const notifications = {
      reminders: {
        [channel]: e.target.checked,
      },
    };

    setSubmittingUpdate(true);
    try {
      analytics.t('toggled notifications', {
        type: 'reminders',
        channel,
        state: notifications.reminders[channel],
      });
      await actions.updateProfile({ notifications }, 'update');
      const { profile: fetchedProfile = {} } = await actions.fetchProfile(false);
      setProfile(fetchedProfile);
      showSuccessToast(toast, '✅ Notification preferences updated');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setSubmittingUpdate(false);
  };

  const renderNotificationsSection = () => (
    <Box
      color="black"
      w="100%"
      alignSelf="center"
      bg="white"
      p={4}
      rounded="lg"
      animation="fadeIn 0.4s"
    >
      <Stack>
        <Text fontSize="xl" fontWeight="bold">
          Notifications
        </Text>
        <Stack>
          <Text mt={4} fontSize="md" fontWeight="medium">
            Class Reminders
          </Text>
          <Divider />
          <HStack justifyContent="space-between">
            <Flex flexDir="column">
              <Text>📩 Email</Text>
              {profile.notifications &&
                profile.notifications.reminders &&
                profile.notifications.reminders.email && (
                  <Text color="gray.600" fontSize="sm">
                    You will receive reminders via email 1 hour, and 10 minutes before class
                  </Text>
                )}
            </Flex>
            <Switch
              isDisabled={submittingUpdate}
              onChange={e => onNotificationToggle(e, 'email')}
              defaultChecked={
                profile.notifications &&
                profile.notifications.reminders &&
                profile.notifications.reminders.email
              }
              colorScheme="purple"
              size="lg"
            />
          </HStack>
          <HStack justifyContent="space-between">
            <Flex flexDir="column">
              <Text>📲 Text Message</Text>
              {profile.notifications &&
                profile.notifications.reminders &&
                profile.notifications.reminders.sms && (
                  <Text color="gray.600" fontSize="sm">
                    You will receive reminders via text 1 hour, and 10 minutes before class
                  </Text>
                )}
            </Flex>
            <Switch
              isDisabled={submittingUpdate}
              onChange={e => onNotificationToggle(e, 'sms')}
              defaultChecked={
                profile.notifications &&
                profile.notifications.reminders &&
                profile.notifications.reminders.sms
              }
              colorScheme="purple"
              size="lg"
            />
          </HStack>
        </Stack>
      </Stack>
    </Box>
  );

  const renderControlBar = () => (
    <Flex left="0" position="absolute" className="native-inset-left">
      <IconButton
        zIndex="3"
        m={3}
        aria-label="Back button"
        icon={<ChevronLeftIcon />}
        size={('md', 'lg')}
        fontSize={['25px', '30px']}
        isRound
        bg="gray.300"
        color="gray.700"
        onClick={() => history.push('/classes-mobile')}
      />
    </Flex>
  );

  // UseEffects
  useEffect(() => {
    analytics.t('viewed profile');
    fetchData();

    const handleRefreshRequest = (params = {}) => {
      const { silent } = params;
      fetchProfile(silent);
    };

    window.zip_requestRefresh = handleRefreshRequest;
  }, []);

  return (
    <>
      {/* Modals */}
      {profile && (
        <AddChildModal
          setChildren={setChildren}
          setAddChildModalOpen={setAddChildModalOpen}
          addChildModalOpen={addChildModalOpen}
          toast={toast}
          validateName={validateName}
          fetchProfile={fetchProfile}
        />
      )}
      {childToEdit && (
        <EditChildModal
          child={childToEdit}
          setChildren={setChildren}
          fetchProfile={fetchProfile}
          setEditChildModalOpen={setEditChildModalOpen}
          editChildModalOpen={editChildModalOpen}
          setHeaderProfile={setHeaderProfile}
          toast={toast}
          validateName={validateName}
        />
      )}

      {/* Content */}
      <Flex className="native-inset-bottom" flexDir="column" h="100%" w={['xs', 'md', 'md', 'lg']}>
        {isiOSNativeDevice() && renderControlBar()}
        {loading && (
          <Box d="flex" justifyContent="center">
            <Spinner fontSize={['2xl', '2xl', '2xl', '3xl']} mt={4} color="white" />
          </Box>
        )}
        <Stack spacing={5}>
          {profile && profile.status !== 'pending_package_onboarding' && renderProfileHeader()}
          {/*profile && profile.referral && renderReferralBlock()*/}
          {profile && renderAccountInfoSection()}
          {/* {profile && showChildInfo() && renderChildInfo()} */}
          {/*profile && renderNotificationsSection()*/}
          {(profile || (!profile && !loading)) && renderSignOutButton()}
        </Stack>
        {/* {profile && profile.referral && renderReferralBlock()} */}
      </Flex>
    </>
  );
};

export default Profile;
