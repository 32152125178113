/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Box, Text, Stack } from '@chakra-ui/react';
import { detect } from 'detect-browser';

const Footer = ({ darkBackground, bg }) => {
  const browser = detect();

  return (
    <Box d="flex" w="100%" justifyContent="center" p={[3, null, 5]} bg={bg || 'purple.500'}>
      <Stack alignItems="center">
        <Stack spacing={5} isInline>
          <Text fontWeight="medium" fontSize="sm" color={darkBackground ? 'white' : 'black'}>
            <a
              href="https://zipschool.webflow.io/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms Of Service
            </a>
          </Text>
          <Text fontWeight="medium" fontSize="sm" color={darkBackground ? 'white' : 'black'}>
            <a
              href="https://zipschool.webflow.io/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </Text>
        </Stack>
        <Stack
          justifyContent="center"
          isInline
          spacing={1}
          color={darkBackground ? 'white' : 'gray.500'}
        >
          <Text>© 2021</Text>
          <Text>
            <a href="https://zipschool.com?ref=app" rel="noopener noreferrer" target="_blank">
              Zip Education Inc.
            </a>
          </Text>
        </Stack>
        <Stack alignItems="center" spacing={1}>
          <Text color={darkBackground ? 'white' : 'gray.400'} fontSize="xs">
            v{process.env.REACT_APP_VERSION}
          </Text>
          {browser && (
            <Text color={darkBackground ? 'white' : 'gray.400'} fontSize="xs">
              {browser.os} | {browser.name} | {browser.version}
            </Text>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default memo(Footer);
