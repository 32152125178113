/* eslint-disable react/prop-types */
import React, { memo, useEffect } from 'react';
import {
  Button,
  Text,
  HStack,
  VStack,
  Image,
  Stack,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { analytics, sendDataToNative } from '@utils';
import { useNativeCallback } from '@hooks';
import NotifcationDemo from '@assets/notifcation-demo.png';
import { onboardingSteps } from '../../constants';
import nativeConstants from '../../../native/constants';

const PushNotificationModal = ({ setCurrentStep }) => {
  // Hooks
  const { isOpen, onOpen, onClose } = useDisclosure();
  const nativeCallback = useNativeCallback();

  // Actions
  const dismissAction = button => () => {
    onClose();

    if (button === 'allow') {
      analytics.t('clicked yes in zipschool push noitifcation modal');
      sendDataToNative({
        action: 'push',
        data: {},
      });
    } else if (button === 'allow_sometimes') {
      analytics.t('clicked sometimes in zipschool push noitifcation modal');
      sendDataToNative({
        action: 'push',
        data: {},
      });
    } else {
      analytics.t('clicked no in zipschool push notification modal');
      setCurrentStep(onboardingSteps.PET_SELECT);
    }
  };

  // UseEffects
  useEffect(() => {
    analytics.t('viewed push notification modal in native onboarding');
    onOpen();
  }, []);

  useEffect(() => {
    if (nativeCallback) {
      const { action } = nativeCallback;
      if (action === nativeConstants.NATIVE_ACTIONS.AUTHORIZED) {
        analytics.t('clicked yes in apple push notification modal');
      } else if (action === nativeConstants.NATIVE_ACTIONS.DENIED) {
        analytics.t('clicked no in apple push notification modal');
      }

      setCurrentStep(onboardingSteps.PET_SELECT);
    }
  }, [nativeCallback]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={dismissAction('close_button')}
      autoFocus={false}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent m={3} rounded="lg">
        <ModalBody>
          <Stack spacing={2}>
            <Text fontWeight="black" fontSize={['lg', 'xl']}>
              🔔 Want notifications about freebies?
            </Text>
            {/* <Image src={NotifcationDemo} /> */}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <VStack w="100%" h="100%">
            <Button
              colorScheme="purple"
              h={['50px', '55px']}
              bg="purple.500"
              fontSize={['lg', 'xl']}
              rounded="lg"
              color="white"
              onClick={dismissAction('allow')}
            >
              Yes! Notify me about freebies
            </Button>
            <Button
              colorScheme="gray"
              h={['50px', '55px']}
              bg="gray.200"
              color="black"
              fontSize={['lg', 'xl']}
              rounded="lg"
              onClick={dismissAction('allow_sometimes')}
            >
              Notify me sometimes about freebies
            </Button>
            <Button
              colorScheme="gray"
              h={['50px', '55px']}
              bg="gray.200"
              color="black"
              fontSize={['lg', 'xl']}
              rounded="lg"
              onClick={dismissAction('deny')}
            >
              No! I don't want freebies
            </Button>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(PushNotificationModal);
