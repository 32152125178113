/* eslint-disable react/prop-types */
import React from 'react';
import { Drawer, DrawerContent, DrawerOverlay, DrawerBody } from '@chakra-ui/react';
import BookingModal from './BookingModal';
import PopupModal from '../PopupModal';
import { isiOSNativeDevice } from '@utils/index';

const BookingModalContainer = ({
  deviceType,
  classData,
  profile,
  fetchProfile,
  fetchAuthState,
  fetchSchedule,
  authState,
  setCurrentBookingClassData,
  completionHandler,
}) => {
  // Render Methods
  const renderBookingModal = () => (
    <BookingModal
      deviceType={deviceType}
      setCurrentBookingClassData={setCurrentBookingClassData}
      classData={classData}
      profile={profile}
      fetchProfile={fetchProfile}
      fetchAuthState={fetchAuthState}
      fetchSchedule={fetchSchedule}
      authState={authState}
      completionHandler={completionHandler}
    />
  );

  return deviceType === 'desktop' ? (
    <Drawer
      isOpen={classData}
      onClose={() => setCurrentBookingClassData(null)}
      placement="right"
      size="lg"
      height="xl"
    >
      <DrawerOverlay>
        <DrawerContent rounded="lg">
          <DrawerBody>{renderBookingModal()}</DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  ) : (
    classData && <PopupModal>{renderBookingModal()}</PopupModal>
  );
};
export default BookingModalContainer;
