/* eslint-disable react/prop-types */
import React, { memo, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Text,
  Image,
  Stack,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { analytics } from '@utils';
import { CDN_HOSTNAME } from '@constants';
import CountUp from 'react-countup';
import PixelFinger from '@assets/pixel-finger.png';

const RewardModal = ({ onClose, onNext, reward, next, currentLessonTitle, currentLessonIndex }) => {
  // Local Properties
  const searchParams = new URLSearchParams(window.location.search);
  const previewClass = searchParams.get('preview_class');

  // State
  const [countdown, setCountdown] = useState(10);

  // References
  const countdownRef = useRef(countdown);
  const audioRef = useRef(null);

  // Actions
  const playSound = async () => {
    if (audioRef && audioRef.current) {
      try {
        await audioRef.current.play();
      } catch (err) {
        console.warn('Could not play reward audio');
      }
    }
  };

  useEffect(() => {
    let interval;
    if (next) {
      interval = setInterval(() => {
        if (countdownRef.current === -1) {
          analytics.t('courses: auto navigated to next lesson', {
            courseTitle: currentLessonTitle,
            currentLessonIndex,
          });
          clearInterval(interval);
          onNext();
        } else {
          setCountdown(prevState => {
            return prevState - 1;
          });
        }
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    countdownRef.current = countdown;
  }, [countdown]);

  return (
    <Modal
      isOpen
      isCentered
      onClose={() => {
        analytics.t('dismissed award modal', { button: 'close' });
        onClose();
      }}
      autoFocus={false}
      closeOnOverlayClick={false}
    >
      <audio ref={audioRef} autoPlay={false}>
        <source src={`${CDN_HOSTNAME}/sounds/effects/ding.mp3`} />
      </audio>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>
          <Stack isInline alignItems="center" spacing={2}>
            <Text fontWeight="black" fontSize={['xl', '2xl']}>
              ✨ Awesome Job!
            </Text>
          </Stack>
        </ModalHeader>
        <ModalBody>
          <HStack w="100%" justifyContent="center">
            {reward && (
              <VStack w="50%" spacing={2}>
                <HStack>
                  <Text fontSize="4xl" fontWeight="bold">
                    {reward.issued ? (
                      reward.balance
                    ) : (
                      <CountUp
                        start={reward.balance}
                        end={reward.amount + reward.balance}
                        duration={5}
                        delay={0.5}
                        onStart={playSound}
                      />
                    )}
                  </Text>
                  <Text fontSize="6xl">⭐</Text>
                </HStack>
                {reward.issued && (
                  <Text color="gray.700" fontSize="sm" textAlign="center">
                    You already received a reward for this class
                  </Text>
                )}
              </VStack>
            )}
            <Stack>
              {next && (
                <VStack position="relative">
                  <Text fontSize={['xl', 'xl', '2xl']}>
                    {next.type === 'lesson'
                      ? `Next lesson will start in ${countdownRef.current}s`
                      : `The quiz will start in ${countdownRef.current}s`}
                  </Text>
                  <Box
                    position="absolute"
                    zIndex="2"
                    top={7}
                    right="0"
                    animation="listShake 1.5s infinite"
                  >
                    <Image h="60px" src={PixelFinger} />
                  </Box>
                  <Button
                    colorScheme="yellow"
                    w="100%"
                    h={['50px', '55px']}
                    bg="rgb(255, 197, 3)"
                    fontSize={['lg', 'xl']}
                    rounded="lg"
                    color="black"
                    _hover={{ background: 'yellow.500' }}
                    onClick={() => {
                      analytics.t('courses: clicked go to next lesson at end of class', {
                        courseTitle: currentLessonTitle,
                        currentLessonIndex,
                      });
                      onNext();
                    }}
                  >
                    {next.title}
                  </Button>
                  <Text
                    _hover={{ color: 'gray.300' }}
                    textAlign="center"
                    textDecoration="underline"
                    color="gray.500"
                    fontWeight="bold"
                    fontSize={['xs', 'sm', 'md']}
                    cursor="pointer"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Go Back
                  </Text>
                </VStack>
              )}
              {previewClass && (
                <Text fontSize={['xl', 'xl', '2xl']}>
                  Ready for your child to level up in math?
                </Text>
              )}
              {!next && (
                <Button
                  colorScheme="purple"
                  h={['50px', '55px']}
                  bg="purple.500"
                  fontSize={['lg', 'xl']}
                  rounded="lg"
                  color="white"
                  _hover={{ background: 'purple.500' }}
                  onClick={() => {
                    analytics.t('courses: clicked go back to courses at end of class', {
                      courseTitle: currentLessonTitle,
                      currentLessonIndex,
                    });
                    onClose();
                  }}
                >
                  {previewClass ? 'Take me to checkout!' : 'Go Back'}
                </Button>
              )}
            </Stack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default memo(RewardModal);
