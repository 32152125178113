/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useState, useRef, useEffect } from 'react';
import {
  Box,
  Text,
  Stack,
  Image,
  Flex,
  Button,
  useBreakpointValue,
  HStack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { CDN_HOSTNAME } from '@constants';
import PlayButton from '@assets/icons/play-button.svg';
import { analytics, getTimeOfDayGreeting, sendDataToNative } from '@utils';
import AddChildModal from '@modals/AddChildModal';
import ClassChannelNative from '../ClassChannelNative';
import ProfileContext from '../../Context/ProfileContext';
import constants from '../constants';
import VideoPlayerNative from '../VideoPlayerNative';

const ClassListNative = ({ items, fetchProfile, petRef }) => {
  // Context
  const profileContext = useContext(ProfileContext);

  // Hooks
  const history = useHistory();
  const channelHeight = useBreakpointValue({
    base: 250,
    md: 250,
    lg: 350,
    xl: 350,
  });

  // State
  const [showIntroVideoButton, setShowIntroVideoButton] = useState(true);
  const [showAddChildModal, setAddChildModal] = useState(false);
  const [childToUpdate, setChildToUpdate] = useState(null);

  // References
  const introVideoRef = useRef(null);

  // Actions
  const onPlayAction = () => {
    if (introVideoRef && introVideoRef.current) {
      if (!introVideoRef.current.muted) {
        setShowIntroVideoButton(false);
      }
    }
  };

  const onEndedAction = () => {
    if (introVideoRef && introVideoRef.current) {
      introVideoRef.current.webkitExitFullscreen();
      setShowIntroVideoButton(true);
    }
    analytics.t('completed getting started video');
  };

  const playVideoAction = () => {
    if (introVideoRef && introVideoRef.current) {
      introVideoRef.current.currentTime = 0;
      introVideoRef.current.muted = false;
      introVideoRef.current.webkitEnterFullscreen();
      introVideoRef.current.play();
      setShowIntroVideoButton(false);
      analytics.t('played getting started video');
    }
  };

  const shouldRenderAddChildModal = () => {
    const child = profileContext.data.children[0];
    setAddChildModal(child && !child.first_name && !child.age);
    setChildToUpdate(child);
  };

  // Render Methods
  const renderChannels = () => (
    <Box w="70%" h="100%">
      {/* Top Learning Track Channel */}
      <ClassChannelNative isDark mode="native" items={items} child={childToUpdate} />
    </Box>
    // <Stack mt={5} spacing={3}>
    //   {/* Top Learning Track Channel */}
    //   <ClassChannelNative
    //     isDark
    //     // title="🚀 Top Learning Tracks"
    //     // subtitle="Award-winning tracks around topics kids love."
    //     mode="native"
    //     items={items}
    //   />
    // </Stack>
  );

  const renderGetStartedVideo = () => (
    <Flex
      className="mobile-border-radius"
      flexDir="column"
      position="relative"
      rounded="xl"
      shadow="lg"
      m="auto"
      maxW={['100%', '90%', '80%', '70%']}
    >
      {showIntroVideoButton && (
        <>
          <Flex
            position="absolute"
            zIndex="1"
            w="100%"
            h="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              bg="#805AD5BF"
              onClick={playVideoAction}
              rounded="full"
              w="48px"
              h="48px"
              pl={5}
              shadow="lg"
            >
              <Image src={PlayButton} />
            </Button>
          </Flex>
          <Box
            position="absolute"
            zIndex="1"
            bottom="0"
            w="100%"
            bg={constants.zipSchoolGold}
            p={2}
          >
            <Text fontSize={['md', 'lg']} fontWeight="bold" textAlign="center">
              ✅ Parents Start Here!
            </Text>
          </Box>
        </>
      )}

      <VideoPlayerNative
        style={{ width: '100%', height: '100%' }}
        vodRef={introVideoRef}
        showIntroVideoButton={showIntroVideoButton}
        options={{
          sources: [profileContext.data.introVideoAsset],
          nativeControlsForTouch: true,
        }}
        onPlay={onPlayAction}
        onEnded={onEndedAction}
      />
    </Flex>
  );

  const renderGreetingHeader = () => {
    const childFirstName = childToUpdate ? `Explorer ${childToUpdate.first_name}` : 'Explorer';

    return (
      <HStack className="native-inset-left native-inset-right" justifyContent="space-between">
        <Stack
          color="white"
          py={1}
          px={3}
          rounded="lg"
          display="inline-block"
          className="backdrop-frost"
        >
          <Text fontSize="xl" fontWeight="bold">
            {`🚀 ${getTimeOfDayGreeting()}, ${childFirstName}!`}
          </Text>
          <Text fontSize="md">Tap what you want to learn about today!</Text>
        </Stack>
        <Button colorScheme="purple" onClick={() => history.push('/profile')}>
          Parent Profile
        </Button>
      </HStack>
    );
  };

  const renderPetSection = () => {
    // Get equipped redeemable
    let equippedRedeemableUrl;
    if (profileContext.data && profileContext.data.redeemables) {
      const equippedRedeemable = profileContext.data.redeemables.find(item => item.active);

      if (equippedRedeemable) {
        equippedRedeemableUrl = equippedRedeemable.url;
      }
    }

    // Get rewards balance
    let rewardBalance = 0;
    if (profileContext.data && profileContext.data.rewards) {
      rewardBalance = profileContext.data.rewards.balance;
    }

    return (
      <Stack
        flexDir="column"
        w="30%"
        h={channelHeight}
        justifyContent="center"
        alignItems="center"
        rounded="lg"
        pr={3}
      >
        <video
          ref={petRef}
          className="flip"
          style={{ height: '150px' }}
          loop
          muted
          autoPlay
          playsInline
          src={equippedRedeemableUrl}
          onClick={() => history.push('/rewards')}
        />
        <HStack
          animation="fadeIn 0.3s"
          fontSize="md"
          fontWeight="bold"
          color="black"
          rounded="full"
          textAlign="center"
          h="40px"
          w="80%"
          justifyContent="center"
          bg="white"
          py={1}
          px={3}
          onClick={() => history.push('/rewards')}
        >
          <Text>🦄 Change Pet</Text>
        </HStack>
        <Text
          animation="fadeIn 0.3s"
          display="inline-block"
          fontSize="md"
          fontWeight="bold"
          color="white"
          rounded="full"
          py={1}
          px={3}
          onClick={() => history.push('/rewards')}
        >
          {`🌟 Your Stars: ${rewardBalance}`}
        </Text>
      </Stack>
    );
  };

  // UseEffects
  useEffect(() => {
    if (profileContext.data && profileContext.data.children) {
      shouldRenderAddChildModal();
    }
  }, [profileContext.data]);

  return (
    <Box w="100%">
      {showAddChildModal && (
        <AddChildModal fetchProfile={fetchProfile} childToUpdate={childToUpdate} />
      )}
      <Box w="100%" h="100%">
        {renderGreetingHeader()}
        <Flex flexDir="row" w="100%" mt={3}>
          {/* <Flex justifyContent="center" p={3}>
          <Image cursor="pointer" src={`${CDN_HOSTNAME}/icons/zipschool-logo-light.svg`} />
        </Flex> */}
          {/* {renderGetStartedVideo()}
        <Box minW="75%" maxW="80%" m="auto">
          {renderNextLessonButton()}
        </Box> */}
          {renderPetSection()}
          {renderChannels()}
        </Flex>
      </Box>
    </Box>
  );
};

export default ClassListNative;
