/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Text,
  Stack,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react';
import { analytics } from '@utils';

const SortModal = ({ setSortMode, sortMode, onClose, onApply }) => (
  <Modal isCentered isOpen onClose={onClose} closeOnOverlayClick>
    <ModalOverlay />
    <ModalContent m={3} rounded="lg">
      <ModalHeader>
        <Stack isInline alignItems="center" spacing={2}>
          <Text fontWeight="bold" fontSize="xl">
            Sort By
          </Text>
        </Stack>
      </ModalHeader>
      <ModalBody>
        <Stack mt={2} spacing={2}>
          <Box
            onClick={() => setSortMode('start-time')}
            borderWidth="1px"
            borderColor="gray.300"
            rounded="lg"
            w="100%"
            key="start-time"
            h="50px"
            d="flex"
            alignItems="center"
            justifyContent="space-between"
            cursor="pointer"
            bg={sortMode === 'start-time' ? '#FFC503' : 'white'}
            color="black"
            p={3}
          >
            <Text fontWeight={sortMode === 'start-time' ? 'bold' : 'normal'}>Start Time</Text>
            {sortMode === 'start-time' && <Text>✅</Text>}
          </Box>
          <Box
            onClick={() => setSortMode('recently-added')}
            borderWidth="1px"
            borderColor="gray.300"
            rounded="lg"
            w="100%"
            key="recently-added"
            h="50px"
            d="flex"
            alignItems="center"
            justifyContent="space-between"
            cursor="pointer"
            bg={sortMode === 'recently-added' ? '#FFC503' : 'white'}
            color="black"
            p={3}
          >
            <Text fontWeight={sortMode === 'recently-added' ? 'bold' : 'normal'}>
              Recently Added
            </Text>
            {sortMode === 'recently-added' && <Text>✅</Text>}
          </Box>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>

        <Button
          ml={2}
          bg="#FFC503"
          fontWeight="medium"
          color="black"
          onClick={() => {
            analytics.t('changed sort', { type: 'sort', selected: sortMode });
            onApply();
          }}
        >
          Apply
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default SortModal;
