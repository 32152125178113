import React from 'react';
import '../App.css';
import { Text, Box, Divider, Stack } from '@chakra-ui/react';

const Sent = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const resetEmail = decodeURIComponent(urlParams.get('email'));

  return (
    <Box>
      <Text fontSize="lg" fontWeight="black">
        ✅ Verification Email Sent
      </Text>
      <Stack mt={4} spacing={3}>
        <Text fontSize="md">
          An email was just sent to <b>{resetEmail}</b>. Please click the link in this email to
          finish resetting your password ✨
        </Text>
        <Divider />
        <Text color="gray.500" fontSize="sm">
          If you did not receieve an email, please check your spam folder for an email from{' '}
          <b>support@zipschool.com</b>. This could also mean you do not have an account with us.
        </Text>
      </Stack>
    </Box>
  );
};

export default Sent;
