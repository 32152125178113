/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Spinner, useBreakpointValue, Text } from '@chakra-ui/react';
import {
  analytics,
  hideCrispLauncher,
  isMobileDevice,
  showCrispLauncher,
  showErrorToast,
  isiOSNativeDevice,
  navigateTo,
} from '@utils';
import { useHistory } from 'react-router-dom';
import SetupView from './components/SetupView';
import CountdownView from './components/CountdownView';
import VODView from './components/VODView';
import Header from '../Header';

const ROOM_STATE = {
  SETUP: 'setup',
  WAITING: 'waiting',
  PLAY: 'play',
};

const VODRoom = ({
  // profile,
  zipClass,
  fetchClassroom,
  student,
  setStudent,
  loading,
  // authState,
  reward,
  next,
  isNoAuth,
  classType,
}) => {
  // Local Properties
  const isMobileDeviceResult = isMobileDevice();
  // const videoWidth = useBreakpointValue({ base: 120, sm: 120, md: 150, lg: 180 });
  // const videoHeight = useBreakpointValue({ base: 104, sm: 104, md: 122, lg: 165 });
  const urlParams = new URLSearchParams(window.location.search);
  const origin = urlParams.get('origin');

  // Hooks
  const toast = useState();
  const history = useHistory();

  // State
  const [currentRoomState, setCurrentRoomState] = useState(null);
  const [currentPoll, setCurrentPoll] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isPortraitMode, setIsPortraitMode] = useState(false);
  const [timeDifference, setTimeDifference] = useState(null);

  // References
  const videoRef = useRef(null);
  const vodRef = useRef(null);
  const zipClassRef = useRef(zipClass);
  const timeDifferenceRef = useRef(null);
  const currentRoomStateRef = useRef(currentRoomState);

  // Actions
  const getImageBackground = () => {
    return currentRoomState === ROOM_STATE.WAITING
      ? 'linear-gradient(137deg, #4621ad, #ffc60b, #48bb78)'
      : 'none';
  };

  const getBackgroundColor = () => {
    let bg;
    if (currentRoomState === ROOM_STATE.PLAY) {
      bg = 'black';
    } else if (currentRoomState === ROOM_STATE.WAITING) {
      bg = 'transparent';
    } else {
      bg = 'purple.500';
    }

    return bg;
  };

  const getBackgroundSize = () => {
    return currentRoomState === ROOM_STATE.WAITING ? '600% 600%' : '100% 100%';
  };

  const onScreenModeChanged = e => {
    if (isMobileDeviceResult) {
      if (e.matches) {
        setIsPortraitMode(true);
        analytics.t('turned to portrait mode');
      } else {
        setIsPortraitMode(false);
        analytics.t('turned to landscape mode');
      }
    }
  };

  const statusCheck = () => {
    if (zipClassRef && zipClassRef.current && timeDifferenceRef && timeDifferenceRef.current) {
      if (
        Date.now() - timeDifferenceRef.current > zipClassRef.current.timestamp &&
        currentRoomStateRef &&
        currentRoomStateRef.current !== ROOM_STATE.SETUP
      ) {
        setCurrentRoomState(ROOM_STATE.PLAY);
        fetchClassroom(true);
      }
    }
  };

  useEffect(() => {
    if (zipClassRef && zipClassRef.current && !loading) {
      setCurrentRoomState(ROOM_STATE.PLAY);
    }
  }, [zipClassRef, loading]);

  // UseEffects
  useEffect(() => {
    let matchMediaQuery;

    try {
      // Currently we aren't pushing setup view on native
      // if (!isiOSNativeDevice()) {
      //   analytics.t('viewed setup view');
      // }

      matchMediaQuery = window.matchMedia('(orientation: portrait)');
      matchMediaQuery.addListener(onScreenModeChanged);

      setIsPortraitMode(matchMediaQuery.matches && isMobileDeviceResult);
      setCurrentRoomState(ROOM_STATE.PLAY);
    } catch (error) {
      console.error('VODRoom Error', error);
      showErrorToast(toast, 'Something went wrong. Please contact ZipSchool support!');
    }

    return () => {
      if (matchMediaQuery) matchMediaQuery.removeListener(onScreenModeChanged);
    };
  }, []);

  /* useEffect(() => {
    let interval;

    try {
      if (zipClassRef && zipClassRef.current && zipClassRef.current.now && !timeDifference) {
        const delta = Date.now() - zipClassRef.current.now;
        setTimeDifference(delta);
      }

      interval = setInterval(statusCheck, 1000);
    } catch (error) {
      console.error('VODRoom Error: ', error);
      showErrorToast(toast, 'Something went wrong. Please contact ZipSchool support!');
    }
    return () => clearInterval(interval);
  }, [zipClassRef.current]); */

  useEffect(() => {
    timeDifferenceRef.current = timeDifference;
  }, [timeDifference]);

  useEffect(() => {
    if (zipClassRef) {
      zipClassRef.current = zipClass;
    }
  }, [zipClass]);

  useEffect(() => {
    if (currentRoomStateRef) {
      currentRoomStateRef.current = currentRoomState;
    }

    if (currentRoomState === ROOM_STATE.PLAY) {
      hideCrispLauncher();
    } else {
      showCrispLauncher();
    }
  }, [currentRoomState]);

  const endClassRoute = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const cohort_id = searchParams.get('cohort_id');

    let pathUri = cohort_id ? `/courses/${cohort_id}` : `/classes`;

    if (isiOSNativeDevice()) {
      pathUri = '/classes-mobile';
    }

    return pathUri;
  };

  const onBack = () => {
    const path = `/${origin}` || isiOSNativeDevice() ? '/classes-mobile' : '/classes';
    analytics.t('pressed back button in vodview', { path });

    history.push(endClassRoute());
  };

  return (
    <Flex
      style={{ backgroundBlendMode: 'soft-light, normal' }}
      flexDir="column"
      justifyContent={currentRoomState === ROOM_STATE.PLAY ? 'center' : 'flex-start'}
      bg={getBackgroundColor()}
      backgroundImage={getImageBackground()}
      backgroundSize={getBackgroundSize()}
      animation={currentRoomState === ROOM_STATE.WAITING ? 'gradientFlow 42s infinite' : 'none'}
      w="100%"
      maxH="100%"
      // minH={currentRoomState !== ROOM_STATE.PLAY ? '100%' : null}
      height={currentRoomState === ROOM_STATE.PLAY ? '100%' : null}
    >
      {currentRoomState !== ROOM_STATE.PLAY && (
        <Header
          onBack={onBack}
          mb={currentRoomState === ROOM_STATE.SETUP ? 10 : 3}
          position={currentRoomState === ROOM_STATE.PLAY ? 'absolute' : null}
        />
      )}
      {loading && (
        <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
          <Spinner size="xl" thickness={4} speed="0.95s" emptyColor="gray.300" color="purple.500" />
        </Flex>
      )}
      {!loading && zipClass && (
        <SetupView
          setCurrentRoomState={setCurrentRoomState}
          roomStateProps={ROOM_STATE}
          classStartTime={zipClass.timestamp}
          setMediaStream={setMediaStream}
          setStudent={setStudent}
          student={student}
          timeDifference={timeDifference}
          videoRef={videoRef}
          // videoWidth={videoWidth}
          // videoHeight={videoHeight}
          zipClass={zipClass}
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: currentRoomState === ROOM_STATE.SETUP ? 'flex' : 'none',
            visibility: currentRoomState === ROOM_STATE.SETUP ? 'visible' : 'hidden',
            animation: currentRoomState === ROOM_STATE.SETUP ? 'fadeIn 0.5s' : 'fadeOut 0.8s',
          }}
        />
      )}

      {currentRoomState === ROOM_STATE.WAITING && (
        <CountdownView
          zipClass={zipClass}
          student={student}
          mediaStream={mediaStream}
          // videoWidth={videoWidth}
          // videoHeight={videoHeight}
          timeDifference={timeDifference}
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            animation: currentRoomState === ROOM_STATE.WAITING ? 'fadeIn 0.5s ease' : 'none',
          }}
        />
      )}
      {!loading && zipClass && currentRoomState === ROOM_STATE.PLAY && (
        <VODView
          zipClass={zipClass}
          student={student}
          mediaStream={mediaStream}
          setCurrentPoll={setCurrentPoll}
          currentPoll={currentPoll}
          setSelectedAnswer={setSelectedAnswer}
          selectedAnswer={selectedAnswer}
          setCurrentGame={setCurrentGame}
          currentGame={currentGame}
          vodRef={vodRef}
          isPortraitMode={isPortraitMode}
          timeDifference={timeDifference}
          onBack={onBack}
          reward={reward}
          next={next}
          isNoAuth={isNoAuth}
          classType={classType}
        />
      )}
    </Flex>
  );
};

export default VODRoom;
