/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Image,
  Stack,
  Text,
  useToast,
  HStack,
  VStack,
} from '@chakra-ui/react';
import actions from '@actions';
import { analytics, convertTZ, showErrorToast } from '@utils';
import { isiOSDevice, isiOSNativeDevice } from '@utils/index';

const BookedItem = ({ item, fetchSchedule, onRef }) => {
  // Local Properties
  const toast = useToast();
  const {
    booking_id: bookingId,
    link,
    title,
    subject_icon: subjectIcon,
    timestamp,
    calendars,
  } = item;

  // State
  const [cancellingBooking, setCancellingBooking] = useState(false);

  // Actions
  const handleCancelBooking = async () => {
    setCancellingBooking(true);
    try {
      await actions.cancelBooking(bookingId);
      analytics.t('clicked cancelled class on booked screen', {
        bookingId,
      });
      await fetchSchedule(true);
    } catch (err) {
      console.warn(err);
      showErrorToast(toast, err.message);
    }
    setCancellingBooking(false);
  };

  // Render Methods
  const renderCancelButton = () => (
    <Box mt={2} textAlign="center">
      <Text
        onClick={handleCancelBooking}
        color="gray.600"
        cursor="pointer"
        style={{ textDecoration: 'underline' }}
      >
        Cancel Booking
      </Text>
    </Box>
  );

  const renderCalendarItems = () => (
    <VStack spacing={2}>
      <Text fontSize={['md', 'lg']} fontWeight="bold">
        Add to your calender
      </Text>
      <HStack spacing={4}>
        {calendars.map(calItem => (
          <VStack key={calItem.type}>
            <Box
              rounded="lg"
              shadow="lg"
              p={2}
              bg="gray.100"
              border="1px #d8d8d8 solid"
              cursor="pointer"
              onClick={() => {
                analytics.t('clicked add to calendar button', { calendar: calItem.type });
                window.open(calItem.url);
              }}
            >
              <Image h={['22px', '24px', '26px', '28px']} src={calItem.icon_url} />
            </Box>
            <Text color="gray.500" fontWeight="bold" fontSize={['xs', 'sm']} textAlign="center">
              {calItem.title}
            </Text>
          </VStack>
        ))}
      </HStack>
    </VStack>
  );

  const renderJoinButton = () => (
    <Box w="100%" textAlign="center">
      <Link
        to={link}
        onClick={() => {
          analytics.t('clicked join button from booked page', item);
        }}
        target={isiOSNativeDevice() ? '_blank' : null}
      >
        <Button
          w="100%"
          h={['45px', '50px', '55px', '60px']}
          borderRadius={80}
          boxShadow="md"
          fontSize={['lg', 'lg', 'xl', 'xl']}
          fontWeight="bold"
          color="white"
          bg="green.400"
          backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
          isLoading={cancellingBooking}
          style={{
            backgroundBlendMode: 'multiply, normal',
            textShadow: '0 -1px 0 rgba(0, 0, 0, .35)',
          }}
          _hover={{ backgroundColor: 'green.400' }}
        >
          <Box>
            <Text>Join</Text>
          </Box>
        </Button>
      </Link>
    </Box>
  );

  const renderJoinExternalButton = () => (
    <Box pt={4}>
      <a
        href={link}
        target="_blank"
        onClick={() => {
          analytics.t('clicked join external from booked screen', { ...item, bigClass: true });
        }}
      >
        <Button
          w="100%"
          h={['45px', '50px', '55px', '60px']}
          borderRadius={80}
          mt={5}
          boxShadow="md"
          fontSize={['lg', 'lg', 'xl', 'xl']}
          fontWeight="bold"
          color="white"
          bg="green.400"
          backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
          style={{
            backgroundBlendMode: 'multiply, normal',
            textShadow: '0 -1px 0 rgba(0, 0, 0, .35)',
          }}
          _hover={{ backgroundColor: 'green.400' }}
        >
          Join
        </Button>
      </a>
    </Box>
  );

  return (
    <Box
      key={bookingId}
      ref={e => onRef(bookingId, e)}
      style={{ backgroundBlendMode: 'multiply, normal' }}
      mb={10}
      rounded="lg"
      shadow="md"
    >
      <Box key={item.id} bg="white" rounded="lg" p={5} borderWidth="2px" borderColor="gray.700">
        <VStack pb={2}>
          <Text fontSize={['lg', 'xl']} fontWeight="black">{`${subjectIcon || ''} ${title}`}</Text>
          <Text fontSize={['sm', 'md']} color="#383838" fontWeight="bold">
            ⏰{' '}
            {convertTZ(timestamp, {
              showTimeZone: true,
              mode: 'time',
            })}{' '}
            on{' '}
            {convertTZ(timestamp, {
              showTimeZone: true,
              mode: 'date',
              dayMode: 'full',
            })}
          </Text>
        </VStack>
        <Divider />
        <Stack pt={4}>
          {renderCalendarItems()}
          <VStack py={1}>
            {link && link.startsWith('http') && renderJoinExternalButton()}
            {link && !link.startsWith('http') && renderJoinButton()}
            {renderCancelButton()}
          </VStack>
        </Stack>
      </Box>
    </Box>
  );
};

export default BookedItem;
