/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Flex, Text, Stack } from '@chakra-ui/react';
import { analytics } from '@utils';
import { TOPICS_LIST } from '../../constants';

const AddTopics = ({ selectedTopics, setSelectedTopics }) => {
  // Actions
  const addTopicAction = topic => () => {
    setSelectedTopics(prevState => {
      const found = prevState.find(({ id }) => id === topic.id);

      if (found) {
        return prevState.filter(({ id }) => id !== topic.id);
      }

      return [...prevState, topic];
    });
  };

  // Render Methods
  const renderTopicItem = topic => {
    const isSelectedTopic = selectedTopics.find(({ id }) => id === topic.id);

    return (
      <Flex
        key={topic.id}
        w="100%"
        h="60px"
        style={{
          backgroundBlendMode: 'multiply, normal',
          textShadow: isSelectedTopic ? '0 -1px 0 rgba(0, 0, 0, .35)' : '',
        }}
        cursor="pointer"
        rounded="full"
        alignItems="center"
        p={[3, 5]}
        border={isSelectedTopic ? 'solid 1px transparent' : 'solid 1px rgb(160, 174, 192)'}
        bg={isSelectedTopic ? 'green.400' : 'white'}
        color={isSelectedTopic ? 'white' : 'black'}
        fontWeight={isSelectedTopic ? 'bold' : 'normal'}
        backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
        onClick={addTopicAction(topic)}
      >
        <Text fontSize="lg" fontWeight="bold">
          {topic.name}
        </Text>
      </Flex>
    );
  };

  // UseEffects
  useEffect(() => {
    analytics.t('viewed Add Topics in native onboarding');
    window.scroll(0, 0);
  }, []);

  return (
    <Stack
      maxW={['100%', '100%', '80%', '75%']}
      h="100%"
      spacing={2}
      overflow="auto"
      margin="auto"
      animation="fadeIn 0.4s"
    >
      {TOPICS_LIST.map(topic => renderTopicItem(topic))}
    </Stack>
  );
};

export default AddTopics;
