/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Flex, Input, Stack } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { analytics } from '@utils';

/**
 *
 * @param {Array<Object>} items List of objects you want to search over
 * @param {Function} runFilters Function that will re-run any existing filters and display all items
 * @param {Function} onResult Callback that returns the list of results
 *
 */
const SearchBar = ({ items, runFilters, onResult }) => {
  // State
  const [itemsSearchMap, setItemsSearchMap] = useState(null);
  const [classItemMap, setClassItemMap] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  // References
  const inputRef = useRef(null);

  // Actions
  const generateSearchMap = () => {
    const nonAlphaNumericRegex = /[^0-9a-z\s]/gi;
    const _itemsSearchMap = {};
    const _classItemMap = {};

    // Search Map
    items.forEach(item => {
      const {
        class_id: classId,
        description,
        duration,
        teacher,
        title,
        subject,
        age_range: ageRange,
      } = item;

      // Description
      if (description) {
        const cleanedDescription = description
          .replace(nonAlphaNumericRegex, '')
          .trim()
          .toLowerCase();
        if (_itemsSearchMap[cleanedDescription]) {
          _itemsSearchMap[cleanedDescription] = [..._itemsSearchMap[cleanedDescription], classId];
        } else {
          _itemsSearchMap[cleanedDescription] = [classId];
        }
      }

      // Duration
      if (duration) {
        const durationStr = duration.toString();
        if (_itemsSearchMap[durationStr]) {
          _itemsSearchMap[durationStr] = [..._itemsSearchMap[durationStr], classId];
        } else {
          _itemsSearchMap[durationStr] = [classId];
        }
      }

      // Teacher
      if (teacher) {
        const cleanedTeacherName = teacher.name
          .replace(nonAlphaNumericRegex, '')
          .trim()
          .toLowerCase();
        if (_itemsSearchMap[cleanedTeacherName]) {
          _itemsSearchMap[cleanedTeacherName] = [..._itemsSearchMap[cleanedTeacherName], classId];
        } else {
          _itemsSearchMap[cleanedTeacherName] = [classId];
        }
      }

      // Title
      if (title) {
        const cleanedTitle = title.replace(nonAlphaNumericRegex, '').trim().toLowerCase();
        if (_itemsSearchMap[cleanedTitle]) {
          _itemsSearchMap[cleanedTitle] = [..._itemsSearchMap[cleanedTitle], classId];
        } else {
          _itemsSearchMap[cleanedTitle] = [classId];
        }
      }

      // Subject
      if (subject) {
        const cleanedSubject = subject.replace(nonAlphaNumericRegex, '').trim().toLowerCase();
        if (_itemsSearchMap[cleanedSubject]) {
          _itemsSearchMap[cleanedSubject] = [..._itemsSearchMap[cleanedSubject], classId];
        } else {
          _itemsSearchMap[cleanedSubject] = [classId];
        }
      }

      // Age Range
      if (ageRange) {
        const ageParts = ageRange.split('-').map(num => parseInt(num, 10));
        for (let i = ageParts[0]; i <= ageParts[1]; i += 1) {
          const agePartStr = i.toString();

          if (_itemsSearchMap[agePartStr]) {
            _itemsSearchMap[agePartStr] = [..._itemsSearchMap[agePartStr], classId];
          } else {
            _itemsSearchMap[agePartStr] = [classId];
          }
        }
      }

      _classItemMap[classId] = item;
    });

    setItemsSearchMap(_itemsSearchMap);
    setClassItemMap(_classItemMap);
  };

  const generateResults = filteredKeys => {
    const classes = [];
    let uniqueClassIds = [];

    filteredKeys.forEach(key => {
      const classIds = itemsSearchMap[key];
      if (classIds.length > 0) {
        uniqueClassIds = [...new Set([...uniqueClassIds, ...classIds])];
      }
    });

    uniqueClassIds.forEach(classId => {
      const classItem = classItemMap[classId];

      if (classItem) {
        classes.push(classItem);
      }
    });

    onResult(classes);

    analytics.t('clicked search', { searchTerm, itemCount: classes.length });

    setIsSearching(false);
  };

  const runSearch = () => {
    if (searchTerm) {
      setIsSearching(true);
      const searchSplit = searchTerm.toLowerCase().split(' ');
      let filteredKeys = Object.keys(itemsSearchMap);

      searchSplit.forEach((term, index) => {
        if (index === 0) {
          filteredKeys = filteredKeys.filter(key => {
            return key.includes(term);
          });
        } else {
          const results = filteredKeys.filter(key => {
            return key.includes(term);
          });

          if (results.length > 0) {
            filteredKeys = results;
          }
        }
      });

      generateResults(filteredKeys);
    }
  };

  const onInputChange = event => {
    setSearchTerm(event.target.value);
  };

  const onEnterKey = e => {
    if (e.key === 'Enter' && !isSearching) {
      runSearch();
    }
  };

  const onClearInput = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
      setSearchTerm('');
      runFilters();
      analytics.t('clicked clear search term button');
    }
  };

  // UseEffects
  useEffect(() => {
    if (items.length > 0) {
      generateSearchMap();
    }
  }, [items]);

  useEffect(() => {
    if (searchTerm !== null && searchTerm.length <= 1) {
      runFilters();
    }
  }, [searchTerm]);

  return (
    <Stack w="100%" alignItems="center" isInline>
      <Box w="100%" position="relative">
        {searchTerm && searchTerm.length > 0 && (
          <Flex
            position="absolute"
            zIndex="2"
            left={['90%', '95%']}
            h="100%"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            onClick={onClearInput}
          >
            <CloseIcon />
          </Flex>
        )}
        <Input
          ref={inputRef}
          style={{ borderColor: 'black' }}
          name="search_bar"
          placeholder="Search for a class"
          focusBorderColor="purple.500"
          type="text"
          h={['45px', '55px']}
          rounded="lg"
          fontSize="md"
          onChange={onInputChange}
          onKeyDown={onEnterKey}
          onFocus={() => analytics.t('focused search box')}
        />
      </Box>
      <Button
        h={['45px', '55px']}
        rounded="full"
        fontSize="lg"
        fontWeight="bold"
        px={6}
        bg="purple.500"
        _hover={{ background: 'purple.500' }}
        color="white"
        isDisabled={!searchTerm}
        isLoading={isSearching}
        onClick={runSearch}
      >
        Search
      </Button>
    </Stack>
  );
};

export default SearchBar;
