/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Image, Text, Box, Flex } from '@chakra-ui/react';
import { analytics, navigateTo } from '@utils';
import PixelFinger from '@assets/pixel-finger.png';
import constants from '../constants';

/**
 * ClassListItem solely for native platforms
 * There will be a details mode. This will be to render class items and not campaign items
 */
const ClassListItemNative = ({
  style,
  channelTitle = '',
  item,
  mode,
  index,
  campaignId,
  child,
}) => {
  // Local Properties
  const origin = window.location.href.split('/').pop();
  const {
    id,
    completed,
    duration,
    title,
    grade,
    poster_url: posterUrl,
    class_count: classCount,
    unlocked,
    isCurrentLesson,
  } = item;

  // Hooks
  const history = useHistory();

  // Actions
  const getBadgeText = () => {
    if (mode === constants.MODES.DETAILS) {
      // if (!unlocked) {
      //   return '🌟 Watch previous class to unlock this one!';
      // }

      return `${duration} mins`;
    }

    return `Grades ${grade}`;
  };

  const onTapAction = () => {
    if (mode === constants.MODES.DETAILS && unlocked) {
      analytics.t('clicked watch now', {
        title,
        origin,
        channelTitle,
        classPosition: index + 1,
      });

      history.push(`/live/${id}?origin=${origin}&campaign_id=${campaignId}`);
      // navigateTo(history, `/live/${id}?origin=${origin}&campaign_id=${campaignId}`);
    } else if (mode === constants.MODES.NEXT_CLASS_ACTION) {
      analytics.t('clicked main cta item', {
        title,
        origin,
        channelTitle,
      });

      history.push(`/live/${id}?origin=${origin}&campaign_id=${campaignId}`);
      // navigateTo(history, `/live/${id}?origin=${origin}&campaign_id=${campaignId}`);
    } else if (mode !== constants.MODES.DETAILS) {
      analytics.t('clicked track item', { campaignId, channelTitle, title, classCount });
      history.push(`/classes-mobile/campaign/${campaignId}`);
    }
  };

  // Render Methods
  const renderPointerFinger = () => {
    if (
      mode === constants.MODES.NEXT_CLASS_ACTION ||
      (mode === constants.MODES.DETAILS && isCurrentLesson)
    ) {
      return (
        <Box position="absolute" zIndex="2" top="10" right="2" animation="listShake 1.5s infinite">
          <Image h="100px" src={PixelFinger} />
        </Box>
      );
    }

    return null;
  };

  const renderTitle = () => {
    const childFirstName = child ? child.first_name : 'Your Child';
    return (
      <Box
        position="absolute"
        bottom="0"
        w="100%"
        pr={mode === constants.MODES.DETAILS ? 0 : 5}
        roundedBottom="lg"
      >
        <Box
          className={
            mode === constants.MODES.NEXT_CLASS_ACTION
              ? 'backdrop-frost-highlighted'
              : 'backdrop-frost'
          }
          p={2}
          roundedBottom="lg"
        >
          <Text
            style={{ textShadow: '0px 0px 3px rgba(255, 255, 255, 0.25)' }}
            fontSize="sm"
            color="white"
          >
            {mode === constants.MODES.NEXT_CLASS_ACTION
              ? `⭐️ Picked For ${childFirstName}`
              : title}
          </Text>
        </Box>
      </Box>
    );
  };

  const renderItemNumber = () => {
    const left = 2;
    const top = 2;
    const py = 1;
    const px = 4;

    if (completed) {
      return (
        <Box
          position="absolute"
          zIndex="2"
          bg="green.400"
          top={top}
          left={left}
          rounded="full"
          py={py}
          px={px}
        >
          <Text color="white" fontSize="3xl" fontWeight="bold">
            ✓
          </Text>
        </Box>
      );
    }

    return (
      <Box
        position="absolute"
        zIndex="2"
        top={top}
        left={left}
        py={py}
        px={px}
        bg={isCurrentLesson || completed ? constants.zipSchoolGold : 'gray.300'}
        rounded="full"
      >
        <Text fontSize="lg" fontWeight="black">
          {index + 1}
        </Text>
      </Box>
    );
  };

  const renderDurationBadge = () => (
    <Box
      className={isCurrentLesson || completed ? 'backdrop-frost' : ''}
      bg={!isCurrentLesson && !completed ? 'gray.300' : ''}
      position="absolute"
      zIndex="2"
      py={1}
      px={2}
      top={2}
      right={2}
      rounded="full"
    >
      <Text
        fontSize={['xs', 'sm']}
        color={mode === constants.MODES.DETAILS && !unlocked ? 'black' : 'white'}
      >
        {getBadgeText()}
      </Text>
    </Box>
  );

  return (
    <Flex
      key={campaignId || id}
      style={style}
      position="relative"
      pr={mode === constants.MODES.DETAILS ? 0 : 5}
      opacity={mode !== constants.MODES.DETAILS || isCurrentLesson || completed ? 1 : 0.75}
      onClick={onTapAction}
    >
      {/* Badge */}
      {mode === constants.MODES.DETAILS && (
        <>
          {renderItemNumber()}
          {renderDurationBadge()}
        </>
      )}

      {/* Pointer Finger */}
      {renderPointerFinger()}

      {/* Card Title Badge */}
      {mode !== constants.MODES.DETAILS && renderTitle()}

      {/* Card Image */}
      <Image
        w="100%"
        h="100%"
        border={isCurrentLesson ? `4px solid ${constants.zipSchoolGold}` : ''}
        rounded="lg"
        src={posterUrl}
        objectFit="cover"
      />
    </Flex>
  );
};

export default memo(ClassListItemNative);
